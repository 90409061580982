import React, { useState, useEffect } from 'react';
import moment from 'moment';

const ReservationForm = () => {
  const [date, setDate] = useState('');
  const [group, setGroup] = useState('');
  const [availableTimeslots, setAvailableTimeslots] = useState([]);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);

  // Funkcija koja se poziva kad se promijeni datum ili grupa
  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Dohvaćanje dostupnih termina za odabranu grupu i datum
    const response = await fetch(`/api/groups/${group}/available-timeslots?date=${date}`);
    const data = await response.json();
    setAvailableTimeslots(data.availableTimeslots);
  };

  // Funkcija koja se poziva kad korisnik odabere željeni termin
  const handleTimeslotSelect = (timeslot) => {
    setSelectedTimeslot(timeslot);
  };

  // Funkcija koja se poziva kad se promijeni datum ili grupa
  useEffect(() => {
    setSelectedTimeslot(null);
  }, [date, group]);

  // Funkcija koja se poziva kad korisnik potvrdi rezervaciju
  const handleReservationSubmit = async () => {
    // Slanje podataka o rezervaciji na backend
    const response = await fetch('/api/reservations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        timeslotId: selectedTimeslot.id,
        groupId: group,
      }),
    });

    // Provjera uspješnosti kreiranja rezervacije
    if (response.status === 201) {
      alert('Rezervacija uspješno kreirana!');
      setDate('');
      setGroup('');
      setSelectedTimeslot(null);
      setAvailableTimeslots([]);
    } else {
      alert('Greška prilikom kreiranja rezervacije.');
    }
  };

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <label htmlFor="date">Odaberi datum:</label>
        <input
          type="date"
          id="date"
          name="date"
          value={date}
          onChange={(event) => setDate(event.target.value)}
        />
        <label htmlFor="group">Odaberi grupu:</label>
        <select id="group" name="group" value={group} onChange={(event) => setGroup(event.target.value)}>
          <option value="">Odaberi grupu</option>
          <option value="1">Grupa 1</option>
          <option value="2">Grupa 2</option>
          <option value="3">Grupa 3</option>
        </select>
        <button type="submit">Traži termine</button>
      </form>

      {availableTimeslots.length > 0 ? (
        <div>
          <h2>Dostupni termini:</h2>
          <ul>
            {availableTimeslots.map((timeslot) => (
              <li key={timeslot.id}>
                <span>{moment(timeslot.start).format('HH:mm')} - {moment(timeslot.end).format('HH:mm')}</span>
                <button onClick={() => handleTimeslotSelect(timeslot)}>Odaberi</button>
              </li>
              ))}
            </ul>
          </div>
              
              ) : (
                
        <p>Nema dostupnih termina za odabranu grupu i datum.</p>
          )}
      </div>
  )
}

export default ReservationForm;