import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function BarChartPosjete({ data, handleBusiestDay }) {
  const [groupedData, setGroupedData] = useState([]);
  const [busiestDay, setBusiestDay] = useState({ date: '', total: 0 });

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      setGroupedData([]);
      return;
    }
  
    const groupedAttendance = {};
  
    data.forEach(attendance => {
      const date = new Date(attendance.timeIn);
      const day = date.toLocaleDateString();
      // console.log(attendance?.gymVisited?.naziv)
      const gym = attendance?.gymVisited?.naziv;
  
      if (!groupedAttendance[day]) {
        groupedAttendance[day] = {};
      }
  
      if (!groupedAttendance[day][gym]) {
        groupedAttendance[day][gym] = 0;
      }
  
      groupedAttendance[day][gym]++;
    });
  
  
    const chartData = [];
  
    for (const [day, gyms] of Object.entries(groupedAttendance)) {
      const dayData = { date: day.split('/').reverse().join('-') };
  
      for (const [gym, count] of Object.entries(gyms)) {
        dayData[gym] = count;
      }
  
      chartData.push(dayData);
    }
  
    setGroupedData(chartData);
  }, [data]);
  
  useEffect(() => {
    const busiestDay = groupedData.reduce(
      (acc, curr) => {
        let currTotal = 0;
        Object.values(curr).forEach(val => {
          if (typeof val === 'number') {
            currTotal += val;
          }
        });
  
        if (currTotal > acc.total) {
          return {
            date: curr.date,
            total: currTotal,
          };
        } else {
          return acc;
        }
      },
      { date: '', total: 0 }
    );
  
    setBusiestDay(busiestDay);
  
    const dateObj = new Date(busiestDay.date);
    const weekday = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
    handleBusiestDay({ weekday, busiestDay });
  }, [groupedData]);
  
// console.log(data)

  // console.log(busiestDay)
  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <BarChart
        data={groupedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        {Object.keys(groupedData[0] || {})
          .filter(key => key !== 'date')
          .map(key => (
            <Bar
              key={key}
              dataKey={key}
              stackId='a'
              name={key}
              fill={key === busiestDay.date ? '#ff7300' : '#8884d8'}
            />
          ))}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartPosjete;
