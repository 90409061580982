import React,{ useState, useEffect,useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
// import Header from "../components/Header";
// import { getUsers } from "../../api/userApi";
// import { getPaket } from "../../api/paketApi";
// import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import AddTaskIcon from '@mui/icons-material/AddTask';
// import { Link } from 'react-router-dom';
// import RemainingDays from "../components/RemainingDays";
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';

// import ErrorIcon from '@mui/icons-material/Error';
import { StartDateConverting } from "./DateConverting";

const TroskoviIzvjestajKomponenta = ({data}) => {

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);

  const handleExportToExcel = () => {
    const exportData = filteredData.map((item) => ({
      naziv: item.naziv,
      datum: item.datum,
      iznos: `${item.iznos} KM`,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Troskovi');
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, 'troskovi.xlsx');
  };
  
  

  useEffect(() => {
    const results = data.filter(
     
      (d) =>
        d.naziv.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredData(results);
  }, [data, searchText]);

  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }


const imeRender = (cell) => {
  return (
    <p direction="row" spacing={1}>
      {`${cell.row.naziv}`}
    </p>
  );
};

const iznosRender = (cell)=>{
    // console.log(cell.row.amount)
  return( 
    <p direction="row" spacing={1}>
      {`${cell.row.iznos} KM`}
  </p>
  )
}
//Kalkulacija broj dana se obracunava u kompomenti RemainigDays
const datumKreiranja = (cell)=>{
  // const datumUplate = new Date(cell.row.date)

return( 
  <p direction="row" spacing={1}>
    <StartDateConverting date={cell.row?.datum}/>
</p>
)
}



  const columns = [

    { field: 'naziv', headerName: 'Naziv troska', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return imeRender(params);
    }
    },
  

    { field: 'iznos', headerName: 'Iznos', 
    renderCell: (params) => {
        return iznosRender(params);
    }
    },
    { field: 'datum', headerName: 'Datum kreiranja', 
    renderCell: (params) => {
        return datumKreiranja(params);
    }
    },
    
  ];
  
  return (
    <Box m="px">
      <Box
        m="0px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
<Box display="flex" justifyContent="space-between">
<Box marginTop="50px" marginRight="10px">
    <TextField
      label="Trazi"
      variant="outlined"
      value={searchText}
      onChange={handleSearch}
    />
  </Box>
  <Box marginTop="50px" marginRight="10px">
    <Button onClick={handleExportToExcel} variant="contained" color="secondary">
      Izvezi u Excel
    </Button>
  </Box>
</Box>

      {/* Render the DataGrid using filteredData instead of data */}
      <DataGrid
        checkboxSelection
        pageSize={9}
        rowsPerPageOptions={[5]}
        rows={filteredData}
        columns={columns}
        getRowId={(row) => row._id}
      />

      </Box>
    </Box>
  );
};

export default TroskoviIzvjestajKomponenta;
