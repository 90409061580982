
import React,{useState, useEffect} from 'react' 
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import EuroIcon from '@mui/icons-material/Euro';
import Pie from "../pie";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {InputLabel, Select,MenuItem} from '@mui/material';
import { getAllGyms } from '../../api/gymApi';
import { getAllPaymentByRange } from '../../api/paymentApi';
import { getPaket } from "../../api/paketApi";
// import IzvjestajChart from '../../components/LineChartIzvjestaj';
import BarChart3 from '../../components/BarChart3';
import { getAllCoststByRange } from '../../api/trosakApi';

const IzvjestajPrihodi = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

const defaultGym = JSON.parse(localStorage.getItem("defaultGym"));
const gymID = defaultGym;
  
const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
const [endDate, setEndDate] = useState(new Date());
const [gyms, setGyms] = useState([]);
const [gym, setGym] = useState(gymID || null); // postavite početnu vrijednost na null ako gymID nije definiran
const [payments, setPayments] = useState([]);  
const [prodatiPaketi, setProdatiPaketi] = useState([]); 
const [progressPaketi, setProgressPaketi] = useState(0);
const [prihodiPaketi, setPrihdiPaketi] = useState(0);  
const [totalCost, setTotalConst] = useState(0);
const [ukPrihodi, setPrihodi] = useState(0);  
const [ostaliPrihodi, setOstaliPrihodi] = useState([]);

// console.log(gym)
const handleChangeGym = (event) => {
  setGym(event.target.value);
};
// createdDate.setHours(0, 0, 0, 0);
startDate.setHours(0, 0, 0, 0);
endDate.setHours(23, 59, 59, 999)
useEffect(() => {
  async function fetchGym() {
    try {
      const fetchedGyms = await getAllGyms();
      const fetchedPaketi = await getPaket({});

      const fetchedCosts = await getAllCoststByRange({
        startDate,
        endDate
      });

      const fetchedPayments = await getAllPaymentByRange({
        startDate,
        endDate
      });

      // console.log(fetchedCosts);

      const filteredPaketi = fetchedPaketi.filter(paket => paket.treningProgram === true || paket.individualniTreningProgram === true );
      // console.log("filteredPaketi", filteredPaketi);
      // console.log("fetchedPayments", fetchedPayments);
      const filteredPayments = fetchedPayments.filter(payment => payment.status === 'completed');
      // console.log("filteredPayments",filteredPayments);

      // Pronalaženje plaćanja sa istim _id kao u filteredPaketi
      const paymentsSaIstimId = filteredPayments.filter(payment => {
        return filteredPaketi.some(paket => paket._id === payment.member?.user?.membership?.plan?._id);
      });

      // Izračunavanje prihoda od prodaje paketa
      const prihodiProgrami = paymentsSaIstimId.reduce((sum, paket) => sum + paket.amount, 0);

      // console.log(prihodiProgrami);
      // grupniTreninzi
      // treningProgram

      const prodatiPaketi = filteredPayments.map(payment => {
        // console.log(payment)
        return {
          id: payment._id,
          date: payment.date,
          amount: payment.amount,
          memberID: payment?.member?.user?._id,
          gymID: payment?.member?.user?.membership?.gym?._id,
          plan: payment.member?.user?.membership?.plan?.nazivPaketa
        }
      });

        // Filtriraj samo prodane pakete koji su prodani u zadnjih 30 dana
        const progressPaket = fetchedPayments.filter(payment => {
            const danPrije30Dana = new Date();
            danPrije30Dana.setDate(danPrije30Dana.getDate() - 30);
            const datumProdajePaketa = new Date(payment.date);
            return datumProdajePaketa >= danPrije30Dana && payment.opis === "clanarina";
          });
    // Izračunavanje prihoda od prodaje paketa
        const prihodi = prodatiPaketi.reduce((sum, paket) => sum + paket.amount, 0);
    // Izračunavanje ostale prihode
        // const filteredOstaliPrihodi = fetchedPayments.filter(payment => payment.opis !== 'clanarina');
        const prihodiOdOstalihPaketa = fetchedCosts.reduce((acc, curr) => acc + curr.iznos, 0);
          // console.log("prihodiOdOstalihPaketa",prihodiOdOstalihPaketa);
        setOstaliPrihodi(prihodiProgrami);
        setPrihodi(prihodi);
        setPrihdiPaketi(prihodi - prihodiProgrami)
        setProgressPaketi(progressPaket);
        setProdatiPaketi(prodatiPaketi);
        setPayments(fetchedPayments);
        setGyms(fetchedGyms);
        setTotalConst(prihodiOdOstalihPaketa);
    } catch (err) {
      console.error(err);
    }
  }
  fetchGym();
}, [endDate, startDate]);
  
// console.log(prodatiPaketi)
return (
  <Box m="20px">
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="IZVJESTAJ O PRIHODIMA"/>
      <Box>
        <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={gym}
          onChange={handleChangeGym}
        >
          {gyms?.map((e, key) => (
            <MenuItem key={key} value={e}>
              {e.naziv}
            </MenuItem>
          ))}
        </Select>
        
      </Box>
      <Box>
      <div>
      <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
             <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            showTimeSelect={false} // dodano
            showTimeSelectOnly={false} // dodano
            dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
            style={{ display: "inline-block", margin: "0 auto" }}
            />

            <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            showTimeSelect={false} // dodano
            showTimeSelectOnly={false} // dodano
            dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
            style={{ display: "inline-block", margin: "0 auto" }}
            />
 
    </div>
      </Box>
    </Box>
  

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={prodatiPaketi.length}
            subtitle="Broj prodatih paketa"
            progress="a"
            // increase={`+${progressPaketi.length}%`}
            icon={
              <Inventory2Icon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
              title={`${prihodiPaketi} KM`}
              subtitle="Prihodi od članarina"
              progress='a'
            //   increase={`+${progressPaketi.length}%`}
            icon={
              <EuroIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={ostaliPrihodi}
            subtitle="Prihodi od programa"
            progress="a"
            // increase="+5%"
            icon={
              <EuroIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
         <StatBox
            title={ukPrihodi}
            subtitle="Ukupni prihodi"
            progress="a"
            // increase="+5%"
            icon={
              <EuroIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
         <StatBox
            title={totalCost}
            subtitle="Troskovi"
            progress="a"
            // increase="+5%"
            icon={
              <EuroIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
         <StatBox
            title={ukPrihodi - totalCost}
            subtitle="Dobit"
            progress="a"
            // increase="+5%"
            icon={
              <EuroIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* ROW 2 */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Prihod od prodaje članarina po paketu
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                {prihodiPaketi + ostaliPrihodi} KM
              </Typography>
            </Box>
            {/* <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box> */}
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <BarChart3 data={payments} startDate={startDate} endDate={endDate}/>
          </Box>
        </Box>

        <Box
          gridColumn="span 4"
          gridRow="span 4"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
        <Pie data={prodatiPaketi}/>
        </Box>

        {/* <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
        
        </Box> */}
      

        {/* ROW 3 */}
      </Box>
      <p>
      {/* Ukupni prihod teretane u određenom periodu
      Prihod ostvaren kroz članarine (razvrstano po vrsti članarina ako postoji više različitih vrsta)
      Prihod ostvaren kroz prodaju dodatne opreme (ako postoji)
      Prihod ostvaren kroz prodaju napitaka i drugih proizvoda (ako postoji)
      Prihod ostvaren kroz iznajmljivanje prostora (ako postoji)
      Ukupni prihod po danu/tjednu/mjesecu/godini */}
      </p>
    </Box>
  );
};

export default IzvjestajPrihodi;