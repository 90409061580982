import axios from 'axios';
import ConfigApp from '../config/ConfigApp';

const baseUrl = `${ConfigApp.URL}/publicapi`;

export const getAllReservation = async () => {
  const response = await axios.get(`${baseUrl}/reservations`);
  return response.data;
};

export const getAllGroups = async () => {
    const response = await axios.get(`${baseUrl}/group`);
    return response.data;
  };