import React,{useEffect, useState} from "react";
import StatBox from "../../components/StatBox";


const BrojClanova = (clanovi) =>{

const brojclanova = clanovi?.clanovi?.length;
// const [activeMembers, setActiveMember] = useState([]);
const [uposlenici, setUposlenici] = useState([]);

useEffect(()=>{
    // let activeMembers = [];
    let uposlenici = []
;
    
    for (const member of clanovi.clanovi) {
        if (member.user.membership.plan.nazivPaketa.bs === "UPOSLENICI") {
            uposlenici.push(member);
            } else {
            //   existingMembers++;
            }
    }

    // setActiveMember(activeMembers);
    setUposlenici(uposlenici);
},[])
    return(
        <>
         <StatBox
           title={brojclanova}
           title2={`${uposlenici.length} uposlenika`}
            subtitle="Broj članova"
            progress="a"
          />
        
        </>
    )
}

export default BrojClanova;

