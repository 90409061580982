import React, { useState, useEffect,useContext } from 'react';
import { GymContext } from "../../theme";
import { makeStyles } from '@mui/styles';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { getAllExerciseByGym } from '../../api/exerciseApi';
import Modal from '@material-ui/core/Modal';

// import Autocomplete from '@mui/material/Autocomplete';
import { createTreningProgram } from '../../api/treningProgramApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TailSpin} from 'react-loader-spinner';
import { getAllMeal } from '../../api/mealApi';
import Autocomplete from "@mui/material/Autocomplete";
import METTableModal from '../../components/MET';
import { getMembersByGym } from "../../api/memberApi";
import { getAllJelovnik } from '../../api/jelovnikApi';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // Dvije kolone
    gap: theme.spacing(2),
    // color: "white",
    // maxWidth: '800px',
    margin: '0 auto',
  },
  box2: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    
    // maxWidth: '800px',
    marginTop:20,
    margin: '0 auto',
  },
  inField:{
      backgroundColor:"#ffffff14",
      color: "white",
  },
  inFieldVj:{
    backgroundColor:"#ffffff14",
    width: 390,
    color: "white",
    marginRight:10
  }

}));

function NewTrainingProgram() {

  const { defaultGym  } = useContext(GymContext);
  const classes = useStyles();

  // const [name, setName] = useState('');
  // const [description, setDescription] = useState('');
  const [name, setName] = useState({ en: '', bs: '' });
  const [description, setDescription] = useState({ en: '', bs: '' });
  const [durationProgram, setDuration] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [intensity, setIntensity] = useState('');
  const [goal, setGoal] = useState('');
  const [level, setLevel] = useState('');
  const [jelovnici, setJelovnici] = useState([]);
  const [image, setImage] = useState(null);
  const [selectedJelovnik, setSelectedJelovnik] = useState([]);

  const [members, setMembers] = useState([]);
  const [member, setMember] = useState("");
  const [type, setType] = useState("");
  // const [nutrition, setNutrition] = useState([]);
  const [exerciseOptions, setExerciseOptions] = useState([]);
  const [mealKinds, setMealKinds] = useState([]);

  const [days, setDays] = useState([]);
  // const [dayOpis, setDayOpis] = useState('');
  const [loading, setLoading] = useState(true);
  const mealTypes = ['Doručak', 'Ručak', 'Večera', 'Međuobrok'];
  const [open, setOpen] = useState(false);

// console.log(selectedJelovnik)

const handleViewMet = () => {
  setOpen(true);
};

const handleCloseModal = () => {
  setOpen(false);
};

  useEffect(() => {
    async function fetchData() {
      try {
        await getAllMeal()
        .then((response) => {
          // console.log("response",response)
          setMealKinds(response);
            setLoading(false);
          });
        // setNutricionist(fetchedData);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(()=>{
    async function fachData(){
      const fetchedData = await getAllExerciseByGym(defaultGym._id);
      setExerciseOptions(fetchedData)
  
    }
    fachData()
  },[defaultGym._id])

  useEffect(() => {
    async function fetchMembers() {
      try {
        await getMembersByGym(defaultGym._id)
        .then((response) => {
          setMembers(response);
          // console.log("response",response)
          // setLoading(false);
        });
        // setMembership(fetchedMembership);
      } catch (error) {
        console.error(error);
      }
    }
    if(type === 'individual'){
      fetchMembers();
    }
  }, [defaultGym._id, type]);

  useEffect(() => {
    async function fetchData() {
      const fetchedData = await getAllJelovnik();
      // console.log(fetchedData)
      setJelovnici(fetchedData.jelovnici);
      // setIsLoaded(true);
    }
    fetchData();
  }, []);


  const handleAddMember = (event) => {
    setMember(event.target.value);
  };

  // console.log("exerciseOptions", exerciseOptions)

  //nova verzija sa prevodom
  const handleDayOpisChange = (dayIndex, language, value) => {
    const newDays = [...days];
    newDays[dayIndex].dayOpis[language] = value;
    setDays(newDays);
  };
  
  //stara verzija bez prevoda
  // const handleDayOpisChange = (dayIndex, value) => {
  //   const newDays = [...days];
  //   newDays[dayIndex].dayOpis = value;
  //   setDays(newDays);
  // };

  const handleAddExercise = (dayIndex) => {
    // console.log("dayIndex", dayIndex)
    const newDays = [...days];
    // console.log("newDays",newDays)
    const exercises = newDays[dayIndex].exercises;
    exercises.push({ exercise: '', exerciseId: '',sets: 0, repetitions: [], rest: [], heavy:[] });
    newDays[dayIndex].exercises = exercises;
    setDays(newDays);
  };

 const handleMealChange = (dayIndex, mealIndex, mealKind) => {
  const newDays = [...days];
  const meal = newDays[dayIndex].meals[mealIndex];
  meal.mealKind = mealKinds.find((m) => m.name === mealKind);
  if (meal.mealKind) {
    let sumGrams = 0;
    meal.mealKind.foods.map((e)=>sumGrams += e.grams)
    meal.grams = sumGrams;
    meal.proteins = meal.mealKind.proteins;
    meal.carbs = meal.mealKind.carbs;
    meal.fats = meal.mealKind.fats;
  }
  newDays[dayIndex].meals[mealIndex] = meal;
  setDays(newDays);
};

  const handleExerciseRemove = (dayIndex, exerciseIndex) => {
    const newDays = [...days];
    const exercises = newDays[dayIndex].exercises.filter((_, index) => index !== exerciseIndex);
    newDays[dayIndex].exercises = exercises;
    setDays(newDays);
  };

  // const handleAddMeal = (dayIndex) => {
  //   const newDays = [...days];
  //   const meals = newDays[dayIndex].meals;
  //   meals.push({ 
  //     description:mealKinds[0].description,
  //     fiber:mealKinds[0].fiber,
  //     name:mealKinds[0].name,
  //     time:mealKinds[0].time,
  //     unsaturatedFatt:mealKinds[0].unsaturatedFatt,

  //     type: mealTypes[0], 
  //     id:mealKinds[0]._id,
  //     proteins: 0, 
  //     carbs: 0, 
  //     fats: 0,
  //     mealKind: mealKinds[0].name // set default meal kind
  //   });
  //   newDays[dayIndex].meals = meals;
  //   setDays(newDays);
  // };
    
  const handleMealTypeChange = (dayIndex, mealIndex, type) => {
    const newDays = [...days];
    newDays[dayIndex].meals[mealIndex].type = type;
    setDays(newDays);
  };


  //nova verzija sa prevedom
  const handleAddDay = () => {
    const newDays = [...days];
    const day = newDays.length + 1;
  
    const exercises = [];
    const meals = [];
    newDays.push({ day, exercises, meals, dayOpis: { en: '', bs: '' } });
    setDays(newDays);
  };
  //stara verzija bez prevoda
  // const handleAddDay = () => {
  //   const newDays = [...days];
  //   const day = newDays.length + 1;

  //   const exercises = [
  //     // { exercise: '', sets: 0, repetitions: [], rest: [] }
  //   ];
  //   // console.log("exercises",exercises)
  //   const meals = [
  //     // { type: 'Doručak', grams: 0, proteins: 0, carbs: 0, fats: 0 },
  //     // { type: 'Ručak', grams: 0, proteins: 0, carbs: 0, fats: 0 },
  //     // { type: 'Večera', grams: 0, proteins: 0, carbs: 0, fats: 0},
  //     // { type: 'Međuobrok', grams: 0, proteins: 0, carbs: 0, fats: 0 },
  //     ];
  //     newDays.push({ day, exercises, meals });
  //     setDays(newDays);
  //     };
     
      const handleExerciseChange = (dayIndex, exerciseIndex, exerciseId, exercise) => {
      const newDays = [...days];
      // console.log("newDays",exercise)
      // newDays[dayIndex].exercises[exerciseIndex].exercise = exercise;
      newDays[dayIndex].exercises[exerciseIndex] = {
        ...newDays[dayIndex].exercises[exerciseIndex],
        exerciseId: exerciseId,
        exercise: exercise
      };
      setDays(newDays);
      };
      
      const handleTrajanjeVjezbeChange = (dayIndex, exerciseIndex, value) => {
        const newDays = [...days];
        newDays[dayIndex].exercises[exerciseIndex].duration = value;
        setDays(newDays);
      };
      const handleMETVjezbeChange = (dayIndex, exerciseIndex, value) => {
        const newDays = [...days];
        newDays[dayIndex].exercises[exerciseIndex].met = value;
        setDays(newDays);
      };

      const handleAddSet = (dayIndex, exerciseIndex) => {
      const newDays = [...days];
      const sets = newDays[dayIndex].exercises[exerciseIndex].sets;
      const repetitions = new Array(sets).fill({ reps: '', rest: '',heavy:'' });
      newDays[dayIndex].exercises[exerciseIndex].repetitions = repetitions;
      setDays(newDays);
      };
      
      const handleSetsChange = (dayIndex, exerciseIndex, sets) => {
      const newDays = [...days];
      const exercise = newDays[dayIndex].exercises[exerciseIndex];
      exercise.sets = sets;
      if (exercise.repetitions.length < sets) {
      for (let i = exercise.repetitions.length; i < sets; i++) {
      exercise.repetitions.push(0);
      exercise.rest.push(0);
      exercise.heavy.push(0);
      }
      } else if (exercise.repetitions.length > sets) {
      exercise.repetitions.splice(sets);
      exercise.rest.splice(sets);
      exercise.heavy.splice(sets);
      }
      newDays[dayIndex].exercises[exerciseIndex] = exercise;
      setDays(newDays);
      };
      
      const handleRepetitionsChange = (
      dayIndex,
      exerciseIndex,
      setIndex,
      repetitions
      ) => {
      const newDays = [...days];
      newDays[dayIndex].exercises[exerciseIndex].repetitions[setIndex] =
      repetitions;
      setDays(newDays);
      };
      
      const handleRestChange = (dayIndex, exerciseIndex, setIndex, rest) => {
      const newDays = [...days];
      newDays[dayIndex].exercises[exerciseIndex].rest[setIndex] = rest;
      setDays(newDays);
      };
      
      const handleHeavyChange = (dayIndex, exerciseIndex, setIndex, heavy) => {
        const newDays = [...days];
        newDays[dayIndex].exercises[exerciseIndex].heavy[setIndex] = heavy;
        setDays(newDays);
        };
        
     
      // console.log("days",days)
      // const handleImageUpload = (event) => {
      //   const file = event.target.files[0];
      //   setImage(file);
      // };
      const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

      const handleImageUpload = async (event) => {
        const imageFile = event.target.files[0];
        
        if (imageFile) {
          // Proverite veličinu slike
          if (imageFile.size > MAX_IMAGE_SIZE) {
            alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
            event.target.value = null; // Poništite odabir slike
            return;
          }
      
          setImage(imageFile);
          // setPendingImage("1");
        }
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        // Ovdje možete izvršiti logiku za spremanje novog programa treninga
        // Na primjer, pozvati API endpoint za stvaranje novog programa
    
        const newProgram = {
          name: JSON.stringify(name),
          description: JSON.stringify(description),
          // name: name,
          // description: description,
          duration: durationProgram,
          intensity: intensity,
          goal: goal,
          level: level,
          type: type,
          // jelovnik: selectedJelovnik,
          jelovnik: selectedJelovnik.map(function(objekat) {
            return objekat._id;
        }),
          gym: defaultGym._id,
          days: days.map((day) => {
            return {
              day: day.day,
              dayOpis: day.dayOpis,
              exercises: day.exercises.map((exercise) => {
                return {
                  exerciseId: exercise.exerciseId,
                  sets: exercise.sets,
                  repetitions: exercise.repetitions,
                  duration: exercise.duration,
                  met: exercise.met,
                  heavy: exercise.heavy,
                  rest: exercise.rest,
                };
              }),
              meals: day.meals.map((meal) => {
                return {
                  type: meal.type,
                  mealKind: meal.mealKind.name,
                  grams: meal.grams,
                  proteins: meal.proteins,
                  carbs: meal.carbs,
                  fats: meal.fats,
                  id: meal.mealKind._id,
                  description: meal.mealKind.description,
                  fiber: meal.mealKind.fiber,
                  name: meal.mealKind.name,
                  time: meal.mealKind.time,
                  unsaturatedFatt: meal.mealKind.unsaturatedFatt,
                };
              }),
            };
          }),
        };

        const formData = new FormData();
        formData.append('name', newProgram.name);
        formData.append('description', newProgram.description);
        formData.append('duration', newProgram.duration);
        formData.append('intensity', newProgram.intensity);
        formData.append('goal', newProgram.goal);
        formData.append('level', newProgram.level);
        formData.append('type', newProgram.type);
        formData.append('gym', newProgram.gym);
        // formData.append('jelovnik', newProgram.jelovnik);
        formData.append('jelovnik', JSON.stringify(newProgram.jelovnik)); 
        // Dodavanje dana, vježbi i obroka
        newProgram.days.forEach((day, dayIndex) => {
          formData.append(`days[${dayIndex}][day]`, day.day);
          // formData.append(`days[${dayIndex}][dayOpis]`, day.dayOpis);
          formData.append(`days[${dayIndex}][dayOpis]`, JSON.stringify(day.dayOpis));

        
          day.exercises.forEach((exercise, exerciseIndex) => {
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][exerciseId]`, exercise.exerciseId);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][sets]`, exercise.sets);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][repetitions]`, exercise.repetitions);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][duration]`, exercise.duration);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][met]`, exercise.met);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][heavy]`, exercise.heavy);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][rest]`, exercise.rest);
          });
        
          day.meals.forEach((meal, mealIndex) => {
            formData.append(`days[${dayIndex}][meals][${mealIndex}][type]`, meal.type);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][mealKind]`, meal.mealKind);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][grams]`, meal.grams);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][proteins]`, meal.proteins);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][carbs]`, meal.carbs);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][fats]`, meal.fats);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][id]`, meal.id);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][description]`, meal.description);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][fiber]`, meal.fiber);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][name]`, meal.name);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][time]`, meal.time);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][unsaturatedFatt]`, meal.unsaturatedFatt);
          });
        });
        
        // Dodavanje slike
        formData.append('image', image);

        // Kreiranje FormData objekta
        // const formData = new FormData();
        // formData.append("image", image); // Dodavanje slike
  
      
        // // Pretvaranje newProgram objekta u JSON i dodavanje kao string u FormData
        // formData.append("newProgram", JSON.stringify(newProgram));
  
      
//  console.log("formData",formData)
//  console.log("program",newProgram)
        // Poziv API endpointa za stvaranje novog programa treninga
        try{
    
          const res = await createTreningProgram(formData)
          // console.log(res)
          if(res.success){
            toast.success('Uspješno ste kreirali program.');
          }
        }catch(err){
          toast.error('Došlo je do greške prilikom kreiranja programa.');
          console.log(err)
        }
      
    
        // Resetiranje polja forme nakon spremanja
        // setName('');
        // setDescription('');
        // setDuration('');
        // setImage('');
        // setStartDate('');
        // setEndDate('');
        // setIntensity('');
        // setGoal('');
        // setLevel('');
        // setType('');
    
    
      };


      return (
        <>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <TailSpin height={80} width={80} />
              </div>
            ) : (
        <form  onSubmit={handleSubmit}>
           <Box  style={{padding:20}}>
        <Box className={classes.form}>
          <Header title="Novi program" subtitle="" />
          <ToastContainer />
        </Box>
        <Box className={classes.form}>
        
      {/* <TextField
        label="Naziv programa"
        className={classes.inField}
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      /> */}
       <TextField
              label="Naziv programa (Bosanski)"
              className={classes.inField}
              value={name.bs}
              onChange={(event) => setName({ ...name, bs: event.target.value })}
              variant="outlined"
              InputProps={{ style: { color: '#aeaeae' } }}
              InputLabelProps={{ style: { color: '#aeaeae' } }}
              required
            />
     
      {/* <TextField
        label="Opis programa"
        multiline
        rows={2}
        className={classes.inField}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      /> */}

    <TextField
        label="Trajanje programa (npr 30 dana)"
        className={classes.inField}
        type="number"
        value={durationProgram}
        onChange={(e) => setDuration(e.target.value)}
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      />

       
  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Intenzitet</InputLabel>
    <Select
      value={intensity}
      variant="outlined"
      className={classes.inField}
      onChange={(e) => setIntensity(e.target.value)}
      required
      style={{ color: 'white' }}
      // InputProps={{ style: { color: '#aeaeae' } }}
      // InputLabelProps={{ style: { color: '#aeaeae' } }}
    >
      <MenuItem value="Nisko">Nisko</MenuItem>
      <MenuItem value="Srednje">Srednje</MenuItem>
      <MenuItem value="Visoko">Visoko</MenuItem>
    </Select>
  </FormControl>
  {/* <TextField
    label="Početni datum"
    className={classes.inField}
    type="date"
    value={startDate}
    onChange={(e) => setStartDate(e.target.value)}
    required
    InputProps={{ style: { color: '#aeaeae' } }}
    InputLabelProps={{ style: { color: '#aeaeae' } }}
  />

  <TextField
    label="Završni datum"
    className={classes.inField}
    type="date"
    value={endDate}
    onChange={(e) => setEndDate(e.target.value)}
    required
    InputProps={{ style: { color: '#aeaeae' } }}
    InputLabelProps={{ style: { color: '#aeaeae' } }}
  /> */}
{/* 
 <TextField
        label="Slika"
        className={classes.inField}
        value={image}
        onChange={(e) => setImage(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      /> */}
      <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />
  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Razina</InputLabel>
    <Select
     className={classes.inField}
      value={level}
      onChange={(e) => setLevel(e.target.value)}
      required
      variant="outlined"
      style={{ color: 'white' }}
      // InputProps={{ style: { color: '#aeaeae' } }}
      // InputLabelProps={{ style: { color: '#aeaeae' } }}
    >

      <MenuItem value="pocetni">Pocetni</MenuItem>
      <MenuItem value="srednji">Srednji</MenuItem>
      <MenuItem value="napredni">Napredni</MenuItem>
      <MenuItem value="elite">Elite</MenuItem>
    </Select>
  </FormControl>

  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Cilj</InputLabel>
    <Select
     className={classes.inField}
      value={goal}
      onChange={(e) => setGoal(e.target.value)}
      required
      variant="outlined"
      style={{ color: 'white' }}
    >
      <MenuItem value="fatloss">Gubitak masti</MenuItem>
      <MenuItem value="musclebuilding">Izgradnja mišića</MenuItem>
      <MenuItem value="transformation">Transformacija</MenuItem>
      <MenuItem value="strength">Snaga</MenuItem>
      <MenuItem value="endurance">Izdržljivost</MenuItem>
      <MenuItem value="flexibility">Fleksibilnost</MenuItem>
    </Select>
  </FormControl>
  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Tip programa</InputLabel>
    <Select
     className={classes.inField}
      value={type}
      onChange={(e) => setType(e.target.value)}
      required
      variant="outlined"
      style={{ color: 'white' }}
    >
      <MenuItem value="group">Grupni</MenuItem>
      <MenuItem value="individual">Individialni</MenuItem>
    </Select>
  </FormControl>
  <TextField
              label="Opis programa (Bosanski)"
              multiline
              rows={8}
              className={classes.inField}
              value={description.bs}
              onChange={(event) => setDescription({ ...description, bs: event.target.value })}
              variant="outlined"
              InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
              required
            />
  {/* <TextField
        label="Opis programa"
        multiline
        rows={8}
        className={classes.inField}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      /> */}
  {type === "individual" ?
  <>
       <InputLabel id="demo-simple-select-label">Odaberi paket</InputLabel>
       <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={member}
           label="Paket"
           onChange={handleAddMember}
       >
         {members?.map((e, key)=>{
           return <MenuItem key={key} value={e}>{e?.firstName} {e?.lastName}</MenuItem>
         })
         }
        
       </Select>
       </>
        :""
      }
    

    <Autocomplete
      multiple
      className={classes.inField}
      options={jelovnici}
      getOptionLabel={(option) => option.name.bs}
      value={selectedJelovnik}
      onChange={(event, newValue) => {
        setSelectedJelovnik(newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Prijedlog jelovnika" variant="outlined" />
      )}
    />
  </Box>
  <Header subtitle="Engleski jezik" />
  <Box className={classes.form}>
   
  
              <TextField
                label="Naziv programa (Engleski)"
                className={classes.inField}
                value={name.en}
                onChange={(event) => setName({ ...name, en: event.target.value })}
                variant="outlined"
                // required
              />
           
    
              <TextField
                label="Opis (Engleski)"
                className={classes.inField}
                multiline
                rows={4}
                value={description.en}
                onChange={(event) => setDescription({ ...description, en: event.target.value })}
                variant="outlined"
                // required
              />
              </Box>
  </Box>
      <div style={{ margin: 20 }}>
      <Typography variant="h6">Sedmični plan treninga i prehrane</Typography>
      <Button
      type="button"
      variant="contained"
      color="primary"
      style={{ marginBottom: 10 }}
      onClick={handleAddDay}
      >
      Dodaj dan
      </Button>
      <Button
      type="button"
      variant="contained"
      color="secundary"
      style={{ marginBottom: 10, marginLeft:20 }}
      onClick={handleViewMet}
      >
      MET lista
      </Button>
      <Modal open={open} onClose={handleCloseModal}>
        <div style={{ width:"50%",position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: 20 }}>
          <h2 style={{color: "black"}}>Tablica vježbanja za MET (metabolički ekvivalent zadatka)</h2>
          {/* Ovdje možete prikazati tablicu s MET brojevima za različite vježbe */}
          <METTableModal/>
        </div>
      </Modal>
      {days.map((day, dayIndex) => (
      <div
      key={dayIndex}
      style={{
      marginBottom: 20,
      background: '#f5f5f5',
      padding: '10px',
      }}
      >
      <Typography
      variant="subtitle1"
      style={{ marginBottom: 5, color: 'black', fontSize: 20 }}
      >
      Dan {day.day}
      
              <TextField
          label="Bosanski"
          type="text"
          style={{ minWidth: 200, marginRight: 10 }}
          variant="outlined"
          required
          value={day.dayOpis.bs}
          onChange={(e) => handleDayOpisChange(dayIndex, 'bs', e.target.value)}
        />
        <TextField
          label="Engleski"
          type="text"
          style={{ minWidth: 200, marginRight: 10 }}
          variant="outlined"
          required
          value={day.dayOpis.en}
          onChange={(e) => handleDayOpisChange(dayIndex, 'en', e.target.value)}
        />

      {/* <TextField
        label="Bosanski"
        type="text"
        style={{ minWidth: 200, marginRight: 10 }}
        variant="outlined"
        required
        value={day.dayOpis}
        onChange={(e) => handleDayOpisChange(dayIndex, e.target.value)}
        // onChange={handleDayOpisChange}
      />
      <TextField
        label="Engleski"
        type="text"
        style={{ minWidth: 200, marginRight: 10 }}
        variant="outlined"
        required
        value={day.dayOpis}
        onChange={(e) => handleDayOpisChange(dayIndex, e.target.value)}
        // onChange={handleDayOpisChange}
      /> */}
      </Typography>


      <Button
      type="button"
      variant="contained"
      color="primary"
      style={{ marginBottom: 10 }}
      onClick={() => handleAddExercise(dayIndex)}
      >
      Dodaj vježbu
      </Button>
      {day.exercises.map((exercise, exerciseIndex) => (

     <div key={exerciseIndex} style={{ marginBottom: 10 }}>
     <Select
  label="Vježba"
  value={exercise.exerciseId}
  onChange={(e) => handleExerciseChange(dayIndex, exerciseIndex, e.target.value, e.currentTarget.getAttribute('data-exercise'))}
  style={{ minWidth: 400, marginRight: 10 }}
>
  {exerciseOptions.map((exerciseOption) => (
    <MenuItem key={exerciseOption._id} value={exerciseOption._id} data-exercise={exerciseOption.name.bs}>
       {exerciseOption.name.bs} {"--"} <span style={{ fontSize:10,backgroundColor: 'gray', padding:3, color:"white", borderRadius:10 }}>{" "} {exerciseOption.type}</span>
    </MenuItem>
  ))}
</Select>
     <TextField
       label="Broj serija"
       type="number"
       style={{ minWidth: 200, marginRight: 10 }}
       value={exercise.sets}
       onChange={(e) => handleSetsChange(dayIndex, exerciseIndex, e.target.value)}
     />
     <TextField
       label="Trajanje vježbe (00:00:00)h:m:s"
       type="text"
       style={{ minWidth: 200, marginRight: 10 }}
       value={exercise.duration || "00:00:00"}
       onChange={(e) => handleTrajanjeVjezbeChange(dayIndex, exerciseIndex, e.target.value)}
     />
     <TextField
       label="MET"
       type="text"
       style={{ minWidth: 200, marginRight: 10 }}
       value={exercise.met}
       onChange={(e) => handleMETVjezbeChange(dayIndex, exerciseIndex, e.target.value)}
     />
     <Button
       type="button"
       variant="contained"
       color="secondary"
       style={{ display: "none"}}
       onClick={() => handleAddSet(dayIndex, exerciseIndex)}
     >
       Dodaj seriju
     </Button>
     {exercise.repetitions.map((repetition, setIndex) => (
       <div key={setIndex} style={{ marginBottom: 10 }}>
         <TextField
           label={`Ponavljanja - Serija ${setIndex + 1}`}
           type="number"
           style={{ minWidth: 200, marginRight: 10 }}
           value={repetition}
           onChange={(e) =>
             handleRepetitionsChange(dayIndex, exerciseIndex, setIndex, e.target.value)
           }
         />
         <TextField
           label="Opterećenje kg"
           type="number"
           style={{ minWidth: 200 }}
           value={exercise.heavy[setIndex]}
           onChange={(e) => handleHeavyChange(dayIndex, exerciseIndex, setIndex, e.target.value)}
         />
         <TextField
           label="Odmor (s)"
           type="number"
           style={{ minWidth: 200 }}
           value={exercise.rest[setIndex]}
           onChange={(e) => handleRestChange(dayIndex, exerciseIndex, setIndex, e.target.value)}
         />
         
       </div>
     ))}
     <Button
       type="button"
       variant="contained"
       color="secondary"
       style={{ marginLeft: 10 }}
       onClick={() => handleExerciseRemove(dayIndex, exerciseIndex)}
     >
       Ukloni vježbu
     </Button>
   </div>
      ))}
      {/* <Button
      type="button"
      variant="contained"
      color="primary"
      style={{ marginBottom: 10 }}
      onClick={() => handleAddMeal(dayIndex)}
      >
      Dodaj obrok
      </Button> */}
      {day.meals.map((meal, mealIndex) => (
        <>
  <div key={mealIndex} style={{ marginBottom: 10 }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Select
          label="Vrsta obroka"
          value={meal.type}
          onChange={(e) =>
            handleMealTypeChange(dayIndex, mealIndex, e.target.value)
          }
          style={{ minWidth: 200 }}
        >
          {mealTypes.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          label="Vrsta jela"
          value={meal.mealKind ? meal.mealKind.name : ""}
          onChange={(e) =>
            handleMealChange(dayIndex, mealIndex, e.target.value)
          }
          style={{ minWidth: 200 }}
        >
          {mealKinds.map((kind, index) => (
            <MenuItem key={index} value={kind.name}>
              {/* {console.log("kind", kind.type)} */}
              <>
              {kind.name}  <span style={{ fontSize:10,backgroundColor: 'gray', padding:3, color:"white", borderRadius:10 }}> {kind.type}</span>
              </>
             
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
          <span style={{color:"gray"}}>Grama:</span> {meal.grams?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Proteini (g):</span> {meal.proteins?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Kalorije (g):</span> {meal.mealKind.calories?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Ugljikohidrati (g):</span> {meal.carbs?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Masti (g):</span> {meal.fats?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Nezasicene m.(g):</span> {meal.mealKind.unsaturatedFatt?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Vlakna (g):</span> {meal.mealKind.fiber?.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  </div>
  </>
))}

</div>
))}
</div>
<Box className={classes.form}>
  <Grid container justify="right" style={{marginTop:15, marginLeft:10}} alignItems="right" spacing={2}>
    <Grid item>
    <Button type="submit"  variant="contained" color="secondary" onClick={handleSubmit}>
      Kreiraj program
    </Button>
  </Grid>
  </Grid>
  </Box>
</form>
  )}
</>
);
}

export default NewTrainingProgram;