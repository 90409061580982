import React from 'react';
import { useState } from 'react';
import { ChromePicker } from 'react-color';
import { Button } from '@mui/material';

function ColorPickerx(props) {
  const color = props.color;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    props.onColorChange(color.hex);
  };

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Odaberi boju
      </Button>
      {displayColorPicker && (
        <div style={{ position: 'absolute', zIndex: '2' }}>
          <div
            style={{
              position: 'fixed',
              top: '0px',
              right: '0px',
              bottom: '0px',
              left: '0px',
            }}
            onClick={handleClose}
          />
          <ChromePicker color={props.color} onChange={handleChange} />
        </div>
      )}
      <div style={{ marginTop: '10px', width: '100px', height: '100px', backgroundColor: color }}></div>
    </div>
  );
}

export default ColorPickerx;
