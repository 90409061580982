import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/note`;
// const baseUrl = 'http://localhost:3001/api/note';

export const createNote = async (newNote) => {
  const response = await axios.post(baseUrl, newNote);
  return response.data;
};

export const getNote = async () => {
  const response = await axios.get(baseUrl);
  // console.log(response)
  return response.data;
};

export const getNoteId = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

// export const createUser = async (newUser) => {
//   const response = await axios.post(baseUrl, newUser);
//   return response.data;
// };

// export const updateUser = async (id, updatedUser) => {
//   const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   return response.data;
// };

export const deleteOneNote = async (id, memId) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};