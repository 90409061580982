import React, {useState, useEffect, useContext} from 'react'
import { Box, Typography, useTheme, Button, TextField,InputLabel } from "@mui/material";
import { FormControl } from '@material-ui/core';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DatePicker from "react-datepicker";
// import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link,useNavigate } from 'react-router-dom';
import { getAllPosjeteByGymByRange,getAllPosjeteByGymAndDateRange } from "../../api/attendenceApi";
import StartDateAndTimeConverting from '../../components/StartDateAndTimeConverting';
import axios from 'axios';
// import { getGostMemberById } from '../../api/memberApi';
import { createOdjavuTripod, createPrijavuTripod, getAllTripodByUserAndDateRange, getAllPrijaveTripodByRange } from '../../api/tripodApi';
import { UserContext } from '../../context/UserProvider';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import {TailSpin} from 'react-loader-spinner';
// import { makeStyles } from '@mui/styles';

// const useStyles = makeStyles((theme) => ({
//   loadingIndicator: {
//     // position: 'fixed',
//     // top: '50%',
//     // left: '50%',
//     // transform: 'translate(-50%, -50%)',
//     // zIndex: 9999, // Postavite odgovarajući z-index da biste bili sigurni da je iznad ostalog sadržaja

//     // padding: '20px',
//     // borderRadius: '5px',
 
//   },
// }));

const CheckIn = () => {
  // const classes = useStyles();
  let gymId  = useParams();
  const { user} = useContext(UserContext);
  // console.log(user)
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate());
  yesterday.setHours(0, 0, 1, 0); // Postavi na 00:01
  
  const today = new Date();
  today.setHours(23, 59, 59, 999); // Postavi na 23:59:59.999
  
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);

  const [posjete, setPosjete] = useState([]);
  const [tripodPrijave, setTripodPrijave] = useState([]);
  console.log(posjete)
  const [isLoading, setIsLoading] = useState(false);
  const userID = user._id;

  const handlePrijaviClick = async () => {
    try {
           setIsLoading(true)
      // Pozivanje funkcije createPrijavuTripod i slanje podataka
      const response = await createPrijavuTripod({ userID });
      if (response.success === true) {
             setIsLoading(false)
        const pijavaData = {
          datumPrijave: response.vrijeme,
          user: userID
        };
        setTripodPrijave(prevState => [...prevState, pijavaData]);
        // console.log('Odgovor od servera:', response);
        toast.success('Uspješno ste otvorili tripod.');
      } else {
        toast.error(`Greška prilikom otvaranja tripoda`);
        // console.log('greska');
      }
      // Ovdje možete obraditi odgovor, prikazati poruku ili ažurirati stanje
    } catch (error) {
      // Obrada greške
      toast.error(`Greška prilikom otvaranja tripoda, ${error}`);
      console.error('Greška prilikom otvaranja tripoda:', error);
    }
  };

  const handleOdjaviClick = async () => {
    // Nakon toga, pozovite POST metodu i createPrijavuTripod
    // const requestData = {
    //   user: user._id,
    // };
    // const userID = user._id;
    // console.log(userID);
    try {
      // Pozivanje funkcije createPrijavuTripod i slanje podataka
      const response = await createOdjavuTripod();
    
      // Ovdje možete obraditi odgovor, prikazati poruku ili ažurirati stanje
      console.log('Odgovor od servera:', response);
    } catch (error) {
      // Obrada greške
      console.error('Greška prilikom prijave:', error);
    }

};

  // Pretpostavka: Dobili ste JWT token od gostujuće teretane
// const guestToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDQ1OTZkODFmMTJkOTE5YjNkNjNjNzUiLCJlbWFpbCI6ImFAYS5hIiwicm9sZSI6Im1lbWJlciIsImlhdCI6MTY5MDIzNDUwNH0.jTl-_7pfoh7WploGywUuhxZ5n3qw5itfTyY7pw-Ba5Y';

// Postavite URL gostujuće teretane
// const baseUrl = 'https://gym-elita-app-o44uu.ondigitalocean.app';

// ID korisnika koji želite dohvatiti
// const userGuestId = '64ed835e55761ed4077209dc';

// Postavite zaglavlje zahtjeva s JWT tokenom
// const headers = {
//   Authorization: `Bearer ${guestToken}`
// };


  useEffect(() => {

    async function fetchPosjete() {
      try {
        const fetchedPosjete = await getAllPosjeteByGymAndDateRange(gymId,startDate,endDate);

        // console.log(fetchedPosjete)
        // const userGuestId = "64f0587affebc5cbb5d2b876";
        // const response = await getGostMemberById({userGuestId});
        // const response = await axios.get(`${baseUrl}/api/users/guest/${userGuestId}`, { headers });


        // console.log("fetchedPosjete",fetchedPosjete)
        
        const filteredPosjete = fetchedPosjete.filter(posjeta => posjeta.gymVisited !== null);
        // Filtriraj posjete i izbaci one s user === null
        const filteredPosjeteUser = filteredPosjete.filter((posjeta) => posjeta.user !== null);

// console.log("filteredPosjete",filteredPosjeteUser);
        setPosjete(filteredPosjeteUser);
        // console.log("response",response)
        // setPosjete(fetchedPosjete);
      } catch (error) {
        console.error(error);
      }
    }

    
    async function fetchTripodPrijave() {
      try {
        const fetchedTripodPrijave = await getAllTripodByUserAndDateRange(
          userID,
         startDate,
          endDate
        );
// console.log("filteredPosjete",filteredPosjeteUser);
        setTripodPrijave(fetchedTripodPrijave);
        // console.log("response",response)
        // setPosjete(fetchedPosjete);
      } catch (error) {
        console.error(error);
      }
    }

    fetchPosjete();
    fetchTripodPrijave();
  }, [endDate, startDate]);

  console.log(tripodPrijave);
  // console.log(startDate)
  
  const userRender = (cell)=>{
    return( 

      <p direction="row" spacing={1}>
        {/* {console.log(cell.row)} */}
        {cell.row.user?.firstName +" "+cell.row.user?.lastName}
    </p>
    )
  }
  const poslovnicaRender = (cell)=>{
    return( 
      <p direction="row" spacing={1}>
        {cell.row?.gymVisited?.naziv}
    </p>
    )
  }
  const statusRender = (cell)=>{
    return( 
      <p direction="row" spacing={1}>
        {cell.row.timeOut !== null ? "Odjavljen" : "Prijavljen"}
    </p>
    )
  }
  const fotografijaRender = (cell) => {

    if (!cell?.row || !cell.row.user) {
      return null; // Return null or a placeholder value if user data is undefined
    }
  
    // const handleClick = () => {
    //   navigate(`/users/${cell.row.user._id}`, { state: cell.row });
    // };
    
    return (
      <>
        <img 
          alt="Slika nije pronadjena"
          // src={`${imageUrl}/${cell.row.user.photo}` }
          src={cell.row.user.photo} 
          style={{width:"75%"}}
          // onClick={handleClick}
        />
      </>
    );
  };
 const vrijemePrijave = (cell)=>{
  
 return <StartDateAndTimeConverting datetime={cell.row?.timeIn}/>
  }
  const vrijemeOdjave = (cell)=>{
  
    return <StartDateAndTimeConverting datetime={cell.row?.timeOut}/>
     }
  
  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    { field: 'photo', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'user', headerName: 'Clan', 
    renderCell: (params) => {
        return userRender(params);
    }
    },
    { field: 'gymVisited', headerName: 'Poslovnica', 
    renderCell: (params) => {
        return poslovnicaRender(params);
    }
    },
    
    { field: 'timeIn', headerName: 'Vrijeme prijave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemePrijave(params);
    }
    },
  
    { field: 'timeOut', headerName: 'Vrijeme odjave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemeOdjave(params);
    }
    },
    { field: 'status', headerName: 'Status', 
    renderCell: (params) => {
        return statusRender(params);
    }
    },
  ];

  return (
    <Box m="20px">
        <ToastContainer />
      <Header
        title="CHECK-IN"
        // subtitle="List of Contacts for Future Reference"
      />
       <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/check-in/gost/posjete">
          <Button type="submit" color="secondary" variant="contained">
           Posjete iz drugih teretana
          </Button>
        </Link>

        <Link to="/check-in/gost">
          <Button type="submit" color="secondary" variant="contained" style={{marginLeft:20}}>
           Članovi koji su posjetili druge teretane
          </Button>
        </Link>
            </Box>
               {/**DUGME ZA OTVARANJE TRIPODA */}
            <Box display="flex" justifyContent='center' style={{marginLeft:-200}}>
              <Header
              title={tripodPrijave?.length}
            />
            </Box>
         
         
            <Box display="flex" justifyContent='center' >
       
               
            <Button
                type="submit"
                color="secondary"
                variant="contained"
                style={{ padding: 20, fontSize: 20 }}
                onClick={handlePrijaviClick}
              >
                ULAZ
            </Button>


  
          <Button type="submit" color="warning" variant="contained"  onClick={handleOdjaviClick} style={{marginLeft:100, marginRight:200, padding:20, fontSize:20}}>
           IZLAZ
          </Button>

            </Box>
         

            {/**KRAJ DUGME ZA OTVARANJE TRIPODA */}
            <FormControl  sx={{minWidth:"200px" }}>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        style={{ display: "inline-block", margin: "0 auto" }}
        />
      
      </FormControl>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={posjete}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default CheckIn;