import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/trening`;
// const baseUrl = 'http://localhost:3001/api/trening';


// export const getAllTreningPrograms = async () => {
//   const response = await axios.get(baseUrl);
//   return response.data;
// };

export const getAllTreningProgramsByGym = async (id) => {
  const response = await axios.get(`${baseUrl}/gym/${id}`);
  return response.data;
};
export const getTreningProgramById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createTreningProgram = async (newTreningProgram) => {
  const response = await axios.post(baseUrl, newTreningProgram);
  // console.log(newMembers)
  return response.data;
};

export const updateTreningProgramByID = async (id, updatedTreningProgram) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedTreningProgram);
  return response.data;
};

export const deleteTreningPrograms = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};

export const deleteMemberTreningPrograms = async (programId,memberId) => {
  const response = await axios.delete(`${baseUrl}/program/${programId}/member/${memberId}`);
  return response.data;
};
