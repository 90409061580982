import axios from 'axios';
// import ConfigApp from '../config/ConfigApp';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/guests`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/guests';

export const getGuests = async () => {
  const response = await axios.get(`${baseUrl}/gosti`);
  return response.data;
};

// export const getUserById = async (id) => {
//   const response = await axios.get(`${baseUrl}/${id}`);
//   return response.data;
// };

export const createGuestUserReservation = async (newGuest) => {
  const response = await axios.post(`${baseUrl}/reservations`,newGuest);
  return response.data;
};


export const updateGuestGroupReservation = async (guestId, selectedReservations) => {
  console.log(selectedReservations)
  const response = await axios.put(`${baseUrl}/reservations/guest/${guestId}`,selectedReservations);
  return response.data;
};



export const createUserGuest = async (newUser) => {
  const response = await axios.post(baseUrl, newUser,{
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',
     },
  });
  // console.log("newUser",newUser)
  return response.data;
};

export const updateUserGuest = async (id, paymentID) => {
  const response = await axios.put(`${baseUrl}/${id}`,paymentID);

  return response.data;
};

// export const deleteUser = async (id) => {
//   const response = await axios.delete(`${baseUrl}/${id}`);
//   return response.data;
// };


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }

// export const addReservationToUser = async (memberId, reservationId) => {
//     const response = await axios.put(`${baseUrl}/${memberId}`, reservationId);
//     // const response = await axios.post(baseUrl, newUser);
//     // console.log(response)
//     return response.data;
//   };
