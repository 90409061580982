import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/membership`;
const baseUrlPlan = `${ConfigApp.URL}/membership/plan`;

// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/membership';
// const baseUrlPlan = 'https://jellyfish-app-og32n.ondigitalocean.app/api/membership/plan';


export const getAllMembershipStatusByGym = async (id, status) => {
  // const response = await axios.get(baseUrl);
  // return response.data;
  // console.log(id, status)
  const gymID = id
  // console.log("gymIDs", gymID)
  const response = await axios.get(`${baseUrl}/gym/${gymID}/${status}`);
  return response.data;
};

export const getMembershipByRange = async ({startDate, endDate}) => {
  // const response = await axios.get(baseUrl);
  const response = await axios.get(`${baseUrl}/${startDate}/${endDate}`);
  return response.data;
};

export const getAllMemberships = async () => {
  // const response = await axios.get(baseUrl);
  // const response = await axios.get(baseUrl);
  const response = await axios.get(`${baseUrl}/gym/all/clanarina/prikaz/membership`); 
  return response.data;
};

export const getAllMembershipByGym = async (id) => {
  // const response = await axios.get(baseUrl);
  // return response.data;
  const gymID = id
  // console.log("gymIDs", gymID)
  const response = await axios.get(`${baseUrl}/gym/${gymID}`);
  return response.data;
};

export const getAllMembershipById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createMembership = async (newMembership) => {
  const response = await axios.post(baseUrl, newMembership);
  // console.log(newGym)
  return response.data;
};

export const updateMembershipPlan = async (id, updateMembership) => {
  //new plan
  //endDay
  //status
  const response = await axios.put(`${baseUrlPlan}/${id}`, updateMembership);
  //kreiramo novi note
  // console.log(id, updateMembership)
  return response.data;
};

export const updateMembership = async (id, updateMembership) => {
  
  // console.log(updateMembership)
  const response = await axios.put(`${baseUrl}/${id}`, updateMembership);
  return response.data;
};

export const updateMembershipDate = async (id, updateMembership) => {
  // console.log(updateMembership)
  const response = await axios.put(`${baseUrl}/date/${id}`, updateMembership);
  return response.data;
};

export const deleteMembership = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};

export const getAllMembershipNotesById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}/notes`);
  return response.data;
};
// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }