import React, {useState, useEffect,useContext} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { getPaketWeb } from "../../api/paketApi";
import CheckIcon from '@mui/icons-material/Check';
import {useNavigate} from 'react-router-dom';
import { UserContext } from '../../context/UserProvider';
// import { fetchProtectedData } from '../../api/fetchProtectedData';

const Paketi = () => {
  const theme = useTheme();
  const { user} = useContext(UserContext);
  const userRole = user?.role;
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [paketi, setPaketi] = useState([]);
  useEffect(() => {

    async function fetchPaket() {
      try {
        const fetchedUPaket = await getPaketWeb({});
        setPaketi(fetchedUPaket);
      } catch (error) {
        console.error(error);
      }
      // await fetchProtectedData();
    }

    fetchPaket()
    
  }, []);

  const grupnitreninziRender = (cell)=>{
     return( 
       <p direction="row" spacing={1}>
         {cell.row.grupniTreninzi ? <CheckIcon/> : " - "}
     </p>
     )
     }
     const treningProgramRender = (cell)=>{

       return( 
         <p direction="row" spacing={1}>
           {cell.row.treningProgram ? <CheckIcon/> : " - "}
       </p>
       )
       }
       const nazivPaketa = (cell)=>{
        const handleClick = () => {
          navigate(`/paket/${cell.row._id}/detalj`, { state: cell.row });
        };
        return( 
          <p direction="row" spacing={1}  onClick={handleClick}>
            {cell.row.nazivPaketa.bs || cell.row.nazivPaketa}
        </p>
        )
        }
        
        const brojDolazaka = (cell)=>{
            // console.log(cell.row.brojDolazaka)
          return( 
            <p direction="row" spacing={1}>
              {/* {cell.row.treningProgram ? <CheckIcon/> : " - "} */}
              {cell.row.brojDolazaka}
          </p>
          )
          }
  const columns = [
    { field: "_id", headerName: "ID", flex: 0.5 },
    { field: 'nazivPaketa', headerName: 'Naziv Paketa"', width: 300,cellClassName: "name-column--cell",
    renderCell: (params) => {
        return nazivPaketa(params);
    }
    },
    {
      field: "cijena",
      headerName: "Cijena",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "trajanje",
      headerName: "Trajanje paketa",
      flex: 1,
    },
    {
      field: "brojDolazaka",
      headerName: "Broj dolazaka",
      flex: 1,
      renderCell: (params) => {
        return brojDolazaka(params);
    }
    },
    {
      field: "brojDolazakaPoDanu",
      headerName: "Broj dnevnih dolazaka",
      flex: 1,
    },
    {
      field: "dostupnoVrijemePocetak",
      headerName: "Dostupno vrijeme početka",
      flex: 1,
    },
    {
      field: "dostupnoVrijemeKraj",
      headerName: "Dostupno vrijeme kraja",
      flex: 1,
    },
    { field: 'grupniTreninzi', headerName: 'Grupni Treninzi', 
    renderCell: (params) => {
        return grupnitreninziRender(params);
    }
    },
    { field: 'treningProgram', headerName: 'Trening Program', 
    renderCell: (params) => {
        return treningProgramRender(params);
    }
    }
  ];

  return (
    <Box m="20px">
      <Header
        title="PAKETI"
        // subtitle="List of Contacts for Future Reference"
      />
          {userRole === 'admin' && (
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/paketi/new">
          <Button type="submit" color="secondary" variant="contained">
            Novi paket
          </Button>
        </Link>
            </Box>
        )}
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={paketi}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
    </Box>
  );
};

export default Paketi;
