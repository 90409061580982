// imageUploadUtils.js

export const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

export const handleImageUpload = async (event, setImage) => {
  const imageFile = event.target.files[0];
  
  if (imageFile) {
    if (imageFile.size > MAX_IMAGE_SIZE) {
      alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
      event.target.value = null;
      return;
    }

    setImage(imageFile);
  }
};
