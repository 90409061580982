import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField,InputLabel,Select,MenuItem } from "@mui/material";
import { FormControl } from '@material-ui/core';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DatePicker from "react-datepicker";
// import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link,useNavigate } from 'react-router-dom';
import { getAllGuestPosjeteByGymByRange } from "../../api/attendenceApi";
import StartDateAndTimeConverting from '../../components/StartDateAndTimeConverting';
import { getAllGyms } from '../../api/gymApi';
import { getSettings } from '../../api/getSettingsApi';
import * as XLSX from 'xlsx';

const PrijaveDrugeTeretane = () => {
  let gymId  = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  // const currentYear = new Date().getFullYear();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate());
  yesterday.setHours(0, 0, 1, 0); // Postavi na 00:01
  
  const today = new Date();
  today.setHours(23, 59, 59, 999); // Postavi na 23:59:59.999
  
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  
    // const [gyms, setGyms] = useState([]);
    const [settingsGym, setSettingsGym] = useState([]);
    const [posjete, setPosjete] = useState([]);
    // const [gymsCombined, setGymsCombined] = useState([]);
    const [selectedGym, setSelectedGym] = useState(null);



  useEffect(() => {

    async function fetchPosjete() {
      try {
        
        const fetchedSettingsGym = await getSettings();
        // const fetchedGyms = await getAllGyms();
        const fetchedPosjete = await getAllGuestPosjeteByGymByRange(
          // gymId,
         {startDate,
          endDate}
        );
     
        setSettingsGym(fetchedSettingsGym);
           // Kreiraj novi niz kombiniranjem podataka iz obj1 i obj2
    // const combinedGyms = fetchedSettingsGym.map(item2 => {
    //   const matchingObj1 = fetchedGyms.find(item1 => item1._id === item2.guestGymId);
    //   return {
    //     gymId: item2.guestGymId,
    //     naziv: matchingObj1 ? matchingObj1.naziv : item2.guestNameGym,
    //   };
    // });

    // // Dodaj podatke iz obj1 koji nisu prisutni u obj2
    // fetchedGyms.forEach(item1 => {
    //   const foundGym = combinedGyms.find(item => item.gymId === item1._id);
    //   if (!foundGym) {
    //     combinedGyms.push({
    //       gymId: item1._id,
    //       naziv: item1.naziv,
    //     });
    //   }
    // });

    //Kombinuj sve posjete sa gym i postavi naziv u posjete
      
//   const combinedAttendances = fetchedPosjete.map(attendance => {
//     const gymId = attendance.gymVisited;
//     const gymInfo = gymsCombined.find(gym => gym.gymId === gymId);
    
//     if (gymInfo) {
//         return {
//             ...attendance,
//             naziv: gymInfo.naziv
//         };
//     }
    
//     return attendance;
// });
    // Postavi novi niz u state
//  console.log(combinedAttendances)
    // setPosjete(fetchedPosjete);
    const filteredPosjete = selectedGym
    ? fetchedPosjete.filter(attendance => attendance.gymVisited === selectedGym)
    : fetchedPosjete;
    //  console.log(filteredPosjete)
    setPosjete(filteredPosjete);
    // Postavi novi niz u state
    // setGymsCombined(combinedGyms);
    // setPosjete(combinedAttendances);

      } catch (error) {
        console.error(error);
      }
    }

    fetchPosjete()
    
  }, [endDate, selectedGym, startDate]);
//   console.log(posjete);

//   console.log("settingsGym",settingsGym);

//   const combinedAttendances = posjete.map(attendance => {
//     const gymId = attendance.gymVisited;
//     const gymInfo = gymsCombined.find(gym => gym.gymId === gymId);
    
//     if (gymInfo) {
//         return {
//             ...attendance,
//             naziv: gymInfo.naziv
//         };
//     }
    
//     return attendance;
// });
// console.log("combinedAttendances",combinedAttendances);

const updatedPosjete = posjete
.filter(posjeta => settingsGym.some(gym => gym.guestGymId === posjeta.gymVisited))
.map(posjeta => {
    const matchingGym = settingsGym.find(gym => gym.guestGymId === posjeta.gymVisited);
    return {
        ...posjeta,
        naziv: matchingGym ? matchingGym.guestNameGym : null
    };
});
const handleExportToExcel = () => {
  const exportData = updatedPosjete.map((item) => ({
    clan: item.user.firstName + " "+ item.user.lastName,
    prijava: item.timeIn,
    odjava: item.timeOut,
    poslovnica: item.naziv,
    // iznos: `${item.iznos} KM`,
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Posjete');

  // Export the workbook to an Excel file
  XLSX.writeFile(workbook, 'posjete-gost.xlsx');
};


  const userRender = (cell)=>{
    return( 

      <p direction="row" spacing={1}>
        {/* {console.log(cell.row)} */}
        {cell.row.user.firstName +" "+cell.row.user.lastName}
    </p>
    )
  }
  const poslovnicaRender = (cell)=>{
    return( 
      <p direction="row" spacing={1}>
        {/* {console.log(cell.row?.naziv)} */}
        {cell.row?.naziv}
    </p>
    )
  }
  const statusRender = (cell)=>{
    return( 
      <p direction="row" spacing={1}>
        {cell.row.timeOut !== null ? "Odjavljen" : "Prijavljen"}
    </p>
    )
  }
  const fotografijaRender = (cell) => {

    if (!cell?.row || !cell.row.user) {
      return null; // Return null or a placeholder value if user data is undefined
    }
  
    const handleClick = () => {
      navigate(`/users/${cell.row.user._id}`, { state: cell.row });
    };
    
    return (
      <>
        <img 
          alt="Slika nije pronadjena"
          // src={`${imageUrl}/${cell.row.user.photo}` }
          src={cell.row.user.photo} 
          style={{width:"75%"}}
          onClick={handleClick}
        />
      </>
    );
  };
 const vrijemePrijave = (cell)=>{
  
 return <StartDateAndTimeConverting datetime={cell.row?.timeIn}/>
  }
  const vrijemeOdjave = (cell)=>{
  
    return <StartDateAndTimeConverting datetime={cell.row?.timeOut}/>
     }
  
  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    { field: 'photo', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'user', headerName: 'Clan', 
    renderCell: (params) => {
        return userRender(params);
    }
    },
    { field: 'gymVisited', headerName: 'Poslovnica', 
    renderCell: (params) => {
        return poslovnicaRender(params);
    }
    },
    
    { field: 'timeIn', headerName: 'Vrijeme prijave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemePrijave(params);
    }
    },
  
    { field: 'timeOut', headerName: 'Vrijeme odjave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemeOdjave(params);
    }
    },
    { field: 'status', headerName: 'Status', 
    renderCell: (params) => {
        return statusRender(params);
    }
    },
  ];

  return (
    <Box m="20px">
      <Header
        title=" Članovi koji su posjetili druge teretane"
        // subtitle="List of Contacts for Future Reference"
      />
     
      <Box display="flex" justifyContent="end" mt="20px">
        {/* <Link to="/check-in/new"> */}
        <Button onClick={handleExportToExcel} variant="contained" color="secondary">
      Izvezi u Excel
    </Button>
        {/* </Link> */}
            </Box>
            {/* <Box marginTop="50px" marginRight="10px">
    <Button onClick={handleExportToExcel} variant="contained" color="secondary">
      Izvezi u Excel
    </Button>
  </Box> */}
            <FormControl  sx={{minWidth:"200px" }}>
            <Box display="flex" mt="20px">
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        style={{ display: "inline-block", margin: "0 auto" }}
        />
                  <Box display="flex" justifyContent="end" mt="0px">
        <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedGym}
          onChange={(event) => setSelectedGym(event.target.value)}
        >
          {settingsGym?.map((e, key) => (
            <MenuItem key={key} value={e.guestGymId}>
              {e.guestNameGym}
            </MenuItem>
          ))}
        </Select>
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={gym}
          // onChange={handleChangeGym}
        >
          {gymsCombined?.map((e, key) => (
            <MenuItem key={key} value={e}>
              {e.naziv}
            </MenuItem>
          ))}
        </Select> */}
        
      </Box>
          </Box>

      </FormControl>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={updatedPosjete}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default PrijaveDrugeTeretane;
