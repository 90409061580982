import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const BarChart3 = ({ data, startDate, endDate }) => {
  // console.log("data", data)
  
  // sort the payments by plan ID
  const sortedData = data?.sort((a, b) => a.member?.user?.membership?.plan?._id?.localeCompare(b.member?.user?.membership?.plan?._id));
// console.log(sortedData)
  // create an object to hold the payment data for each plan
  const paymentsByPlan = {};

  // iterate through each payment and add it to the corresponding plan in the paymentsByPlan object
  sortedData.forEach((payment) => {
    // console.log(payment)
    const date = new Date(payment.date);
    if (date >= startDate && date <= endDate) {
      const planId = payment.member?.user?.membership.plan._id;
      if (!paymentsByPlan[planId]) {
        paymentsByPlan[planId] = { name: payment.member?.user?.membership.plan.nazivPaketa, amount: 0 };
      }
      paymentsByPlan[planId].amount += payment.amount;
    }
  });

  // convert the paymentsByPlan object into an array of objects that Recharts can use to render the chart
  const chartData = Object.values(paymentsByPlan);

  // define the colors to use for each plan
  const colors = ['#82ca9d', '#8884d8', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffc1c1', '#888888'];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amount" stackId="a" fill={colors[0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChart3;
