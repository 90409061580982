import React, {useState, useEffect, useContext} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
// import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, TextField } from "@mui/material";
// import { Formik } from "formik";
// import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../components/Header";
import { updateMembershipPlan } from '../api/membershipApi';
// import { createPayment } from '../api/paymentApi';
import { getPaket, getPaketWeb } from "../api/paketApi";
// import { useParams } from 'react-router-dom';
// import { getUserById } from "../api/userApi";
// import { Typography, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
// import { EndDateConverting, StartDateConverting } from './DateConverting';
import QuizIcon from '@mui/icons-material/Quiz';
import './membership.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createClanarinaUredjivanje } from '../api/clanarinaUredjivanjeApi';
import { UserContext } from '../context/UserProvider'; 
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { EndDateConverting } from './DateConverting';
import {TailSpin} from 'react-loader-spinner';
const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    card: {
      width: '80%',
      marginTop: theme.spacing(2),
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const MembershipRenewalForm = ({ clan, onUpdateResponse,onLoadingChange }) => {
  const { user} = useContext(UserContext);

  // const clan = clan;  
    // console.log("clan", clan)
  const classes = useStyles();
  // const { id } = useParams();
  // const isNonMobile = useMediaQuery("(min-width:600px)");
  // const [response, setResponse] = useState("");
  const [paketi, setPaketi] = useState([]);
  const [paket, setPaket] = useState("");
  const [gym, setGym] = useState("");
  // const [user, setUser] = useState("");
  const [placanje, setPlacanje] = useState("");
  const [napomena, setNapomena] = useState(null);
  // const [brojMjeseci, setBrojMjeseci] = useState(1);
  // const userId = localStorage.getItem('userId');
  const userId = user._id;
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [brojMjeseciPaket, setBrojMjeseciPaket] = useState(1);
  // const [showUplatnicaSekcija, setShowUplatnicaSekcija] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [popust, setPopust] = useState(0);
  const [popustKM, setPopustKM] = useState(0);
  // const [membership, setMembership] = useState("")
  const [paketError, setPaketError] = useState(false);
  const [placanjeError, setPlacanjeError] = useState(false);
  

// console.log("clan", clan.status)


  const handleChangePaket = (event) => {
    // console.log(paket)
    setPaket(event.target.value);
    setPaketError(false); // Reset error on change
  };
  const handleChangePlacanje = (event) => {
    // console.log(paket)
    setPlacanje(event.target.value);
    setPlacanjeError(false); // Reset error on change
  };
  const handleNapomena = (event) => {
    // console.log(paket)
    setNapomena(event.target.value);
  };
  
  const handleBrojMjeseci = (event) => {
    // console.log(paket)
    setBrojMjeseciPaket(event.target.value);
  };

  useEffect(() => {
   async function fetchPaket() {
    try {
      const fetchedUPaket = await getPaketWeb({});
      setPaketi(fetchedUPaket);
    } catch (error) {
      console.error(error);
    }
  }

  fetchPaket()

    //fetch gymIf from local storage
    const savedGym = localStorage.getItem('defaultGym');
    // console.log(savedGym)
    if (savedGym) {
      //  console.log(savedGym)
      setGym(JSON.parse(savedGym));
    }

  }, []);


  useEffect(() => {
    async function handleGyms() {
      // Calculate the price and discounted price
  
      // Pronađite odgovarajući popust na temelju broja mjeseci
      const popust = paket?.popusti?.find(
        (popust) =>
          brojMjeseciPaket >= popust.raspon.minMjeseci &&
          brojMjeseciPaket <= popust.raspon.maxMjeseci
      );
      // console.log(popust)
      const cijneas = brojMjeseciPaket * paket?.cijena;
      const popusts = popust?.postotak ? (brojMjeseciPaket * paket?.cijena) * (popust?.postotak / 100) : 0;
      const CijenaSaPopustom = popust?.postotak ? (brojMjeseciPaket * paket?.cijena) - (brojMjeseciPaket * paket?.cijena) * (popust?.postotak / 100) : 0;
  
      setPrice(cijneas);
      setPopust(popust?.postotak || 0);
      setDiscountedPrice(CijenaSaPopustom);
      setPopustKM(popusts);
    }
    handleGyms();
  }, [brojMjeseciPaket, paket?.cijena, paket?.popusti]);


  const handleConfirmProduzi = async (e) => {
    e.preventDefault();
    // setLoading(true);
    if (!paket) {
      setPaketError(true);
      alert("Odaberi paket");
      return; // Stop the form submission
    }
    if (!placanje) {
      setPlacanjeError(true);
      alert("Odaberi način plaćanja");
      return; // Stop the form submission
    }
    onLoadingChange(true);
// console.log(values)
const updatedPlan = { ...paket, trajanje: brojMjeseciPaket };
    // const {address1,contact,email,firstName,lastName,password,photo} = values;
    const membershipId = clan?.membership
  
    try {
      let date = ""
      if (placanje == "odgodjeno"){
        date = ""
      }else{
        date = new Date()
      }
      const newPaket = {
        paket: updatedPlan,
        gym: gym,
        napomena: napomena, //clanarinu
        placanje: placanje, //ide na placanje
        endDate: clan?.endDate,
        // memberId: clan.member,
        // plan: updatedPlan,
        membership: membershipId,
        // gym: gym,
        amount: paket.cijena,
        paymentMethod: placanje,
        date: date,
        uplata: discountedPrice === 0 ? brojMjeseciPaket * paket.cijena : discountedPrice,
        userID: clan.id,
        popust: popustKM,
        popustProcenat: popust,
        trajanjePaketa: brojMjeseciPaket,
        opis:"Produzenje clanarine",
        status:'active',
        uposlenik: user.firstName + " " + user.lastName ,
      };
      // console.log(newPaket)

          const updatedMembershipPlan = await updateMembershipPlan(membershipId, newPaket);
            //  console.log(updatedMembershipPlan)
             if(updatedMembershipPlan) {
              onUpdateResponse(updatedMembershipPlan); // use the prop here

            const opis = "produzenje";
            const uredioClanarinu = await createClanarinaUredjivanje({membershipId, userId, fakturaId:updatedMembershipPlan.racunId,opis})
              setPaket("");
              setPlacanje("");
              alert("Uspješno ste produžili članarinu.");
              
          }
      // }
    } catch (error) {
      alert("Došlo je do greške prilikom produženja članarine.");
      // toast.error('Došlo je do greške prilikom produženja članarine.');
      console.log(error);
    }
    //  toast.success('Uspješno ste produžili članarinu.');
    onLoadingChange(false);
    // toast.success('Uspješno ste produžili članarinu.');
  };

  // const handleOpenDialog = (cell) => {
  //   // setMemberToDelete(cell);
  //   setDeleteDialogOpen(true);
  // };
  const handleCancelProduzi = () => {
    // setMemberToDelete(null);
    setDeleteDialogOpen(false);
  };


  return (
  
  
    <Box m="20px">
      {/* <Header title={`Produženje članarine: ${user?.firstName +" "+ user?.lastName}`} subtitle="Create a New User Profile" />
       */}
      {/* {response} */}
      <ToastContainer />
            {/* <form onSubmit={handleSubmit}> */}
            {/* <form onSubmit={handleDelete}> */}
            <Box
              display="grid"
            //   gap="30px"
            //   gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            //   sx={{
            //     "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            //   }}
            >
        {/* <Card className={classes.card} sx={{ display: 'flex' }}> */}
        <Tooltip title="
        Azurira se clan, status, poslovnica i trajanje paketa. 
        Poslovnica zavisi od korisnika koji ce produziti paket korisniku.
        Cijena paketa je cijan clanarine i prikazate ce se u izvjestajima.
        ">
          <QuizIcon alignItems="flex-end"/>
        </Tooltip>
        <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Odaberi paket</InputLabel>
        <Select
        style={{minWidth: "200px",border: "1px solid #000", color:"black", backgroundColor:'white', width:"100%"}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paket}
            label="Paket"
            onChange={handleChangePaket}
            required
            error={paketError}
            >
            {paketi?.map((e, key)=>{
            return <MenuItem key={key} value={e}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
            })
             }
        </Select>
        <Box>
        <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Broj mjeseci</InputLabel>
        <TextField
          style={{ backgroundColor: 'white', width: '100%' }}
          inputStyle={{ color: 'black' }}
          id="outlined-multiline-flexible-napomena"
          type="number"
          label="Broj mjeseci"
          multiline
          value={brojMjeseciPaket}
          onChange={handleBrojMjeseci}
        />
          </Box>
        {/* </Card> */}
        {/* <Card className={classes.card} sx={{ display: 'flex' }}> */}
        <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Nacin placanja</InputLabel>
        <Select
            style={{minWidth: "200px", border: "1px solid #000", color:"black", backgroundColor:'white', width:"100%"}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={placanje}
            label="Placanje"
            onChange={handleChangePlacanje}
            required
            error={placanjeError}
            >
            <MenuItem value="" disabled>Odaberite način plaćanja</MenuItem>
            <MenuItem value={"gotovina"}>Gotovina</MenuItem>
            <MenuItem value={"kartica"}>Karica</MenuItem>
            <MenuItem value={"POS terminal"}>POS terminal</MenuItem>
            <MenuItem value={"odgodjeno"}>Odgodjeno</MenuItem>
            
        </Select>
        
        {/* </Card> */}
            </Box>

            <Box>
            {/* <Card className={classes.card} sx={{ display: 'flex' }} style={{color:"black"}}> */}
            <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Napomena</InputLabel>
            <TextField
                style={{backgroundColor:'white', width:"100%"}}
                id="outlined-multiline-flexible-napomena"
                label="Napomena"
                multiline
                maxRows={6}
                onChange={handleNapomena}
        />
{/* </Card> */}
            </Box>
            <Box>   
              <p>--Iznos--</p>
    
                    <p>Cijena bez popusta: {price}</p>
                    <p>Popust: {popust}</p>
                    <p>Iznos popusta: {popust}</p>
                    <p>Članska kartica: {popustKM}</p>
                    {/* <p>Trenutni paket: {member.rfid}</p> */}
                    <p>Iznos sa popusom: {discountedPrice}</p>
            </Box>
        
            <Card className={classes.card} sx={{ display: 'flex' }} style={{backgroundColor:'transparent'}}>
            <Box display="flex" justifyContent="end" mt="20px">
            {clan.status ==="active" ?
              <Button 
              // type="submit" 
              onClick={()=>setDeleteDialogOpen(true)}
              color="secondary" 
              variant="contained">
               Produži članarinu
              </Button>
              :
              <Button 
              // type="submit" 
              onClick={handleConfirmProduzi}
              color="secondary" 
              variant="contained"
              >
               Produži članarinu
              </Button>
              }
            </Box>
            </Card>
          {/* </form> */}
          <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelProduzi}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Produženje aktivne članarine</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Članarina je aktivna do <EndDateConverting date={clan.endDate}/>, da li želite da je produžite?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelProduzi} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmProduzi}
            color="secondary" 
            variant="contained"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Produži
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
 
  
  );
};

export default MembershipRenewalForm;
