import React, { useEffect, useState } from 'react';

const GuzvaPoVremenuIDanima = ({ posjeteData }) => {
  const [najveciInterval, setNajveciInterval] = useState('');
  const [najposjecenijiDan, setNajposjecenijiDan] = useState('');
  const [brojPosjetaNajposjecenijegDana, setBrojPosjetaNajposjecenijegDana] = useState(0);

  useEffect(() => {
    // Mapirajte posjete u vremenske intervale i broj posjeta po intervalu
    const intervali = {};
    const dani = {};

    posjeteData.forEach((posjeta) => {
      const vreme = new Date(posjeta.timeIn).getHours();
      const interval = `${vreme}-${vreme + 2}`; // Interval od 2 sata
      if (!intervali[interval]) {
        intervali[interval] = 0;
      }
      intervali[interval]++;

      const dan = new Date(posjeta.timeIn).toLocaleDateString('en-US', { weekday: 'long' });
      if (!dani[dan]) {
        dani[dan] = 0;
      }
      dani[dan]++;
    });

    // Pronađite interval s najvećim brojem posjeta
    let najveciInterval = '';
    let maxPosjete = 0;
    for (const interval in intervali) {
      if (intervali[interval] > maxPosjete) {
        maxPosjete = intervali[interval];
        najveciInterval = interval;
      }
    }

    // Pronađite dan s najvećim brojem posjeta
    let najposjecenijiDan = '';
    let maxPosjeteDan = 0;
    for (const dan in dani) {
      if (dani[dan] > maxPosjeteDan) {
        maxPosjeteDan = dani[dan];
        najposjecenijiDan = dan;
      }
    }

    // Postavite najveći interval i najposjećeniji dan u stanje komponente
    setNajveciInterval(najveciInterval);
    setNajposjecenijiDan(najposjecenijiDan);
    setBrojPosjetaNajposjecenijegDana(maxPosjeteDan);
  }, [posjeteData]);

  return (
    <>
      {najveciInterval && najposjecenijiDan && brojPosjetaNajposjecenijegDana && (
        <div>
          <p>
            Najveći broj posjeta je u vremenskom intervalu od {najveciInterval} sata.
          </p>
          <p>
            Najposjećeniji dan je {najposjecenijiDan} s {brojPosjetaNajposjecenijegDana} posjeta.
          </p>
        </div>
      )}
    </>
  );
};

export default GuzvaPoVremenuIDanima;
