import React from 'react';
import { Box, IconButton, useTheme } from "@mui/material";
import { useContext } from "react";
import { GymContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Routes, Route, useParams, Link, useNavigate } from 'react-router-dom';
import ExitToAppIcon from "@mui/icons-material/ExitToApp"; 
import { UserContext } from '../../context/UserProvider'; // Prilagodite putanju prema stvarnom mjestu gdje se nalazi UserContext
import Avatar from '@material-ui/core/Avatar';


const Topbar = () => {
  const { user} = useContext(UserContext);
  // console.log("user",user)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { colorMode } = useContext(GymContext);
  const navigate = useNavigate(); // Dodajte ovo ako koristite react-router-dom
// console.log("user",user)
  // Funkcija za odjavu
  const handleLogout = () => {
    // Implementirajte logiku za odjavu ovdje, npr. brisanje tokena, resetiranje stanja itd.
    localStorage.removeItem('token');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');
    localStorage.removeItem('defaultGym');
    // Nakon što se odjavite, preusmjerite korisnika na početni ekran ili drugu odgovarajuću rutu
    navigate('/login'); // Ovo preusmjerava korisnika na početni ekran
    window.location.reload();
  };

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        {/* <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton> */}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <Link to='/postavke'>
            <SettingsOutlinedIcon />
          </Link>
        </IconButton>
        <IconButton>
        <Link to="/mojprofil">
      <Avatar src={user?.photo} alt="Avatar korisnika" />
        </Link>
        </IconButton>
        {/* Dodajte ikonu za odjavu ovdje */}
        <IconButton onClick={handleLogout}>
          <ExitToAppIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
