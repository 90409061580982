import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const PieChartIzvjestajPaketiProdaja = (props) => {
  const prodatiPaketi = props.data;

  if (!prodatiPaketi) {
    // Ako prodatiPaketi nije definiran, možete izvršiti odgovarajuće radnje ili prikazati poruku o grešci
    return <div>Podaci nisu dostupni</div>;
  }

  // Kreiranje objekta za praćenje broja faktura po paketu
  const brojFakturaPoPaketu = {};

  // Mapiranje podataka i brojanje faktura po paketu

  prodatiPaketi.forEach((faktura) => {
 
    const nazivPaketa = faktura.nazivPaketa;
    const brojFaktura = faktura.fakture.length;
  
    if (!brojFakturaPoPaketu[nazivPaketa]) {
      brojFakturaPoPaketu[nazivPaketa] = brojFaktura;
    } else {
      brojFakturaPoPaketu[nazivPaketa] += brojFaktura;
    }
  });
 
  // Mapiranje podataka u oblik pogodan za PieChart
  const data = Object.keys(brojFakturaPoPaketu).map((nazivPaketa) => ({
    id: nazivPaketa,
    value: brojFakturaPoPaketu[nazivPaketa],
    label: `${nazivPaketa} (${brojFakturaPoPaketu[nazivPaketa]} faktura)`,
  }));

  return (
    <PieChart
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
        },
      ]}
      height={400}
      // customComponents={{
      //   node: CustomLabel, // Koristite CustomLabel za oznake
      // }}
    />
  );
}

export default PieChartIzvjestajPaketiProdaja;
