import React from 'react';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import PieChartIzvjestajPaketiProdaja from '../../components/PieChartIzvjestajPaketiProdaja';

const PieIzvjestajPaketiProdaja = (props) => {

  const grupisanoPaketi = props.data;
  // console.log(grupisanoPaketi)
  return (
    <Box m="20px">
      <Header />
      <Box height="60vh">
        <PieChartIzvjestajPaketiProdaja data={grupisanoPaketi}/>
      </Box>
    </Box>
  );
};

export default PieIzvjestajPaketiProdaja;
