import React,{useEffect, useState} from "react";
import StatBox from "../../components/StatBox";


const UplateMjesec = (uplateData) =>{

const uplate = uplateData.uplateData;

const ukupanPrihod = uplate.reduce((acc, cur) => {
    if (cur.status !== "pending") {
      return acc + cur.amount;
    } else {
      return acc;
    }
  }, 0);
    return(
        <>
         <StatBox
           title={`${ukupanPrihod} KM`}
            subtitle="Uplate mjesec"
            title2={`${uplate.length} uplate`}
            // subtitle="Broj aktivnih članova"
            progress="a"
          />
        
        </>
    )
}

export default UplateMjesec;

