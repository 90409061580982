import React, { useState } from 'react';
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Collapse,
  IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ClearIcon from '@mui/icons-material/Clear';
import { updateReservationAttendees } from '../api/reservationsApi';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

function ReservationList({ reservations, setReservations }) {
  const [expandedReservation, setExpandedReservation] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [deletingMember, setDeletingMember] = useState(null);


      // Sortirajte rezervacije po danu
  reservations.sort((a, b) => {
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  });

  const handleExpand = (index) => {
    if (expandedReservation === index) {
      setExpandedReservation(null);
    } else {
      setExpandedReservation(index);
    }
  };

  const onRemoveUser = async (reservationId, attendeeId) => {
    try {
      // Pozovite funkciju za uklanjanje korisnika iz rezervacije
      const response = await updateReservationAttendees(reservationId, attendeeId);
    //   console.log(response);

      // Ažurirajte stanje rezervacija tako da prikažete ažurirane podatke
      const updatedReservations = [...reservations];
      const reservationIndex = updatedReservations.findIndex((r) => r._id === reservationId);

      if (reservationIndex !== -1) {
        updatedReservations[reservationIndex] = response; // Zamijenite staru rezervaciju sa ažuriranom
        setReservations(updatedReservations); // Ažurirajte stanje
      }
    } catch (error) {
      console.error(error);
    }
  };
  const openDeleteDialog = (reservationId, attendeeId) => {
    setDeletingMember({ reservationId, attendeeId });
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeletingMember(null);
    setIsDialogOpen(false);
  };

// console.log(weeklySchedule)
const translateToCroatian = (day) => {
  switch (day) {
    case 'Monday':
      return 'Ponedjeljak';
    case 'Tuesday':
      return 'Utorak';
    case 'Wednesday':
      return 'Srijeda';
    case 'Thursday':
      return 'Četvrtak';
    case 'Friday':
      return 'Petak';
    case 'Saturday':
      return 'Subota';
    case 'Sunday':
      return 'Nedjelja';
    default:
      return '';
  }
}
  return (
    <List>
      {reservations.map((reservation, index) => (
        <div key={index}>
          <ListItem button onClick={() => handleExpand(index)}>
            <ListItemText
              primary={`${translateToCroatian(reservation.day)} ${reservation.startTime}-${reservation.endTime}`}
              secondary={`Broj rezervacija: ${reservation.attendees.length} / ${reservation.group.maxCapacity}`}
            />
            <IconButton>
              <ExpandMoreIcon
                style={{
                  transform: expandedReservation === index ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s ease',
                }}
              />
            </IconButton>
          </ListItem>
          <Collapse in={expandedReservation === index}>
            <List>
              {reservation.attendees.map((attendee, attendeeIndex) => (
                <ListItem key={attendeeIndex}>
                  <ListItemAvatar>
                    {attendee?.photo ? (
                      <Avatar src={attendee?.photo} />
                    ) : (
                      <Avatar>{attendee?.firstName?.charAt(0)}</Avatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${attendee.firstName} ${attendee.lastName} ${attendee.brojClanske !== undefined ? attendee.brojClanske : ""}`}
                  />
                  {/* {attendee.membership && (
                    <ListItemText
                      secondary={`Paket: ${attendee?.membership?.plan?.nazivPaketa}`}
                      style={{ color: 'white' }}
                      classes={{ secondary: 'white-text' }}
                    />
                  )}
                  {attendee.membership && (
                    <ListItemText
                      secondary={`Br. clanske: ${attendee.membership.brojClanske}`}
                      style={{ color: 'white' }}
                      classes={{ secondary: 'white-text' }}
                    />
                  )} */}
                 
                    {/* <IconButton onClick={() => onRemoveUser(reservation._id,attendee._id)}>
                      <ClearIcon style={{ color: 'red' }} />
                    </IconButton> */}
                    <IconButton onClick={() => openDeleteDialog(reservation._id, attendee._id)}>
                        <ClearIcon style={{ color: 'red' }} />
                    </IconButton>
                
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
      <Dialog open={isDialogOpen} onClose={closeDeleteDialog}>
  <DialogTitle>Potvrda brisanja</DialogTitle>
  <DialogContent>
    <DialogContentText>
      Da li ste sigurni da želite ukloniti ovog člana?
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={closeDeleteDialog} color="primary">
      Otkaži
    </Button>
    <Button
      onClick={() => {
        onRemoveUser(deletingMember.reservationId, deletingMember.attendeeId);
        closeDeleteDialog();
      }}
      color="secondary"
    >
      Da
    </Button>
  </DialogActions>
</Dialog>
    </List>
  );
}

export default ReservationList;
