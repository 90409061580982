// FakturaDetalj.js

import React,{useState, useEffect} from 'react';
import {  Button} from "@mui/material";
// import {  Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
// import {makeStyles,} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import './invoice.css';
import { getAllPaymentByYear, updatePaymentByID } from '../../api/paymentApi';
import { TailSpin } from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

// const useStyles = makeStyles((theme) => ({
//   root: {
//     // minWidth: 275,
//     // width:"80%",
//     margin: theme.spacing(2),
//     display: 'flex',
//     padding:10,
//   },
//   title: {
//     fontSize: 16,
//     fontWeight: 'bold',
//   },
//   subtitle: {
//     fontSize: 14,
//     color: theme.palette.text.secondary,
//   },
//   content: {
//     marginTop: theme.spacing(2),
//   },
//   leftColumn: {
//     flex: '1',
//   },
//   rightColumn: {
//     flex: '1',
//     marginLeft: theme.spacing(2),
//   },
//   tableContainer: {
//     marginTop: theme.spacing(2),
//   },
//   table: {
//     minWidth: 650,
//   },
// }));



const FakturaDetalj = () => {
  // const classes = useStyles();
  const { state: data } = useLocation();
  const jsonObj = localStorage.getItem('defaultGym');
  const [editMode, setEditMode] = useState(false);
  const [editedUnitPrice, setEditedUnitPrice] = useState(data?.paket?.cijena);
  
  const [editedPaymentDate, setEditedPaymentDate] = useState(new Date(data.createdAt).toLocaleDateString());
  const [brojMjeseci, setBrojMjeseci] = useState(data?.trajanjePaketa);
  // const [editedQuantity, setEditedQuantity] = useState();
  const [iznos, setIznos] = useState(0);
  const [iznosZaPopsut, setIznosZaPopsut] = useState(0);
  const [popustFin, setPopsut] = useState(0);
  const [popustUprocentima, setPopustUprocentima] = useState(data?.popust);
  // const [uplate, setUplate] = useState([]);
  const [redniBrUplate, setRedniBrUplate] = useState(0);
  const [loading, setLoading] = useState(false);
  

console.log(editedPaymentDate);

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      const datas = await getAllPaymentByYear(new Date(data.createdAt));
      // setUplate(datas);
      setLoading(false)
      // Filtriranje prema _id
      const filteredUplata = datas.find((uplata) => uplata._id === data._id);
  
      // Pronalaženje rednog mjesta filtrirane uplate
      const index = datas.indexOf(filteredUplata);
  
      // Sada imate `filteredUplata` i `index` koji pokazuje na kojem je rednom mjestu
      setRedniBrUplate(index + 1)
    }
  
    fetchData();
  }, [data._id]);
   
  useEffect(() => {
    const ukupnoFaktura = () => {
      // const jedinicnaCijena = data.paket.cijena;
      if (data?.paket?.grupniPaket) {
        setEditedUnitPrice(data?.paket?.cijena / data?.paket?.brojClanova)
        // setBrojMjeseci((data.amount + Number(data.popust)) / jedinicnaCijena);
        // if(editedQuantity === undefined) {
        //   setEditedQuantity((Number(data.amount) + Number(data.popust)) / Number(jedinicnaCijena))
        // }
        
      }
      // return data.amount;
    };
    ukupnoFaktura();
    setIznos(brojMjeseci * editedUnitPrice);
    setIznosZaPopsut(brojMjeseci * data?.paket?.cijena);
    setPopsut(iznos * popustUprocentima / 100)
    //popust

  // const izracunajPopust = (cijenaBezPopusta, cijenaSaPopustom) => {
  //   const popust = ((cijenaBezPopusta - cijenaSaPopustom) / cijenaBezPopusta) * 100;
  //   return popust;
  // };

  // const cijenaSaPopustom = iznosZaPopsut - data?.popust;
  // const popust = izracunajPopust(iznosZaPopsut, cijenaSaPopustom);
  // setPopustUprocentima(editedDiscount|| popust);
  }, [brojMjeseci, data.amount, data.paket.cijena, data.popust, editedUnitPrice, iznos, iznosZaPopsut, popustUprocentima]);

 
  const teretana = JSON.parse(jsonObj);
  // console.log("popustFin",popustFin);
  // console.log("popustFin",iznos);
  const currentYear = new Date(data.date).getFullYear().toString().slice(-2);
  // const izracunajPopust = (cijenaBezPopusta, cijenaSaPopustom) => {
  //   const popust = ((cijenaBezPopusta - cijenaSaPopustom) / cijenaBezPopusta) * 100;
  //   return popust;
  // };

  // const cijenaSaPopustom = iznosZaPopsut - data?.popust;
  // const popust = izracunajPopust(iznosZaPopsut, cijenaSaPopustom);
  // console.log(`Popust u postocima: ${popust.toFixed(2)}%`);

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    // Implementirajte logiku za spremanje uređenih podataka na backendu
    setEditMode(false);
  };
  // console.log(new Date(editedPaymentDate))
  const handleSaveClick = async () => {
    setEditMode(false);
    try {
      // Razdvojite mjesec, dan i godinu
      // const [day, month, year] = editedPaymentDate.split('/');
      // Kreirajte novi Date objekt s pravilnim formatom
      // console.log(new Date(editedPaymentDate))
      // const noviDatumPlacanja = new Date(`${year}-${month}-${day}T00:00:00.000Z`);
      const noviDatumPlacanja = new Date(editedPaymentDate);
      const ukIznos         = iznos - popustFin;
      const finalniPopust   = Number(popustFin.toFixed(2));
      const datumPlacanja   = noviDatumPlacanja;
      const fakturaId       = data._id;
      const popustProcenti = popustUprocentima;
      const trajanje = brojMjeseci;

      const newValue = {
        ukIznos,
        finalniPopust,
        datumPlacanja,
        popustProcenti,
        trajanje,
      }

      // console.log(newValue)
    //  const newValue = parseFloat(updatedPayment.amount);
     
     // Ovdje trebate dodati logiku za slanje ažuriranih podataka na backend
     // Poziv servisa ili API-ja za ažuriranje plaćanja s ažuriranim podacima
     // Postavljanje ažuriranih podataka u state nakon uspješnog ažuriranja
     setLoading(true)
     const res = await updatePaymentByID(fakturaId, newValue);
     // Pozivanje funkcije za ažuriranje u roditeljskom komponenti
     // console.log("res",res);
     if(res){
      setLoading(false)
      //  alert("Uspješno stvorena transakcija");
       toast.success('Uspješno ste azurirali iznos.');
       // alert("Uspjesno se kreirali clana");
     }
    } catch (error) {
      toast.error('Greška prilikom ažuriranja plaćanja.');
      console.error('Greška prilikom ažuriranja plaćanja:', error);
    }
  };

  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
      </div>
    ) : (
    <div className="invoice-box">
    <ToastContainer />
    <table cellPadding="0" cellSpacing="0">
      <tr className="top">
      <td colSpan="2">
          <table>
    
            <tr>
              
              <td className="title">
                <img
                  src={teretana.photo}
                  style={{ width: '100%', maxWidth: '100px' }}
                  alt="Invoice Logo"
                />
              </td>
            </tr>
            
          </table>
      </td>
      <td></td>
        <td></td>
        <td></td>
        <td></td>
        
        <td style={{right:0}}>
                Id #: {data?._id}<br />
                Datum kreiranja: {new Date(data.date).toLocaleDateString()}{' '}<br />
                {/* Datum plaćanja: {new Date(data.createdAt).toLocaleDateString()}{' '}<br /> */}
                Datum plaćanja: {editMode ? <input type="date" value={editedPaymentDate} onChange={(e) => setEditedPaymentDate(e.target.value)} /> : editedPaymentDate}<br />
                Datum odobrenja: {new Date(data?.associatedClanarinaUredjivanje[0]?.datumUredjivanja).toLocaleDateString()}{' '}<br />
                Odobrio: {data.associatedClanarinaUredjivanje[0]?.uredio?.firstName}{' '}{data.associatedClanarinaUredjivanje[0]?.uredio?.lastName}
              </td>
       
       
      </tr>

      <tr className="information">
        <td colSpan="2">
          <table>
            <tr>
              <td>
                {teretana.naziv}<br />
                {teretana.address}<br />
                {teretana.city}<br />
                {teretana.phone}
              </td>
            </tr>
          </table>
        </td>
        
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>
              {data?.member?.user?.firstName}{" "}{data?.member?.user?.lastName}<br />
              {data?.member?.user?.address}<br />
              {data?.member?.user?.phone}<br />
              </td>
      </tr>
      <br/>
      <br/>
      <br/>
      <br/>
      <td></td>
        <td></td>
      <td style={{fontSize:20, fontWeight:"bold"}}>FAKTURA br. {redniBrUplate}/{currentYear}</td>
 <tr className="headingItem">
        <td> Način plaćanja:</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{data.paymentMethod} #</td>
      </tr>
      <tr className="heading">
        <td>Stavke</td>
        <td>Opis</td>
        <td></td>
        <td></td>
        <td></td>
        <td>Količina</td>
        <td>Jed. cijena</td>
        <td>Cijena</td>
      </tr>

      <tr className="item">
        <td>1. {data.paket?.nazivPaketa?.bs}{" "}</td>
        <td>{`${data.opis}`}</td>
        <td></td>
        <td></td>
        <td></td> 
        <td>{editMode ? <input type="text" value={brojMjeseci} onChange={(e) => setBrojMjeseci(e.target.value)} /> : brojMjeseci}</td>
        <td>{Number(editedUnitPrice).toFixed(2)}</td>
        <td>{Number(iznos).toFixed(2)}</td>
      </tr>

      <tr className="headingItemTotal">
        <td># Popust: {editMode ? <input type="text" value={Number(popustUprocentima)} onChange={(e) => setPopustUprocentima(e.target.value)} /> : Number(popustUprocentima)}%</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{Number(popustFin).toFixed(2)}</td>
      </tr>

      {/* <tr className="item last">
        <td>Domain name (1 year)</td>

        <td>$10.00</td>
      </tr> */}

      <tr className="total">
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td>Ukupno:</td>
        <td>{Number(iznos - popustFin).toFixed(2)} </td>
      </tr>

      
    </table>
    <td>
  {editMode ? (
    <>
    <Button onClick={handleSaveClick} variant="contained" color="secondary">Spremi</Button>
    <Button onClick={handleCancel}  color="secondary" variant="contained" style={{ marginLeft: "20px", backgroundColor: "#ffbf02" }}>Odustani</Button>
    </>
  ) : (
    <Button onClick={handleEditClick} color="secondary" variant="contained">Uredi</Button>
  )}
</td>
  </div>
  )}
     </>
  );
};

export default FakturaDetalj;
