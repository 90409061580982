import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/auth/login`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/auth/login';

  
export const getAllLogins = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getLoginById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createLogin = async (userDetails) => {
    // console.log(userDetails)
  const response = await axios.post(baseUrl, userDetails);
  return response.data;
};

export const updateLogin  = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteLogin  = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }