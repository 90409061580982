import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@mui/material';
import { createReservation } from '../api/reservationsApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NoviTermin({ open, onClose, idGroup, color }) {

   
  const [noviTermin, setNoviTermin] = useState({
    day: '',
    startTime: '',
    endTime: '',
    groupId: idGroup,
    attendees:[],
    color:color,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNoviTermin({
      ...noviTermin,
      [name]: value,
    });
  };

  const handleSave = async () => {
    // console.log("noviTermin",noviTermin)
    try {
    const res = await createReservation(noviTermin);
    toast.success('Uspješno ste kreirali termin.');
    console.log(res)
    }catch (error){
      console.error(error);
      toast.warn('🦄 Greska! Već postoji rezervacija za ovaj dan i vrijeme!');
    }
    // console.log(res);
    // Validacija podataka ovdje (npr. provjera da li su svi inputi popunjeni)
    // Ako su podaci ispravni, pozovite funkciju dodajRezervaciju s novim terminom
    // dodajRezervaciju(noviTermin);
    // Zatvorite dijalog
    onClose();
  };
  const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
  const translateToCroatian = (day) => {
    switch (day) {
      case 'Monday':
        return 'Ponedjeljak';
      case 'Tuesday':
        return 'Utorak';
      case 'Wednesday':
        return 'Srijeda';
      case 'Thursday':
        return 'Četvrtak';
      case 'Friday':
        return 'Petak';
      case 'Saturday':
        return 'Subota';
      case 'Sunday':
        return 'Nedjelja';
      default:
        return '';
    }
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <ToastContainer />
      <DialogTitle>Novi Termin</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Dodajte novi termin rezervacije.
        </DialogContentText>
        <form>
        <FormControl fullWidth margin="normal" required>
            <InputLabel>Dan</InputLabel>
            <Select
              name="day"
              value={noviTermin.day}
              onChange={handleInputChange}
            >
              {days.map((day) => (
                <MenuItem key={day} value={day}>
                  {translateToCroatian(day)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            name="startTime"
            label="Početak"
            fullWidth
            value={noviTermin.startTime}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          <TextField
            name="endTime"
            label="Kraj"
            fullWidth
            value={noviTermin.endTime}
            onChange={handleInputChange}
            margin="normal"
            required
          />
          {/* <FormControl fullWidth margin="normal" required>
            <InputLabel>Grupa</InputLabel>
            <Select
              name="group"
              value={noviTermin.group}
              onChange={handleInputChange}
            >
              <MenuItem value="Grupa 1">Grupa 1</MenuItem>
              <MenuItem value="Grupa 2">Grupa 2</MenuItem>
    
            </Select>
          </FormControl> */}
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Odustani
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained">
          Spremi
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NoviTermin;
