
import React, {useState, useEffect, useContext} from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { Box, Button, TextField, Modal,Zoom } from "@mui/material";
// import CardMedia from '@mui/material/CardMedia';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
import { useLocation, useParams,Link,useNavigate } from "react-router-dom";
import { Edit } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
// import { getMembersByUserId } from '../../api/memberApi';
// import { getUserById } from "../../api/userApi"
import InputLabel from '@mui/material/InputLabel';
// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {updateUserGuest} from '../../api/guestApi';
import BarChart2 from '../../components/BarChart2';
import LineFullChart from '../../components/LineFullChart';
import UserWeeklyCalendar from './userCalendar';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
  IconButton, Box, Button, TextField, Modal,Zoom
} from '@mui/material';
import {TailSpin} from 'react-loader-spinner';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { styled } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigApp from '../../config/ConfigApp';
import { updateMembershipDate } from '../../api/membershipApi';
import axios from 'axios';
import { createClanarinaUredjivanje, createDnevnaClanarinaUredjivanje } from '../../api/clanarinaUredjivanjeApi';
import { updateMembershipPaket, updateUserImage } from '../../api/userApi';
import { getPaketWeb } from '../../api/paketApi';
import { UserContext } from '../../context/UserProvider'; 
import { EndDateConverting } from '../../components/DateConverting';
import { createCheckInn, createCheckOut } from '../../api/attendenceApi';


// const imageUrl = `${ConfigApp.URLIMAGE}`;
const baseUrl = `${ConfigApp.URL}`;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
  userDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  userDetailsText: {
    marginLeft: theme.spacing(2),
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    padding: 16,
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    fontSize: 16,
    color: '#888',
    marginBottom: theme.spacing(1),
  },
  image: {
    // width: '100%',
    // height: '100%',
    objectFit: 'cover', // Omogućava da se slika prilagodi dimenzijama containera zadržavajući omjer
  },
  // container1: {
  //   // width: '300px', // Primjer: postavite željenu širinu containera
  //   // height: '300px', // Primjer: postavite željenu visinu containera
  //   border: '1px solid #ccc', // Primjer: dodajte željeni stil okvira
  //   overflow: 'hidden', // Spriječava prelazak slike preko granica containera
  // },

}));
const ZoomableImage = styled('img')(({ theme, zoomed }) => ({
  width: zoomed ? '100%' : '40%',
  height: zoomed ? 'auto' : '40%',
  objectFit: 'contain',
  transition: 'all 0.3s ease',
}));

const ViewUser = () => {
  const { id } = useParams();
  const { state: data } = useLocation();
  const navigate = useNavigate();
  // console.log(data.user._id)
  const { user} = useContext(UserContext);
  // const userId = localStorage.getItem('userId'); 
    const userId = user._id;  
    const defaultGym = JSON.parse(localStorage.getItem("defaultGym"));
    const gymId = defaultGym?._id;
  // console.log("data",data)
  // console.log("User", data ? data : "User object is null or undefined");
  const [paketi, setPaketi] = useState([]);
  const [paket, setPaket] = useState("");
  // const isNonMobile = useMediaQuery("(min-width:600px)");
  // console.log(paket)
  // const userRole = localStorage.getItem('userRole');
  const userRole = user?.role;
  // const [user, setUser] = useState("")
  // const [member, setMember] = useState({})
  const options = { timeZone: 'Europe/Sarajevo' };
  const start = new Date(data.user?.membership?.startDate);
  const startDay = start.toLocaleString('en-GB', options);

  const end = new Date(data.user?.membership?.endDate);
  const endDay = end.toLocaleString('en-GB',options);
  const [startD, setStartD] = useState(startDay);
  const [endD, setEndD] = useState(endDay);
  const [isLoading, setIsLoading] = useState(false);

  const [newStartD, setNewStartD] = useState(startD);
  const [newEndD, setNewEndD] = useState(endD);
  const [reason, setReason] = useState('');
  // const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(data.user.photo); // Initialize with the existing user's photo
  const [pendingImage, setPendingImage] = useState(null); // Store the pending image until saved
  // const [image, setImage] = useState(data.user.photo);
  const classes = useStyles();



  const [isOpen, setIsOpen] = useState(false);

  const [dugmeStatus, setDugmeStatus] = useState(false);

  const [zoomed, setZoomed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPaketOpen, setIsModalPaketOpen] = useState(false);
  const [isOpenPodaciClana, setIsOpenPodaciClana] = useState(false);

  // const [checkInn, setCheckInn] = useState(false);
  // const [checkOut, setCheckOut] = useState(true);
  


  const [userData, setUserData] = useState({
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    // Add other user attributes here and initialize their values
  });

  // ...

  const handleInputChange = (e, attributeName) => {
    const newValue = e.target.value;

    // Create a copy of the userData object and update the specific attribute
    setUserData((prevUserData) => ({
      ...prevUserData,
      [attributeName]: newValue,
    }));
  };


  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    // console.log(imageFile)
    if (imageFile) {
      // const imageUrl = URL.createObjectURL(imageFile);
      setSelectedImage(imageFile);
      setPendingImage("1")
    }
  };
  const handleSaveImage = async () => {
    if (pendingImage) {
      try {
        const formData = new FormData();
        formData.append('image', selectedImage);

        const updatedUser = await updateUserImage(data.user._id, formData);
  
        // Validate that updatedUser is not undefined before accessing 'photo'.
        const updatedImageUrl = updatedUser?.photo;
  
        if (updatedImageUrl) {
          setSelectedImage(updatedImageUrl);
          setPendingImage(null); // Clear the pending image
  
          // Optionally, you can also display a success message to the user.
          toast.success('Uspješno ste ažurirali sliku.');
        } else {
          // Handle the case where 'updatedUser' or 'updatedImageUrl' is undefined.
          console.error('Error updating user image: Invalid API response');
        }
      } catch (error) {
        console.error('Error updating user image:', error);
        // Handle the error and display an error message to the user if needed.
      }
    }
  };
  

    //datum modal
  const handleStartDChange = (event) => {
    setNewStartD(event.target.value);
  };

  const handleEndDChange = (event) => {
    setNewEndD(event.target.value);
  };

 
//promjena paketa
const handleMembeschipPAketSaveChanges = () => {
  const membershipID = data.user?.membership._id;
 
  const member = data?._id;
  // console.log(membershipID, paket, member)
  // Ovdje možete izvršiti logiku za spremanje promijenjenih datuma u bazu podataka
  // Primjer:
  // Poslati PUT zahtjev na server sa novim datuma članarine
  // updateMembershipDate(id, newStartD, newEndD);
  updateMembershipPaket(membershipID, {paket, member})
    .then((updateMembership) => {
      // Handle the success response from the API
      // console.log('Membership dates updated successfully:', updateMembership);
      // Close the modal after successful update
      

      toast.success('Uspješno ste ažurirali članarinu.');
      // closeModal();
    })
    .catch((error) => {
      // Handle the error response from the API
      console.error('Error updating membership dates:', error);
      // Display an error message to the user (optional)
      // You can use a toast or any other notification library here
    });

  }

  const handleSaveChanges = () => {
    const membershipID = data.user?.membership._id;
    // console.log(membershipID)

    // Ovdje možete izvršiti logiku za spremanje promijenjenih datuma u bazu podataka
    // Primjer:
    // Poslati PUT zahtjev na server sa novim datuma članarine
    // updateMembershipDate(id, newStartD, newEndD);
    updateMembershipDate(membershipID, {
      membership: {
        startDate: newStartD,
        endDate: newEndD,
        reason:reason,

      },
    })
      .then((updateMembership) => {
        // Handle the success response from the API
        // console.log('Membership dates updated successfully:', updateMembership);
        // Close the modal after successful update
        
        setStartD(newStartD);
        setEndD(newEndD);
        toast.success('Uspješno ste ažurirali datum članarine.');
        // closeModal();
      })
      .catch((error) => {
        // Handle the error response from the API
        console.error('Error updating membership dates:', error);
        // Display an error message to the user (optional)
        // You can use a toast or any other notification library here
      });
    // Zatim zatvorite modal

    // closeModal();
  };

  //kraj datum modal
  const handleToggleZoom = () => {
    setZoomed(!zoomed);
  };


  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const openModalPaket = () => {
    setIsModalPaketOpen(true);
  };
  
  const closeModalPaket = () => {
    setIsModalPaketOpen(false);
  };

  const openModalPodaciClan = () => {
    setIsOpenPodaciClana(true);
  };
  
  const closeModalPodaciClan = () => {
    setIsOpenPodaciClana(false);
  };
  
  const openModal = () => {
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleChangePaket = (event) => {
    // console.log(paket)
    setPaket(event.target.value);
  };

  // Dummy podaci za korisnika
  const latestPayment = data.payments[data.payments.length - 1];

  // const userIDs = data.user;

  const sendConfirmationEmail = async (email) => {
    try {
      const res = await axios.post(`${baseUrl}/sendConfirmationEmail/aktivniranaClanarina`, {
        email: email,
      });
  
      if (res.status === 200) {
        console.log('Potvrda o registraciji uspješno poslana');
      } else {
        console.log('Greška prilikom slanja potvrde o registraciji');
      }
    } catch (error) {
      console.error('Greška prilikom slanja potvrde o registraciji:', error);
    }
  };

   const handleApproveTraining = async (userIDs) => {
    try {

      setIsLoading(true)
      const userUpdatetd = await updateUserGuest(userIDs, {paymentID:latestPayment._id});
      
      // console.log("userUpdatetd",userUpdatetd)
      if(userUpdatetd){
        const membershipId = userUpdatetd.membership;
        // const userId = userUpdatetd._id;
        const opis = "odobrenje";
        // alert("Uspješno stvorena transakcija");
        const uredioClanarinu = await createClanarinaUredjivanje({membershipId, userId, fakturaId:latestPayment._id, opis})
        // console.log("uredioClanarinu",uredioClanarinu)
        setIsLoading(false);
        setDugmeStatus(false);
        toast.success('Uspješno ste ažurirali korisnika.');
        sendConfirmationEmail(userUpdatetd.email);
        // alert("Uspjesno se kreirali clana");
    }else{
      setIsLoading(false)
      toast.error('Upsss, desio se problem pokusatje ponovo.');
    }
      // Dodajte kod koji želite izvršiti nakon uspješnog ažuriranja korisnika
    } catch (error) {
      console.error('Greška prilikom odobravanja treninga:', error);
    }
  };

  const handleCheckInn = async ()=>{
    const userId = data?.user?._id; 
    const zahtjev = 1;
    setIsLoading(true);
    const resData = await createCheckInn({userId, gymId, zahtjev });
    setIsLoading(false);
    // console.log(resData)
  }
  const handleCheckOut = async ()=>{
    const userId = data?.user?._id; 
    const zahtjev = 2;
    setIsLoading(true)
    const resData = await createCheckOut({userId, gymId, zahtjev });
      setIsLoading(false)
    // console.log(resData)
  }
  const handleRegistrujTermin = async ()=>{
    try {

      setIsLoading(true)
      //kreiraj prijavu
      // const userUpdatetd = await updateUserGuest(userIDs, {paymentID:latestPayment._id});
      const membershipId = data.user.membership._id;
      const plan = data.user.membership.plan._id;
      const cijenaPaketa = data?.user?.membership?.plan?.cijena;
      const opis = "dnevna clanarina";
      const uposlenikId = userId;
      const member = data._id;
      const gym = data.default_gym._id;
      const uredioClanarinu = await createDnevnaClanarinaUredjivanje({gym, membershipId, member, uposlenikId, opis,cijenaPaketa, plan})
        // console.log("uredioClanarinu",uredioClanarinu)
        toast.success('Uspješno ste prijavili dnevnu članarinu.');
        setIsLoading(false);
    } catch (error) {
      console.error('Greška prilikom odobravanja treninga:', error);
    }
    
  }

  useEffect(() => {
    async function fetchPaket() {
     try {
       const fetchedUPaket = await getPaketWeb({});
       setPaketi(fetchedUPaket);
     } catch (error) {
       console.error(error);
     }
   }
   
   if(data.user?.membership?.status === "pending"){
    setDugmeStatus(true);
   }

   fetchPaket()
   }, []);


   const handleClick = () => {
    navigate(`/users/${id}/edit`, { state: data});
  };
// console.log(data.user.membership.status)
  return (
    <Container maxWidth="mx" className={classes.container}>
         <ToastContainer />
      <div className={classes.userDetails}>
      
         {typeof selectedImage === "string" ? (
                    <Avatar
                    alt={data.user.firstName} 
                    src={selectedImage} 
                    className={classes.avatar} 
                    sx={{
                      width: 206,
                      height: 206,
                      ...(data.user.membership.status === "active"
                        ? {}
                        : data.user.membership.status === "pending"
                        ? {
                            border: "5px solid #ffa502",
                            borderRadius: "83%",
                          }
                          : data.user.membership.status === "expired"
                          ? {
                            border: "5px solid #f10",
                            borderRadius: "83%",
                          }
                        : {}),
                    }}
                  />
          //    <Avatar 
          //    alt={data.user.firstName} 
          //    // src={`${imageUrl}/${data.user.photo}` }
          //    src={selectedImage} 
          //    className={classes.avatar} 
          //    sx={{ width: 200, height: 200 }}
          //  />
          ) : (
            <Avatar
            alt={data.user.firstName} 
            src={URL.createObjectURL(selectedImage)}
            className={classes.avatar} 
            sx={{
              width: 206,
              height: 206,
              ...(data.user.membership.status === "active"
                ? {}
                : data.user.membership.status === "pending"
                ? {
                    border: "5px solid #ffa502",
                    borderRadius: "83%",
                  }
                  : data.user.membership.status === "expired"
                  ? {
                    border: "5px solid #f10",
                    borderRadius: "83%",
                  }
                : {}),
            }}
          />
          
            // <img
            //   style={{ width: "100%" }}
            //   src={URL.createObjectURL(selectedImage)}
            //   alt="Slika vježbe"
            // />
          )}
        <label htmlFor="upload-photo">
            <input
              type="file"
              id="upload-photo"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
            {/* <IconButton component="span">
              <AddPhotoAlternateIcon style={{ color: 'white' }} />
            </IconButton> */}
          </label>
        <div className={classes.userDetailsText}>
          <Typography variant="h1" component="h2" gutterBottom>
          {data.user.firstName} {data.user.lastName}
            {/* {userRole === 'admin' && ( */}
            <Edit onClick={handleClick} style={{ color: 'orange' }} /> 
            {/* )} */}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Datum rodjenja:  <EndDateConverting date={data.date_of_birth}/>
          </Typography>
          <Typography variant="body1" gutterBottom>
            Spol: {data.gender == "male" ? "Muško" : "Zensko"}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Težina:  {data.weight}kg
          </Typography>
          <Typography variant="body1" gutterBottom>
            Visina:  {data.height}cm
          </Typography>
          <Typography variant="body1" gutterBottom>
            Telefon:  {data.user?.phone}
          </Typography>
          <Typography variant="body1" gutterBottom>
          Ulica: {data.user?.address}
        </Typography>
        </div>
        <div className={classes.userDetailsText}>
        <Typography variant="h1" component="h2" gutterBottom>
          {/* {data.user.firstName} {data.user.lastName} */}#
          </Typography>
        <Typography variant="body1" gutterBottom>
        Email: {data.user?.email}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Tip članarine: {data.user?.membership?.plan?.nazivPaketa.bs}
          {userRole === 'admin' && (
          <Edit onClick={openModalPaket} style={{ color: 'orange' }} />
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Početak članarine: {startD}
          {userRole === 'admin' && (
          <Edit onClick={openModal} style={{ color: 'orange' }} />
          )}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Kraj članarine: {endD}
        </Typography>
        {data?.user?.membership?.maxVisitsPerDay !== null ?
        <Typography variant="body1" gutterBottom>
          Broj dnevnih dolazaka: {data?.user?.membership?.maxVisitsPerDay}
        </Typography>
        : ""}
         {data?.user?.membership?.maxVisits !== null ?
        <Typography variant="body1" gutterBottom>
          Broj fleksiblnih dolazaka: {data?.user?.membership?.maxVisits}
        </Typography>
        : ""}
        <Typography variant="body1" gutterBottom>
        Status članarine: {data.user?.membership?.status === "active" ? "Aktivna" 
        : data.user?.membership?.status === "pending" ? "U obradi" 
        : "Neaktivana"}
        </Typography> 
        {pendingImage && (
            <Button type="submit" variant="contained" color="success" onClick={handleSaveImage}>
              Spasi Sliku
            </Button>
          )}
           {userRole === 'admin' && (
           
          <>
        
          <Button type="submit" color="secondary" variant="contained" style={{marginLeft:20, backgroundColor:"skyblue"}} onClick={handleCheckInn}>
            Prijava
            </Button >
           
            <Button type="submit" color="secondary" variant="contained" style={{marginLeft:20, backgroundColor:"azure"}} onClick={handleCheckOut}>
            Odjava
            </Button >
          
            </>
          )}
          {isLoading ? (
              <div className={classes.loadingIndicator}>
                <TailSpin color="#007BFF" height={80} width={80} />
              </div>
            ) : (
              <>
                {data.user.membership.plan.ogranicenjeBrojDana === 1 ?
                  <Button 
                    onClick={() => handleRegistrujTermin(data.user._id)}
                    variant="contained" color="secondary" style={{marginLeft:20}}
                  >
                    Dnevni termin
                  </Button>
                :"" }
              </>
            )}
        <Button type="submit" color="warning" variant="contained" style={{marginLeft:20}} onClick={handleOpen}>
        Provjeri uplatu
        </Button >
      
        {isLoading ? (
              <div className={classes.loadingIndicator}>
                <TailSpin color="#007BFF" height={80} width={80} />
              </div>
            ) : (
              <>
                {dugmeStatus === true ?
                  <Button 
                    onClick={() => handleApproveTraining(data.user._id)}
                    variant="contained" color="secondary" style={{marginLeft:20}}
                  >
                    Odobri članarinu
                  </Button>
                :"" }
              </>
            )}

<Modal open={isModalPaketOpen} onClose={closeModalPaket}>
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "70%",
            height: "80%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflow: 'auto', // Dodajte ovu liniju za omogućavanje scrolla
          }}
        >
          <Typography variant="body1" component="div">
          <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Detalji trenutne članarine
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Tip trenutne članarine: {data.user?.membership?.plan.nazivPaketa.bs}
        </Typography>
        
        <Typography color="textSecondary" gutterBottom>
          Odaberi novu članarinu
        </Typography>
        {/* <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Odaberi paket</InputLabel> */}
        <Select
        style={{minWidth: "200px",border: "1px solid #000", color:"black", backgroundColor:'white', width:"100%"}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paket}
            label="Paket"
            onChange={handleChangePaket}
            >
            {paketi?.map((e, key)=>{
            return <MenuItem key={key} value={e}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
            })
             }
        </Select>
        <Box position="relative">
 
      <Button type="submit" tyle={{ justifyContent: 'flex-start' }} color="success" variant="contained" onClick={handleMembeschipPAketSaveChanges}>
        Izmijeni članarinu
      </Button>
      <Button style={{justifyContent: 'flex-end', float:"right" }} color="error" variant="contained" onClick={closeModalPaket}>
        Zatvori
      </Button>
    </Box>
      </CardContent>
      </Card>
          </Typography>
        </Box>
      </Modal>


        <Modal open={isOpen} onClose={handleClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: "70%",
            height: "80%",
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            overflow: 'auto', // Dodajte ovu liniju za omogućavanje scrolla
          }}
        >
          <Typography variant="body1" component="div">
          <Card className={classes.root}>
      <CardContent>
        <Typography variant="h5" component="h2">
          Detalji zadnje uplate
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          ID uplate: {latestPayment?._id}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Iznos: {latestPayment?.amount} KM
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Opis: {latestPayment?.opis}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Datum: {latestPayment?.date}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Metoda plaćanja: {latestPayment?.paymentMethod}
        </Typography>
        <Typography color="textSecondary" gutterBottom>
          Status: {latestPayment?.status}
        </Typography>
        <Box position="relative">
      <ZoomableImage 
      // src={`${imageUrl}/${latestPayment?.paymentProof}` }
      src={latestPayment?.paymentProof}
      // src={latestPayment?.paymentProof} 
      alt="Dokaz uplate" zoomed={zoomed} />
      <IconButton
        onClick={handleToggleZoom}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
        }}
      >
        {zoomed ? <ZoomOutIcon /> : <ZoomInIcon />}
      </IconButton>
    </Box>
      </CardContent>
      </Card>
          </Typography>
        </Box>
      </Modal>

      <Modal open={isModalOpen} onClose={closeModal}>
  <Box
    sx={{
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '80%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      overflow: 'auto', // Dodajte ovu liniju za omogućavanje scrolla
    }}
  >
    <div>
      <h2>Uredi datum članarine</h2>
      <Typography variant="body1" gutterBottom>
        Početak članarine:---{startD}---
        <input
          type="date"
          value={newStartD}
          onChange={handleStartDChange}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        Kraj članarine: ---{endD}---
        <input
          type="date"
          value={newEndD}
          onChange={handleEndDChange}
        />
      </Typography>
      <Typography variant="body1" gutterBottom>
        Razlog izmjene:
        <textarea
          required
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          rows={4} // Set the number of rows you want to display in the textarea
          cols={50} // Set the number of columns you want to display in the textarea
        />
      </Typography>
      <Button type="submit" tyle={{ justifyContent: 'flex-start' }} color="success" variant="contained" onClick={handleSaveChanges}>
        Izmijeni datum
      </Button>
      <Button style={{justifyContent: 'flex-end', float:"right" }} color="error" variant="contained" onClick={closeModal}>
        Zatvori
      </Button>
    </div>
  </Box>
</Modal>
  {/* Edit Clan info modal*/}
  <Modal open={isOpenPodaciClana} onClose={handleClose}>
  <Box
    sx={{
      position: 'absolute',
      top: '40%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '70%',
      height: '80%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      p: 4,
      overflow: 'auto',
    }}
  >
    <Typography variant="body1" component="div">
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" component="h2">
            Izmjena podataka o članu
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Ime
            <input
              type="text"
              value={data.user.firstName}
              onChange={(e) => handleInputChange(e, 'firstName')}
            />
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Prezime
            <input
              type="text"
              value={data.user.lastName}
              onChange={(e) => handleInputChange(e, 'lastName')}
            />
          </Typography>
          <Typography color="textSecondary" gutterBottom>
          Datum rođenja: {data.date_of_birth}
          <input
          type="date"
          value={newStartD}
          onChange={handleStartDChange}
        />
          </Typography>
        
          <Typography color="textSecondary" gutterBottom>
            Spol: {data.user.gender}
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Težina
            <input
              type="text"
              value={data.weight}
              onChange={(e) => handleInputChange(e, 'weight')}
            />
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Visina
            <input
              type="text"
              value={data.height}
              onChange={(e) => handleInputChange(e, 'height')}
            />
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Telefon
            <input
              type="text"
              value={data.user.phone}
              onChange={(e) => handleInputChange(e, 'phone')}
            />
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Ulica
            <input
              type="text"
              value={data.user.address}
              onChange={(e) => handleInputChange(e, 'address')}
            />
          </Typography>
          <Typography color="textSecondary" gutterBottom>
            Email
            <input
              type="text"
              value={data.user.email}
              onChange={(e) => handleInputChange(e, 'email')}
            />
          </Typography>
          <Box position="relative">
            <Button
              type="submit"
              style={{ justifyContent: 'flex-start' }}
              color="success"
              variant="contained"
              onClick={handleSaveChanges}
            >
              Izmijeni podatke
            </Button>
            <Button
              style={{ justifyContent: 'flex-end', float: 'right' }}
              color="error"
              variant="contained"
              onClick={closeModalPodaciClan}
            >
              Zatvori
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Typography>
  </Box>
</Modal>

          </div>
      </div>
      <Divider />
      <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle} style={{color:"#141b2e"}}>
              Historija uplata
                </Typography>
                <Typography variant="body1" className={classes.cardDescription}  style={{color:"#141b2e"}}>
    <Box height="25vh" width={"70vh"}>
        <BarChart2 data={data}/>
      </Box>
        </Typography> 
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle}  style={{color:"#141b2e"}}>
              Historija posjeta
              </Typography>
              <Typography>
    <Box height="25vh" width={"70vh"}>
        <LineFullChart data={data.user._id}/>
      </Box>
        </Typography>  
            </CardContent>
          </Card>
        </Grid>

       
      </Grid>
      <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle}  style={{color:"#141b2e"}}>
              Raspored:
              </Typography>
              <Typography variant="body1" className={classes.cardDescription}  style={{color:"#141b2e"}}>
              <Box height="25vh" width={"70vh"}>
              <UserWeeklyCalendar data={data}/>
              </Box>
                  </Typography>   
            </CardContent>
          </Card>
        </Grid>
        {/* Ostatak koda komponenti... */}
        <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle} style={{color:"#141b2e"}}>
                Napredak treninga
                </Typography>
                <Typography variant="body1" className={classes.cardDescription}  style={{color:"#141b2e"}}>

    {/* Users should be able to view their payment history and download invoices */}
    <Box height="25vh" width={"70vh"}>
        {/* <BarChart2 data={data}/> */}
      </Box>
        </Typography> 
            </CardContent>
          </Card>
        </Grid>
        {/* Ostatak koda komponenti... */}
      </Grid>
    </Container>
  );
};

export default ViewUser;


