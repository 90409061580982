import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/vjezbe`;
// const baseUrl = 'http://localhost:3001/api/vjezbe';

// export const getAllExercise = async () => {
//   const response = await axios.get(baseUrl);
//   return response.data;
// };

export const getAllExerciseByGym = async (gymId) => {
  const response = await axios.get(`${baseUrl}/gym/${gymId}`);
  return response.data;
};

export const getExerciseById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createExercise = async (exerciseData) => {
  try {
    // const formData = new FormData();
    // formData.append('name', exerciseData.name);
    // formData.append('description', exerciseData.description);
    // formData.append('muscleGroups', JSON.stringify(exerciseData.muscleGroups));
    // formData.append('videoID', exerciseData.videoID);
    // formData.append('equipment', JSON.stringify(exerciseData.equipment));
    // formData.append('type', exerciseData.type);
    // formData.append('image', exerciseData.image);

  // formData.forEach((value, key) => {
  //     console.log(key, value);
  //   });
  const response = await axios.post(baseUrl, exerciseData);
  // console.log(exerciseData)
    // const response = await fetch('http://localhost:3001/api/vjezbe', {
    //   method: 'POST',
    //   body: exerciseData,
    //   // headers: {
    //   //   'Content-Type': 'multipart/form-data',
    //   // },
    // });
  
// console.log(response)
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
};

export const updateExercise = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteExercise = async (id) => {
  // console.log(id)
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};
