import React from 'react';
import { Box, Typography } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

const Unauthorized = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="70vh"
    >
      <LockIcon fontSize="large" color="action" />
      <Typography variant="h4" color="textSecondary" mt={2}>
      Niste ovlašteni za pristup ovoj stranici.
      </Typography>
      {/* <Typography variant="body1" color="textSecondary" mt={1}>
      Niste ovlašteni za pristup ovoj stranici.
      </Typography> */}
    </Box>
  );
};

export default Unauthorized;
