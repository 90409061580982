import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/oprema`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/oprema';

// export const getAllEquipments = async () => {
//   const response = await axios.get(baseUrl);
//   return response.data;
// };

export const getAllEquipmentsByGymId = async (gymId) => {
  const response = await axios.get(`${baseUrl}/gym/${gymId}`);
  return response.data;
};

export const getEquipmentById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createEquipment = async (newEquipment) => {
  const response = await axios.post(baseUrl, newEquipment);
  console.log(newEquipment)
  return response.data;
};

export const updateEquipment = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteEquipment = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};
