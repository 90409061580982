import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/reklama`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/oprema';

export const getAllReklama = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getReklamaById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createReklama = async (newReklama) => {
  const response = await axios.post(baseUrl, newReklama);
  return response.data;
};

export const updateReklama = async (id, updatedReklama) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedReklama);
  return response.data;
};

export const deleteReklama = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};
