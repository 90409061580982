import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/users`;


export const getUsers = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getUsersByGym = async (id) => {
  const response = await axios.get(`${baseUrl}/gym/${id}`);
  return response.data;
};

export const getUsersByRole = async () => {
  const response = await axios.get(`${baseUrl}/role`);
  return response.data;
};

// export const getUsersByRoles = async (role) => {
//   const response = await axios.get(`${baseUrl}/role/${role}`);
//   return response.data;
// };


export const getUserById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};


export const createUser = async (newUser) => {
  const response = await axios.post(baseUrl, newUser);
  // console.log("newUser",newUser)
  return response.data;
};

export const updateUser = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const updateUserImage = async (id, updatedUserImage) => {
  // console.log(id, updatedUserImage)
  const response = await axios.put(`${baseUrl}/replaceimage/${id}`, updatedUserImage);
  return response.data;
};
export const updateMembershipPaket = async (id, updatedUserMembership) => {
  // console.log(id, updatedUserMembership)
  const response = await axios.put(`${baseUrl}/membership/clanarina/promjena/${id}`, updatedUserMembership);
  return response.data;
};

export const deleteUser = async (id, queryParameters) => {
  // console.log("data", queryParameters)
  // const response = await axios.delete(`${baseUrl}/${id}`, password);
  const response = await axios.delete(`${baseUrl}/delete/${id}`, { params: queryParameters });
  // console.log(response.data);
  return response.data;
};

export const updateDefaultGym = async (userId, gymId) => {
  // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  // console.log(userId, gymId)
}