import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
import { getAllExerciseByGym,deleteExercise } from '../../api/exerciseApi';
// import ConfigApp from '../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;
import {TailSpin} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

export const muscleGroupColors = {
  "1": { group: "Ruke", color: "#2d3c93" },
  "2": { group: "Ramena", color: "#480355" },
  "3": { group: "Prsa", color: "#16a1ce" },
  "4": { group: "Ledja", color: "#019745" },
  "5": { group: "Vrat", color: "#9d8677" },
  "6": { group: "Stomak", color: "#fcb141" },
  "7": { group: "Noge", color: "#b430cb" },
  "8": { group: "Gluteus", color: "#f04238" },
  // Dodajte ostale grupe mišića i boje ovdje
};


const Exercises = (props) => {
  let gymId = useParams();
  const navigate = useNavigate();

  let newGymId = gymId?.gymID || props?.data?._id;
  // console.log(newGymId)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [exercise, setExercise] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [isExerciseCreated, setIsExerciseCreated] = useState(false);

  const [memberToDelete, setMemberToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const fetchedData = await getAllExerciseByGym(newGymId);
        setExercise(fetchedData);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  // console.log(exercise)

  useEffect(() => {
    if (!loading && isExerciseCreated) {
      toast.success('Uspješno ste obrisali vjezbu.');
      setIsExerciseCreated(false); // Resetujte stanje nakon prikazivanja poruke
    }
  }, [loading, isExerciseCreated]);

const handleDelete = (cell) => {
  setMemberToDelete(cell);
  setDeleteDialogOpen(true);
};

const handleCancelDelete = () => {
  setMemberToDelete(null);
  setDeleteDialogOpen(false);
  // setPassword('');
};

const handleConfirmDelete = async () => {
  if (memberToDelete) {
    setLoading(true);
    try {
      // Pozivanje API za brisanje vježbe
     const res =  await deleteExercise(memberToDelete.row._id);
    //  console.log(res)
      if (res.status === 201) {
        // console.log(res)
        setIsExerciseCreated(true);
      }
      // Ažuriranje stanja kako bi se uklonila obrisana vježba
      setExercise((prevExercises) => prevExercises.filter((ex) => ex._id !== memberToDelete.row._id));

      // Zatvaranje dijaloga i resetovanje stanja
      setDeleteDialogOpen(false);
      setMemberToDelete(null);
      // Ovdje možete dodati toast obavijest o uspehu
      // toast.success("Vježba je uspješno obrisana.");
    } catch (error) {
      // console.error("Došlo je do greške prilikom brisanja: ", error.response.data.message);
      // Ovdje možete dodati toast obavijest o grešci
      alert(error.response.data.message)
      // toast.error("Došlo je do greške prilikom brisanja vježbe.");
    } finally {
      setLoading(false); // Postavi loading na false nakon završetka
      // toast.success('Uspješno ste kreirali vježbu.');
  }
  }
};


  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredMembership = exercise.filter((exer) => {
    // console.log(exer)
    if (exer) {
      return exer?.name?.bs.toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });

  
  const nameRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.name.bs) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell?.row?.name.bs} / {cell?.row?.name.en}
      </p>
    );
  }


  const equipmentRender = (cell) => {
    // console.log(cell.row.equipment); // Log cell.row to check its value
    if (!cell?.row || !cell.row.equipment) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    const dataName = cell.row.equipment.map((e)=>e.name.bs)
    return (
      <p direction="row" spacing={1}>
        {`${dataName} `}
      </p>
    );
  }
  
  const muscleGroupsRender = (cell) => {
    const muscleGroupsArray = cell.row.muscleGroups[0].split(",");
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.muscleGroups) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {muscleGroupsArray?.map((item) => item.split("-")[0]) // Razdvajanje brojeva
                .filter((item, index, array) => array.indexOf(item) === index) // Uklanjanje duplih brojeva
                .map((number) => {
                  const muscleGroup = muscleGroupColors[number];
                  return (
                    <span
                    key={number}
                    style={{
                      fontWeight: 'bold',
                      color: "white",
                      marginRight: 5,
                      backgroundColor: muscleGroup?.color,
                      padding: 2,
                      borderRadius: 5,
                      overflow: 'hidden',
                      width:"100%",
                      // float:'left'
                    }}
                  >
                    {muscleGroup?.group}
                  </span>
                  );
                })}
        {/* {`${cell.row.muscleGroups} `} */}
      </p>
    );
  }
  

  const descriptionRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.description.bs) {
      return <p>Nema opisa</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.description.bs} / {cell.row.description.en}
      </p>
    );
  }
// console.log(membership)

const editRender = (cell)=>{
  // console.log(cell.row)
  const handleClick = () => {
    navigate(`/vjezba/${cell.row._id}/edit`, { state: cell.row });
  };
return( 
  // <Link data={cell} to={`/users/${cell?.id}/edit`}>
    <EditIcon onClick={handleClick}></EditIcon>
  //  </Link>
)
}


const fotografijaRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row || !cell.row.image) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  const handleClick = () => {
    navigate(`/vjezba/${cell.row._id}/detalj`, { state: cell.row });
  };
  return (
    <>
     {/* <Link data={cell} to={`/vjezba/${cell?.id}/detalj`}> */}
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.image}` }
        src={cell.row.image} 
        style={{width:"75%"}}
     onClick={handleClick}
      />
      {/* </Link> */}
    </>
  );
};

const typeRender = (cell) => {
    return( 
      <p direction="row" spacing={0}>
       {cell.row.type}
    </p>
    )
    }

  const columns = [

    { field: 'image', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'name', headerName: 'Naziv vježbe',width: 300,  
    renderCell: (params) => {
        return nameRender(params);
    }
    },
    { field: 'description', headerName: 'Opis vježbe',width: 300, 
    renderCell: (params) => {
        return descriptionRender(params);
    }
    },
    { field: 'type', headerName: 'Tip', 
    renderCell: (params) => {
        return typeRender(params);
    }
    },
    { field: 'muscleGroups', headerName: 'Grupa mišića', width: 200,
    renderCell: (params) => {
        return muscleGroupsRender(params);
    }
    },
    { field: 'equipment', headerName: 'Oprema',  width: 200,
    renderCell: (params) => {
        return equipmentRender(params);
    }
    },
    // { field: 'steps', headerName: 'Koraci',  width: 200,
    // renderCell: (params) => {
    //     return stepsRender(params);
    // }
    // },
  
    { field: 'yyy', headerName: 'Uredi',  width: 50,
    renderCell: (params) => {
        return editRender(params);
    }
    },
    { field: 'ccc', headerName: 'Brisi',  width: 50,
    renderCell: (params) => {
      return (
        <p direction="row" spacing={0}>
          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(params)}
          />
        </p>
      );
    }
    },
  ];
  // console.log(membership)
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <Box m="20px">
             <ToastContainer />
      <Header
        title="VJEŽBE"
        // subtitle="List of Contacts for Future Reference"
      />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/vjezba/new">
          <Button type="submit" color="secondary" variant="contained">
            Nova vježba
          </Button>
        </Link>
        </Box>
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={filteredMembership}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Potvrdite brisanje vjezbe!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    )}
    </>
  );
};

export default Exercises;
