import axios from 'axios';

export const fetchProtectedData = async () => {
  try {
    // Dohvati JWT token iz lokalne pohrane
    const token = localStorage.getItem('token');

    if (!token) {
      throw new Error('Niste autorizirani');
    }

    // Postavi token u zaglavlje autorizacije
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    // Dohvati zaštićene podatke
    // const response = await axios.get('http://localhost:3001/api/protected');
    // console.log("response", response)
    // // Vrati odgovor s podacima
    // return response.data;
  } catch (error) {
    console.error('Greška prilikom dohvata zaštićenih podataka', error);
    throw error;
  }
};
