import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../../theme";
import Header from "../../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
import { deleteFood, getAllFood } from '../../../api/foodApi';
import {TailSpin} from 'react-loader-spinner';
import ConfigApp from '../../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;

const Food = (props) => {
  let id = useParams();
  // const gymId = id.gymID
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [food, setFood] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // const [gym, setGym] = useState('');
  const [loading, setLoading] = useState(true);

  const [isExerciseCreated, setIsExerciseCreated] = useState(false);

  const [foodToDelete, setfoodToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);



  useEffect(() => {
    async function fetchData() {
      try {
       await getAllFood()
        .then((response) => {
          // console.log(response)
          setFood(response);
          setLoading(false);
        });;
      
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  // console.log(food)
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredfoodship = food.filter((food) => {

    if (food) {
      return food.name?.bs.toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });

  const handleDelete = (cell) => {
    setfoodToDelete(cell);
    setDeleteDialogOpen(true);
  };
  
  const handleCancelDelete = () => {
    setfoodToDelete(null);
    setDeleteDialogOpen(false);
    // setPassword('');
  };
  
  const handleConfirmDelete = async () => {
    if (foodToDelete) {
      setLoading(true);
      try {
        // Pozivanje API za brisanje vježbe
       const res =  await deleteFood(foodToDelete.row._id);
      //  console.log(res)
        if (res.status === 201) {
          // console.log(res)
          setIsExerciseCreated(true);
        }
        // Ažuriranje stanja kako bi se uklonila obrisana vježba
        setFood((prevExercises) => prevExercises.filter((ex) => ex._id !== foodToDelete.row._id));
  
        // Zatvaranje dijaloga i resetovanje stanja
        setDeleteDialogOpen(false);
        setfoodToDelete(null);
        // Ovdje možete dodati toast obavijest o uspehu
        // toast.success("Vježba je uspješno obrisana.");
      } catch (error) {
        // console.error("Došlo je do greške prilikom brisanja: ", error.response.data.message);
        // Ovdje možete dodati toast obavijest o grešci
        alert(error.response.data.message)
        // toast.error("Došlo je do greške prilikom brisanja vježbe.");
      } finally {
        setLoading(false); // Postavi loading na false nakon završetka
        // toast.success('Uspješno ste kreirali vježbu.');
    }
    }
  };


  const nameRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.name.bs) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.name.bs}/ {cell.row.name.en}
      </p>
    );
  }
  
  const brandRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.brand) {
      return <p>Nema brenda</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.brand}
      </p>
    );
  }
  
  
  const servingSizeRender = (cell) => {
    if (!cell?.row || !cell.row.servingSize) {
      return <p>Nema podataka</p>; 
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.servingSize}
      </p>
    );
  }
  const servingUnitRender = (cell) => {
    if (!cell?.row || !cell.row.servingUnit) {
      return <p>Nema podataka</p>; 
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.servingUnit}
      </p>
    );
  }

const caloriesRender = (cell) => {
  if (!cell?.row || !cell.row.calories) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.calories}
    </p>
  );
}

const carbsRender = (cell) => {
  if (!cell?.row || !cell.row.carbs) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.carbs}
    </p>
  );
}

const proteinsRender = (cell) => {
  if (!cell?.row || !cell.row.proteins) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.proteins}
    </p>
  );
}

const fatsRender = (cell) => {
  if (!cell?.row || !cell.row.fats) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.fats}
    </p>
  );
}

const fiberRender = (cell) => {
  if (!cell?.row || !cell.row.fiber) {
    return <p>0</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.fiber}
    </p>
  );
}

const unsaturatedFattRender = (cell) => {
  if (!cell?.row || !cell.row.unsaturatedFatt) {
    return <p>0</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.unsaturatedFatt}
    </p>
  );
}

const editRender = (cell)=>{
  // console.log("s",cell.row)
  const handleClick = () => {
    navigate(`/namirnice/${cell.row._id}/edit`, {state: cell.row});
  };
return( 
  // <Link data={cell} to={`/namirnice/${cell.row._id}/edit`}>
   <EditIcon onClick={handleClick}></EditIcon>
  //  </Link>
)
}
// const deleteRender = (cell)=>{
// return( 
//   <p direction="row" spacing={0}>
//     <DeleteIcon style={{ color:  'red' }}/>
// </p>
// )
// }

const fotografijaRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row || !cell.row.image) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.image}` }
        src={cell.row.image} 
        style={{width:"75%"}}
  
      />
    </>
  );
};

  const columns = [

    { field: 'image', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'name', headerName: 'Naziv', 
    renderCell: (params) => {
        return nameRender(params);
    }
    },
    { field: 'brand', headerName: 'Brend', 
    renderCell: (params) => {
        return brandRender(params);
    }
    },
    { field: 'servingSize', headerName: 'Veličina serviranja', 
    renderCell: (params) => {
        return servingSizeRender(params);
    }
    },
    { field: 'servingUnit', headerName: 'Jedinica mjere', 
    renderCell: (params) => {
        return servingUnitRender(params);
    }
    },
    { field: 'calories', headerName: 'Kalorije (gr)', 
    renderCell: (params) => {
        return caloriesRender(params);
    }
    },
    { field: 'carbs', headerName: 'Ugljiko-hidrati (gr)', 
    renderCell: (params) => {
        return carbsRender(params);
    }
    },
    { field: 'proteins', headerName: 'Proteini (gr)', 
    renderCell: (params) => {
        return proteinsRender(params);
    }
    },
    { field: 'fats', headerName: 'Masti (gr)', 
    renderCell: (params) => {
        return fatsRender(params);
    }
    },
    { field: 'unsaturatedFatt', headerName: 'Nezasićene masne kiseline (gr)', 
    renderCell: (params) => {
        return unsaturatedFattRender(params);
    }
    },
    { field: 'fiber', headerName: 'Vlakna (gr)', 
    renderCell: (params) => {
        return fiberRender(params);
    }
    },



    { field: 'yyy', headerName: 'Uredi',  width: 50,
    renderCell: (params) => {
        return editRender(params);
    }
    },
    { field: 'ccc', headerName: 'Brisi',  width: 50,
    renderCell: (params) => {
      return (
        <p direction="row" spacing={0}>
          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(params)}
          />
        </p>
      );
    }
    },
  ];
  // console.log(foodship)
  return (
    <Box m="20px">
      <Header
        title="NAMIRNICE"
        // subtitle="List of Contacts for Future Reference"
      />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/namirnice/new">
          <Button type="submit" color="secondary" variant="contained">
            Nova namirnica
          </Button>
        </Link>
        </Box>
        {loading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
        </div>
      ) : (
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={filteredfoodship}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
       )}
           <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Potvrdite brisanje namirnice!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Food;
