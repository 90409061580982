import React, { useState, useEffect, useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { Link } from 'react-router-dom';
import RemainingDays from "../components/RemainingDays";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import { EndDateConvertingWithTime } from "./DateConverting";
import { StartDateConverting } from "./DateConverting";
const ClanarinaIzvjestajKomponenta = ({ data }) => {
  // console.log(data);
  const handleExportToExcel = () => {
    const exportData = data.map((item) => ({
      Ime: item.user.firstName,
      Prezime: item.user.lastName,
      // Opis: item.opis,
      Paket: item.plan.nazivPaketa?.bs,
      'Broj Clanske': item.brojClanske,
      'Pocetak clanarine': StartDateConverting({ date: item.startDate }),
      'Kraj clanarine': StartDateConverting({ date: item.endDate }),
       Status: item.status,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clanarine');
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, `clanarine.xlsx`);
  };
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  if (!data) {
    return <div>Učitavam korisnike...</div>;
  }

  const fotografijaRender = (cell) => (

    // <Link data={cell} to={`/users/${cell.row.user?._id}`}>
      <Stack direction="row" spacing={2}>
        <Avatar alt="Image" src={cell?.row?.user?.photo} />
      </Stack>
    // </Link>
  );

  const imeRender = (cell) => (
    <p direction="row" spacing={1}>
      {cell?.row?.user?.firstName}
    </p>
  );

  const prezimeRender = (cell) => (
    <p direction="row" spacing={1}>
      {cell?.row?.user?.lastName}
    </p>
  );

  const ostaloDana = (cell) => {
    const startDate = new Date(cell.row.startDate);
    const endDate = new Date(cell.row.endDate);

    return (
      <p direction="row" spacing={1}>
        <RemainingDays startDate={startDate} endDate={endDate} membership={cell.row} />
      </p>
    );
  };

  const paketRender = (cell) => (
    <p direction="row" spacing={1}>
      {cell.row?.plan.nazivPaketa?.bs}
    </p>
  );

  const clanskaRender = (cell) => (
    <p direction="row" spacing={1}>
      {cell?.row?.user?.membership?.brojClanske}
    </p>
  );

  const placanjeRender = (cell) => {
    const payments = cell?.row?.payments || [];
    const paymentMethod = payments.length ? payments[payments.length - 1].paymentMethod : '';

    return (
      <p direction="row" spacing={1}>
        {paymentMethod}
      </p>
    );
  };
  const datumAktivacijeRender = (cell) => (
    <p direction="row" spacing={1}>
      <EndDateConvertingWithTime date={cell?.row?.startDate} />
    </p>
  );
  const datumIstekaRender = (cell) => (
    
    <p direction="row" spacing={1}>
      <EndDateConvertingWithTime date={cell?.row?.endDate} />
    </p>
  );

  const spolRender = (cell) => (
    <p direction="row" spacing={1}>
      {cell?.row?.user.spol === "male" ? "M" : cell?.row?.user.spol === "female" ? "Z" : ""}
    </p>
  );

  const statusClanarine = (cell) => (
    <p direction="row" spacing={1}>
      {cell.row.status}
    </p>
  );

  const columns = [
    { field: 'brojClanske', headerName: 'br. Clan', renderCell: clanskaRender },
    { field: 'photo', headerName: 'Foto', renderCell: fotografijaRender },
    { field: 'firstName', headerName: 'Ime', flex: 1, cellClassName: "name-column--cell", renderCell: imeRender },
    { field: 'lastName', headerName: 'Prezime', flex: 1, cellClassName: "name-column--cell", renderCell: prezimeRender },
    { field: 'datumAktivacije', headerName: 'Datum aktivacije',flex: 1,cellClassName: "name-column--cell", renderCell: datumAktivacijeRender },
    { field: 'datumIsteka', headerName: 'Datum isteka', flex: 1, cellClassName: "name-column--cell", renderCell: datumIstekaRender },
    { field: '#', headerName: 'Status', renderCell: statusClanarine },
    { field: 'plan', headerName: 'Paket', renderCell: paketRender },
    // { field: 'payments', headerName: 'Placanje', renderCell: placanjeRender },
    // { field: 'spol', headerName: 'Spol', flex: 1, renderCell: spolRender },
    { field: '-', headerName: 'Ostalo', flex: 1, renderCell: ostaloDana },
  ];

  return (
    <Box m="2px">
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
          <Box
  display="flex"
  justifyContent="flex-end"
  marginTop="0px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleExportToExcel} variant="contained" color="secondary">
  Izvezi u Excel
  </Button>
</Box>
        <DataGrid
          checkboxSelection
          pageSize={10}
          rowsPerPageOptions={[10]}
          rows={data}
          columns={columns}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default ClanarinaIzvjestajKomponenta;
