import React, { useState, useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { GymContext } from "../../theme";
import Header from "../../components/Header";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@mui/styles";
import { TextField, Button, Select, MenuItem } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { getAllFood } from "../../api/foodApi";
// import { TailSpin } from "react-loader-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  Typography,
} from "@mui/material";
import { createJelovnik } from "../../api/jelovnikApi";
import { getAllMeal } from "../../api/mealApi";
// import ConfigApp from "../../config/ConfigApp";
import calculateNutrients from "../../utils/calculateNutrients"; // Promijenite putanju prema stvarnoj lokaciji funkcije
// const imageUrl = `${ConfigApp.URLIMAGE}`;
import { handleImageUpload, MAX_IMAGE_SIZE } from '../../imageUploadUtils'; 


const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    maxWidth: "800px",
    margin: "0 auto",
  },
  inField: {
    backgroundColor: "#ffffff14",
  },
  optionContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
}));

const NutrientTable = ({ nutrients }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nutrient</TableCell>
            <TableCell>Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Calories</TableCell>
            <TableCell>{nutrients.calories}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Proteins</TableCell>
            <TableCell>{nutrients.proteins}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Carbs</TableCell>
            <TableCell>{nutrients.carbs}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fats</TableCell>
            <TableCell>{nutrients.fats}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Unsaturated Fats</TableCell>
            <TableCell>{nutrients.unsaturatedFats}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fiber</TableCell>
            <TableCell>{nutrients.fiber}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const NewJelovnik = () => {
  const { defaultGym } = useContext(GymContext);
  const classes = useStyles();

  const [foodList, setFoodList] = useState([]);
  const [image, setImage] = useState(null);
  const [name, setName] = useState({ en: '', bs: '' });
  const [description, setDescription] = useState({ en: '', bs: '' });
  // const [name, setName] = useState("");
  // const [time, setTime] = useState(null);
  // const [description, setDescription] = useState("");
  const [selectedFoods, setSelectedFoods] = useState([]);
  const [selectedTipObroka, setSelectedTipObroka] = useState("");
  const [selectedMealTimes, setSelectedMealTimes] = useState([]);
  const [loading, setLoading] = useState(true);


  const [totalNutrients, setTotalNutrients] = useState({
    calories: 0,
    proteins: 0,
    carbs: 0,
    fats: 0,
    unsaturatedFats: 0,
    fiber: 0,
  });
  const [mealOptions, setMealOptions] = useState([]);
  const [dayNutrients, setDayNutrients] = useState([]);
  // const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

  // const handleImageUpload = async (event) => {
  //   const imageFile = event.target.files[0];
    
  //   if (imageFile) {
  //     // Proverite veličinu slike
  //     if (imageFile.size > MAX_IMAGE_SIZE) {
  //       alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
  //       event.target.value = null; // Poništite odabir slike
  //       return;
  //     }
  
  //     setImage(imageFile);
  //     // setPendingImage("1");
  //   }
  // };

  const jelovnikTypes = [
    "Fitness",
    "Standardni",
    "Veganski",
    "Vegetarijanski",
    "Bezglutenski",
    "Paleo",
    "Ketogena",
    "Pescetarijanski",
    "Mediterranski",
    "Niskokalorični",
    "Visokoproteinski",
    "Makrobiotički",
    "Sirova hrana",
    "Ayurvedski",
    "Jednostavni obroci",
    "Detoksikacijski",
    "Niskomasni",
    "Energetski obroci",
    "Flexitarijanski",
    "Jelovnik za trudnice",
    "Jelovnik za dojenje",
    "Jelovnik za dijabetes",
    "Jelovnik za održavanje težine",
    "Jelovnik za visoki krvni tlak",
    "Jelovnik za alergičare",
    "Jelovnik za snagu i izdržljivost",
    "Jelovnik za mršavljenje",
    "Jelovnik za rast mišića",
    "Jelovnik za ravnotežu hormona",
    "Jelovnik za probavne smetnje",
    "Jelovnik za veganske sportaše",
    "Jelovnik za povećanje energije",
    "Jelovnik za detoksikaciju jetre",
  ];

  const brojObroka = [
    "Obrok 1",
    "Obrok 2",
    "Obrok 3",
    "Obrok 4",
    "Obrok 5",
    "Obrok 6",
    "Obrok 7",
    "Obrok 8",
    "Obrok 9",
    "Obrok 10",
    "Obrok 11",
    "Obrok 12",
    "Obrok 13",
    "Obrok 14",
    "Obrok 15",
    "Obrok 16",
    "Obrok 17",
    "Obrok 18",
    "Obrok 19",
    "Obrok 20",
  ];

  const handleTipObrokaChange = (event) => {
    setSelectedTipObroka(event.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await getAllMeal();
        setFoodList(response);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  // const calculateNutrients = (food, grams) => {
  //   const gramsFactor = grams / 100;
  //   return {
  //     calories: food.calories * gramsFactor,
  //     proteins: food.proteins * gramsFactor,
  //     carbs: food.carbs * gramsFactor,
  //     fats: food.fats * gramsFactor,
  //     unsaturatedFats: food.unsaturatedFatt * gramsFactor,
  //     fiber: food.fiber * gramsFactor,
  //   };
  // };

  const getTotalNutrients = () => {
    const newDayNutrients = [];
  
    for (const selectedFood of selectedFoods) {
      let dayTotalNutrients = {
        calories: 0,
        proteins: 0,
        carbs: 0,
        fats: 0,
        unsaturatedFats: 0,
        fiber: 0,
      };
  
      for (const meal of selectedFood.meals) {
        if (meal.mealKind) {
          const gramsFactor = meal.mealKind.foods.reduce((totalGrams, food) => {
            return totalGrams + food.grams;
          }, 0) / 100;
  
          const mealNutrients = calculateNutrients(meal.mealKind, gramsFactor);
  
          dayTotalNutrients.calories += mealNutrients.calories;
          dayTotalNutrients.proteins += mealNutrients.proteins;
          dayTotalNutrients.carbs += mealNutrients.carbs;
          dayTotalNutrients.fats += mealNutrients.fats;
          dayTotalNutrients.unsaturatedFats += mealNutrients.unsaturatedFats;
          dayTotalNutrients.fiber += mealNutrients.fiber;
        }
      }
  
      newDayNutrients.push(dayTotalNutrients);
    }
  
    setDayNutrients(newDayNutrients);
  };
  

  useEffect(() => {
    getTotalNutrients();
  }, [selectedFoods]);

  const updateSelectedFoodGrams = (foodId, newGrams) => {
    setSelectedFoods((prevState) =>
      prevState.map((item) =>
        item._id === foodId ? { ...item, grams: newGrams } : item
      )
    );
  };

  const handleAddDay = () => {
    const newDay = {
      dayName: "",
      meals: [
        {
          mealType: "",
          mealKind: null,
        },
      ],
      nutrients: {
        // calories: 0,
        // proteins: 0,
        // carbs: 0,
        // fats: 0,
        // unsaturatedFats: 0,
        // fiber: 0,
      },
    };
    setSelectedFoods((prevFoodList) => [...prevFoodList, newDay]);
    setDayNutrients((prevDayNutrients) => [...prevDayNutrients, newDay.nutrients]);
  };

  const handleRemoveDay = (dayIndex) => {
    setSelectedFoods((prevFoodList) =>
      prevFoodList.filter((_, index) => index !== dayIndex)
    );
    setDayNutrients((prevDayNutrients) =>
      prevDayNutrients.filter((_, index) => index !== dayIndex)
    );
  };

  const handleMealTimeChange = (dayIndex, mealIndex, newTime) => {
    setSelectedFoods((prevFoodList) => {
      const updatedFoodList = [...prevFoodList];
      updatedFoodList[dayIndex].meals[mealIndex].mealTime = newTime;
      return updatedFoodList;
    });
  };

  const handleAddMeal = (dayIndex) => {
    const newMeal = {
      mealType: "",
      mealKind: null,
      mealTime: null,
    };
    setSelectedFoods((prevFoodList) => {
      const updatedFoodList = [...prevFoodList];
      updatedFoodList[dayIndex].meals.push(newMeal);
      return updatedFoodList;
    });
  };

  const handleRemoveMeal = (dayIndex, mealIndex) => {
    setSelectedFoods((prevFoodList) => {
      const updatedFoodList = [...prevFoodList];
      updatedFoodList[dayIndex].meals = updatedFoodList[dayIndex].meals.filter(
        (_, index) => index !== mealIndex
      );
      return updatedFoodList;
    });
  };

  const handleDayNameChange = (dayIndex, newName) => {
    setSelectedFoods((prevFoodList) => {
      const updatedFoodList = [...prevFoodList];
      updatedFoodList[dayIndex].dayName = newName;
      return updatedFoodList;
    });
  };

  const handleMealTypeChange = (dayIndex, mealIndex, newType) => {
    setSelectedFoods((prevFoodList) => {
      const updatedFoodList = [...prevFoodList];
      updatedFoodList[dayIndex].meals[mealIndex].mealType = newType;
      return updatedFoodList;
    });
  };

  const handleFoodSelectChange = (dayIndex, mealIndex, foodId) => {
    setSelectedFoods((prevFoodList) => {
      const updatedFoodList = [...prevFoodList];
      updatedFoodList[dayIndex].meals[mealIndex].mealKind = foodId;
      return updatedFoodList;
    });
  };

  const fetchMealOptions = async (mealType) => {
    try {
      const response = await getAllMeal(mealType);
      setMealOptions(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchMealOptions(selectedTipObroka);
  }, [selectedTipObroka]);

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  // };


  const handleSubmit = async (event) => {
    event.preventDefault();

    const jelovnikData = {
      name: JSON.stringify(name),
      description: JSON.stringify(description),
      // name,
      // description,
      type: selectedTipObroka,
      mealsOption: selectedFoods.map((day) => ({
        dayName: day.dayName,
        meals: day.meals.map((meal) => ({
          mealType: meal.mealType,
          mealKind: meal.mealKind?._id,
          mealTime:meal.mealTime,
        })),
      })),
    };

    const formData = new FormData();
    formData.append("name", name);
    formData.append("image", image);
    formData.append("description", description);
    formData.append("type", selectedTipObroka);
    formData.append("jelovnikData", JSON.stringify(jelovnikData));

    // console.log([...formData]);
    // console.log(jelovnikData);
    try {
      await createJelovnik(formData);
      toast.success("Jelovnik je uspješno kreiran");
    } catch (error) {
      console.error(error);
      toast.error("Došlo je do pogreške prilikom kreiranja jelovnika");
    }
  };

  return (
    <>
      <Header />
      <Box sx={{ mt: 10, display: "flex", justifyContent: "center" }}>
      <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit}
              className={classes.form}
            >
             {/* <TextField
                label="Naziv jelovnika"
                variant="outlined"
                className={classes.inField}
                value={name}
                onChange={(event) => setName(event.target.value)}
                required
              /> */}
               <TextField
              label="Naziv jelovnika (Bosanski)"
              value={name.bs}
              onChange={(event) => setName({ ...name, bs: event.target.value })}
              variant="outlined"
              required
            />
              {/* <TextField
                label="Opis jelovnika"
                multiline
                rows={8}
                variant="outlined"
                className={classes.inField}
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                required
              /> */}
              <TextField
              label="Opis jelovnika (Bosanski)"
              multiline
              rows={8}
              value={description.bs}
              onChange={(event) => setDescription({ ...description, bs: event.target.value })}
              variant="outlined"
              required
            />

              <Select
                value={selectedTipObroka}
                onChange={handleTipObrokaChange}
                className={classes.inField}
                variant="outlined"
                label="Tip jelovnika"
              >
                {jelovnikTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="file"
                onChange={(e) => handleImageUpload(e, setImage)}
                variant="outlined"
                className={classes.inField}
                sx={{ mt: 2 }}
              />
              {/* <Button variant="contained" component="label">
                Odaberi sliku jelovnika
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleImageUpload}
                />
              </Button> */}
              <Button color="secondary" 
               onClick={handleAddDay}
              variant="contained">
                Dodaj dan
              </Button>

              {selectedFoods?.map((day, dayIndex) => (
                <div key={dayIndex} style={{ marginBottom: 10 }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom={2}
                  >
                    <TextField
                      label="Naziv dana"
                      variant="outlined"
                      className={classes.inField}
                      value={day.dayName}
                      onChange={(e) =>
                        handleDayNameChange(dayIndex, e.target.value)
                      }
                      required
                    />
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleRemoveDay(dayIndex)}
                    >
                      Ukloni dan
                    </Button>
                  </Box>

                  {day.meals.map((meal, mealIndex) => (
                    <div
                      key={mealIndex}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 10,
                      }}
                    >
                      <Select
                        value={meal.mealType}
                        onChange={(e) =>
                          handleMealTypeChange(dayIndex, mealIndex, e.target.value)
                        }
                        style={{ minWidth: 200 }}
                        variant="outlined"
                        label="Vrsta obroka"
                      >
                        {brojObroka.map((type, index) => (
                          <MenuItem key={index} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                      <Select
                        value={meal.mealKind || ""}
                        onChange={(e) =>
                          handleFoodSelectChange(
                            dayIndex,
                            mealIndex,
                            e.target.value
                          )
                        }
                        className={classes.inField}
                        variant="outlined"
                        label="Odaberi obrok"
                        renderValue={(selected) => (
                          <div className={classes.optionContainer}>
                            {selected && selected.image && (
                              <Avatar
                                alt={selected.name.bs}
                                // src={`${imageUrl}/${selected.image}`}
                                src={selected.image}
                                style={{ marginRight: 8 }}
                              />
                            )}
                            <Typography>
                              {selected ? selected.name.bs : ""}
                            </Typography>
                          </div>
                        )}
                      >
                        {mealOptions.map((option, index) => (
                          <MenuItem key={index} value={option}>
                            <div className={classes.optionContainer}>
                              {option.image && (
                                <Avatar
                                  alt={option.name.bs}
                                  // src={`${imageUrl}/${option.image}`}
                                  src={option.image}
                                  style={{ marginRight: 8 }}
                                />
                              )}
                              <Typography>{option.name.bs}</Typography>
                            </div>
                          </MenuItem>
                        ))}
                      </Select>
                      <Box flex={1} marginRight={2}>
                        <TextField
                          type="time"
                          value={meal.mealTime || ""}
                          onChange={(e) =>
                            handleMealTimeChange(
                              dayIndex,
                              mealIndex,
                              e.target.value
                            )
                          }
                          variant="outlined"
                          className={classes.inField}
                          required
                        />
                      </Box>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleRemoveMeal(dayIndex, mealIndex)}
                      >
                        Ukloni obrok
                      </Button>
                    </div>
                  ))}
                  <Button
                    type="button"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: 10 }}
                    onClick={() => handleAddMeal(dayIndex)}
                  >
                    Dodaj obrok
                  </Button>
                  <NutrientTable nutrients={dayNutrients[dayIndex]} />
                </div>
              ))}
              <Header subtitle="Engleski jezik" />
              <TextField
                label="Naziv jelovnika (Engleski)"
                value={name.en}
                onChange={(event) => setName({ ...name, en: event.target.value })}
                variant="outlined"
                // required
              />
              <TextField
                label="Opis jelovnika (Engleski)"
                multiline
                rows={4}
                value={description.en}
                onChange={(event) => setDescription({ ...description, en: event.target.value })}
                variant="outlined"
                // required
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
              <Button type="submit" color="secondary" variant="contained">
                Kreiraj jelovnik
              </Button>
            </div>
            </Box>
          </Grid>
        </Grid>
      </Box>

    </>
  );
};

export default NewJelovnik;
