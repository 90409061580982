import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Header from "../../components/Header";
import SastojciTabela from '../../components/SastojciTabela';
import { makeStyles } from '@mui/styles';

// Stilovi za komponentu
const useStyles = makeStyles((theme) => ({
  forma: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto',
    padding: 20,
    backgroundColor: "#ffffff14"
  }
}));

const JelovnikDetalj = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state: jelovnik } = useLocation();

  return (
    <> 
      <Grid container spacing={2} style={{marginLeft: 20}}>
        <Header title="Detalj jelovnika" />  
      </Grid>
      {jelovnik.mealsOption.map((dan, index) => (
        <div key={index}>
          <Typography variant="h5" style={{ marginLeft: 20 }}>Dan: {dan.dayName}</Typography>
          {dan.meals.map((obrok, obrokIndex) => (
            <Grid container spacing={2} style={{ padding: 20 }} key={obrokIndex}>
              <Grid item xs={4}>
                <div style={{ position: "relative" }}>
                  <img
                    style={{ width: "100%" }}
                    src={obrok.mealKind.image}
                    alt={`Slika obroka - ${obrok.mealKind.name}`}
                  />
                </div>
              </Grid>
              <Grid item xs={8}>
                <Paper elevation={3} className={classes.forma}>
                  <Typography variant="h6">Vrijeme obroka: {obrok.mealTime}</Typography>
                  <Typography variant="h6">Tip obroka: {obrok.mealType}</Typography>
                  <Typography variant="h6">Naziv obroka: {obrok.mealKind.name}</Typography>
                  <Typography variant="body1">Opis: {obrok.mealKind.description}</Typography>
                  <SastojciTabela data={obrok.mealKind.foods}/>
                </Paper>
              </Grid>
            </Grid>
          ))}
        </div>
      ))}
    </>
  );
};

export default JelovnikDetalj;

// import SastojciTabela from '../../components/SastojciTabela';
{/* <SastojciTabela data={data.foods}/> */}