// import React, {useState} from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { Box, Button, TextField } from "@mui/material";
// import { Formik } from "formik";
// import * as yup from "yup";
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
// import Tooltip from '@mui/material/Tooltip';
// import { createPaket } from '../../api/paketApi';

// import { styled } from '@mui/material/styles';
// import Paper from '@mui/material/Paper';
// import Grid from '@mui/material/Grid';

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));

// const NewMembership = () => {
//   const isNonMobile = useMediaQuery("(min-width:600px)");

//   const [response, setResponse] = useState("");
//   const [age, setAge] = React.useState('');

//   const handleChange = (event) => {
//     setAge(event.target.value);
//   };

//   const handleFormSubmit = async (values) => {

//       // console.log(values)
//     try {
//         const res = await createPaket(values);
//         setResponse(res.message);
//         console.log(res.message)
//       } catch (error) {
//         console.log(error);
//       }
//     // Reset form inputs
//     // setFirstName('');
//     // setLastName('');
//     // setEmail('');
//     // setPassword('');
//   };

  
//   return (
//     // <Box m="20px">
//     //   <Header title="Kreiraj novi paket" 
//     // //   subtitle="Create a New User Profile" 
//     //   />
//     //   {response}
//     //   <Formik
//     //     onSubmit={handleFormSubmit}
//     //     initialValues={initialValues}
//     //     validationSchema={checkoutSchema}
//     //   >
//     //     {({
//     //       values,
//     //       errors,
//     //       touched,
//     //       handleBlur,
//     //       handleChange,
//     //       handleSubmit,
//     //     }) => (
//     //       <form onSubmit={handleSubmit}>
//     //         <Box
//     //           display="grid"
//     //           gap="30px"
//     //           gridTemplateColumns="repeat(4, minmax(0, 1fr))"
//     //           sx={{
//     //             "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
//     //           }}
//     //         >
//     //           <TextField
//     //             fullWidth
//     //             variant="filled"
//     //             type="text"
//     //             label="Naziv paketa"
//     //             onBlur={handleBlur}
//     //             onChange={handleChange}
//     //             value={values.nazivPaketa}
//     //             name="nazivPaketa"
//     //             error={!!touched.v && !!errors.nazivPaketa}
//     //             helperText={touched.nazivPaketa && errors.nazivPaketa}
//     //             sx={{ gridColumn: "span 2" }}
//     //           />
//     //           <TextField
//     //             fullWidth
//     //             variant="filled"
//     //             type="text"
//     //             label="Cijena"
//     //             onBlur={handleBlur}
//     //             onChange={handleChange}
//     //             value={values.cijena}
//     //             name="cijena"
//     //             error={!!touched.cijena && !!errors.cijena}
//     //             helperText={touched.cijena && errors.cijena}
//     //             sx={{ gridColumn: "span 2" }}
//     //           />
//     //              <Tooltip title="
//     //     Broj dolazaka u teretanu koje korisnici dobivaju s ovim paketom.
//     //     "
//     //     >
//     //            <TextField
//     //             fullWidth
//     //             variant="filled"
//     //             type="text"
//     //             label="Broj dolazaka"
//     //             onBlur={handleBlur}
//     //             onChange={handleChange}
//     //             value={values.brojDolazaka}
//     //             name="brojDolazaka"
//     //             error={!!touched.brojDolazaka && !!errors.brojDolazaka}
//     //             helperText={touched.brojDolazaka && errors.brojDolazaka}
//     //             sx={{ gridColumn: "span 2" }}
//     //           />
//     //           </Tooltip>
//     //            <Tooltip title="
//     //     Upisite samo broj. npr:  1 (obracunva za jedan mjesec)
//     //     ">
//     //             <TextField
//     //               fullWidth
//     //               variant="filled"
//     //               type="text"
//     //               label="Trajanje paketa"
//     //               onBlur={handleBlur}
//     //               onChange={handleChange}
//     //               value={values.trajanje}
//     //               name="trajanje"
//     //               error={!!touched.trajanje && !!errors.trajanje}
//     //               helperText={touched.trajanje && errors.trajanje}
//     //               sx={{ gridColumn: "span 2" }}
//     //             />
//     //           </Tooltip>
//     //           <Tooltip title='
//     //           Na primjer, ako je paket članstva dostupan samo radnim danima od 9:00 do 17:00, "dostupno vrijeme početka" bi bilo 9:00, a "dostupno vrijeme kraja" bi bilo 17:00.
//     //           '>
//     //           <TextField
//     //               fullWidth
//     //               variant="filled"
//     //               type="text"
//     //               label="Dostupno vrijeme početka"
//     //               onBlur={handleBlur}
//     //               onChange={handleChange}
//     //               value={values.dostupnoVrijemePocetak}
//     //               name="dostupnoVrijemePocetak"
//     //               error={!!touched.dostupnoVrijemePocetak && !!errors.dostupnoVrijemePocetak}
//     //               helperText={touched.dostupnoVrijemePocetak && errors.dostupnoVrijemePocetak}
//     //               sx={{ gridColumn: "span 2" }}
//     //             />
//     //              </Tooltip>
//     //              <TextField
//     //               fullWidth
//     //               variant="filled"
//     //               type="text"
//     //               label="Dostupno vrijeme kraja"
//     //               onBlur={handleBlur}
//     //               onChange={handleChange}
//     //               value={values.dostupnoVrijemeKraj}
//     //               name="dostupnoVrijemeKraj"
//     //               error={!!touched.dostupnoVrijemeKraj && !!errors.dostupnoVrijemeKraj}
//     //               helperText={touched.dostupnoVrijemeKraj && errors.dostupnoVrijemeKraj}
//     //               sx={{ gridColumn: "span 2" }}
//     //             />
//     //           <TextField
//     //             fullWidth
//     //             variant="filled"
//     //             type="text"
//     //             label="Opis"
//     //             onBlur={handleBlur}
//     //             onChange={handleChange}
//     //             value={values.opis}
//     //             name="opis"
//     //             error={!!touched.opis && !!errors.opis}
//     //             helperText={touched.opis && errors.opis}
//     //             sx={{ gridColumn: "span 4" }}
//     //           />
//     //         </Box>
//     //         <Box display="flex" justifyContent="end" mt="20px">
//     //           <Button type="submit" color="secondary" variant="contained">
//     //            Kreiraj paket
//     //           </Button>
//     //         </Box>
//     //       </form>
//     //     )}
//     //   </Formik>
//     // </Box>
//     <Box sx={{ flexGrow: 1 }}>
//       <Grid container spacing={2}>
//         <Grid item xs={6} md={8}>
//           <Item>xs=6 md=8</Item>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Item>xs=6 md=4</Item>
//         </Grid>
//         <Grid item xs={6} md={4}>
//           <Item>xs=6 md=4</Item>
//         </Grid>
//         <Grid item xs={6} md={8}>
//           <Item>xs=6 md=8</Item>
//         </Grid>
//       </Grid>
//     </Box>
//   );
// };

// // const phoneRegExp =
// //   /^((\+[1-9]{1,4}[ -]?)|(\([0-9]{2,3}\)[ -]?)|([0-9]{2,4})[ -]?)*?[0-9]{3,4}[ -]?[0-9]{3,4}$/;

// const checkoutSchema = yup.object().shape({
//   nazivPaketa: yup.string().required("required"),
//   cijena: yup.string().required("required"),
//   trajanje: yup.string().required("required"),
//   opis: yup.string(),
// //   email: yup.string().email("invalid email").required("required"),
// //   contact: yup
// //     .string()
// //     .matches(phoneRegExp, "Phone number is not valid")
// //     .required("required"),
// //   address1: yup.string().required("required"),
// //   password: yup.string().required("required"),
// });
// const initialValues = {
//     nazivPaketa: "",
//     cijena: "",
//     opis: "",
//     trajanje: "",
// //   contact: "",
// //   address1: "",
// //   password: "",
// };

// export default NewMembership;

import React,{ useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createMembership } from '../../api/membershipApi';

function NewMembership({ user }) {
  const [gymId, setGymId] = useState('');
  const [packageId, setPackageId] = useState('');
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const membership = await createMembership(user._id, gymId, packageId);
      navigate('/home'); // redirect to dashboard after successful creation
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {error && <p>{error}</p>}
      <label>
        Select gym:
        <select value={gymId} onChange={(event) => setGymId(event.target.value)}>
          <option value="">-- Select gym --</option>
          {/* map over gyms and render option for each gym */}
        </select>
      </label>
      <br />
      <label>
        Select package:
        <select value={packageId} onChange={(event) => setPackageId(event.target.value)}>
          <option value="">-- Select package --</option>
          {/* map over packages and render option for each package */}
        </select>
      </label>
      <br />
      <button type="submit">Create membership</button>
    </form>
  );
}

export default NewMembership;