import React, { useState, useContext, useEffect } from "react";
// import { Box } from "@mui/material";
import { GymContext } from "../../../theme";
import Header from "../../../components/Header";
import {useLocation} from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@mui/styles";
import { TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { createEquipment } from "../../../api/equpmentsApi";
import { getAllFood } from "../../../api/foodApi";
import { TailSpin } from "react-loader-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createMeal, updateMeal } from "../../../api/mealApi";
import TableaHranjavihSastojaka from "../../../components/TabelaUkupnoHranjvihSastojaka";



const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    maxWidth: "800px",
    margin: "0 auto",
  },
  inField: {
    backgroundColor: "#ffffff14",
  },
}));

const EditMeal = () => {
  // const { defaultGym } = useContext(GymContext);
  const classes = useStyles();
  const { state: data } = useLocation();

  //bs
  const [name, setName] = useState(data.name.bs);
  const [description, setDescription] = useState(data.description.bs);
  //en
  const [nameEn, setNameEn] = useState(data.name.en);
  const [descriptionEn, setDescriptionEn] = useState(data.description.en);
  
  const [foodList, setFoodList] = useState([]);
  const [image, setImage] = useState(data.image);
  const [time, setTime] = useState(data.time);
  const [selectedFoods, setSelectedFoods] = useState(data.foods);
  const [selectedTipObroka, setSelectedTipObroka] = useState(data.type);
  const [loading, setLoading] = useState(true);
  const mealTypes = ["Dorucak", "Rucak", "Vecera", "Medjuobrok"];

  // console.log("selectedFoods",selectedFoods);
  const [totalNutrients, setTotalNutrients] = useState({
    calories: 0,
    proteins: 0,
    carbs: 0,
    fats: 0,
    unsaturatedFats: 0,
    fiber: 0,
  });

  const handleTipObrokaChange = (e, selectedValues) => {
    setSelectedTipObroka(selectedValues);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await getAllFood().then((response) => {
          setFoodList(response);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const calculateNutrients = (food, grams) => {
    const gramsFactor = grams / 100;
    return {
      calories: food?.calories === undefined ? food?.foodId?.calories : food?.calories * gramsFactor,
      proteins: food?.proteins === undefined ? food?.foodId?.proteins : food?.proteins * gramsFactor,
      carbs: food?.carbs === undefined ? food?.foodId?.carbs : food?.carbs * gramsFactor,
      fats: food?.fats === undefined ? food?.foodId?.fats : food?.fats * gramsFactor,
      unsaturatedFats: food?.unsaturatedFatt === undefined ? food?.foodId?.unsaturatedFatt : food?.unsaturatedFatt * gramsFactor,
      fiber: food?.fiber === undefined ? food?.foodId?.fiber : food?.fiber * gramsFactor,
    };
  };

  const getTotalNutrients = () => {
    let newTotalNutrients = {
      calories: 0,
      proteins: 0,
      carbs: 0,
      fats: 0,
      unsaturatedFats: 0,
      fiber: 0,
    };

    for (const selectedFood of selectedFoods) {
      const gramsFactor = selectedFood.grams / 100;
      newTotalNutrients.calories += selectedFood.calories === undefined ? selectedFood.foodId?.calories : selectedFood?.calories * gramsFactor;
      newTotalNutrients.proteins += selectedFood.proteins === undefined ? selectedFood.foodId?.proteins : selectedFood?.proteins * gramsFactor;
      newTotalNutrients.carbs += selectedFood.carbs === undefined ? selectedFood.foodId?.carbs : selectedFood?.carbs * gramsFactor;
      newTotalNutrients.fats += selectedFood.fats === undefined ? selectedFood.foodId?.fats : selectedFood?.fats * gramsFactor;
      newTotalNutrients.unsaturatedFats += selectedFood.unsaturatedFatt === undefined ? selectedFood.foodId?.unsaturatedFatt : selectedFood?.unsaturatedFatt * gramsFactor;
      newTotalNutrients.fiber += selectedFood.fiber === undefined ? selectedFood.foodId?.fiber : selectedFood?.fiber * gramsFactor;
    }

    setTotalNutrients(newTotalNutrients);
  };

  useEffect(() => {
    getTotalNutrients();
  }, [ selectedFoods]);

  const updateSelectedFoodGrams = (foodId, newGrams) => {
    setSelectedFoods((prevState) =>
      prevState.map((item) =>
        item._id === foodId ? { ...item, grams: newGrams } : item
      )
    );
  };
  
  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      setImage(imageFile);
    }
  };

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    
    const mealData = {
      name: {
        bs: name,
        en: nameEn,
      },
      description: {
        bs: description,
        en: descriptionEn,
      },
      time,
      type: selectedTipObroka,
      foods: selectedFoods.map((selectedFood) => {
        // Provjeravamo da li selectedFood ima svojstvo foodId i ako ima, koristimo _id iz foodId,
        // inače koristimo _id direktno iz selectedFood.
        const foodId = selectedFood.foodId ? selectedFood.foodId._id : selectedFood._id;
      
        return {
          foodId: foodId,
          grams: selectedFood.grams,
        };
      }),
      
    };

    const formData = new FormData();
    formData.append("image", image);
    formData.append("mealData", JSON.stringify(mealData));
    try {
      const res = await updateMeal(data._id,formData);
      if (res) {
        toast.success("Uspješno ste kreirali obrok.");
      }

      setName("");
      setDescription("");
      setImage(null);
      setSelectedFoods([]);
      setSelectedTipObroka("");
    } catch (error) {
      console.log(error);
      toast.error("Došlo je do greške prilikom kreiranja obroka.");
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <TailSpin height={80} width={80} />
        </div>
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Header title="Novi obrok" subtitle="" />
          <ToastContainer />
          {image && typeof image === "object" ? (
            <img style={{ width: "60%" }} src={URL.createObjectURL(image)} alt="Slika vježbe" />
            ) : (
            <img style={{ width: "60%" }} src={image} alt="Slika vježbe" />
            )}
          <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            // required
          />
          <TextField
            label="Naziv"
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            required
          />
          <Autocomplete
            single
            options={mealTypes}
            getOptionLabel={(option) => option}
            value={selectedTipObroka}
            onChange={handleTipObrokaChange}
            renderInput={(params) => (
              <TextField {...params} label="Tip obroka" variant="outlined" />
            )}
          />
          <TextField
            label="Vrijeme pripreme obroka u minutama"
            value={time}
            onChange={(event) => setTime(event.target.value)}
            variant="outlined"
            required
          />

          <TextField
            label="Opis"
            multiline
            rows={8}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="outlined"
            required
          />
    

          <Autocomplete
            multiple
            options={foodList}
            getOptionLabel={(option) => option?.foodId?.name.bs === undefined ? option?.foodId?.name?.bs : option?.foodId?.name?.bs}
            // getOptionLabel={(option) => console.log(option)}
            value={selectedFoods}
            onChange={(event, newValue) => {
              setSelectedFoods(
                newValue.map((food) => ({
                  ...food,
                  grams: food.grams || 100,
                }))
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Namirnice" variant="outlined" />
            )}
          />

          {selectedFoods.map((selectedFood) => (
            <div key={selectedFood._id}>
              {/* <h3>{selectedFood.name.bs === undefined ? selectedFood?.foodId?.name.bs : selectedFood?.name.bs}</h3> */}
              <TextField
                label="Grama"
                type="number"
                value={selectedFood.grams}
                onChange={(event) =>
                  updateSelectedFoodGrams(
                    selectedFood._id,
                    parseFloat(event.target.value)
                  )
                }
                variant="outlined"
                required
              />
            </div>
          ))}

          <div>
            <h2>Ukupno hranjivih sastojaka:</h2>
            {/* <TableaHranjavihSastojaka data={selectedFoods}/> */}
      
          </div>
          <Header subtitle="Engleski jezik" />
              <TextField
                label="Naziv obroka (Engleski)"
                value={nameEn}
                onChange={(event) => setNameEn(event.target.value)}
                variant="outlined"
                // required
              />
              <TextField
                label="Opis (Engleski)"
                multiline
                rows={4}
                value={descriptionEn}
                onChange={(event) => setDescriptionEn(event.target.value)}
                variant="outlined"
                // required
              />
          <Grid container justify="left" alignItems="left" spacing={2}>
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                Ažuriraj obrok
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default EditMeal;
