import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/messages`;


export const getAllMemssages = async (id) => {
  const response = await axios.get(`${baseUrl}`);
  return response.data;
};

export const getAllMesagesByUserId = async (userId) => {
  const response = await axios.get(`${baseUrl}/${userId}`);
  return response.data;
};

export const createMessage = async (newMessage) => {
  const response = await axios.post(baseUrl, newMessage);
  // console.log(newGym)
  return response.data;
};

// export const updateMembershipPlan = async (id, updateMembership) => {
//   //new plan
//   //endDay
//   //status
//   const response = await axios.put(`${baseUrlPlan}/${id}`, updateMembership);
//   //kreiramo novi note
//   // console.log(id, updateMembership)
//   return response.data;
// };

// export const updateMembership = async (id, updateMembership) => {
  
//   // console.log(updateMembership)
//   const response = await axios.put(`${baseUrl}/${id}`, updateMembership);
//   return response.data;
// };

// export const deleteMembership = async (id) => {
//   const response = await axios.delete(`${baseUrl}/${id}`);
//   return response.data;
// };

// export const getMembershipById = async (id) => {
//   const response = await axios.get(`${baseUrl}/${id}`);
//   return response.data;
// };
// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }