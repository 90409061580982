import React from 'react';

const StartDateAndTimeConverting = ({ datetime }) => {
  // console.log(datetime)
  const formattedDate = new Date(datetime).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  const formattedTime = new Date(datetime).toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  return (
    <div>
      <p>{formattedDate} -- {formattedTime}</p>
    </div>
  );
};

export default StartDateAndTimeConverting;
