import React,{useEffect, useState} from "react";
import StatBox from "../../components/StatBox";


const BrojClanovaSala = ({posjeteData}) =>{

    // const posjete = posjeteData;

    // Dobijte današnji datum u formatu 'YYYY-MM-DD'
    const danasnjiDatum = new Date().toISOString().split('T')[0];
    
    // Filtrirajte uplate kako biste zadržali samo one s datumom koji odgovara današnjem datumu
    const posjeteDanas = posjeteData.filter((posjeta) => {
        const datumPosjete = posjeta.timeIn.split('T')[0];
        return datumPosjete === danasnjiDatum && posjeta.timeOut === null;
      });
    
    return(
        <>
         <StatBox
           title={`${posjeteDanas.length}`}
           subtitle="Treuntni broj članova u sali"
            // subtitle="Broj aktivnih članova"
            progress="a"
          />
        
        </>
    )
}

export default BrojClanovaSala;

