import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../theme";
// import Header from "./Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
// import { getAllMembershipByGym } from "../../api/membershipApi";
// import { getAllTrener } from "../../api/trenerApi";
// import { StartDateConverting } from '../../components/DateConverting';
// import AddTaskIcon from '@mui/icons-material/AddTask';
// import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
import { getAllFood } from '../api/foodApi';
import {TailSpin} from 'react-loader-spinner';
import ConfigApp from '../config/ConfigApp';
const imageUrl = `${ConfigApp.URLIMAGE}`;

const Food = (props) => {
  let id = useParams();
  const gymId = id.gymID
  const navigate = useNavigate();
// console.log("data",props.data)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [food, setFood] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // const [gym, setGym] = useState('');
  const [loading, setLoading] = useState(false);

 // Dodajte stanje za praćenje ukupnih vrijednosti
 const [totals, setTotals] = useState({
  calories: 0,
  carbs: 0,
  proteins: 0,
  fats: 0,
  unsaturatedFatt: 0,
  fiber: 0,
  grams: 0,
});

useEffect(() => {
  // Izračunajte ukupne vrijednosti kada se podaci promijene


  const calculatedTotals = props.data.reduce((acc, item) => {
    acc.calories += item.foodId?.calories || 0;
    acc.carbs += item.foodId?.carbs || 0;
    acc.proteins += item.foodId?.proteins || 0;
    acc.fats += item.foodId?.fats || 0;
    acc.unsaturatedFatt += item.foodId?.unsaturatedFatt || 0;
    acc.fiber += item.foodId?.fiber || 0;
    acc.grams += item?.grams || 0;
    return acc;
  }, {
    calories: 0,
    carbs: 0,
    proteins: 0,
    fats: 0,
    unsaturatedFatt: 0,
    fiber: 0,
    grams: 0,
  });

  setTotals(calculatedTotals);
}, [props.data]);


  const nameRender = (cell) => {
    console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row.foodId || !cell.row.foodId.name) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.foodId.name.bs}
      </p>
    );
  }
  
  const brandRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row.foodId || !cell.row.foodId.brand) {
      return <p>Nema brenda</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.foodId.brand}
      </p>
    );
  }
  
  
  const servingSizeRender = (cell) => {
    if (!cell?.row.foodId || !cell.row.foodId.servingSize) {
      return <p>Nema podataka</p>; 
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.foodId.servingSize}
      </p>
    );
  }
  const servingUnitRender = (cell) => {
    if (!cell?.row.foodId || !cell.row.foodId.servingUnit) {
      return <p>Nema podataka</p>; 
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.foodId.servingUnit}
      </p>
    );
  }

const caloriesRender = (cell) => {
  if (!cell?.row.foodId || !cell.row.foodId?.calories) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.foodId?.calories}
    </p>
  );
}

const carbsRender = (cell) => {
  if (!cell?.row.foodId || !cell.row?.foodId?.carbs) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.foodId?.carbs}
    </p>
  );
}

const proteinsRender = (cell) => {
  if (!cell?.row.foodId || !cell.row.foodId?.proteins) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.foodId?.proteins}
    </p>
  );
}

const fatsRender = (cell) => {
  if (!cell?.row.foodId || !cell.row.foodId?.fats) {
    return <p>Nema podataka</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.foodId?.fats}
    </p>
  );
}

const fiberRender = (cell) => {
  if (!cell?.row.foodId || !cell.row.foodId?.fiber) {
    return <p>0</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.foodId?.fiber}
    </p>
  );
}

const unsaturatedFattRender = (cell) => {
  if (!cell?.row?.foodId || !cell.row.foodId?.unsaturatedFatt) {
    return <p>0</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row.foodId?.unsaturatedFatt}
    </p>
  );
}
const ukupnoGrRender = (cell) => {
  if (!cell?.row || !cell.row?.grams) {
    return <p>0</p>; 
  }
  return (
    <p direction="row" spacing={1}>
      {cell.row?.grams}
    </p>
  );
}




const fotografijaRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row.foodId || !cell.row.foodId?.image) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.image}` }
        src={cell.row.foodId?.image} 
        style={{width:"75%"}}
  
      />
    </>
  );
};

  const columns = [
    // {
    //   field: 'totals',
    //   headerName: 'Ukupno',
    //   renderCell: (params) => (
    //     <p>
    //       Kalorije: {totals.calories}, Ugljiko-hidrati: {totals.carbs}, Proteini: {totals.proteins}, 
    //       Masti: {totals.fats}, Nezasićene masne kiseline: {totals.unsaturatedFatt}, Vlakna: {totals.fiber}, 
    //       Ukupna težina: {totals.grams}
    //     </p>
    //   ),
    // },
    { field: 'image', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'name', headerName: 'Naziv', 
    renderCell: (params) => {
        return nameRender(params);
    }
    },
    { field: 'brand', headerName: 'Brend', 
    renderCell: (params) => {
        return brandRender(params);
    }
    },
   
    { field: 'calories', headerName: 'Kalorije (gr)', 
    renderCell: (params) => {
        return caloriesRender(params);
    }
    },
    { field: 'carbs', headerName: 'Ugljiko-hidrati (gr)', 
    renderCell: (params) => {
        return carbsRender(params);
    }
    },
    { field: 'proteins', headerName: 'Proteini (gr)', 
    renderCell: (params) => {
        return proteinsRender(params);
    }
    },
    { field: 'fats', headerName: 'Masti (gr)', 
    renderCell: (params) => {
        return fatsRender(params);
    }
    },
    { field: 'unsaturatedFatt', headerName: 'Nezasićene masne kiseline (gr)', 
    renderCell: (params) => {
        return unsaturatedFattRender(params);
    }
    },
    { field: 'fiber', headerName: 'Vlakna (gr)', 
    renderCell: (params) => {
        return fiberRender(params);
    }
    },
    { field: 'grams', headerName: 'Ukupna tezina', 
    renderCell: (params) => {
        return ukupnoGrRender(params);
    }
    },
    { field: 'servingUnit', headerName: 'Jedinica mjere', 
    renderCell: (params) => {
        return servingUnitRender(params);
    }
    },

  ];
  // console.log(membership)
  return (
    <Box m="20px">
  
     
        {loading ? (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
        
        </div>
      ) : (
        <Box>
        <Box m="0px 0 0 0">
          <Typography variant="h5" gutterBottom>
          Ukupne vrijednosti:
        </Typography>
        <Typography variant="body1" gutterBottom>
          Kalorije (gr): {totals.calories} 
        </Typography>
        <Typography variant="body1"  gutterBottom>
          Ugljiko-hidrati (gr): {totals.carbs}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Proteini (gr): {totals.proteins}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Masti (gr): {totals.fats}
        </Typography>
        <Typography variant="body1"  gutterBottom>
          Nezasićene masne kiseline (gr): {totals.unsaturatedFatt}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Vlakna (gr): {totals.fiber}
        </Typography>
        <Typography variant="body1" gutterBottom>
          Ukupna težina: {totals.grams}
        </Typography> 

        </Box>
        
    <Typography variant="h5">Sastojci: </Typography>
 
        <Box
          m="0px 0 0 0"
          height="50vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            pageSize={8}
            rowsPerPageOptions={[5]}
            rows={props.data}
            columns={columns}
            // components={{ Toolbar: GridToolbar }}
            getRowId={(row) =>  row._id}
          />
        </Box>
      </Box>
       )}
    </Box>
  );
};

export default Food;
