import React, { useState,useContext } from 'react';
import { GymContext } from "../../theme";
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createCost } from '../../api/trosakApi';

const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      maxWidth: '400px',
      margin: '0 auto',
    },
    inField:{
        backgroundColor:"#ffffff14"
    }

  }));

const NoviTrosak = () => {

  const classes = useStyles();

  const [naziv, setNaziv] = useState('');
  const [iznos, setIznos] = useState('');



  const handleSubmit = async (event) => {
    event.preventDefault();

    const trosakData = {
      naziv,
      iznos,
    }

    // const formData = new FormData();
    // formData.append('naziv', trosakData.naziv);
    // formData.append('iznos', trosakData.iznos);

    try {

    const res = await createCost(trosakData)
    if(res){
      // alert("Uspješno stvorena transakcija");
      toast.success('Uspješno ste kreirali trosak.');
      // alert("Uspjesno se kreirali clana");
  }

    // console.log(res)
    // Resetiranje polja forme nakon spremanja
    setNaziv('');
    setIznos('');
    // setQrCode('');
    }catch(error){
      console.log(error);
      toast.error('Došlo je do greške prilikom kreiranja troska.');

    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
        <Header title="Novi trosak" subtitle="" />
        <ToastContainer />
      <TextField
        className={classes.inField}
        label="Naziv troska"
        value={naziv}
        onChange={(event) => setNaziv(event.target.value)}
        variant="outlined"
        required
      />
      <TextField
        label="Iznos"
        className={classes.inField}
        value={iznos}
        onChange={(event) => setIznos(event.target.value)}
        variant="outlined"
        type="number" // Dodajte ovde type="number"
        required
      />
  

         <Grid container justify="left" alignItems="left" spacing={2}>
        <Grid item>
          <Button type="submit" variant="contained" color="secondary">
        Spasi
        </Button>
      </Grid>
    </Grid>
    </form>
  );
};

export default NoviTrosak;
