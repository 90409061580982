import React, { useEffect, useState } from "react";

const ProsjekPoGodinama = ({ clanoviData }) => {
  const [ukupanProsjek, setUkupanProsjek] = useState(0);
  const [brojClanova18_25, setBrojClanova18_25] = useState(0);
  const [brojClanova25_30, setBrojClanova25_30] = useState(0);
  const [brojClanova30_40, setBrojClanova30_40] = useState(0);
  const [brojClanova40_50, setBrojClanova40_50] = useState(0);
  const [brojClanova50_60, setBrojClanova50_60] = useState(0);
  const [brojClanovaManjeOd18, setBrojClanovaManjeOd18] = useState(0);
  const [brojClanovaViseOd60, setBrojClanovaViseOd60] = useState(0);
  const clanovi = clanoviData;

  // Filtrirajte članove koji imaju ispravan datum rođenja i nisu zaposlenici
  const validniClanovi = clanovi.filter((clan) => {
    const datumRodjenja = new Date(clan.date_of_birth);
    return (
      !isNaN(datumRodjenja.getTime()) &&
      clan.user.role !== ("admin" || "employee" || 'trainer')
    );
  });

  useEffect(() => {
    // Izračunajte ukupan prosjek godina za sve članove s ispravnim datumom rođenja
    const ukupnoClanova = validniClanovi.length;
    const ukupnoGodina = validniClanovi.reduce((acc, clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return acc + trenutneGodine;
    }, 0);

    const prosjekGodina =
      ukupnoClanova > 0 ? ukupnoGodina / ukupnoClanova : 0;

    setUkupanProsjek(prosjekGodina);

    // Izračunajte broj članova u različitim rasponima godina
    const brojClanova18_25 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine >= 18 && trenutneGodine <= 25;
    }).length;
    setBrojClanova18_25(brojClanova18_25);

    const brojClanova25_30 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine > 25 && trenutneGodine <= 30;
    }).length;
    setBrojClanova25_30(brojClanova25_30);

    const brojClanova30_40 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine > 30 && trenutneGodine <= 40;
    }).length;
    setBrojClanova30_40(brojClanova30_40);

    const brojClanova40_50 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine > 40 && trenutneGodine <= 50;
    }).length;
    setBrojClanova40_50(brojClanova40_50);

    const brojClanova50_60 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine > 50 && trenutneGodine <= 60;
    }).length;
    setBrojClanova50_60(brojClanova50_60);

    const brojClanovaManjeOd18 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine < 18;
    }).length;
    setBrojClanovaManjeOd18(brojClanovaManjeOd18);

    const brojClanovaViseOd60 = validniClanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      const trenutneGodine = new Date().getFullYear() - datumRodjenja.getFullYear();
      return trenutneGodine > 60;
    }).length;
    setBrojClanovaViseOd60(brojClanovaViseOd60);
  }, [validniClanovi]);

  return (
    <>
      <div>
        <h3>Ukupan prosjek godina svih članova:</h3>
        <p >Ukupan prosjek godina: {ukupanProsjek.toFixed(2)} godina</p>
        <h3>Broj članova u različitim rasponima godina:</h3>
        <p style={{margin:0, padding:0}}>Godine 18-25: {brojClanova18_25}</p>
        <p style={{margin:0, padding:0}}>Godine 25-30: {brojClanova25_30}</p>
        <p style={{margin:0, padding:0}}>Godine 30-40: {brojClanova30_40}</p>
        <p style={{margin:0, padding:0}}>Godine 40-50: {brojClanova40_50}</p>
        <p style={{margin:0, padding:0}}>Godine 50-60: {brojClanova50_60}</p>
        <p style={{margin:0, padding:0}}>Manje od 18 godina: {brojClanovaManjeOd18}</p>
        <p style={{margin:0, padding:0}}>Više od 60 godina: {brojClanovaViseOd60}</p>
      </div>
    </>
  );
};

export default ProsjekPoGodinama;
