import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';

const PieChartIzvjestajClanovi = (props) => {
  const prodatiPaketi = props.data;
  // console.log(props.data)
  if (!prodatiPaketi) {
    // Ako prodatiPaketi nije definiran, možete izvršiti odgovarajuće radnje ili prikazati poruku o grešci
    return <div>Podaci nisu dostupni</div>;
  }

  // Map to new object format
  const data = Object.keys(prodatiPaketi).map((plan, index) => ({
    id: index + 1,
    value: prodatiPaketi[plan],
    label: plan,
  }));  
  // console.log(data.value)
//   const CustomLabel = (props) => (
//     console.log(props)
// //     <text x={props.x} y={props.y} dy={12} textAnchor="middle" fill="white" fontSize={16}>
// //   {props.data.value}
// // </text>
//   );

  return (
    <PieChart
    series={[
      {
        data,
        highlightScope: { faded: 'global', highlighted: 'item' },
        faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
      },
    ]}
    height={400}
    // customComponents={{
    //   node: CustomLabel, // Koristite CustomLabel za oznake
    // }}
  />
  );
}

export default PieChartIzvjestajClanovi;
