import React, { useState, useEffect } from 'react';
import {
  Typography,
  useTheme,
  Box,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import Header from '../../components/Header';
import StatBox from '../../components/StatBox';
import { tokens } from '../../theme';
import 'react-datepicker/dist/react-datepicker.css';
import { getAllMemberships } from '../../api/membershipApi';
import { TailSpin } from 'react-loader-spinner';
import FaktureBarClanarine from '../../components/BarChartClanarine';
import ClanarinaIzvjestajKomponenta from '../../components/clanarinaIzvjestajKOmponenta';
import { EndDateConverting } from '../../components/DateConverting';
import { getPaket, getPaketWeb } from '../../api/paketApi';


// Funkcija za izračun broja aktivnih, isteklih i članarina u obradi
const calculateMembershipCounts = (memberships) => {
  let activeCount = 0;
  let expiredCount = 0;
  let obradaCount = 0;

  for (const membership of memberships) {
    if (membership.status === 'active') {
      activeCount++;
    } else if (membership.status === 'expired') {
      expiredCount++;
    } else if (membership.status === 'pending') {
      obradaCount++;
    }
  }

  return { activeCount, expiredCount, obradaCount };
};

const IzvjestajClanarine = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth() -1, 1)); 
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)); // Dodali smo 30 dana u milisekundama
  const [filteredMemberships, setFilteredMemberships] = useState([]);
  const [membershipStatus, setMembershipStatus] = useState();

  const [filteredMembershipsByStatus, setFilteredMembershipsByStatus] = useState([]);
  const [paket, setPaket] = useState("");
  const [paketi, setPaketi] = useState([]);

  const [activeMembershipsCount, setActiveMembershipsCount] = useState(0);
const [expiredMembershipsCount, setExpiredMembershipsCount] = useState(0);
const [obradaMembershipsCount, setObradaMembershipsCount] = useState(0);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  useEffect(() => {
    async function fetchData() {
      const memberships = await getAllMemberships();
      setData(memberships);
      // setLoading(false);

      const fachedPaketi = await getPaketWeb()
      setPaketi(fachedPaketi);
      setLoading(false);
    }
    
    fetchData();
  }, []);

  // Filtriraj članarine prema odabranom rasponu datuma
  useEffect(() => {
    const filtered = data.filter((membership) => {
      const membershipStartDate = new Date(membership.startDate);
      const membershipEndDate = new Date(membership.endDate);
      return membershipStartDate >= startDate && membershipEndDate <= endDate;
    });

    setFilteredMemberships(filtered);

  
  }, [data, startDate, endDate]);

  // Filtriraj članarine prema statusu
  useEffect(() => {
    // Filtriraj članarine prema statusu
    const filteredMembershipsByStatus = filteredMemberships.filter((membership) => {
      if (membershipStatus === 'active') {
        return membership.status === 'active';
      } else if (membershipStatus === 'expired') {
        return membership.status === 'expired';
      }else if (membershipStatus === 'pending') {
        return membership.status === 'pending';
      }
      return true;
    });
  
    setFilteredMembershipsByStatus(filteredMembershipsByStatus);

    
  }, [filteredMemberships, membershipStatus]);


  useEffect(() => {
    // Filtriranje članarina prema odabranom rasponu datuma
    const filteredByDate = data.filter((membership) => {
      const membershipStartDate = new Date(membership.startDate);
      const membershipEndDate = new Date(membership.endDate);
      return membershipStartDate >= startDate && membershipEndDate <= endDate;
    });
  
    // Filtriranje članarina prema odabranoj opciji iz padajućeg izbornika
    const filteredByOption = paket
      ? filteredByDate.filter((membership) => membership.plan._id === paket)
      : filteredByDate;
  
    setFilteredMemberships(filteredByOption);
  
    const { activeCount, expiredCount, obradaCount } = calculateMembershipCounts(
      filteredByOption
    );
  
    setActiveMembershipsCount(activeCount);
    setExpiredMembershipsCount(expiredCount);
    setObradaMembershipsCount(obradaCount);
  }, [data, paket, startDate, endDate]);
  



 // Pronalaženje najduže članarine
const findLongestMembership = () => {
  if (filteredMembershipsByStatus.length === 0) {
    return null; // Nema članarina
  }

  let longestMembership = data[0];

  for (const membership of data) {
    const startDate = new Date(membership.startDate);
    const endDate = new Date(membership.endDate);

    const longestStartDate = new Date(longestMembership.startDate);
    const longestEndDate = new Date(longestMembership.endDate);

    if (endDate.getTime() - startDate.getTime() > longestEndDate.getTime() - longestStartDate.getTime()) {
      longestMembership = membership;
    }
  }

  return longestMembership;
};

// Pozovite funkciju za pronalazak najduže članarine
const longestMembership = findLongestMembership();

// Provjerite jesmo li pronašli najdužu članarinu i prikažite datume
let startDateOfLongestMembership = null;
let endDateOfLongestMembership = null;

if (longestMembership) {
  startDateOfLongestMembership = new Date(longestMembership.startDate);
  endDateOfLongestMembership = new Date(longestMembership.endDate);
}


// console.log("status",filteredMembershipsByStatus)
  return (
    <>
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <TailSpin height={80} width={80} />
        </div>
      ) : (
        <Box m="20px">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Header title="IZVJESTAJ O ČLANARINAMA" />

              <Box>
                <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                >
                  {/* Dodajte opcije poslovnica */}
                </Select>
                <FormControl component="fieldset">
                  <label htmlFor="membership-status">Status članarine:</label>
                  <RadioGroup
                    row
                    aria-label="membership status"
                    name="membership-status"
                    value={membershipStatus}
                    onChange={(e) => setMembershipStatus(e.target.value)}
                  >
                    <FormControlLabel value="active" control={<Radio style={{ color: 'white' }} />} label="Aktivna" />
                    <FormControlLabel value="expired" control={<Radio style={{ color: 'white' }} />} label="Istekla" />
                    <FormControlLabel value="pending" control={<Radio style={{ color: 'white' }} />} label="U obradi" />
                  </RadioGroup>
                </FormControl>
                {/* <TextField
                  id="expiry-days"
                  label="Raspon dana isteka"
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                /> */}
                <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Paketi</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paket}
          label="Način plaćanja"
          onChange={(e) => setPaket(e.target.value)}
        >
          <MenuItem value="" disabled>Odaberi paket</MenuItem>
          {paketi?.map((e, key)=>{
                    return <MenuItem key={key} value={e._id}>{e?.nazivPaketa} -- {e?.cijena}</MenuItem>
                  })
                  }
        </Select>
      </FormControl>

                <FormControl sx={{ minWidth: '200px' }}>
                  <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    showTimeSelect={false}
                    showTimeSelectOnly={false}
                    dateFormat="dd/MM/yyyy"
                    style={{ display: 'inline-block', margin: '0 auto' }}
                  />
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    showTimeSelect={false}
                    showTimeSelectOnly={false}
                    dateFormat="dd/MM/yyyy"
                    style={{ display: 'inline-block', margin: '0 auto' }}
                  />
                  
                </FormControl>
                <FormControl sx={{ minWidth: '200px' }}>
                {longestMembership && (
    <>
      <Typography variant="h6">Najduža članarina:</Typography>
      <Typography variant="body1">
        Datum početka: {startDateOfLongestMembership.toLocaleDateString()}
      </Typography>
      <Typography variant="body1">
        Datum isteka: {endDateOfLongestMembership.toLocaleDateString()}
      </Typography>
    </>
  )}
                </FormControl>
              </Box>
       
            </Box>
          </Box>
          {/* GRID & CHARTS */}
          <Box
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="140px"
            gap="20px"
          >
            {/* ROW 1 */}
            <Box
              gridColumn="span 3"
              backgroundColor={colors.primary[400]}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <StatBox
                title={filteredMembershipsByStatus.length}
                subtitle="Broj članarina"
                progress="a"
              />
            </Box>

            <Box
  gridColumn="span 3"
  backgroundColor={colors.primary[400]}
  display="flex"
  alignItems="center"
  justifyContent="center"
>
  <StatBox
    subtitle="Aktivne članarine"
    progress="a"
    title={activeMembershipsCount}
  />
</Box>

<Box
  gridColumn="span 3"
  backgroundColor={colors.primary[400]}
  display="flex"
  alignItems="center"
  justifyContent="center"
>
  <StatBox
    subtitle="Istekle članarine"
    progress="a"
    title={expiredMembershipsCount}
  />
</Box>

<Box
  gridColumn="span 3"
  backgroundColor={colors.primary[400]}
  display="flex"
  alignItems="center"
  justifyContent="center"
>
  <StatBox
    subtitle="Članarine u obradi"
    progress="a"
    title={obradaMembershipsCount}
  />
</Box>


            <Box
  gridColumn="span 12"
  gridRow="span 5"
  backgroundColor={colors.primary[400]}
  overflow="auto"
>
  <ClanarinaIzvjestajKomponenta data={filteredMembershipsByStatus} />
</Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default IzvjestajClanarine;
