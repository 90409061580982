import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

const SpolClanova = ({ data }) => {
  const [spolData, setSpolData] = useState([]);

  // console.log("spolData",spolData)
  const groupBySpol = () => {
    let muskarci = { name: 'Muškarci', value: 0 };
    let zene = { name: 'Žene', value: 0 };

    data.forEach((clan) => {
      if (clan?.gender === 'male') {
        muskarci.value++;
      } else if (clan?.gender === 'female') {
        zene.value++;
      }
    });

    setSpolData([muskarci, zene]);
  };

  useEffect(() => {
    groupBySpol();
  }, [data]); // Run the effect whenever the data changes

  // Generate a unique key for the component based on the length of the data array
  const key = `spol-chart-${data.length}`;

  // console.log(data)

  return (
    <ResponsiveContainer key={key} width="100%" height={550}>
      <PieChart>
        <Pie
          data={spolData}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          outerRadius={120}
          fill="#8884d8"
          labelLine={false}
          label={(entry) => `${entry.name} (${entry.value})`}
        >
          {spolData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={index === 0 ? '#0088FE' : '#FFBB28'} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default SpolClanova;
