import React,{ useState, useEffect,useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import * as XLSX from 'xlsx';

const PaketiIzvjestaj = ({data}) => {

const newData = data.map((grupa, index) => ({
  id: index+1,  // Dodajte jedinstveni identifikator (možete koristiti i nešto drugo umjesto indexa)
  nazivPaketa: grupa.nazivPaketa,
  brojFaktura: grupa.fakture.length,
  fakture: grupa.fakture
  // Ostale informacije koje želite prikazati u retku
}));
// console.log("newData", newData);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }


  const handleExportToExcel = () => {
      const exportData = newData.map((item) => {
        const ukupanPrihod = item.fakture.reduce((total, faktura) => {
          return total + faktura.amount;
        }, 0);
    
        const ukupanPopust = item.fakture.reduce((totalPopust, faktura) => {
          const popust = parseFloat(faktura.popust) / 100;
          return totalPopust + faktura.amount * popust;
        }, 0);
    
        return {
          Paket: item.nazivPaketa,
          'Broj uplata': item.fakture.length,
          'Ukupno': ukupanPrihod - ukupanPopust,
        };
      });
    
      const worksheet = XLSX.utils.json_to_sheet(exportData);
    
    
    // Postavite širinu stupaca ručno
    worksheet['!cols'] = [
      { width: 40 }, // Širina za ID
      { width: 15 }, // Širina za Ime
      { width: 15 }, // Širina za Prezime
      { width: 40 }, // Širina za Opis
      // { width: 40 }, // Širina za Opis
      // Dodajte preostale širine za ostale stupce prema potrebi
    ];
  
    // Dodajte sumu iznosa na kraju tabele
    const sumFormula = `SUM(C2:C${exportData.length + 1})`; // Formula za zbrajanje iznosa
    XLSX.utils.sheet_add_aoa(worksheet, [[null, 'Total', { f: sumFormula }]], { origin: -1 });
  
    // Formatirajte stupac 'Iznos' kao broj
    for (let i = 2; i <= exportData.length + 2; i++) {
      XLSX.utils.cell_set_number_format(worksheet, `C${i}`, '$#,##0.00');
    }
  
    const workbook = XLSX.utils.book_new();
  
    // Dodajte datum i ime odobravajuće osobe u naziv datoteke
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
    // const odobravajucaOsoba = exportData.length > 0 ? exportData[0].odobrio : 'Nepoznato';
  
    XLSX.utils.book_append_sheet(workbook, worksheet, `Paketi_${formattedDate}`);
  
    // Export radne knjige u Excel datoteku
    XLSX.writeFile(workbook, `Izvjestaj_paketi_${formattedDate}.xlsx`);
  };


const ukupnoRender = (cell)=>{
  // console.log(cell.row.fakture)
     // Ukupan prihod od svih plaćenih faktura
     const ukupanPrihod = cell.row.fakture.reduce((total, faktura) => {
      return total + faktura.amount;
    }, 0);
    
    const ukupanPopust = cell.row.fakture.reduce((totalPopust, faktura) => {
      const popust = parseFloat(faktura.popust) / 100;
      return totalPopust + faktura.amount * popust;
    }, 0);
return( 
  <p direction="row" spacing={1}>
      {ukupanPrihod - ukupanPopust}
  </p>
)
}


  const columns = [
    { field: "id", headerName: "ID",},
    { field: "nazivPaketa", headerName: "Naziv paketa", flex: 0.5 },
    { field: "brojFaktura", headerName: "Broj uplata", flex: 0.5 },
    { field: 'ukupno', headerName: 'Ukupno', 
    renderCell: (params) => {
        return ukupnoRender(params);
    }
    },
    // { field: 'firstName', headerName: 'Ime', flex: 1,
    // cellClassName: "name-column--cell",
    // renderCell: (params) => {
    //     return imeRender(params);
    // }
    // },
    // { field: 'lastName', headerName: 'Prezime', flex: 1,
    // cellClassName: "name-column--cell",
    // renderCell: (params) => {
    //     return prezimeRender(params);
    // }
    // },
    // { field: 'datumIsteka', headerName: 'Datum isteka', flex: 1,
    // cellClassName: "name-column--cell",
    // renderCell: (params) => {
    //     return datumIstekaRender(params);
    // }
    // },
    // { field: '#', headerName: 'Status', 
    // renderCell: (params) => {
    //     return statusClanarine(params);
    // }
    // },
    // { field: 'plan', headerName: 'Paket', 
    // renderCell: (params) => {
    //     return paketRender(params);
    // }
    // },
    // { field: 'payments', headerName: 'Placanje', 
    // renderCell: (params) => {
    //     return placanjeRender(params);
    // }
    // },
    // { field: 'spol', headerName: 'Spol', flex: 1,
    // renderCell: (params) => {
    //     return spolRender(params);
    // }
    // },
    // { field: '-', headerName: 'Ostalo', flex: 1,
    // renderCell: (params) => {
    //     return ostaloDana(params);
    // }
    // },
  
  ];
  
  return (
    <Box m="2px">
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleExportToExcel} variant="contained" color="secondary">
  Izvezi u Excel
  </Button>
</Box>
       <DataGrid
      rows={newData}
      columns={columns}
      pageSize={10}
      rowsPerPageOptions={[10]}
      getRowId={(row) => row.id}
    />

      </Box>
    </Box>
  );
};

export default PaketiIzvjestaj;
