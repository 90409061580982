import React, {useState, useContext, useEffect} from 'react';
import { Link,useNavigate } from "react-router-dom";
import { Box, Button, TextField, Paper, Grid, Autocomplete, Select, MenuItem, Alert } from "@mui/material";
import Header from "../../components/Header";
import { Edit } from '@mui/icons-material';
import { GymContext } from "../../theme";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { updatePayment } from '../../api/paymentApi';
import MembershipRenewalForm from '../../components/MembershipRenewalForm';
import Badge from '@mui/material/Badge';
import { EndDateConverting, StartDateConverting } from '../../components/DateConverting';
// import RemainingDays from "../../components/RemainingDays";
// import { getAllMembershipByGym } from "../../api/membershipApi";
import { getMembersByGym } from "../../api/memberApi";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getClanarinaUredjivanjeByClanarinaId } from '../../api/clanarinaUredjivanjeApi';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import {TailSpin} from 'react-loader-spinner';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing.unit * 2
  },
  customBadge: {
    backgroundColor: "#f10",
    color: "white",
    // marginLeft:30,
  }
}));


const ProduziClanarinuZapocenik = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const defaultGym = JSON.parse(localStorage.getItem("defaultGym"));
  const gymID = defaultGym?._id;
  // console.log("gymID", gymID)
    const [member, setMember] = useState(null);
    const [membersdb, setMembersDb] = useState([]);
    const [members, setMembers] = useState([]);
    const [placanje, setPlacanje] = useState("");
    const [open, setOpen] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [filteredMembers, setFilteredMembers] = useState([]);
    const [clanarinaUredjivanje, setClanarinaUredjivanje] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [loading, setLoading] = useState(false);

    // console.log(member)

  const handleLoadingChange = (isLoading) => {
  
    setLoading(isLoading);

    // if(isLoading===false){
    //   // Alert("Uspješno ste produžili članarinu.");
    //   alert("Uspješno ste produžili članarinu.");
    //   // toast.success('Uspješno ste produžili članarinu.');
    // }
  };

    const handleUpdateResponse = (response) => {
      // Here, you can handle the response from the MembershipRenewalForm
      console.log('Updated response from child component:', response);
      // You can set this response to the state or perform other actions as required
      setMember(response)
    };
  // console.log(member)
    const MIN_SEARCH_LENGTH = 2;

    function getRemainingDays(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diff = Math.floor((end - start) / (1000 * 60 * 60 * 24));
        // const diffDays = Math.floor(Math.abs((start - end) / oneDay));
        const remainingDays = Math.max(0, diff);
        return remainingDays;
      }

      const handleOpenDialog = (item) => {
        setSelectedItem(item);
        setOpenDialog(true);
      };

      const handleCloseDialog = () => {
        setOpenDialog(false);
      };
useEffect(() => {
  async function fetchMembership() {
    try {
      // const fetchedsetMembership = await getAllMembershipByGym(gymID);
      const fetchedsetMembership = await getMembersByGym(gymID);
      // console.log("fetchedsetMembership", fetchedsetMembership)
      setMembersDb(fetchedsetMembership);
    } catch (error) {
      console.error(error);
    }
  }

  fetchMembership();


  async function fetchIzmenaClanarine() {
    try {
      const fetchedUPaket = await getClanarinaUredjivanjeByClanarinaId(member?.membership);
      // console.log(fetchedUPaket)
      setClanarinaUredjivanje(fetchedUPaket);
    } catch (error) {
      console.error(error);
    }
  }

  fetchIzmenaClanarine()
}, [ member?.membership]);
      
      //konvertirajte podatke o članstvu u oblik prikladan za Autocomplete
useEffect(() => {
  const memberList = membersdb.map((member) => {
    return {
      id: member.user._id,
      name: `${member.user.firstName} ${member.user.lastName}`,
      photo: member.user.photo,
      email: member.user.email,
      phone: member.user.phone,
      datumRodjenja: member.date_of_birth,
      spol: member.gender,
      height: member.height,
      weight: member.weight,
      brojClanske: member.user.membership.brojClanske,
      poslovnica:member.default_gym.naziv,
      status: member.user.membership.status,
      startDate:member.user.membership.startDate,
      endDate:member.user.membership.endDate,
      brojDnevnihDolazaka:member.user.membership.maxVisitsPerDay,
      brojFleksiblnihDolazaka:member.user.membership.maxVisits,
      paket:member?.user?.membership?.plan?.nazivPaketa.bs,
      placanje:member.payments[member?.payments?.length - 1]?.paymentMethod,
      placanjeId:member.payments[member?.payments?.length - 1],
      notes:member.notes,
      membership:member.user.membership._id,
      member:member._id
    };
  });

  setMembers(memberList);
}, [membersdb]);

// console.log(member?.id)
// const handleMembershipRenewalData = (renewalData) => {
//   console.log("Received data from MembershipRenewalForm:", renewalData);
//   // Process the data as needed
// };


const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    const payment = {

      paymentMethod: placanje,
      createdAt: new Date(),
      status: "completed",
      // description: "Opis transakcije",
      // Dodajte ovdje ostale potrebne vrijednosti za stvaranje transakcije
  }
  const updatedPayment = await updatePayment(member.placanjeId._id, payment);
  
        if(updatedPayment){
            // alert("Uspješno stvorena transakcija");
            toast.success('Uspješno se izvršili naknadno plaćanje.');
            // alert("Uspjesno se izvrsili naknadno placanje");
        }

  } catch (error) {
    toast.error('Došlo je do greške prilikom naknadnog plaćanja.');
    console.log(error);
  }
};


const handleChangePlacanje = (event) => {
  // console.log(paket)
  setPlacanje(event.target.value);
};

const memberIdToFind = member?.member;

const foundMember = membersdb?.find(member => member?._id === memberIdToFind);
// console.log(foundMember?.user.membership.status)
const handleClick = () => {
  navigate(`/users/${foundMember?.user?._id}/edit`, { state: foundMember});
};

useEffect(()=>{

},[foundMember])


  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <Box m="20px">
      <Header title="PRODUZENJE CLANARINE" subtitle="" />
      <Header title={member?.name} />
      <ToastContainer />
      {/* <AutoInput
        label="Pretrazivanje po imenu, prezimenu ili clanskom broju"
        apiUrl={`http://localhost:3001/api/membership/gym/${defaultGym._id}`}
        // apiUrlRFID={`http://localhost:3001/api/membership/gym/${defaultGym._id}/rfid`}
        onData={handleData}
      /> */}
      {/* // Autocomplete komponenta */}
      {/* <Autocomplete
        options={members}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Član" variant="outlined" />
        )}
        onChange={(event, value) => setMember(value)}
      /> */}
 <Autocomplete
  open={open}
  onOpen={() => {
    if (inputValue && inputValue.length >= MIN_SEARCH_LENGTH) {
      setOpen(true);
    }
  }}
  onClose={() => {
    if (inputValue) {
      setOpen(false);
    }
  }}
  inputValue={inputValue}
  onInputChange={(e, value, reason) => {
    setInputValue(value);

    if (!value || value.length < MIN_SEARCH_LENGTH || reason === 'reset') {
      setOpen(false);
      setFilteredMembers([]); // Resetirajte filtriranje kad se input isprazni
    } else {
      const filtered = members.filter((member) =>
        member.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredMembers(filtered); // Postavite filtrirane članove
    }
  }}
  options={filteredMembers} ///* Koristite filtrirane članove */
  getOptionLabel={(option) => option.name}
  style={{ width: 300 }}
  renderInput={(params) => (
    <TextField {...params} label="Upišite naziv člana" variant="outlined" />
  )}
  onChange={(event, value) => setMember(value)}
/>

      {member && (
        <div>
            <Grid container spacing={2}  sx={{marginTop:"50px"}}>
            <Grid item xs={4} md={2}>
            {member.notes?.length > 0 ? <Link to={`/membership/${member?.membership}/notes`}>
      {/* <ErrorIcon style={{color: "darkgoldenrod"}}/> */}
      <Badge badgeContent={member.notes?.length} color="secondary" style={{padding:"7px"}}>
      {/* <ErrorIcon style={{color: "darkgoldenrod"}}/> */}
    </Badge>
      </Link> : ""}
      <Edit onClick={handleClick} style={{ color: 'orange' }} /> 
            <Stack direction="row" spacing={2}>
            <Avatar
  alt={member.name}
  src={member.photo}
  sx={{
    width: 206,
    height: 206,
    ...(member.status === "active"
      ? {
        border: "5px solid #4cceac",
        borderRadius: "83%",
      }
      : member.status === "pending"
      ? {
          border: "5px solid #ffa502",
          borderRadius: "83%",
        }
        : member.status === "expired"
        ? {
          border: "5px solid #f10",
          borderRadius: "83%",
        }
      : {}),
  }}
/>

    </Stack>
   
</Grid>
  <Grid item xs={4} md={3}>
  <Box style={{marginLeft:20}}>   
    <p>--Podaci--</p>
    <Typography>Članska kartica: {member.brojClanske}</Typography>
    <Typography >Datum rodjenja:  <EndDateConverting date={member.datumRodjenja}/>
          <Typography>Email: {member.email}</Typography>
          <Typography>Telefon: {member.phone}</Typography>
          <Typography>Spol: {member.spol === "male" ? "Muško" : "Zensko"}</Typography>
          <Typography>Visina: {member.height}</Typography>
          <Typography>Tezina: {member.weight}</Typography>
          </Typography>
          <Typography>Poslovnica: {member.poslovnica}</Typography>
        </Box>
        
  </Grid>
  <Grid item xs={4} md={3}>
  <Typography>--Clanarina--</Typography>
          <Typography>Status: 
          {member.status === "active" ?
         <Badge badgeContent={member.status} color="secondary" style={{ padding: "7px",marginLeft:15, paddingTop:15}}></Badge>
          : member.status === "pending" ?
          <Badge badgeContent={member.status} color="warning" style={{ padding: "7px", marginLeft:10 }}></Badge>
          : <Badge
          classes={{ badge: classes.customBadge }}
          className={classes.margin}
          badgeContent={member.status}
          style={{marginLeft:30,}}
        >
         
        </Badge>
        
          // <Badge badgeContent={member.status} color="warning" style={{ marginLeft:20,marginTop:10, padding: "7px" }}></Badge>
          }
            
          </Typography>
          <Typography>Datum pocetka clanarine: <StartDateConverting date={member.startDate}/></Typography>
          <Typography>Datum isteka clanarine: <StartDateConverting date={member.endDate}/></Typography>
          <Typography>Paket: {member?.paket || member?.plan?.nazivPaketa.bs}</Typography>
          <Typography>Broj dnevnih dolazaka: {member.brojDnevnihDolazaka}</Typography>
          <Typography >Broj fleksiblnih dolazaka: {member?.brojFleksiblnihDolazaka}</Typography>
          <Typography>Nacin placanja: {member.placanje}</Typography>
          <Typography>Ostalo dana: {getRemainingDays(member.startDate, member.endDate)} </Typography>
          {/* {clanarinaUredjivanje && clanarinaUredjivanje.map((e)=>{
            return <p>Izmijenio: {e.uredio.firstName +" "+e.uredio.lastName} <StartDateConverting date={e.datumUredjivanja}/></p>
          })} */}
          
          <Button color="secondary" variant="contained" onClick={() => handleOpenDialog(clanarinaUredjivanje)}>Prikaži historiju izmjena</Button>

          <Box display="flex" mt="20px">
            
            
            
            {member.placanje == "odgodjeno" ?

            <form onSubmit={handleSubmit}>
                 <Select
                 style={{minWidth: "200px",border: "1px solid #000", color:"black", backgroundColor:"white"}}
                     labelId="demo-simple-select-label"
                     id="demo-simple-select"
                     value={placanje}
                     label="Placanje"
                     onChange={handleChangePlacanje}
                     >
         
                     <MenuItem value={"gotovina"}>Gotovina</MenuItem>
                     <MenuItem value={"kartica"}>Karica</MenuItem>
                     <MenuItem value={"POS terminal"}>POS terminal</MenuItem>
                     
                     {/* <MenuItem value={"odgodjeno"}>Odgodjeno</MenuItem> */}
                     
                 </Select>
                 <Box display="flex" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
               Izvrsi placanje
              </Button>
            </Box>
                 </form>
              : ""
            }
            </Box>
  </Grid>
  <Dialog open={openDialog} onClose={handleCloseDialog}>
  <DialogTitle>Historija izmjena</DialogTitle>
  {/* <DialogContent>
     {selectedItem && selectedItem.map((e)=>{
            return <p>Izmijenio: {e.uredio.firstName +" "+e.uredio.lastName} <StartDateConverting date={e.datumUredjivanja}/></p>
          })}
  
  </DialogContent> */}
  <DialogActions>
    <Button onClick={handleCloseDialog}>Zatvori</Button>
  </DialogActions>
</Dialog>
  <Grid item xs={4} md={3}>
  {member?.status !== "pending" && member?.placanje !== "odgodjeno" && (
  <MembershipRenewalForm 
    clan={member}
    onUpdateResponse={handleUpdateResponse}
    onLoadingChange={handleLoadingChange} 
  />
)}


  </Grid>
</Grid>
  


       
        {/* <Link to={`/membership-renewal/${member?._id}`} color="secondary" variant="contained">
       
        
        <Button color="secondary" variant="contained"> Produzi clanarinu</Button>
        </Link> */}
        </div>
      )}
    </Box>
     )}
     </>
  );
};


export default ProduziClanarinuZapocenik;
