import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
// import { getAllMembershipByGym } from "../../api/membershipApi";
// import { getAllTrener } from "../../api/trenerApi";
// import { StartDateConverting } from '../../components/DateConverting';
// import AddTaskIcon from '@mui/icons-material/AddTask';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
// import ConfigApp from '../../config/ConfigApp';
import { getAllMemssages } from '../../api/messageApi';
import StartDateAndTimeConverting from '../../components/StartDateAndTimeConverting';
// const imageUrl = `${ConfigApp.URLIMAGE}`;


const Notification = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [messages, setMessages] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllMemssages();
        setMessages(fetchedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  // console.log(messages)
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const naslovRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.title) {
      return <p>No user data available</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.title}
      </p>
    );
  }
  
// console.log(membership)
const datumRender = (cell)=>{
  // console.log(cell.row)
  return( 
    <p direction="row" spacing={1}>
      <StartDateAndTimeConverting datetime={cell.row?.createdAt}/>
      {/* {cell.row?.createdAt} */}
  </p>
  )
}


const obavjesetniRender = (cell)=>{
  // console.log(cell.row)
  return( 
    <p direction="row" spacing={1}>
      {cell.row.users.length}
  </p>
  )
}

const editRender = (cell)=>{
  // console.log(cell.row)
return( 
  <Link data={cell} to={`/users/${cell?.id}/edit`}>
   <EditIcon></EditIcon>
   </Link>
)
}
const deleteRender = (cell)=>{
return( 
  <p direction="row" spacing={0}>
    <DeleteIcon style={{ color:  'red' }}/>
</p>
)
}


  const columns = [
    // { field: "brojClanske", headerName: "br.clanske", flex: 0.5, width: 50 },
    // { field: 'brojClanske', headerName: 'br.clanske', flex: 0.5, width: 50,
    // renderCell: (params) => {
    //     return clanskaRender(params);
    // }
    // },
    // { field: 'photo', headerName: 'Foto', 
    // renderCell: (params) => {
    //     return fotografijaRender(params);
    // }
    // },
    { field: 'title', headerName: 'Naslov poruke',width: 300, 
    renderCell: (params) => {
        return naslovRender(params);
    }
    },
    { field: 'users', headerName: 'Broj obavjestenih clanova',width: 300,  
    renderCell: (params) => {
        return obavjesetniRender(params);
    }
    },
    { field: 'createdAt', headerName: 'Datum poruke',width: 300,  
    renderCell: (params) => {
        return datumRender(params);
    }
    },
    
    { field: 'yyy', headerName: 'Uredi',  width: 50,
    renderCell: (params) => {
        return editRender(params);
    }
    },
    { field: 'ccc', headerName: 'Brisi',  width: 50,
    renderCell: (params) => {
        return deleteRender(params);
    }
    },
  ];
  // console.log(membership)
  return (
    <Box m="20px">
      <Header
        title="OBAVJESTENJA"
        // subtitle="List of Contacts for Future Reference"
      />
       <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/notifikacije/novo">
          <Button type="submit" color="secondary" variant="contained">
            Nova notifikacija
          </Button>
        </Link>
        </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/notifikacije/new">
          <Button type="submit" color="secondary" variant="contained">
            Novo obavjestenje
          </Button>
        </Link>
        </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={messages}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
    </Box>
  );
};

export default Notification;
