import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Grid from '@material-ui/core/Grid';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  IconButton,
  TextField,
  Button,
  InputLabel,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ColorPickerx from '../../components/ColorPicker';
import { getGymById, updateGym } from '../../api/gymApi';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
    marginBottom: '100px',
  },
  inField: {
    backgroundColor: '#ffffff14',
  },
  avatar: {
    borderRadius: 0,
  },
}));

const EditGym = () => {
  const classes = useStyles();
  const { gymId } = useParams();
  const [response, setResponse] = useState({});
  const [gymData, setGymData] = useState({});
  const [color, setColor] = useState(response.color || '#fff');
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState();

  useEffect(() => {
    async function fetchGym() {
      try {
        const response = await getGymById(gymId);
        setResponse(response);
        setGymData({
          naziv: response?.naziv || '',
          address: response?.address || '',
          city: response?.city || '',
          zipCode: response?.zipCode || '',
          phone: response?.phone || '',
          transakRacun: response?.transakRacun || '',
          email: response?.email || '',
          opis: {
            bs: response?.opis?.bs || '',
            en: response?.opis?.en || '',
          },
          kapacitet: response?.kapacitet || '',
          ...response?.workingDays?.reduce((acc, day) => {
            acc[`${day.day}WorkingHours`] = day.hours;
            return acc;
          }, {}),
        });
        setImage(response.photo);
      } catch (error) {
        console.error(error);
      }
    }

    fetchGym();
  }, [gymId]);

  const handleColorChange = (newColor) => {
    setColor(newColor);
  };

  // console.log(gymData)

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('naziv', gymData.naziv);
    formData.append('address', gymData.address);
    formData.append('city', gymData.city);
    formData.append('zipCode', gymData.zipCode);
    formData.append('phone', gymData.phone);
    formData.append('email', gymData.email);
    formData.append('image', image);
    formData.append('transakRacun', gymData.transakRacun);
    formData.append('opis', JSON.stringify(gymData.opis));
    formData.append('kapacitet', gymData.kapacitet);

    try {
      setLoading(true);

      const workingDays = days.map((day) => ({
        day,
        hours: gymData[`${day}WorkingHours`],
      }));
      formData.append('workingDays', JSON.stringify(workingDays));

      const updatedGym = await updateGym(gymId, formData);
      setResponse(updatedGym);
      setLoading(false);
      toast.success('Uspješno ste izmijenili podatke poslovnice.');
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error('Došlo je do greške.');
    }
  };

  const MAX_IMAGE_SIZE = 200 * 1024;

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];

    if (imageFile) {
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null;
        return;
      }

      setImage(imageFile);
    }
  };

  const days = [
    'Ponedjeljak',
    'Utorak',
    'Srijeda',
    'Cetvrtak',
    'Petak',
    'Subota',
    'Nedjelja',
  ];

  return (
    <Grid container justify="center" style={{ height: '100vh' }}>
        {loading ? (
      // Prikaz učitavanja
      <div>Loading...</div>
    ) : (
      <Grid item xs={6}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Header title={`UREDI POSLOVNICU: ${response?.naziv}`} />
          <ToastContainer />

          {typeof image === 'string' ? (
            <Avatar
              alt={response.name}
              src={image}
              className={classes.avatar}
              sx={{ width: '100%', height: '100%', borderRadius: 0 }}
            />
          ) : (
            <Avatar
              alt={response.name}
              src={image ? URL.createObjectURL(image) : ''}
              className={classes.avatar}
              sx={{ width: '100%', height: '100%', borderRadius: 0 }}
            />
          )}

          <label htmlFor="upload-photo">
            <input
              type="file"
              id="upload-photo"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
            <IconButton component="span">
              <AddPhotoAlternateIcon style={{ color: 'white' }} />
            </IconButton>
          </label>

          <InputLabel htmlFor="naziv">Naziv</InputLabel>
          <TextField
            className={classes.inField}
            id="naziv"
            value={gymData.naziv}
            onChange={(event) => setGymData({ ...gymData, naziv: event.target.value })}
            variant="outlined"
            required
          />

          <InputLabel htmlFor="Adresa">Adresa</InputLabel>
          <TextField
            className={classes.inField}
            id="Adresa"
            value={gymData.address}
            onChange={(event) => setGymData({ ...gymData, address: event.target.value })}
            variant="outlined"
            required
          />

          <InputLabel htmlFor="Grad">Grad</InputLabel>
          <TextField
            className={classes.inField}
            id="Grad"
            value={gymData.city}
            onChange={(event) => setGymData({ ...gymData, city: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="posbr">Poštanski broj</InputLabel>
          <TextField
            className={classes.inField}
            id="posbr"
            value={gymData.zipCode}
            onChange={(event) => setGymData({ ...gymData, zipCode: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="trr">Transak racun</InputLabel>
          <TextField
            className={classes.inField}
            id="trr"
            type="number"
            value={gymData.transakRacun}
            onChange={(event) => setGymData({ ...gymData, transakRacun: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="Kapacitet">Kapacitet</InputLabel>
          <TextField
            className={classes.inField}
            id="Kapacitet"
            type="number"
            value={gymData.kapacitet}
            onChange={(event) => setGymData({ ...gymData, kapacitet: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="Telefon">Telefon</InputLabel>
          <TextField
            className={classes.inField}
            id="Telefon"
            value={gymData.phone}
            onChange={(event) => setGymData({ ...gymData, phone: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="Email">Email</InputLabel>
          <TextField
            className={classes.inField}
            label="Email"
            type="email"
            value={gymData.email}
            onChange={(event) => setGymData({ ...gymData, email: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="Opis">Opis</InputLabel>
          <TextField
            className={classes.inField}
            id="Opis"
            multiline
            rows={4}
            value={gymData?.opis?.bs}
            onChange={(event) => setGymData({ ...gymData, opis: { ...gymData.opis, bs: event.target.value } })}
            variant="outlined"
          />

          {days.map((day) => (
            <React.Fragment key={day}>
              <InputLabel htmlFor={`rdv-${day}`}>{`Radno vrijeme za ${day}`}</InputLabel>
              <TextField
                id={`rdv-${day}`}
                className={classes.inField}
                value={gymData[`${day}WorkingHours`]}
                onChange={(event) =>
                  setGymData({
                    ...gymData,
                    [`${day}WorkingHours`]: event.target.value,
                  })
                }
                variant="outlined"
              />
            </React.Fragment>
          ))}

          <Header subtitle="Engleski jezik" />

          <TextField
            label="Opis (Engleski)"
            multiline
            rows={4}
            value={gymData?.opis?.en}
            onChange={(event) => setGymData({ ...gymData, opis: { ...gymData.opis, en: event.target.value } })}
            variant="outlined"
          />

          <ColorPickerx onColorChange={handleColorChange} color={color} />

          <Button type="submit" variant="contained" color="secondary">
            Sačuvaj promene
          </Button>
        </form>
      </Grid>
          )}
    </Grid>
  );
};

export default EditGym;
