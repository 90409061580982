const muscleGroupColors = {
    "1": { group: "Ruke", color: "#2d3c93" },
    "2": { group: "Ramena", color: "#480355" },
    "3": { group: "Prsa", color: "#16a1ce" },
    "4": { group: "Ledja", color: "#019745" },
    "5": { group: "Vrat", color: "#9d8677" },
    "6": { group: "Stomak", color: "#fcb141" },
    "7": { group: "Noge", color: "#b430cb" },
    "8": { group: "Gluteus", color: "#f04238" },
    // Dodajte ostale grupe mišića i boje ovdje
  };

export default muscleGroupColors;