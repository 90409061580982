import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { getAllReservationByGroupId } from '../../api/reservationsApi';

const UserWeeklyCalendar = (props) => {

  const group = props.data.groupPrograms;
  // console.log("group",group);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [minTime, setMinTime] = useState(moment().hours(8).minutes(0));
  const [reservations, setReservations] = useState([]);

  useEffect(() => {
    async function fetchReservations() {
      try {
        const groupIds = group.map((g) => g._id);
        const reservationsForGroups = await fetchReservationsForGroups(groupIds);
        setReservations(reservationsForGroups);
        // console.log(reservationsForGroups)
        const sortedReservations = reservationsForGroups.sort((a, b) => {
          const startTimeA = moment(`${a.day} ${a.startTime}`, 'dddd HH:mm');
          const startTimeB = moment(`${b.day} ${b.startTime}`, 'dddd HH:mm');
          if (startTimeA.isBefore(startTimeB)) {
            return -1;
          } else if (startTimeA.isAfter(startTimeB)) {
            return 1;
          } else {
            return 0;
          }
        });

        if (sortedReservations.length > 0) {
          const earliestReservation = sortedReservations[0];
          const startTime = moment(`${earliestReservation.day} ${earliestReservation.startTime}`, 'dddd HH:mm');
          setMinTime(startTime);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchReservations();
  }, [group]);

  const fetchReservationsForGroups = async (groupIds) => {
    const promises = groupIds.map((groupId) => getAllReservationByGroupId(groupId));
const reservationsForGroups = await Promise.all(promises);
return reservationsForGroups.flat();
}

const handleEventSelect = (event) => {
setSelectedEvent(event);
};

const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const today = moment();
const weekStart = today.clone().startOf('week');
const weekEnd = today.clone().endOf('week');

const weekDays = [];
for (let i = 0; i <= 6; i++) {
const date = weekStart.clone().add(i, 'day');
weekDays.push(date);
}

const getEventsForDay = (day) => {
return reservations.filter((reservation) => {
const reservationDay = moment(reservation.day, 'dddd');
return reservationDay.isSame(day, 'day');
});
};

return (
<div>
<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
{weekDays?.map((day, index) => (
<div key={index} style={{ margin: '0', border: "1px solid" }}>
<div style={{borderBottom:"1px solid"}}>
<div style={{ fontWeight: 'bold', padding:"10px" }}>{daysOfWeek[index]}</div>
<div style={{ fontWeight: 'bold', padding:"10px" }}>{day.format('MMM DD')}</div>
</div>
{getEventsForDay(day)?.map((reservation) => (
<div
key={reservation.id}
style={{ margin: '0px', padding: '0px', border: '1px solid black',backgroundColor: reservation.color }}
>
<div style={{ fontWeight: 'bold' }}>{reservation.group.name}</div>
<div>{moment(reservation.startTime, 'HH:mm').format('h:mm a')} - {moment(reservation.endTime, 'HH:mm').format('h:mm a')}</div>
<div>{reservation.group.gym.naziv} - {reservation?.group?.trainer?.user?.firstName} {reservation?.group?.trainer?.user?.lastName}</div>
</div>
))}
</div>
))}
</div>
</div>
);
};

export default UserWeeklyCalendar;
