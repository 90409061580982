import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
// import { getAllMembershipByGym } from "../../api/membershipApi";
// import { getMembersByGym } from "../../api/memberApi";
import { StartDateConverting } from '../../components/DateConverting';
// import AddTaskIcon from '@mui/icons-material/AddTask';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
import {deleteTreningPrograms, getAllTreningProgramsByGym } from '../../api/treningProgramApi';
import {TailSpin} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// import ConfigApp from '../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;


const TreningPrograms = (props) => {
  let gymId = useParams();
  // const navigate = useNavigate();

  let newGymId = gymId?.gymID || props?.data?._id;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [programs, setPrograms] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [loading, setLoading] = useState(false);
  const [equpmentToDelete, setEqupmentToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isExerciseCreated, setIsExerciseCreated] = useState(false);

  // const [equipments, setEquipments] = useState([]);

  useEffect(() => {
    async function fetchPaket() {
      try {
        setLoading(true);
        const fetchedMembership = await getAllTreningProgramsByGym(newGymId);
        setPrograms(fetchedMembership);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchPaket();
  }, []);

// console.log(programs)

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };



  const handleDelete = (cell) => {
    // console.log(cell)
    setEqupmentToDelete(cell);
    setDeleteDialogOpen(true);
  };
  
  const handleCancelDelete = () => {
    setEqupmentToDelete(null);
    setDeleteDialogOpen(false);
    // setPassword('');
  };
  useEffect(() => {
    if (!loading && isExerciseCreated) {
      toast.success('Uspješno ste obrisali opremu.');
      setIsExerciseCreated(false); // Resetujte stanje nakon prikazivanja poruke
    }
  }, [loading, isExerciseCreated]);


  const handleConfirmDelete = async () => {
    if (equpmentToDelete) {
      setLoading(true);
      try {
        // Pozivanje API za brisanje vježbe
        const res = await deleteTreningPrograms(equpmentToDelete.row._id);
        // console.log(res)
        if (res.status === 201) {
          // console.log(res)
          setIsExerciseCreated(true);
        }
  
        // Ažuriranje stanja kako bi se uklonila obrisana vježba
        // setEquipments((prevExercises) => prevExercises.filter((ex) => ex._id !== equpmentToDelete.row._id));
  
        // Zatvaranje dijaloga i resetovanje stanja
        setDeleteDialogOpen(false);
        setEqupmentToDelete(null);
        // Ovdje možete dodati toast obavijest o uspehu
        // toast.success("Vježba je uspješno obrisana.");
      } catch (error) {
        // console.error("Došlo je do greške prilikom brisanja: ", error.response.data.message);
        // Ovdje možete dodati toast obavijest o grešci
        alert(error.response.data.message)
        // toast.error("Došlo je do greške prilikom brisanja vježbe.");
      } finally {
        setLoading(false); // Postavi loading na false nakon završetka
        // toast.success('Uspješno ste kreirali vježbu.');
    }
    }
  };
  

  const filteredMembership = programs.filter((member) => {

    if (member?.name) {
      return member?.name?.bs?.toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });

  const nameRender = (cell) => {
    if (!cell?.row || !cell.row.name.bs) {
      return <p>No name data available</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell?.row?.name?.bs}
      </p>
    );
  }
  
// console.log(membership)

const  opisRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row.description.bs}
  </p>
  )
}

const startDateRender = (cell)=>{
  // console.log(cell.row)
  if (!cell?.row || !cell.row.startDate) {
    return <p>-</p>; // Return a placeholder value or an error message
  }
  return( 
    <p direction="row" spacing={1}>
      <StartDateConverting date={cell.row?.startDate}/>
      {/* {cell.row.startDate} */}
  </p>
  )
}
const endDateRender = (cell)=>{
  if (!cell?.row || !cell.row.endDate) {
    return <p>-</p>; // Return a placeholder value or an error message
  }
  return( 
    <p direction="row" spacing={1}>
      <StartDateConverting date={cell.row?.endDate}/>
      {/* {cell.row.startDate} */}
  </p>
  )
}



// const editRender = (cell)=>{
//     console.log("ss",cell.row._id)
//   const handleClick = () => {
//     navigate(`/programs/${cell.row._id}/edit`, { state: cell.row });
//   };
// return( 
//   // <Link data={cell} to={`/users/${cell?.id}/edit`}>
//     <EditIcon onClick={handleClick}></EditIcon>
//   //  </Link>
// )
// }

// const deleteRender = (cell)=>{
// return( 
//   <p direction="row" spacing={0}>
//     <DeleteIcon style={{ color:  'red' }}/>
// </p>
// )
// }

const fotografijaRender = (cell) => {
  // console.log(cell.row._id); // Log cell.row to check its value
  if (!cell?.row || !cell.row.image) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  // const handleClick = () => {
  //   navigate(`/users/${cell.row.user._id}`, { state: cell.row });
  // };
  // const handleClick = () => {
  //   navigate(`/programs/${cell.row._id}/detalj`, { state: cell.row });
  // };
  
  return (
    <>
    <Link to={`/programs/${cell.row._id}/detalj`}>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.image}` }
        src={cell.row.image} 
        style={{width:"75%"}}
        // onClick={handleClick}
      />
    </Link>
    </>
  );
};

//Kalkulacija broj dana se obracunava u kompomenti RemainigDays
const brojVjezbiRender = (cell)=>{
  let sumOfexercis = 0
  cell.row?.days.map((e)=>console.log(sumOfexercis += e.exercises.length))
return( 
  <p direction="row" spacing={1}>
   {sumOfexercis}
</p>
)
}

const brojObrokaRender = (cell)=>{
  let sumOfmeals = 0
  cell.row?.days.map((e)=>console.log(sumOfmeals += e.meals.length))
return( 
  <p direction="row" spacing={1}>
   {sumOfmeals}
</p>
)
}

const trajanjeRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row.duration} min
  </p>
  )
}
const ciljRender = (cell)=>{
  
  return( 
    <p direction="row" spacing={1}>
      {cell.row.goal}
  </p>
  )
}

const intensityRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row.intensity}
  </p>
  )
}
const maxVisitsPerDayRender = (cell)=>{
  const maxVisits =cell.row.user.membership.maxVisitsPerDay
  return( 
    <p direction="row" spacing={1}>
      {maxVisits  ? maxVisits : "-"}
  </p>
  )
}

const typeRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row.type}
  </p>
  )
}
const levelRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row.level}
  </p>
  )
}
  const columns = [
    // { field: "brojClanske", headerName: "br.clanske", flex: 0.5, width: 50 },
    // { field: 'brojClanske', headerName: 'br.clanske', flex: 0.5, width: 50,
    // renderCell: (params) => {
    //     return clanskaRender(params);
    // }
    // },
    { field: 'image', headerName: 'Slika', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'name', headerName: 'Naziv programa', 
    renderCell: (params) => {
        return nameRender(params);
    }
    },
    { field: 'description', headerName: 'Opis', 
    renderCell: (params) => {
        return opisRender(params);
    }
    },
    { field: 'duration', headerName: 'Trajanje', 
    renderCell: (params) => {
        return trajanjeRender(params);
    }
    },
    // { field: 'payments', headerName: 'Placanje', 
    // renderCell: (params) => {
    //     return placanjeRender(params);
    // }
    // },
    { field: 'startDate', headerName: 'Pocetak programa', 
    renderCell: (params) => {
        return startDateRender(params);
    }
    },
    { field: 'endDate', headerName: 'Kraj programa', 
    renderCell: (params) => {
        return endDateRender(params);
    }
    },
    { field: 'exercises', headerName: 'Broj vjezbi',  width: 80,
    renderCell: (params) => {
        return brojVjezbiRender(params);
    }
    },
    { field: 'meals', headerName: 'Broj obroka',  width: 80,
    renderCell: (params) => {
        return brojObrokaRender(params);
    }
    },
    { field: 'goal', headerName: 'Cilj', flex: 0.5,
    renderCell: (params) => {
        return ciljRender(params);
    }
    },
    { field: 'intensity', headerName: 'Intenzitet', flex: 1,
    renderCell: (params) => {
        return intensityRender(params);
    }
    },
    { field: 'level', headerName: 'Nivo', flex: 1,
    renderCell: (params) => {
        return levelRender(params);
    }
    },
    { field: 'type', headerName: 'Tip', flex: 1,
    renderCell: (params) => {
        return typeRender(params);
    }
    },

    { field: 'ccc', headerName: 'Brisi',  width: 50,
    renderCell: (params) => {
      return (
        <p direction="row" spacing={0}>
          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(params)}
          />
        </p>
      );
    }
    },
  ];
  // console.log(membership)
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <Box m="20px">
       <ToastContainer />
      <Header
        title="PROGRAMI"
        // subtitle="List of Contacts for Future Reference"
      />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/programs/new">
          <Button type="submit" color="secondary" variant="contained">
            Novi program
          </Button>
        </Link>
      
        </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={filteredMembership}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Potvrdite brisanje programa!
          </DialogContentText>
          {/* {memberToDelete?.row?.user?.firstName + " " + memberToDelete?.row?.user?.lastName } */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Šifra"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.blackBorder} 
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
     )}
     </>
  );
};

export default TreningPrograms;
