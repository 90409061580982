import React,{useEffect, useState} from "react";
import StatBox from "../../components/StatBox";


const UplateDanas = (uplateData) =>{

    const uplate = uplateData.uplateData;
  // console.log(uplate)
    // Dobijte današnji datum u formatu 'YYYY-MM-DD'
    const danasnjiDatum = new Date().toLocaleString('en-US', { timeZone: 'Europe/Belgrade' }).split(',')[0];
    
    // Filtrirajte uplate kako biste zadržali samo one s datumom koji odgovara današnjem datumu
    const uplateDanas = uplate.filter((uplata) => {
      const uplataDatum = new Date(uplata.createdAt).toLocaleString('en-US', { timeZone: 'Europe/Belgrade' }).split(',')[0];
      // console.log(uplataDatum)
      return uplataDatum === danasnjiDatum &&  uplata.status !== "pending";
    });
   
    // console.log(uplateDanas)
    // Izračunajte ukupan prihod od uplata za današnji datum
    const ukupanPrihodDanas = uplateDanas.reduce((acc, cur) => {
      if (cur.paymentMethod !== "odgodjeno") {
        return acc + cur.amount;
      } else {
        return acc;
      }
    }, 0);
    
    // console.log(uplateDanas); // Ovdje ćete imati sve uplate za današnji datum
    // console.log(ukupanPrihodDanas); // Ovdje ćete imati ukupan prihod od uplata za današnji datum
    

    return(
        <>
         <StatBox
           title={`${ukupanPrihodDanas} KM`}
            subtitle="Uplate danas"
            title2={`${uplateDanas.length} uplate`}
            // subtitle="Broj aktivnih članova"
            progress="a"
          />
        
        </>
    )
}

export default UplateDanas;

