import React, { useState } from 'react';
import { Box, Button, TextField, Typography, IconButton, Avatar } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Header from '../../components/Header';
import { createGym } from '../../api/gymApi';
import ColorPickerx from '../../components/ColorPicker';
import { ToastContainer, toast } from 'react-toastify';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
    marginBottom: '100px',
    borderRadius: 0,
  },
  inField: {
    backgroundColor: "#ffffff14"
  }
}));

const NewGym = () => {
  const [color, setColor] = useState('#fff');
  const [selectedImage, setSelectedImage] = useState(null); 
  const classes = useStyles();
  const [workingHours, setWorkingHours] = useState({
    Ponedjeljak: '', Utorak: '', Srijeda: '', Cetvrtak: '', Petak: '', Subota: '', Nedjelja: ''
  });


  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB

  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    if (imageFile && imageFile.size > MAX_IMAGE_SIZE) {
      toast.error('Slika je prevelika. Maksimalna veličina je 200 KB.');
      return;
    }
    setSelectedImage(imageFile);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    if (selectedImage) {
      formData.append('image', selectedImage);
    }
    formData.append('color', color);

    Object.keys(workingHours).forEach(day => {
      formData.append(`${day}`, workingHours[day]);
    });


    try {
      const res = await createGym(formData);
      toast.success(res.message);
    } catch (error) {
      toast.error('Došlo je do greške pri kreiranju poslovnice.');
    }
  };

  const handleColorChange = (color) => {
    setColor(color);
  };


  const handleWorkingHoursChange = (day, value) => {
    setWorkingHours(prev => ({ ...prev, [day]: value }));
  };


  return (
    <Box m="20px">
      <Header title="Kreiraj novu poslovnicu" subtitle="Ovdje možete kreirati novu poslovnicu" />
      <ToastContainer />
      <form onSubmit={handleFormSubmit} className={classes.form}>
        <label htmlFor="upload-photo">
          <input
            type="file"
            id="upload-photo"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleImageUpload}
          />
          <IconButton component="span">
            <AddPhotoAlternateIcon style={{ color: 'white' }} />
          </IconButton>
        </label>
        {selectedImage && (
          <Avatar
            src={URL.createObjectURL(selectedImage)}
            className={classes.avatar}
            sx={{ width: 200, height: 200 }}
          />
        )}
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Naziv"
          name="naziv"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Adresa"
          name="address"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Grad"
          name="city"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Poštanski broj"
          name="zipCode"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Telefon"
          name="phone"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Email"
          name="email"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="number"
          label="Kapacitet"
          name="kapacitet"
          sx={{ gridColumn: 'span 2' }}
        />
        <TextField
          fullWidth
          variant="filled"
          type="text"
          label="Transakcijski račun"
          name="transakRacun"
          sx={{ gridColumn: 'span 2' }}
        />

{Object.keys(workingHours).map(day => (
          <TextField
            key={day}
            fullWidth
            variant="filled"
            type="text"
            label={`${day} Radno Vrijeme`}
            value={workingHours[day]}
            onChange={(e) => handleWorkingHoursChange(day, e.target.value)}
            sx={{ gridColumn: 'span 2' }}
          />
        ))}

        <TextField
          fullWidth
          multiline
          rows={4}
          variant="filled"
          type="text"
          label="Opis (Bosanski)"
          name="opis.bs"
          // Dodajte ostale event handlere...
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          variant="filled"
          type="text"
          label="Opis (English)"
          name="opis.en"
          // Dodajte ostale event handlere...
        />
        <ColorPickerx onColorChange={handleColorChange} color={color} />
        
        <Button type="submit" color="secondary" variant="contained">
          Kreiraj poslovnicu
        </Button>
      </form>
    </Box>
  );
};

export default NewGym;
