import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/settings`;
// const baseUrl = 'http://localhost:3001/api/namirnice';

export const getSettings = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const createSettings = async (newSettings) => {
  const response = await axios.post(baseUrl, newSettings);
//   console.log(newFood)
  return response.data;
};

// export const updateFood = async (id, updatedUser) => {
//   const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   return response.data;
// };

// export const deleteFood = async (id) => {
//   const response = await axios.delete(`${baseUrl}/${id}`);
//   return response.data;
// };


export const updateSettings = async (status, settingsId) => {
    // console.log(status, settingsId)
    const response = await axios.put(`${baseUrl}/${settingsId}`, { status });
    return response.data;
  };