import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Box, Container, Typography, Grid, Paper, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import {getGroupById} from '../../api/groupApi'
// import {getUserById} from '../../api/userApi'
import Button from '@mui/material/Button';
// import GroupCalendar from '../../components/groupSchedule';
// import ClearIcon from '@mui/icons-material/Clear';
// import './group-style.css'
import { getAllReservationByGroupId, getAllReservationByGroupIdGost } from '../../api/reservationsApi';
import ReservationList from '../../components/ResrvationList';
import NovaRezervacijaDialog from '../../components/NovaRezervacijaDialog';
import NoviTermin from '../../components/NoviTermin';

const GroupDetails = () => {
    const { id } = useParams();
// console.log(id)
  const [groupData, setGroupData] = useState({});
  const [users, setUsers] = useState([]);
  const [rezervacije, setRezervacije] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTerminOpen, setTerminDialogOpen] = useState(false);

  const color = rezervacije[0]?.color;
  const openNovaRezervacija = () => {
    setDialogOpen(true);
  };
  
  const closeNovaRezervacija = () => {
    setDialogOpen(false);
  };
  const openNoviTermin = () => {
    setTerminDialogOpen(true);
  };
  
  const closeNoviTermin = () => {
    setTerminDialogOpen(false);
  };
  
  
  useEffect(() => {
    async function fetchGroup() {
      try {
        const data = await getGroupById(id);
        setGroupData(data);
        const dataReservation = await getAllReservationByGroupId(id);
        const gostReservation = await getAllReservationByGroupIdGost(id);

                    // Iterirajte kroz niz dataReservation
           // Iterirajte kroz niz dataReservation
          dataReservation.forEach(reservation => {
              // Pronađite odgovarajući objekt iz niza gostReservation prema _id
              const matchingReservation = gostReservation.find(gostRes => gostRes._id === reservation._id);

              // Ako je pronađen odgovarajući objekt
              if (matchingReservation) {
                  // Dodajte svakog člana iz matchingReservation.attendees u reservation.attendees
                  matchingReservation.attendees.forEach(attendee => {
                      reservation.attendees.push(attendee);
                  });
              }
            });

            // Sada će niz dataReservation sadržavati niz attendees za svaki objekt
            // console.log(dataReservation);


        setRezervacije(dataReservation);
        // console.log("dataReservation",dataReservation)
        // console.log("gostReservation",gostReservation)
        if (users.length === 0) {
          const members = await getGroupMembers();
          setUsers(members);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchGroup();
  }, []);
  
  // console.log("users",users)
  async function getGroupMembers() {
    const members = [];
    for (let i = 0; i < rezervacije?.length; i++) {
      // console.log("rezervacije",rezervacije )
      // const member = await getUserById(rezervacije[i].attendees._id);
      // members.push(member);
    }
    return members;
  }
  


  const tableCellStyle = {
  color: 'black', // Postavite boju teksta na crnu
};

  // Organizujemo rezervacije po danima u sedmici
  const weeklySchedule = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  };

  // Popunjavamo sedmični raspored sa rezervacijama
  rezervacije.forEach((reservation) => {
    // console.log("reservation",reservation)
    const day = reservation.day;
    //  console.log("day",day)
    const timeSlot = `${reservation.startTime} - ${reservation.endTime}`;
    //  console.log("timeSlot",reservation.group.name)
    const groupName = reservation.group.name.bs;
    // console.log("groupName",groupName)
    weeklySchedule[day].push({ timeSlot, groupName });
  });

  // Prvo, izdvojite sva vremena iz niza rezervacija
const reservationTimes = rezervacije.map((reservation) =>{
  const timeSlot = `${reservation.startTime} - ${reservation.endTime}`;
  return timeSlot
});
  // console.log("reservationTimes", reservationTimes)

  // Zatim, pretvorite ih u jedinstveni niz koristeći Set kako biste izbjegli dupliciranje
const uniqueTimes = [...new Set(reservationTimes)];
  // console.log("uniqueTimes",uniqueTimes)
  // Sortirajte vremena koristeći funkciju za usporedbu
uniqueTimes.sort((time1, time2) => {
  const [start1, end1] = time1.split(' - ');
  const [start2, end2] = time2.split(' - ');

  const [hours1, minutes1] = start1.split(':').map(Number);
  const [hours2, minutes2] = start2.split(':').map(Number);

  // Usporedite po satima i minutama početka vremena
  if (hours1 < hours2) return -1;
  if (hours1 > hours2) return 1;
  if (minutes1 < minutes2) return -1;
  if (minutes1 > minutes2) return 1;

  // Ako su sati i minute isti, usporedite po završetku vremena
  const [endHours1, endMinutes1] = end1.split(':').map(Number);
  const [endHours2, endMinutes2] = end2.split(':').map(Number);

  if (endHours1 < endHours2) return -1;
  if (endHours1 > endHours2) return 1;
  if (endMinutes1 < endMinutes2) return -1;
  if (endMinutes1 > endMinutes2) return 1;

  return 0;
});
    const daysOfWeek = Object.keys(weeklySchedule);
  const timeSlots = [
    ...uniqueTimes, // Dodajte jedinstvena vremena iz rezervacija
    // '06:00 - 07:00',
    // '07:00 - 08:00',
    // '08:00 - 09:00',
    // '09:00 - 10:00',
    // '10:00 - 11:00',
    // '11:00 - 12:00',
    // '12:00 - 13:00',
    // '13:00 - 14:00',
    // '14:00 - 15:00',
    // '15:00 - 16:00',
    // '16:00 - 17:00',
    // '17:30 - 18:30',
    // '18:30 - 19:30',
    // '19:00 - 20:00',
    // '20:00 - 21:00',
    // '21:00 - 22:00',
    // '22:00 - 23:00',
    // '23:00 - 24:00',
    // Dodajte ostale sate po potrebi
  ];
//  
  // console.log(daysOfWeek)
  if (!groupData) {
    return <div>Ucitavanje...</div>;
  }

  const formatTime = (time) => {
  const [start, end] = time.split(' - ');
  return `${start.slice(0, 5)} - ${end.slice(0, 5)}`;
};

// console.log(weeklySchedule)
const translateToCroatian = (day) => {
  switch (day) {
    case 'Monday':
      return 'Ponedjeljak';
    case 'Tuesday':
      return 'Utorak';
    case 'Wednesday':
      return 'Srijeda';
    case 'Thursday':
      return 'Četvrtak';
    case 'Friday':
      return 'Petak';
    case 'Saturday':
      return 'Subota';
    case 'Sunday':
      return 'Nedjelja';
    default:
      return '';
  }
};
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box>
            <img src={groupData.image} alt={groupData.name} style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="h4">{groupData?.name?.bs || ""}</Typography>
            <Typography variant="body1">{groupData?.description?.bs || ""}</Typography>
          </Box>
          <Box>
          <Button  onClick={openNovaRezervacija}color="secondary" variant="contained" style={{marginRight:"20px", backgroundColor:"##b5ad32"}}>
            Nova rezervacija
          </Button>
          <Button  onClick={openNoviTermin}color="secondary" variant="contained" style={{marginRight:"20px", backgroundColor:"##b5ad32"}}>
            Novi termin
          </Button>
        <Typography variant="h5">Termini</Typography>
          <NovaRezervacijaDialog open={dialogOpen} onClose={closeNovaRezervacija} reservations={rezervacije}/>
          <NoviTermin open={dialogTerminOpen} onClose={closeNoviTermin} idGroup={id} color={color}/>
        <List>
        <ReservationList reservations={rezervacije} setReservations={setRezervacije}/>
        </List>
      </Box>
        </Grid>
      </Grid>
      <Box mt={12}>
        {/* <Typography variant="h5">Raspored</Typography> */}
        <Grid container spacing={0} style={{ justifyContent: "center", alignItems: "center" }}>
        <Container>
      <Typography variant="h4" gutterBottom>
        Sedmični raspored treninga
      </Typography>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {daysOfWeek.map((day) => (
              <TableCell key={day} align="center" style={{ ...tableCellStyle, textTransform: 'uppercase', fontWeight: 'bold' }}>
              {translateToCroatian(day)}
            </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {timeSlots.map((timeSlot) => (
            <TableRow key={timeSlot}>
              <TableCell style={tableCellStyle}>{timeSlot}</TableCell>
              {daysOfWeek.map((day) => (
                <TableCell key={day} align="center" style={tableCellStyle}>
                  {weeklySchedule[day].find((reservation) => reservation.timeSlot === timeSlot)?.groupName || ''}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
            {/* <Container>
      <Typography variant="h4" gutterBottom>
        Sedmični Raspored
      </Typography>
      <Grid container spacing={1}>
        {Object.keys(weeklySchedule).map((day) => (
          <Grid item xs={12} md={2} key={day}>
            <Paper elevation={3} style={{ padding: '16px' }}>
              <Typography variant="h6" gutterBottom>
                {day}
              </Typography>
              {weeklySchedule[day].length === 0 ? (
                <Typography variant="body2">Nema rezervacija za ovaj dan.</Typography>
              ) : (
                weeklySchedule[day].map((reservation, index) => (
                  <div key={index}>
                    <Typography variant="body1">{reservation.timeSlot}</Typography>
                    <Typography variant="body2">{reservation.groupName}</Typography>
                  </div>
                ))
              )}
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container> */}
       {/* <GroupCalendar data={groupData}/> */}
          {/* {schedule.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Paper>
                <Typography variant="body1">{item.day}</Typography>
                <Typography variant="body2">{`${item.startTime} - ${item.endTime}`}</Typography>
              </Paper>
            </Grid>
          ))} */}
        </Grid>
      </Box>
      {/* <Box mt={4}>
        <Typography variant="h5">Članovi</Typography>
        <List>
        {users?.map((member, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            {member.photo ? (
              <Avatar src={member.photo} />
            ) : (
              <Avatar>{member.firstName.charAt(0)}</Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={`${member.firstName} ${member.lastName}`}
            // secondary={member.membership ? `Br. clanske: ${member.membership.brojClanske}` : ''}
          />
          {member.membership && (
            <ListItemText secondary={`Paket: ${member?.membership?.plan?.nazivPaketa}`} 
            style={{ color: 'white'}}
            classes={{ secondary: 'white-text' }}
            />
          )}
          {member.membership && (
            <ListItemText secondary={`Br. clanske: ${member.membership.brojClanske}`} 
            style={{ color: 'white'}}
            classes={{ secondary: 'white-text' }}
            />
          )}
          {onRemoveUser && (
            <IconButton onClick={() => onRemoveUser(member._id)}>
              <ClearIcon style={{ color: 'red' }}/>
            </IconButton>
          )}
        </ListItem>
      ))}
        </List>
      </Box> */}
    </Container>
  );
};

export default GroupDetails;


// U CSS datoteci
// .white-text {
//     color: white !important;
//   }