import React, { useState } from 'react';
import { Box, Button, TextField, Checkbox, FormControlLabel,Typography } from "@mui/material";
import { Formik, FieldArray, Field } from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../../components/Header";
import Tooltip from '@mui/material/Tooltip';
import { createPaket } from '../../api/paketApi';
import { ToastContainer, toast } from 'react-toastify';
import {TailSpin} from 'react-loader-spinner';
import { makeStyles } from '@mui/styles';
import "./paket.css";


const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999, // Postavite odgovarajući z-index da biste bili sigurni da je iznad ostalog sadržaja

    padding: '20px',
    borderRadius: '5px',
 
  },
}));

const NewPaketForm = () => {
  const classes = useStyles();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [isLoading, setIsLoading] = useState(false);

  const [tipPak, setTipPaketa] = useState(false);



  const handleFormSubmit = async (values) => {
    // console.log(values)
    try {
      // setIsLoading(true); // Postavite isLoading na true prilikom početka asinhronog poziva
      const res = await createPaket(values);
      setIsLoading(false); // Postavite isLoading na false nakon što asinhroni poziv završi
      // setResponse(res.message);
      if(res){
        toast.success('Uspješno ste kreirali paket.');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    // nazivPaketa: "",
    nazivPaketa: { en: '', bs: '' },
    opis: { en: '', bs: '' },
    cijena: "",
    brojDolazaka: "",
    trajanje: "",
    ogranicenjeBrojDana: "",
    brojDolazakaPoDanu: "",
    dostupnoVrijemePocetak: "",
    dostupnoVrijemeKraj: "",
    // opis: "",
    grupniPaket:tipPak,
    brojClanova: null,
    grupniTreninzi: false,
    treningProgram: false,
    individualniTreningProgram: false,
    ishrana: false,
    vjezbe: false,
    qrVjezbe: false,
    statInOut: false,
    statusPaketa:false,
    fields: [
      { naziv: '', vrijednost: '', checked: false }
    ],
    popusti: [
      { raspon: { minMjeseci: '', maxMjeseci: '' }, postotak: '' },
    ],
  };

  // const handleSubmit = (values) => {
  //   console.log(values.fields);
  // };

  return (
    <Box m="20px">
      <Header title="Kreiraj novi paket" />
      {/* {response} */}
      <ToastContainer />
      {isLoading ? (
        <div className={classes.loadingIndicator}>
          <TailSpin color="#007BFF" height={80} width={80} />
        </div>
      ) : (
      <Formik
        onSubmit={handleFormSubmit}
        initialValues={initialValues}
        validationSchema={checkoutSchema}
      >
        {({
          values,
          errors,
          touched,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          
        }) => (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gap="30px"
              gridTemplateColumns="repeat(4, minmax(0, 1fr))"
              sx={{
                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
              }}
            >
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Naziv paketa"
                name="nazivPaketa"
                onChange={handleChange}
                value={values.nazivPaketa}
                error={!!touched.v && !!errors.nazivPaketa}
                helperText={touched.nazivPaketa && errors.nazivPaketa}
                // sx={{ gridColumn: "span 2" }}
              /> */}
             <TextField
                className={classes.inField}
                label="Naziv paketa (Bosanski)"
                value={values.nazivPaketa.bs}
                onChange={(event) => setFieldValue('nazivPaketa.bs', event.target.value)}
                variant="filled"
                required
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Cijena"
                name="cijena"
                onChange={handleChange}
                value={values.cijena}
                error={!!touched.cijena && !!errors.cijena}
                helperText={touched.cijena && errors.cijena}
                // sx={{ gridColumn: "span 2" }}
              />
               <Tooltip title="Broj termina u teretanu koje korisnici dobivaju s ovim paketom. Npr. 20 termina">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Broj termina"
                onChange={handleChange}
                value={values.brojDolazaka}
                name="brojDolazaka"
                error={!!touched.brojDolazaka && !!errors.brojDolazaka}
                helperText={touched.brojDolazaka && errors.brojDolazaka}
              />
              </Tooltip>
              <Tooltip title="Upisite samo broj. npr:  1 (obracunva za jedan mjesec)">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Broj mjeseci"
                onChange={handleChange}
                  value={values.trajanje}
                  name="trajanje"
                  error={!!touched.trajanje && !!errors.trajanje}
                  helperText={touched.trajanje && errors.trajanje}
              />
                  </Tooltip>
                  <Tooltip title='
              Ako ste paket odnosi na broj dana. npr. 1 (dnevna clanarina), 7 (sedmicna)
              '>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Broj dana"
                onChange={handleChange}
                value={values.ogranicenjeBrojDana}
                name="ogranicenjeBrojDana"
                error={!!touched.ogranicenjeBrojDana && !!errors.ogranicenjeBrojDana}
                helperText={touched.ogranicenjeBrojDana && errors.ogranicenjeBrojDana}
              />
                </Tooltip>
                <Tooltip title="Broj dolazaka po danu. Npr. 1 Znaci da je clanu omogucen jedan dolazak u toku dana">
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Broj dolazaka po danu"
                onChange={handleChange}
                value={values.brojDolazakaPoDanu}
                name="brojDolazakaPoDanu"
                error={!!touched.brojDolazakaPoDanu && !!errors.brojDolazakaPoDanu}
                helperText={touched.brojDolazakaPoDanu && errors.brojDolazakaPoDanu}
              />
                 </Tooltip>
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Dostupno vrijeme početka"
                onChange={handleChange}
                value={values.dostupnoVrijemePocetak}
                name="dostupnoVrijemePocetak"
                error={!!touched.dostupnoVrijemePocetak && !!errors.dostupnoVrijemePocetak}
                helperText={touched.dostupnoVrijemePocetak && errors.dostupnoVrijemePocetak}
              />
              <TextField
                fullWidth
                variant="filled"
                type="text"
                label="Dostupno vrijeme kraja"
                onChange={handleChange}
                  value={values.dostupnoVrijemeKraj}
                  name="dostupnoVrijemeKraj"
                  error={!!touched.dostupnoVrijemeKraj && !!errors.dostupnoVrijemeKraj}
                  helperText={touched.dostupnoVrijemeKraj && errors.dostupnoVrijemeKraj}
              />
              
              {/* <TextField
                fullWidth
                variant="filled"
                type="text"
                multiline
                rows={2}
                label="Opis"
                onChange={handleChange}
                value={values.opis}
                name="opis"
                error={!!touched.opis && !!errors.opis}
                helperText={touched.opis && errors.opis}
              /> */}
               <TextField
                className={classes.inField}
                label="Opis (Bosanski)"
                multiline
                rows={2}
                value={values.opis.bs}
                onChange={(event) => setFieldValue('opis.bs', event.target.value)}
                variant="filled"
                required
              />
              
              <FormControlLabel
            control={
              <Checkbox
                color="primary"
                style={{ color: "#ffffff" }}
                name="tipPaketa"
                onChange={(event) => {
                  handleChange(event);
                  setTipPaketa(event.target.checked);
                }}
                checked={values.tipPaketa}
                error={!!touched.tipPaketa && !!errors.tipPaketa}
              />
            }
            label="Grupni paket (Porodični...)"
          />
              {tipPak && (
                <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Broj članova u paketu"
                  onChange={handleChange}
                  value={values.brojClanova}
                  name="brojClanova"
                  error={!!touched.brojClanova && !!errors.brojClanova}
                  helperText={touched.brojClanova && errors.brojClanova}
                />
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="grupniTreninzi"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("grupniTreninzi", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.grupniTreninzi} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.grupniTreninzi && !!errors.grupniTreninzi}
                  />
                }
                label="Pristup grupnim treninzima"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="treningProgram"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("treningProgram", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.treningProgram} // Postavite vrijednost na temelju polja vrijednosti
                  />
                }
                label="Standardni planovi treninga i ishrane"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="individualniTreningProgram"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("individualniTreningProgram", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.individualniTreningProgram} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.individualniTreningProgram && !!errors.individualniTreningProgram}
                  />
                }
                label="Individualni planovi treninga i ishrane"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="ishrana"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("ishrana", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.ishrana} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.ishrana && !!errors.ishrana}
                  />
                }
                label="Ishrana"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="vjezbe"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("vjezbe", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.vjezbe} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.vjezbe && !!errors.vjezbe}
                  />
                }
                label="Vježbe"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="qrVjezbe"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("qrVjezbe", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.qrVjezbe} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.qrVjezbe && !!errors.qrVjezbe}
                  />
                }
                label="Prikaz vježbi na spravi QR kode skeniranjem"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="statInOut"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("statInOut", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.statInOut} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.statInOut && !!errors.statInOut}
                  />
                }
                label="Statistika prijava i odjava"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="statsExercises"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("statsExercises", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.statsExercises} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.statsExercises && !!errors.statsExercises}
                  />
                }
                label="Statistika vježbanja"
              />
                <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    style={{ color: "#ffffff" }}
                    name="statusPaketa"
                    onChange={(event) => {
                      handleChange(event);
                      setFieldValue("statusPaketa", event.target.checked); // Postavite vrijednost na temelju stanja oznake
                    }}
                    checked={values.statusPaketa} // Postavite vrijednost na temelju polja vrijednosti
                    error={!!touched.statusPaketa && !!errors.statusPaketa}
                  />
                }
                label="Status paketa"
              />
         
  </Box>
 <Box>
 <Box>
              <Typography variant="h4" component="h2" style={{ padding: 20 }}>
                Popusti
              </Typography>
              <FieldArray name="popusti">
                {({ push, remove }) => (
                  <div>
                    {values.popusti.map((popust, index) => (
                      <div key={index} style={{ minWidth: 600, display: 'flex', alignItems: 'center' }}>
                        <div>
                          <Field
                            name={`popusti[${index}].raspon.minMjeseci`}
                            render={({ field }) => (
                              <div>
                                <TextField
                                  label="Min Mjeseci"
                                  type="number"
                                  {...field}
                                />
                              </div>
                            )}
                          />
                        </div>
                        <div>
                          <Field
                            name={`popusti[${index}].raspon.maxMjeseci`}
                            render={({ field }) => (
                              <div>
                                <TextField
                                  label="Max Mjeseci"
                                  type="number"
                                  {...field}
                                />
                              </div>
                            )}
                          />
                        </div>
                        <div>
                          <Field
                            name={`popusti[${index}].postotak`}
                            render={({ field }) => (
                              <div>
                                <TextField
                                  label="Postotak"
                                  type="number"
                                  {...field}
                                />
                              </div>
                            )}
                          />
                        </div>
                        <div>
                          <Button
                            variant="contained"
                            color="secondary"
                            style={{ backgroundColor: "red" }}
                            onClick={() => remove(index)}
                          >
                            Ukloni
                          </Button>
                        </div>
                      </div>
                    ))}
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => push({ raspon: { minMjeseci: '', maxMjeseci: '' }, postotak: '' })}
                      >
                        Dodaj
                      </Button>
                    </div>
                  </div>
                )}
              </FieldArray>
            </Box>

 <Typography variant="h4" component="h2" style={{padding:20}}>
 Dodatna polja za opis paketa
</Typography>

<FieldArray name="fields">
  {({ push, remove }) => (
    <div>
      {values.fields.map((field, index) => (
        <div key={index} style={{ minWidth:600,display: 'flex', alignItems: 'center' }}>
                <div>
          <Field
            name={`fields[${index}].naziv`}
            render={({ field }) => (
              <div>
                <TextField
                  label="Naziv"
                  {...field}
                />
                {/* Dodajte ostale elemente ovdje */}
              </div>
            )}
          />
        </div>

          <Tooltip title="Ako se promjeni stanje u pokju vrijenost onda ce chackbox biti iskljucen i obratno.">
          <div>
          
            <Field
              name={`fields[${index}].vrijednost`}
              render={({ field }) => (
                <TextField
                type="text"
                  label="Vrijednost"
                  {...field}
                  disabled={values.fields[index].checked === true}
                />
              )}
            />
           
          </div>
          </Tooltip>
          <div>
            <Field
              name={`fields[${index}].checked`}
              render={({ field }) => (
                <Checkbox
                // color="primary"
                style={{ color: "#ffffff" }}
                  checked={field.value}
                  {...field}
                  disabled={values.fields[index].vrijednost !== ''}
                />
              )}
            />
          </div>
          <div>
            <Button
              variant="contained"
              color="secondary"
              style={{backgroundColor:"red"}}
              onClick={() => remove(index)}
            >
              Ukloni
            </Button>
          </div>
        </div>
      ))}
      <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => push({ naziv: '', vrijednost: '', checked: false })}
        >
          Dodaj
        </Button>
      </div>
    </div>
  )}
</FieldArray>
<Header subtitle="Engleski jezik" />
<TextField
                className={classes.inField}
                label="Naziv paketa (Engleski)"
                value={values.nazivPaketa.en}
                onChange={(event) => setFieldValue('nazivPaketa.en', event.target.value)}
                variant="filled"
                required
              />
               <div>
               <TextField
                className={classes.inField}
                label="Opis (Engleski)"
                multiline
                rows={2}
                value={values.opis.en}
                onChange={(event) => setFieldValue('opis.en', event.target.value)}
                variant="filled"
                required
              />
              </div>

            </Box>
            <Box display="flex" justifyContent='center' mt="20px" style={{padding:40}}>
              <Button type="submit" color="secondary" variant="contained">
               Kreiraj paket
              </Button>
            </Box>
          </form>
        )}
      </Formik>
      )}
    </Box>
  );
};

const checkoutSchema = yup.object().shape({
  // nazivPaketa: yup.string().required("required"),
  // cijena: yup.string().required("required"),
  // brojDolazaka: yup.string().required("required"),
  // trajanje: yup.string().required("required"),
  // ogranicienjeBrojDana: yup.string().required("required"),
  // brojDolazakaPoDanu: yup.string().required("required"),
  // dostupnoVrijemePocetak: yup.string().required("required"),
  // dostupnoVrijemeKraj: yup.string().required("required"),
  // opis: yup.string(),
  // grupniTreninzi: yup.boolean(),
  // treningProgram: yup.boolean(),
  // individualniTreningProgram: yup.boolean(),
  // ishrana: yup.boolean(),
  // vjezbe: yup.boolean(),
  // qrVjezbe: yup.boolean(),
  // statInOut: yup.boolean(),
});

export default NewPaketForm;
