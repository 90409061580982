import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/nutricionist`;

// const baseUrl = 'http://localhost:3001/api/nutricionist';

export const getAllNutricionist = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getNutricionistById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createNutricionist = async (newNutricionist) => {
  const response = await axios.post(baseUrl, newNutricionist);
  console.log(newNutricionist)
  return response.data;
};

export const updateNutricionist = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteNutricionist = async (id,queryParameters) => {
  const response = await axios.delete(`${baseUrl}/${id}`, { params: queryParameters });
  // const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }