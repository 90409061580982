import React, { useState, useEffect,useContext } from 'react';
import { Box, Typography, Button,Checkbox } from "@mui/material";
import Header from "../../components/Header";
import { getPaketById, deletePaket } from '../../api/paketApi'; // Prilagodite ovim linijama za dohvat podataka i brisanje paketa
import { useParams,useLocation, useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { UserContext } from '../../context/UserProvider';


const ViewPaket = () => {
    const navigate = useNavigate();
const { state: data } = useLocation();
  const { paketId } = useParams();
  const [paket, setPaket] = useState(data);
  const { user} = useContext(UserContext);
  const userRole = user?.role;
  // console.log("data",data)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await getPaketById(paketId); // Prilagodite ovu liniju za dohvat podataka o paketu
        // setPaket(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [paketId]);

  const handleDelete = async () => {
    try {
    //   await deletePaket(paketId); // Prilagodite ovu liniju za brisanje paketa
    //   history.push('/paketi'); // Nakon brisanja preusmjerite korisnika na listu paketa
    } catch (error) {
      console.error(error);
    }
  };
  const handleClick = () => {
    navigate(`/paket/${data._id}/edit`, { state: data});
  };
  return (
    <Box m="20px" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: 'calc(100vh - 40px)' }}>
      <Header
        title="Detalji paketa"
        sx={{ textAlign: 'left' }}
        />
          {userRole === 'admin' && (
      <EditIcon onClick={handleClick}></EditIcon>
      )}
      {paket ? (
        <div style={{ textAlign: 'left' }}>
          <Typography variant="h5" gutterBottom>
            Naziv paketa: {paket.nazivPaketa.bs}
          </Typography>
          <Typography variant="body1">
            Cijena: {paket.cijena} KM
          </Typography>
          <Typography variant="body1">
            Opis: {paket.opis.bs}
          </Typography>
          <Typography variant="body1">
            Trajanje paketa / broj mjeseci: {paket.trajanje}
          </Typography>
          <Typography variant="body1">
            Broj dolazaka po danu: {paket.brojDolazakaPoDanu}
          </Typography>
          <Typography variant="body1">
            Dostupno vrijeme koristenja paketa: od {paket.dostupnoVrijemePocetak} do {paket.dostupnoVrijemeKraj}
          </Typography>
          <Typography variant="body1">
            Broj termina: {paket.brojDolazaka}
          </Typography>
          <Typography variant="body1">
            Broj dana (dnevna clanarina): {paket.ogranicenjeBrojDana}
          </Typography>
          ----------------------------------------------------------------
          <Typography variant="body1">
          Pristup grupnim treninzima: {paket.grupniTreninzi ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
          Standardni planovi treninga i ishrane: {paket.treningProgram ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
          Individualni planovi treninga i ishrane: {paket.individualniTreningProgram ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
            Ishrana: {paket.ishrana ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
            Vježbe: {paket.vjezbe ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
            Prikaz vježbi na spravi QR kode skeniranjem: {paket.qrVjezbe ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
            Statistika prijava i odjava: {paket.statInOut ? 'Da' : 'Ne'}
          </Typography>
          <Typography variant="body1">
            Statistika vježbanja: {paket.statsExercises ? 'Da' : 'Ne'}
          </Typography>
          ----------------------------------------------------------------
          <Typography variant="h6" gutterBottom style={{ marginTop: 20 }}>
            Dodatna polja za opis paketa:
          </Typography>
          {paket.fields.map((field) => (
            <div key={field._id}>
              <Typography variant="body1">
                - {field.naziv} {field.vrijednost} {field.checked ? <Checkbox checked={field.checked} disabled /> : " " }
              </Typography>
              {/* <Typography variant="body1">
                Vrijednost: {field.vrijednost}
              </Typography> */}
              {/* <Typography variant="body1">
                Checked: <Checkbox checked={field.checked} disabled />
              </Typography> */}
            </div>
          ))}
           ----------------------------------------------------------------
          {paket.popusti.length > 0 && (
  <div>
    <Typography variant="h4" gutterBottom>
      Popusti:
    </Typography>
            {paket.popusti.map((popust, index) => (
            <div key={index}>
                <Typography variant="body1">
                Popust {index + 1}:
                </Typography>
                <Typography variant="body1">
                --Min Mjeseci: {popust.raspon.minMjeseci || 'N/A'} -  Max Mjeseci: {popust.raspon.maxMjeseci || 'N/A'}
                </Typography>
                <Typography variant="body1">
                --Postotak: {popust.postotak || 'N/A'}%
                </Typography>
            </div>
            ))}
        </div>
        )}
      {userRole === 'admin' && (
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDelete}
            style={{ marginTop: 20 }}
          >
            Obriši paket
          </Button>
            )}
        </div>
      ) : (
        <Typography variant="body1">Učitavanje paketa...</Typography>
      )}
    </Box>
  );
};

export default ViewPaket;
