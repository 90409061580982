import React,{ useState, useEffect,useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import Header from "../components/Header";
// import { getUsers } from "../../api/userApi";
// import { getPaket } from "../../api/paketApi";
// import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import AddTaskIcon from '@mui/icons-material/AddTask';
import { Link, useNavigate } from 'react-router-dom';
// import RemainingDays from "../components/RemainingDays";
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


// import ErrorIcon from '@mui/icons-material/Error';
import { StartDateConverting } from "../../components/DateConverting";

const OdobreneClanarine = ({data}) => {
  const navigate = useNavigate();
  // console.log("datasss", data)
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
// console.log("filteredData", filteredData)
  // const handleExportToExcel = () => {
  //   const exportData = filteredData.map((item) => ({
  //     Ime: item.clanarina?.user?.firstName,
  //     Prezime: item.clanarina?.user?.lastName,
  //     Opis: item.opis,
  //     Paket: item.fakturaId?.paket?.nazivPaketa?.bs,
  //     Iznos: `${Number(item.fakturaId?.amount)}`,
  //     'Datum kreiranja': StartDateConverting({ date: item.fakturaId.date }),
  //     'Nacin placanja': item.fakturaId.paymentMethod,
  //     Status: item.status === 'completed' ? 'Plaćeno' : 'U obradi',
  //     'Datum placanja': item.date ? StartDateConverting({ date: item.fakturaId?.createdAt }) : 'Nije plaćeno',
  //       odobrio: item.uredio?.firstName,
  //   }));
  
  //   const worksheet = XLSX.utils.json_to_sheet(exportData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, 'Fakture');
  
  //   // Export the workbook to an Excel file
  //   XLSX.writeFile(workbook, `fakture.xlsx`);
  // };

  const handleExportToExcel = () => {
    const exportData = filteredData.map((item) => ({
      ID: item._id,
      Ime: item.clanarina?.user?.firstName,
      Prezime: item.clanarina?.user?.lastName,
      Opis: item.opis,
      Paket: item.fakturaId?.paket?.nazivPaketa?.bs,
      Iznos: item.fakturaId?.paymentMethod === "odgodjeno" ? 0 :  Number(item.fakturaId?.amount),
      'Datum kreiranja fakture': StartDateConverting({ date: item.fakturaId.date }),
      'Nacin placanja': item.fakturaId?.paymentMethod,
      Status: item.fakturaId.status === 'completed' ? 'Plaćeno' : 'U obradi',
      'Datum placanja fakture': item?.fakturaId?.paymentMethod !== "odgodjeno" ? StartDateConverting({ date: item.fakturaId?.createdAt }) : "-",
      odobrio: item.uredio.firstName,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
  
    // Postavite širinu stupaca ručno
    worksheet['!cols'] = [
      { width: 10 }, // Širina za ID
      { width: 15 }, // Širina za Ime
      { width: 15 }, // Širina za Prezime
      { width: 20 }, // Širina za Opis
      { width: 40 }, // Širina za Opis
      // Dodajte preostale širine za ostale stupce prema potrebi
    ];
  
    // Dodajte sumu iznosa na kraju tabele
    const sumFormula = `SUM(F2:F${exportData.length + 1})`; // Formula za zbrajanje iznosa
    XLSX.utils.sheet_add_aoa(worksheet, [[null, null, null, null, 'Ukupno', { f: sumFormula }]], { origin: -1 });
  
    // Formatirajte stupac 'Iznos' kao broj
    for (let i = 2; i <= exportData.length + 2; i++) {
      XLSX.utils.cell_set_number_format(worksheet, `F${i}`, '$#,##0.00');
    }
  
    const workbook = XLSX.utils.book_new();
  
    // Dodajte datum i ime odobravajuće osobe u naziv datoteke
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
    const odobravajucaOsoba = exportData.length > 0 ? exportData[0].odobrio : 'Nepoznato';
  
    XLSX.utils.book_append_sheet(workbook, worksheet, `Fakture_${formattedDate}_${odobravajucaOsoba}`);
  
    // Export radne knjige u Excel datoteku
    XLSX.writeFile(workbook, `Izvjestaj_odobrene_fakture_uposlenik_${formattedDate}_${odobravajucaOsoba}.xlsx`);
  };
  // useEffect(() => {
  //   const results = data?.filter(
     
  //     (d) =>
  //       d?.member?.user?.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
  //       d?.member?.user?.lastName.toLowerCase().includes(searchText.toLowerCase())
  //   );

  //   setFilteredData(results);
  // }, [data, searchText]);

  useEffect(() => {
    const results = data?.filter((d) => {
        // console.log(d)
      const member = d?.member;
      if (d.clanarina.user) {
        const user = d.clanarina.user;
        if (
          user?.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
          user?.lastName?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }
      } else {
        // Ako member nije definiran, ovdje možete odlučiti što želite raditi s tim zapisima
        // Na primjer, ako želite prikazati zapise bez člana, možete vratiti true ili ih ignorirati.
        return true;
      }
      return false;
    });
  
    setFilteredData(results);
  }, [data, searchText]);
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  

// console.log("filteredData", filteredData)
  // const { statusclanarine, setStatusClanarine } = useContext(GymContext);
  const [isOpen, setIsOpen] = useState(false);

  // console.log(statusclanarine)

  // const handleOpen = () => {
  //   setIsOpen(!isOpen);
  // };
  // const userId = "aaa"
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }

const imeRender = (cell) => {
  return (
    <p direction="row" spacing={1}>
      {`${cell?.row?.clanarina?.user?.firstName}`}
    </p>
  );
};

const prezimeRender = (cell) => {
  return (
    <p direction="row" spacing={1}>
      {`${cell?.row?.clanarina?.user?.lastName}`}
    </p>
  );
};

const opisRender = (cell)=>{
  // console.log(cell.row.opis)
return( 
  <p direction="row" spacing={1}>
      {`${cell?.row?.opis}`}
  </p>
)
}
const iznosRender = (cell)=>{
    // console.log(cell.row.amount)
  return( 
    <p direction="row" spacing={1}>
      {`${cell?.row?.fakturaId?.amount} KM`}
  </p>
  )
}
//Kalkulacija broj dana se obracunava u kompomenti RemainigDays
const datumKreiranja = (cell)=>{
  // const datumUplate = new Date(cell.row.date)

return( 
  <p direction="row" spacing={1}>
    <StartDateConverting date={cell?.row?.fakturaId?.date}/>
</p>
)
}

const datumPlacanjaRender = (cell)=>{
//   const datumUplate = new Date(cell.row.fakturaId.date)
//   console.log(datumUplate)
return( 
  <p direction="row" spacing={1}>
     {cell.row?.fakturaId?.paymentMethod !== "odgodjeno" ?
  <StartDateConverting date={cell.row?.fakturaId?.createdAt}/> : " - " }
    {/* {cell.row?.fakturaId?.createdAt !== null ? <StartDateConverting date={cell.row?.fakturaId?.createdAt}/> : "Nije placano"} */}
</p>
)
}


const pnacinPLacanjaRender = (cell)=>{
  const nacinPlacanja = cell.row?.fakturaId?.paymentMethod
return( 
  <p direction="row" spacing={1}>
     {nacinPlacanja}
   
</p>
)
}

const statusFakture = (cell)=>{
  // console.log(cell.row)
  return( 
    <p direction="row" spacing={1}>
      {/* {cell.row.status === "completed" ? "Plaćeno" : "U obradi"} */}
      {cell.row.fakturaId.status === "completed" ? "Plaćeno" : "U obradi"}
  </p>
  )
  }
const paketRender = (cell)=>{
  // console.log(cell?.row.member.user.membership.plan.nazivPaketa)
// const gym = cell?.row?.user.membership?.gym?.naziv;
return( 
  <p direction="row" spacing={1}>
    {cell?.row?.fakturaId?.paket?.nazivPaketa?.bs}
</p>
)
}
// console.log(filteredData)
const odobrio = (cell)=>{
  // console.log("sss",cell.row)
return( 
  <p direction="row" spacing={1}>
  {cell?.row.uredio?.firstName}
</p>
)
}
const fakturaDetaljRender = (cell) => {
  // console.log(cell.row)
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row) {
    return null; // Return null or a placeholder value if user data is undefined
  }

//   const handleClick = () => {
//     navigate(`/izvjestaj/fakture/${cell.row._id}/detalj`, { state: cell.row });
//   };
  
  return (
    <>
       {/* <Link data={cell} to={`/fakture/${cell.row._id}/detalj`}> */}
   {/* <RemoveRedEyeIcon  onClick={handleClick}></RemoveRedEyeIcon> */}
    {/* </Link> */}
    </>
  );
};


const fotografijaRender = (cell) => {
    if (!cell?.row || !cell.row?.clanarina?.user) {
      return null;
    }

    const handleClick = () => {
      navigate(`/users/${cell.row.user._id}`, { state: cell.row });
    };

    return (
      <>
        <img
          alt="Slika nije pronadjena"
          src={cell.row.clanarina.user.photo}
          style={{ width: "75%" }}
          onClick={handleClick}
        />
      </>
    );
  };
  const columns = [
    { field: 'foto', headerName: 'Slika', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    // { field: 'photo', headerName: 'Foto', 
    // renderCell: (params) => {
    //     return fotografijaRender(params);
    // }
    // },
    { field: 'firstName', headerName: 'Ime', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return imeRender(params);
    }
    },
    { field: 'lasttName', headerName: 'Prezime', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return prezimeRender(params);
    }
    },
    { field: 'opis', headerName: 'Opis', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return opisRender(params);
    }
    },
    { field: 'paket', headerName: 'Paket', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return paketRender(params);
    }
    },

    { field: 'amount', headerName: 'Iznos', 
    renderCell: (params) => {
        return iznosRender(params);
    }
    },
    { field: 'createdAt', headerName: 'Datum plaćanja', 
    renderCell: (params) => {
        return datumPlacanjaRender(params);
    }
    },
    { field: 'paymentMethod', headerName: 'Nacin placanja', 
    renderCell: (params) => {
        return pnacinPLacanjaRender(params);
    }
    },
    { field: 'status', headerName: 'Status', 
    renderCell: (params) => {
        return statusFakture(params);
    }
    },
    { field: 'date', headerName: 'Datum kreiranja', flex: 1,
    renderCell: (params) => {
        return datumKreiranja(params);
    }
    },
    { field: 'oodbrio', headerName: 'Odobrio', flex: 1,
    renderCell: (params) => {
        return odobrio(params);
    }
    },
    // { field: 'gym', headerName: 'Poslovnica', 
    // renderCell: (params) => {
    //     return poslovnicaRender(params);
    // }
    // },
  ];
  
  return (
    <Box m="px">
      <Box
        m="0px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
             <Box display="flex" justifyContent="flex-end">
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearch}
        />
      </Box>
   
<Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleExportToExcel} variant="contained" color="secondary">
  Izvezi u Excel
  </Button>
</Box>
      {/* Render the DataGrid using filteredData instead of data */}
      <DataGrid
        checkboxSelection
        pageSize={9}
        rowsPerPageOptions={[5]}
        rows={filteredData}
        columns={columns}
        getRowId={(row) => row._id}
      />

      </Box>
    </Box>
  );
};

export default OdobreneClanarine;
