import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams, Link, useNavigate,useLocation} from 'react-router-dom';
import Header from "../../../components/Header";
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Chip,
} from '@mui/material';
import SastojciTabela from '../../../components/SastojciTabela';

// ... (import other necessary components and libraries)
const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      // gap: theme.spacing(2),
      // maxWidth: '400px',
      margin: '0 auto',
      padding:20
    },
    inField: {
      backgroundColor: "#ffffff14"
    }
  }));


const MealDetalj = () => {
  const classes = useStyles();
  const { state: data } = useLocation();
  const navigate = useNavigate();
  console.log("data",data)
  // Extract exercise details
  const {
    name,
    time,
    type,
    description,
    foods,
    image,
  } = data;

  const handleClick = () => {
    navigate(`/obrok/${data._id}/edit`, { state: data});
  };
// console.log(data)
  // Convert muscleGroups to an array of strings
  // const muscleGroupsArray = Array.isArray(muscleGroups) ? muscleGroups : [];

  return (
    <> 
    <Grid container spacing={2} style={{marginLeft:20}}>
    <Header title="Detalj obroka" />  
    </Grid>
    <Grid container spacing={2} style={{padding:20}}>
      <Grid item xs={4}>
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "100%" }}
            src={image}
            alt={`Slika vježbe - ${name}`}
          />
        </div>
      </Grid>
      <Grid item xs={8}>
        <Paper elevation={3} className={classes.form}>
              {/* <Link > */}
   <EditIcon onClick={handleClick}></EditIcon>
   {/* </Link> */}
          {/* <Header title="Detalji vježbe" /> */}
          <Typography variant="h4">Naziv obroka: {name.bs}</Typography>
          <Typography variant="body1">Opis: {description.bs}</Typography>
          {/* <Typography variant="body1">Mišićne grupe: {muscleGroups[0].name}</Typography> */}

        </Paper>

     
      </Grid>
    </Grid>

    <Grid>
  
    <SastojciTabela data={data.foods}/>
    </Grid>
    </>
  );
};

export default MealDetalj;
