import React, { useEffect, useState } from 'react';
import { Typography, useTheme, Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
// import {getMembers, getMembersByRange} from '../../api/memberApi'
// import {getPaket} from '../../api/paketApi'
// import PieIzvjestajClanovi from '../pie/pieClanoviIzvjestaj';
// import SpolClanova from '../../components/PieChartspolaClanova';
// import ClanoviIzvjestajKomponenta from '../../components/clanoviIzvjestajKomponenta';
import { TailSpin } from 'react-loader-spinner';
import PaketiIzvjestaj from '../../components/paketiIzvjestajKomponenta';
import { getAllPaymentByRange } from "../../api/paymentApi";
import PieIzvjestajPaketiProdaja from '../pie/PieIzvjestajPaketiProdaja';

const IzvjestajPaketi = () => {
    const theme = useTheme();
const colors = tokens(theme.palette.mode);

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [fakture, setFakture] = useState([]);
  const [brojNovihClanova, setBrojNovihClanova] = useState('');
  const [grupeFakturaPaketi, setPaketiGrupeFaktura] = useState([])
  const [grupisaniPaketi, setGrupisaniPaketi] = useState([])
  const [ukupanPrihod, setUkupanPrihod] = useState(0);
  const [ukupanPopust, setUkupanPopust] = useState(0);
  const [ukupanPrihodSaPopustom, setUkupanPrihodSaPopustom] = useState(0);

  const [filteredFakture, setFilteredFakture] = useState([]);


  const [membershipStatus, setMembershipStatus] = useState("");
  const [paket, setPaket] = useState("");
  const [paketi, setPaketi] = useState([]);
  const [pendingFakture, setPendingFakture] = useState([]);


  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999)

//   console.log("membershipStatus", membershipStatus)
// console.log("filteredFakture", filteredFakture)


  useEffect(() => {
    async function fetchData() {
      try {
        // setLoading(true);
  
        // Dohvatanje svih faktura u određenom rasponu datuma
        const fakture = await getAllPaymentByRange({
          startDate,
          endDate,
        });
        // console.log(fakture)
  // Filtriranje faktura prema odabranom statusu
  const filteredFakture = membershipStatus
  ? fakture.filter((faktura) => faktura.status === membershipStatus)
  : fakture;

         // Filtriranje faktura sa statusom "pending"
      const pendingFakture = filteredFakture.filter((faktura) => faktura.status === 'pending');
       // Filtriranje faktura sa statusom "completed"
       const completedFakture = filteredFakture.filter((faktura) => faktura.status === 'completed');
        // console.log("completedFakture",completedFakture)
        // Grupisanje faktura po nazivu paketa
        const grupisaneFakture = filteredFakture.reduce((grupe, faktura) => {
          const nazivPaketa = faktura.paket?.nazivPaketa?.bs;
  
          if (!grupe[nazivPaketa]) {
            grupe[nazivPaketa] = [];
          }
  
          grupe[nazivPaketa].push(faktura);
  
          return grupe;
        }, {});
  
        // Konvertujte objekat u niz grupa
        const nizGrupa = Object.keys(grupisaneFakture).map((nazivPaketa) => ({
          nazivPaketa,
          fakture: grupisaneFakture[nazivPaketa],
        }));
  
        // Ukupan prihod od svih plaćenih faktura
      const ukupanPrihod = completedFakture.reduce((total, faktura) => {
        return total + faktura.amount;
      }, 0);
      
      const ukupanPopust = completedFakture.reduce((totalPopust, faktura) => {
        const popust = parseFloat(faktura.popust) / 100;
        return totalPopust + faktura.amount * popust;
      }, 0);
      
      // console.log("Ukupan prihod: " + ukupanPrihod.toFixed(2));
      // console.log("Ukupan popust: " + ukupanPopust.toFixed(2));
      // console.log("Ukupan prihod sa popustom: " + (ukupanPrihod - ukupanPopust).toFixed(2));
      

        //Ukupan prihod
        setUkupanPrihod(ukupanPrihod.toFixed(2));
        setUkupanPopust(ukupanPopust.toFixed(2));
        setUkupanPrihodSaPopustom((ukupanPrihod - ukupanPopust).toFixed(2));

        setFakture(fakture);
        // Postavljanje grupisanih faktura u stanje komponente
        setPaketiGrupeFaktura(nizGrupa);
        
        setFilteredFakture(filteredFakture);
        //neplacene fakture
        setPendingFakture(pendingFakture);
      } catch (error) {
        console.error('Greška pri dohvatanju faktura:', error);
      } finally {
        // setLoading(false);
      }
    }
  
    fetchData();
  }, [endDate, membershipStatus, startDate]);

// console.log("brojDanaDoIsteka", brojDanaDoIsteka)

  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
      </div>
    ) : (
    <Box m="20px">
    <Box display="flex" justifyContent="space-between" alignItems="center">
   
    <Box>
    <Header title="IZVJESTAJ - PRODAJA PO PAKETIMA"/>
   
    <Box>
    
    <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
    <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
            //  value={gym}
            //  onChange={handleChangeGym}
           >
    {/* {gyms?.map((e, key) => (
    <MenuItem key={key} value={e}>
    {e.naziv}
    </MenuItem>
    ))} */}
    </Select>
     <FormControl component="fieldset">
       <label htmlFor="membership-status">Status fakure:</label>
     <RadioGroup
        row
        aria-label="membership status"
        name="membership-status"
        // value={selectedStatus}
        onChange={(e) => setMembershipStatus(e.target.value)}
      >
        <FormControlLabel value="completed" control={<Radio  style={{color:"white"}} />} label="Placene" />
        <FormControlLabel value="pending" control={<Radio  style={{color:"white"}}/>} label="Neplacene" />
      </RadioGroup>
    </FormControl>
   {/* <TextField
        id="expiry-days"
        label="Raspon dana isteka"
        type="number"
        InputLabelProps={{ shrink: true }}
        variant="standard"
        value={brojDanaDoIsteka}
        onChange={(e) => setBrojDanaDoIsteka(e.target.value)}
      /> */}
       {/* <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Način plaćanja</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paymentType}
          label="Način plaćanja"
          onChange={(e) => setNacinPlacanjaStatus(e.target.value)}
        >
          <MenuItem value="">Svi načini plaćanja</MenuItem>
          <MenuItem value="kartica">Kartica</MenuItem>
          <MenuItem value="odgodjeno">Odgodjeno plaćanje</MenuItem>
          <MenuItem value="gotovina">Gotovina</MenuItem>
        </Select>
      </FormControl> */}
    <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Paketi</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paket}
          label="Način plaćanja"
          onChange={(e) => setPaket(e.target.value)}
        >
          <MenuItem value="" disabled>Odaberi paket</MenuItem>
          {paketi?.map((e, key)=>{
                    return <MenuItem key={key} value={e._id}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
                  })
                  }
        </Select>
      </FormControl>
    
      <FormControl  sx={{minWidth:"200px" }}>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
        />
      
      </FormControl>
  </Box>
  </Box>
  </Box>
  {/* GRID & CHARTS */}
  <Box
    display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridAutoRows="140px"
    gap="20px"
  >
    {/* ROW 1 */}
    <Box
      gridColumn="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <StatBox
        title={ukupanPrihod}
        subtitle="Ukupan prihod"
        title2={ukupanPopust > 1 ? `Popust: ${ukupanPopust}` : ""}
        title3={ukupanPopust > 1 ? `Ukupno sa popustom: ${ukupanPrihodSaPopustom}` :""}
        progress="a"
        // increase={`+${progressPaketi.length}%`}
        // icon={
        //   <HowToRegIcon
        //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        //   />
        // }
      />
    </Box>
   
<Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
        title={grupeFakturaPaketi.length}
        subtitle="Broj paketa"
        progress="a"
    />
  </Box>
  <Box
  
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
    title={filteredFakture.length}
    subtitle="Broj faktura"
      progress="a"
    />
  </Box>
  <Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
    title={pendingFakture.length}
    subtitle="Neplacene fakture"
      progress="a"
    />
  </Box>
  {/* ROW 2 */}
  {/* <Box
    gridColumn="span 6"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    <Box
      mt="25px"
      p="0 30px"
      display="flex "
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="600"
          color={colors.grey[100]}
        >
          Broj posjeta u toku mjeseca
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          color={colors.greenAccent[500]}
        >
      
        </Typography>
      </Box>
      <Box>
       
      </Box>
    </Box>
    <Box height="250px" m="-20px 0 0 0">
    <BarChartPosjete data={rangePosjete} handleBusiestDay={handleBusiestDay}/>
    </Box>
  </Box> */}
 
  {/* <Box
    gridColumn="span 4"
    gridRow="span 4"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    Spol članova
    <SpolClanova data={members}/>

  </Box> */}
  <Box
    gridColumn="span 12"
    gridRow="span 4"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    Analiza prodaje po paketima
    <PieIzvjestajPaketiProdaja data={grupeFakturaPaketi}/>
  </Box>
  <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {/* Prosječno vrijeme posjeta po danu */}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              </Typography>
            </Box>
            <Box>
              {/* <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton> */}
            </Box>
          </Box>
          <Box height="450px" m="-40px 0 0 0">
              <PaketiIzvjestaj data={grupeFakturaPaketi}/>
          </Box>
        </Box>
      
</Box>

</Box>
)}
</>
);
};

export default IzvjestajPaketi;