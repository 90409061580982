import React, { useState, useEffect } from 'react';
import { createGroup } from '../../api/groupApi';
import { getAllGyms } from '../../api/gymApi';
import { getAllTrener } from '../../api/trenerApi';
import TimePicker from 'react-time-picker';
import Header from "../../components/Header";
import ColorPickerx from '../../components/ColorPicker';
import Grid from '@material-ui/core/Grid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@mui/styles';

import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
  customInput: {
    '& input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour': {
      width: '40px !important',
    },
    '& input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute.react-time-picker__inputGroup__input--hasLeadingZero': {
      width: '40px !important',
  }
  },
  customInput2:{
    '& input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute.react-time-picker__inputGroup__input--hasLeadingZero': {
      width: '40px !important',
  },
  '& input.react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour': {
    width: '40px !important',
  }
  }
}));

const GroupForm = () => {
  const classes = useStyles();
  // const [name, setName] = useState('');
  // const [description, setDescription] = useState('');
  const [name, setName] = useState({ en: '', bs: '' });
  const [description, setDescription] = useState({ en: '', bs: '' });
  const [trainers, setTrainers] = useState([]);
  const [trainer, setTrainer] = useState('');
  const [maxCapacity, setMaxCapacity] = useState('');
  const [image, setImage] = useState('');
  const [selectedDays, setSelectedDays] = useState([]);
  const [times, setTimes] = useState({});
  const [err, setErr] = useState('');
  const [color, setColor] = useState('#fff');

  // console.log(trainer)

  const [gyms, setGyms] = useState([]);
  const [gym, setGym] = useState('');

  useEffect(() => {
    const fetchTrainers = async () => {
      const result = await getAllTrener();
      const fetchedGyms = await getAllGyms({});
      setGyms(fetchedGyms);
      setTrainers(result);
    };
    fetchTrainers();
  }, []);

  const handleColorChange = (color) => {
    setColor(color);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const schedules = selectedDays.map((day) => ({ day, times: times[day] }));

    const formData = new FormData();
    formData.append('name', JSON.stringify(name));
    formData.append('description', JSON.stringify(description));
    formData.append('trainer', trainer);
    formData.append('maxCapacity', maxCapacity);
    formData.append('gym', gym);
    formData.append('color', color);
    formData.append('image', image);

    schedules.forEach((schedule, index) => {
      formData.append(`schedules[${index}][day]`, schedule.day);
      schedule.times.forEach((time, timeIndex) => {
        formData.append(`schedules[${index}][times][${timeIndex}][start]`, time.start);
        formData.append(`schedules[${index}][times][${timeIndex}][end]`, time.end);
      });
    });


    try {
      const res = await createGroup(formData);
      setName('');
      setSelectedDays([]);
      setTimes({});
      toast.success('Uspješno ste kreirali grupu.');
    } catch (error) {
      setErr(error.response.data.message);
      console.error(error.response.data.message);
      toast.warn('🦄 Greska! Već postoji rezervacija za ovaj dan i vrijeme!');
    }
  };

  const handleDayChange = (day) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((selectedDay) => selectedDay !== day));
      setTimes((prevTimes) => {
        const { [day]: _, ...rest } = prevTimes;
        return rest;
      });
    } else {
      setSelectedDays([...selectedDays, day]);
      setTimes((prevTimes) => {
        return {
          ...prevTimes,
          [day]: [{ start: '00:00', end: '00:00' }],
        };
      });
    }
  };

  const handleTimeChange = (day, index, newTime, type) => {
    setTimes((prevTimes) => {
      const dayTimes = prevTimes[day];
      const updatedTimes = dayTimes?.map((time, i) => {
        if (i === index) {
          return { ...time, [type]: newTime };
        }
        return time;
      });
      return { ...prevTimes, [day]: updatedTimes };
    });
  };

  const handleAddTime = (day) => {
    setTimes((prevTimes) => {
      const dayTimes = prevTimes[day];
      const newTime = { start: '00:00', end: '00:00' };
      const updatedTimes = [...dayTimes, newTime];
      return { ...prevTimes, [day]: updatedTimes };
    });
  };

  const handleRemoveTime = (day, index) => {
    setTimes((prevTimes) => {
      const dayTimes = prevTimes[day];
      const updatedTimes = dayTimes.filter((time, i) => i !== index);
      if (updatedTimes.length === 0) {
        const { [day]: _, ...rest } = prevTimes;
        return rest;
      }
      return { ...prevTimes, [day]: updatedTimes };
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box m="20px">
        <Header title="NOVA GRUPA" />
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={6}>
          <TextField
              label="Naziv grupe (Bosanski)"
              value={name.bs}
              onChange={(event) => setName({ ...name, bs: event.target.value })}
              variant="filled"
              fullWidth
              margin="normal"
              required
            />
            {/* <TextField
              variant="filled"
              label="Naziv grupe"
              value={name}
              onChange={(event) => setName(event.target.value)}
              fullWidth
              margin="normal"
            /> */}
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal" variant="filled">
              <InputLabel>Gym</InputLabel>
              <Select value={gym} onChange={(event) => setGym(event.target.value)}>
                <MenuItem value="">Odaberi teretanu</MenuItem>
                {gyms?.map((e) => (
                  <MenuItem value={e._id} key={e._id}>{e.naziv}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal" variant="filled">
              <InputLabel>Trener</InputLabel>
              <Select value={trainer} onChange={(event) => setTrainer(event.target.value)}>
                <MenuItem value="">Odaberi trenera</MenuItem>
                {trainers?.map((e) => (
                  <MenuItem value={e._id} key={e._id}>{`${e.member.user?.firstName} ${e.member.user?.lastName}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Maksimalan kapacitet"
              variant="filled"
              value={maxCapacity}
              onChange={(event) => setMaxCapacity(event.target.value)}
              fullWidth
              margin="normal"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Slika"
              className={classes.inField}
              type="file"
              accept="image/*"
              onChange={handleImageUpload}
              required
            />
          </Grid>
          <Grid item xs={6}>
          <TextField
              label="Opis (Bosanski)"
              className={classes.inField}
              value={description.bs}
              onChange={(event) => setDescription({ ...description, bs: event.target.value })}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              required
            />
          {/* <TextField
        label="Opis"
        className={classes.inField}
        multiline
        rows={4}
        fullWidth
        variant="outlined"
        value={description}
        onChange={(event) => setDescription(event.target.value)}
      /> */}
          </Grid>
        </Grid>

        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Dani</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="monday"
                  checked={selectedDays.includes('Monday')}
                  onChange={() => handleDayChange('Monday')}
                />
              }
              label="Ponedjeljak"
            />
            {times['Monday']?.map((time, index) => (
              <div className="time" key={index}>
                <span>od</span>
                <TimePicker
                  disableClock
                  clearIcon={null}
                  onChange={(newTime) => handleTimeChange('Monday', index, newTime, 'start')}
                  value={time.start}
                  className={classes.customInput}
                />
                <span>do</span>
                <TimePicker
                  disableClock
                  clearIcon={null}
                  onChange={(newTime) => handleTimeChange('Monday', index, newTime, 'end')}
                  value={time.end}
                  className={classes.customInput2}
                />
                <Button
                  type="button"
                  variant="contained"
                  color="secondary"
                  style={{ backgroundColor: "red", height: "20px", marginBottom: "10px", color: "white", float: "left" }}
                  onClick={() => handleRemoveTime('Monday', index)}
                >
                  Brisi
                </Button>
              </div>
            ))}
            {times?.Monday?.length > 0 ?
              <Button
                type="button"
                variant="contained"
                color="secondary"
                style={{ width: "65px", height: "20px", marginBottom: "2px", color: "white" }}
                onClick={() => handleAddTime('Monday')}
              >
                Dodaj
              </Button>
              : " "
            }
          </FormGroup>
        </FormControl>

        <FormControl component="fieldset" margin="normal" style={{ marginLeft: "10px" }}>
          <FormLabel component="legend">-</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  id="tuesday"
                  checked={selectedDays.includes('Tuesday')}
                  onChange={() => handleDayChange('Tuesday')}
                />
              }
              label="Utorak"
            />
            {times['Tuesday']?.map((time, index) => (
              <div className="time" key={index}>
                <span>od</span>
                <TimePicker
                  disableClock
                  clearIcon={null}
                  onChange={(newTime) => handleTimeChange('Tuesday', index, newTime, 'start')}
                  value={time.start}
                  className={classes.customInput}
                />
                <span>do</span>
                <TimePicker
                  disableClock
                  clearIcon={null}
                  onChange={(newTime) => handleTimeChange('Tuesday', index, newTime, 'end')}
                  value={time.end}
                  className={classes.customInput2}
                />
                <Button
                  type="button"
                  style={{ backgroundColor: "red", height: "20px", marginBottom: "10px", color: "white", float: "left" }}
                  onClick={() => handleRemoveTime('Tuesday', index)}
                >
                  Brisi
                </Button>
              </div>
            ))}
            {times?.Tuesday?.length > 0 ?
              <Button
                variant="contained"
                color="secondary"
                style={{ width: "65px", height: "20px", marginBottom: "2px", color: "white" }}
                onClick={() => handleAddTime('Tuesday')}
              >
                Dodaj
              </Button>
              : " "
            }
          </FormGroup>
        </FormControl>
       {/* srijeda */}
  <FormControl component="fieldset" margin="normal" style={{marginLeft:"10px"}}>
    <FormLabel component="legend">-</FormLabel>
    <FormGroup>
    <FormControlLabel
    control={
    <Checkbox
    id="wednesday"
    checked={selectedDays.includes('Wednesday')}
    onChange={() => handleDayChange('Wednesday')}
    />
    }
    label="Srijeda"
    />
    {times['Wednesday']?.map((time, index) => (
    <div className="time" key={index}>
       <span>od</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Wednesday', index, newTime, 'start')}
    value={time.start}
    className={classes.customInput}
    />
    <span>do</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Wednesday', index, newTime, 'end')}
    value={time.end}
    className={classes.customInput2}
    />
    <Button type="button" style={{backgroundColor:"red", height:"20px", marginBottom:"10px", color:"white", float:"left"}}  onClick={() => handleRemoveTime('Wednesday', index)}>
    Brisi
    </Button>
    </div>
    ))}
    {times?.Wednesday?.length > 0 ?
    <Button variant="contained" color="secondary" style={{width:"65px", height:"20px", marginBottom:"2px", color:"white"}} onClick={() => handleAddTime('Wednesday')}>
    Dodaj
    </Button>
    : " "
    }
    </FormGroup>
    </FormControl>
  {/* cetvrtak */}
  <FormControl component="fieldset" margin="normal" style={{marginLeft:"10px"}}>
    <FormLabel component="legend">-</FormLabel>
    <FormGroup>
    <FormControlLabel
    control={
    <Checkbox
    id="thursday"
    checked={selectedDays.includes('Thursday')}
    onChange={() => handleDayChange('Thursday')}
    />
    }
    label="Četvrtak"
    />
    {times['Thursday']?.map((time, index) => (
    <div className="time" key={index}>
       <span>od</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Thursday', index, newTime, 'start')}
    value={time.start}
    className={classes.customInput}
    />
    <span>do</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Thursday', index, newTime, 'end')}
    value={time.end}
    className={classes.customInput2}
    />
    <Button type="button" style={{backgroundColor:"red", height:"20px", marginBottom:"10px", color:"white", float:"left"}}  onClick={() => handleRemoveTime('Thursday', index)}>
    Brisi
    </Button>
    </div>
    ))}
    {times?.Thursday?.length > 0 ?
    <Button variant="contained" color="secondary" style={{width:"65px", height:"20px", marginBottom:"2px", color:"white"}} onClick={() => handleAddTime('Thursday')}>
    Dodaj
    </Button>
    : " "
    }
    </FormGroup>
    </FormControl>
  {/* petak */}
  <FormControl component="fieldset" margin="normal" style={{marginLeft:"10px"}}>
    <FormLabel component="legend">-</FormLabel>
    <FormGroup>
    <FormControlLabel
    control={
    <Checkbox
    id="friday"
    checked={selectedDays.includes('Friday')}
    onChange={() => handleDayChange('Friday')}
    />
    }
    label="Petak"
    />
    {times['Friday']?.map((time, index) => (
    <div className="time" key={index}>
       <span>od</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Friday', index, newTime, 'start')}
    value={time.start}
    className={classes.customInput}
    />
    <span>do</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Friday', index, newTime, 'end')}
    value={time.end}
    className={classes.customInput2}
    />
    <Button type="button" style={{backgroundColor:"red", height:"20px", marginBottom:"10px", color:"white", float:"left"}}  onClick={() => handleRemoveTime('Friday', index)}>
    Brisi
    </Button>
    </div>
    ))}
    {times?.Friday?.length > 0 ?
    <Button variant="contained" color="secondary" style={{width:"65px", height:"20px", marginBottom:"2px", color:"white"}} onClick={() => handleAddTime('Friday')}>
    Dodaj
    </Button>
    : " "
    }
    </FormGroup>
    </FormControl>
  {/* subota */}
  <FormControl component="fieldset" margin="normal" style={{marginLeft:"10px"}}>
    <FormLabel component="legend">-</FormLabel>
    <FormGroup>
    <FormControlLabel
    control={
    <Checkbox
    id="saturday"
    checked={selectedDays.includes('Saturday')}
    onChange={() => handleDayChange('Saturday')}
    />
    }
    label="Subota"
    />
    {times['Saturday']?.map((time, index) => (
    <div className="time" key={index}>
       <span>od</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Saturday', index, newTime, 'start')}
    value={time.start}
    className={classes.customInput}
    />
    <span>do</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Saturday', index, newTime, 'end')}
    value={time.end}
    className={classes.customInput2}
    />
    <Button type="button" style={{backgroundColor:"red", height:"20px", marginBottom:"10px", color:"white", float:"left"}}  onClick={() => handleRemoveTime('Saturday', index)}>
    Brisi
    </Button>
    </div>
    ))}
    {times?.Saturday?.length > 0 ?
    <Button variant="contained" color="secondary" style={{width:"65px", height:"20px", marginBottom:"2px", color:"white"}} onClick={() => handleAddTime('Saturday')}>
    Dodaj
    </Button>
    : " "
    }
    </FormGroup>
    </FormControl>
  {/* nedjelja */}
  <FormControl component="fieldset" margin="normal" style={{marginLeft:"10px"}}>
    <FormLabel component="legend">-</FormLabel>
    <FormGroup>
    <FormControlLabel
    control={
    <Checkbox
    id="sunday"
    checked={selectedDays.includes('Sunday')}
    onChange={() => handleDayChange('Sunday')}
    />
    }
    label="Nedjelja"
    />
    {times['Sunday']?.map((time, index) => (
    <div className="time" key={index}>
       <span>od</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Sunday', index, newTime, 'start')}
    value={time.start}
    className={classes.customInput}
    />
    <span>do</span>
    <TimePicker
    disableClock
    clearIcon={null}
    onChange={(newTime) => handleTimeChange('Sunday', index, newTime, 'end')}
    value={time.end}
    className={classes.customInput2}
    />
    <Button type="button" style={{backgroundColor:"red", height:"20px", marginBottom:"10px", color:"white", float:"left"}}  onClick={() => handleRemoveTime('Sunday', index)}>
    Brisi
    </Button>
    </div>
    ))}
    {times?.Sunday?.length > 0 ?
    <Button variant="contained" color="secondary" style={{width:"65px", height:"20px", marginBottom:"2px", color:"white"}} onClick={() => handleAddTime('Sunday')}>
    Dodaj
    </Button>
    : " "
    }
    </FormGroup>
    </FormControl>


    <div>
    <ColorPickerx onColorChange={handleColorChange} color={color} />
    </div>
    <Header subtitle="Engleski jezik" />
    <Grid item xs={6}>
              <TextField
              className={classes.inField}
              fullWidth
                label="Naziv grupe (Engleski)"
                value={name.en}
                onChange={(event) => setName({ ...name, en: event.target.value })}
                variant="outlined"
                required
              />
  </Grid>
  <Grid item xs={6}>
              <TextField
                label="Opis (Engleski)"
                className={classes.inField}
                fullWidth
                multiline
                rows={4}
                value={description.en}
                onChange={(event) => setDescription({ ...description, en: event.target.value })}
                variant="outlined"
                required
              />
                </Grid>
    <Grid container justify="center" alignItems="center" spacing={4} style={{padding:50}}>
      <Grid item>
        <Button type="submit" variant="contained" color="secondary">
          Kreiraj grupu
        </Button>
      </Grid>
    </Grid>
    </Box>
    </form>
    );
    };
    
    export default GroupForm;
