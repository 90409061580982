import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/reservations`;
// const baseUrl = 'http://localhost:3001/api/reservations';

export const getAllReservation = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getReservationById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createReservation = async (newReservation) => {
  const response = await axios.post(baseUrl, newReservation);
  // console.log(newReservation)
  return response.data;
};

export const updateReservation = async (id, updatedReservation) => {
  // console.log(id, updatedReservation)
  const response = await axios.put(`${baseUrl}/${id}`, updatedReservation);
  return response.data;
};

export const updateReservationAttendees = async (id, clanId) => {
  // console.log(id, updatedReservation)
  const response = await axios.put(`${baseUrl}/${id}/user/${clanId}`);
  return response.data;
};

export const deleteReservation = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};

export const getAllReservationByGroupId = async (id) => {
  // console.log(id)
  const response = await axios.get(`${baseUrl}/group/${id}`);
  return response.data;
};

export const getAllReservationByGroupIdGost = async (id) => {
  // console.log(id)
  const response = await axios.get(`${baseUrl}/group/${id}/gost`);
  return response.data;
};
// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }