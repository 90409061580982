import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate ,HashRouter,Switch} from 'react-router-dom'
import Topbar from "./scenes/global/Topbar";
import Sidebar from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
// import Users from "./scenes/users";
// import Invoices from "./scenes/invoices";
// import Contacts from "./scenes/contacts";
// import Bar from "./scenes/bar";
// import Form from "./scenes/form";
// import Line from "./scenes/line";
// import Pie from "./scenes/pie";
// import FAQ from "./scenes/faq";
// import Geography from "./scenes/geography";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { GymContext, useMode } from "./theme";
// import Calendar from "./scenes/calendar/calendar";
import NewUserForm from "./scenes/users/newUser";
import Unauthorized from './scenes/auth/Unauthorized';
import Paketi from "./scenes/paketi";
import NewPaketForm from "./scenes/paketi/newPaket";
import CheckIn from "./scenes/check-in";
// import RFIDCard from "./scenes/check-in/newCheckIn";
import AllGym from "./scenes/gym";
import NewGym from "./scenes/gym/newGym";
import { getAllGyms } from './api/gymApi';

import ViewUser from "./scenes/users/ViewUser";
import MembershipRenewalForm from "./components/MembershipRenewalForm";
import MembershipNotes from "./components/NoteMembership";
import PaymentsEdit from "./components/PaymentsEdit";
import Grupe from "./scenes/grupe/index";
import NewGrupa from "./scenes/grupe/newGrupa";
import Membership from "./scenes/clanarina/index";
import NewMembership from "./scenes/clanarina/newMembership";
import ProduziClanarinuZapocenik from "./scenes/clanarina/produziClanarinuZapocenik";
import Monitor from "./scenes/monitor";
import IzvjestajPrihodi from "./scenes/izvjestaji/IzvjestajPrihodi"
import IzvjestajClanovi from "./scenes/izvjestaji/IzvjestajClanovi"
import IzvjestajTroskovi from "./scenes/izvjestaji/IzvjestajTroskovi";
import IzvjestajPosjete from "./scenes/izvjestaji/IzvjestajPosjete";
import IzvjestajFakture from "./scenes/izvjestaji/IzvjestajFakture";
import IzvjestajUposlenici from "./scenes/izvjestaji/IzvjestajUposlenici";
import TrainerForm from "./scenes/trener/newTrener";
import ReservationForm from "./scenes/rezervacije/newReservation";
import GroupSchedule from "./scenes/schedule";
import GroupDetails from "./scenes/grupe/detailsGrupa";
import Login from "./scenes/auth/Login"; 
// import withAuthorization, { Roles } from './components/Authorization';
import Authorization from './components/Authorization';
// import { fetchProtectedData } from './api/fetchProtectedData';
import axios from "axios";
import TreningPrograms from "./scenes/trening";
import NewTrainingProgram from "./scenes/trening/NewTrainingProgram";
import Treneri from "./scenes/trener";
import ViewTrener from "./scenes/trener/ViewTrener";
import Exercises from "./scenes/vjezbe";
import NewExercise from "./scenes/vjezbe/NewExercise";
import NewEqupment from "./scenes/oprema/NewEqupment";
import Equpment from "./scenes/oprema";
import Guests from "./scenes/guests";
import Nutritions from "./scenes/nutrition";
import Food from "./scenes/nutrition/food";
import Meal from "./scenes/nutrition/meal";
import NutricionistForm from "./scenes/nutrition/newUserNutrition";
import NewFood from "./scenes/nutrition/food/newFood";
import NewMeal from "./scenes/nutrition/meal/newMeal";
import ViewNutricionist from "./scenes/nutrition/ViewNutricionist";
import NewJelovnik from "./scenes/nutrition/noviJelovnik";
import Jelovnik from "./scenes/nutrition/Jelovnik";
import NewNotification from "./scenes/notifikacije/newNotification";
import Notification from "./scenes/notifikacije/index";
import NoviTrosak from "./scenes/troskovi/NoviTrosak";
import NewMessage from "./scenes/notifikacije/NewMessage";
import GuestGyms from "./scenes/settingGym";
import NewSettings from "./scenes/settingGym/newGuestGym";
import PrijaveDrugeTeretane from "./scenes/check-in/prijaveDrugeTeretane";
import PosjeteIzDrguihTeretana from "./scenes/check-in/PosjeteIzDrugihTeretana";
import DetailedExercise from "./scenes/vjezbe/detaljVjezbe";
import EditExercise from "./scenes/vjezbe/editVjezba";
import DetailedEqupment from "./scenes/oprema/detaljOprema";
import EditEqupment from "./scenes/oprema/EditEqupment";
import TrainingProgramDetalj from "./scenes/trening/programDetalj";
import TrainingProgramEdit from "./scenes/trening/programEdit";
import EditFood from "./scenes/nutrition/food/editFood";
import MealDetalj from "./scenes/nutrition/meal/mealDetail";
import EditMeal from "./scenes/nutrition/meal/mealEdit";
import ViewPaket from "./scenes/paketi/paketView";
import EditPaket from "./scenes/paketi/paketEdit";
import ResetPassword from "./components/ResetPassword";
import GroupCalendar from "./scenes/calendar/calendar";
import { UserProvider } from "./context/UserProvider";
import FakturaDetalj from "./scenes/izvjestaji/fakturaDetalj";
import EditUser from "./scenes/users/editUser";
import LogViewer from "./components/LogViewer";
import ViewUposlenik from "./scenes/users/viewUposlenik";
import EditGym from "./scenes/gym/editGym";
import JelovnikDetalj from "./scenes/nutrition/jelovnikDetalj";
import JelovnikEdit from "./scenes/nutrition/jelovnikEdit";
import EditTrainer from "./scenes/trener/EditTrainer";
import EditNutricionist from "./scenes/nutrition/nutricionistEdit";
import IzvjestajClanarine from "./scenes/izvjestaji/IzvjestajClanarine";
import IzvjestajTripod from "./scenes/izvjestaji/IzvjestajTripod";
import IzvjestajPaketi from "./scenes/izvjestaji/IzvjestajPaketi";
import NovaReklama from "./scenes/reklame/novaReklama";
import Reklame from "./scenes/reklame";

const App = () => {

  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [gyms, setGyms] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  const [defaultGym, setDefaultGym] = useState(localStorage.getItem('defaultGym') || gyms[0]);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Set the token in the default headers for every request
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      setIsAuthenticated(true);
    } else {
      // Remove the token from the default headers if not authenticated
      delete axios.defaults.headers.common['Authorization'];
      setIsAuthenticated(false);
    }
  }, []);

  const handleLogout = () => {
    // Clear the token and set isAuthenticated to false
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    if (storedIsAuthenticated === 'true') {
      setIsAuthenticated(true);
    }
  }, []);

  useEffect(() => {
    const fetchGyms = async () => {
      //  await fetchProtectedData();
      const allGyms = await getAllGyms();
      setGyms(allGyms);
    };

    fetchGyms();

    const savedGym = localStorage.getItem('defaultGym');
    if (savedGym) {
      setDefaultGym(JSON.parse(savedGym));
    }
  }, []);


  // console.log("users",users)
  return (
    <UserProvider>
    <GymContext.Provider value={{ colorMode, defaultGym,setDefaultGym, gyms }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />

        <div className="app">
          {isAuthenticated && <Sidebar isSidebar={isSidebar} />}
          <main className="content">
            {isAuthenticated && <Topbar setIsSidebar={setIsSidebar} />}

            <Routes>
            <Route
                  path="/"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <Dashboard />
                    </Authorization>
                  }
                />
                <Route
                  path="/users/new"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <NewUserForm />
                    </Authorization>
                  }
                />
                <Route
                  path="/users/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <EditUser />
                    </Authorization>
                  }
                />
                <Route
                  path="/users/:uloga/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      {/* <TrainerForm /> */}
                      <NewUserForm/>
                    </Authorization>
                  }
                />
                
                <Route
                  path="/mojprofil"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <ViewUposlenik />
                    </Authorization>
                  }
                />
                 <Route
                  path="/users/treneri"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <Treneri />
                    </Authorization>
                  }
                />
                 <Route
                  path="/trener/:id"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <ViewTrener />
                    </Authorization>
                  }
                />
                 <Route
                  path="/trener/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <EditTrainer />
                    </Authorization>
                  }
                />
              <Route
                  path="/users/:id"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <ViewUser />
                    </Authorization>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                  
                      <GroupCalendar />
           
                  }
                />
              <Route
                  path="/grupe"
                  element={
                    <Authorization allowedRoles={['admin', 'trainer','editor']}>
                      <Grupe />
                    </Authorization>
                  }
                />
           <Route
                  path="/grupe/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <NewGrupa />
                    </Authorization>
                  }
                />
              <Route
                  path="/grupe/:id"
                  element={
                    <Authorization allowedRoles={['admin', 'trainer','editor']}>
                      <GroupDetails />
                    </Authorization>
                  }
                />

                 <Route
                  path="/gym/:gymID/vjezbe"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <Exercises />
                    </Authorization>
                  }
                />
                
                <Route
                  path="/vjezba/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NewExercise />
                    </Authorization>
                  }
                />
                <Route
                  path="/vjezba/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <DetailedExercise />
                    </Authorization>
                  }
                />
                 <Route
                  path="/vjezba/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <EditExercise />
                    </Authorization>
                  }
                />
                 <Route
                  path="/gym/:gymID/oprema"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <Equpment />
                    </Authorization>
                  }
                />
                <Route
                  path="/oprema/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NewEqupment />
                    </Authorization>
                  }
                />
                 <Route
                  path="/oprema/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <DetailedEqupment />
                    </Authorization>
                  }
                />
                  <Route
                  path="/oprema/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <EditEqupment />
                    </Authorization>
                  }
                />
                
            <Route
                  path="/paketi"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <Paketi />
                    </Authorization>
                  }
                />
              <Route
                  path="/paketi/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <NewPaketForm />
                    </Authorization>
                  }
                />
                 <Route
                  path="/paket/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <ViewPaket />
                    </Authorization>
                  }
                />
                 <Route
                  path="/paket/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <EditPaket />
                    </Authorization>
                  }
                />
              <Route
                  path="/reservations/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <ReservationForm />
                    </Authorization>
                  }
                />
            <Route
                  path="/gym/:gymID/schedule"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <GroupSchedule />
                    </Authorization>
                  }
                />
            <Route
                  path="/gym/:gymID/posjete"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <CheckIn />
                    </Authorization>
                  }
                />
                <Route
                  path="/check-in/gost"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <PrijaveDrugeTeretane />
                    </Authorization>
                  }
                />
                 <Route
                  path="/check-in/gost/posjete"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <PosjeteIzDrguihTeretana />
                    </Authorization>
                  }
                />
                
           {/* <Route
                  path="/posjete/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <RFIDCard />
                    </Authorization>
                  }
                /> */}
            <Route
                  path="/gym"
                  element={
                    <Authorization allowedRoles={['admin','employee','trainer','editor']}>
                      <AllGym />
                    </Authorization>
                  }
                />
                 <Route
                  path="/gym/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <NewGym />
                    </Authorization>
                  }
                />
                 <Route
                  path="/gym/:gymId/edit"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <EditGym />
                    </Authorization>
                  }
                />
              <Route
                  path="/clanarina/new"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <NewMembership />
                    </Authorization>
                  }
                />
     
              <Route
                  path="/gym/:gymID/users"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <Membership />
                    </Authorization>
                  }
                />
                {/* <Route
                  path="/gym/:gymID/guest"
                  element={
                    <Authorization allowedRoles={['admin','member']}>
                      <Guests/>
                    </Authorization>
                  }
                /> */}
              {/* <Route
                path="/gym/:gymID/users"
                element={
                  withAuthorization([Roles.USER])(
                    isAuthenticated ? (
                      <Membership />
                    ) : (
                      <Navigate to="/login" />
                    )
                  )
                }
              /> */}
                 <Route
                  path="/membership-renewal"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <ProduziClanarinuZapocenik />
                    </Authorization>
                  }
                />

              <Route
                  path="/membership-renewal/:id"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <MembershipRenewalForm />
                    </Authorization>
                  }
                />
              <Route
                  path="/membership/:id/notes"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <MembershipNotes />
                    </Authorization>
                  }
                />
              
              <Route
                  path="/payments/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <PaymentsEdit />
                    </Authorization>
                  }
                />
              <Route
                  path="/gym/:gymID/monitor"
                  element={
                    <Authorization allowedRoles={['admin','employee']}>
                      <Monitor />
                    </Authorization>
                  }
                />
             <Route
                  path="/izvjestaj/prihodi"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajPrihodi />
                    </Authorization>
                  }
                />
              <Route
                  path="/izvjestaj/troskovi"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajTroskovi />
                    </Authorization>
                  }
                />
                <Route
                  path="/trosak/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <NoviTrosak />
                    </Authorization>
                  }
                />
             <Route
                  path="/izvjestaj/clanovi"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajClanovi />
                    </Authorization>
                  }
                />
                <Route
                  path="/izvjestaj/clanarine"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajClanarine />
                    </Authorization>
                  }
                />

              <Route
                  path="/izvjestaj/posjete"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajPosjete />
                    </Authorization>
                  }
                />

              <Route
                  path="/izvjestaj/fakture"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajFakture />
                    </Authorization>
                  }
                />
                <Route
                  path="/izvjestaj/paketi"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajPaketi />
                    </Authorization>
                  }
                />
             <Route
                  path="/izvjestaj/fakture/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <FakturaDetalj />
                    </Authorization>
                  }
                />

              <Route
                  path="/izvjestaj/uposlenici"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajUposlenici />
                    </Authorization>
                  }
                />
                   <Route
                  path="/izvjestaj/tripod"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <IzvjestajTripod />
                    </Authorization>
                  }
                />
              <Route
                  path="/gym/:gymID/programs"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <TreningPrograms/>
                    </Authorization>
                  }
                />
                <Route
                  path="/programs/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NewTrainingProgram/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/programs/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <TrainingProgramDetalj/>
                    </Authorization>
                  }
                />
                
                <Route
                  path="/programs/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <TrainingProgramEdit/>
                    </Authorization>
                  }
                />
                <Route
                  path="/users/nutricionisti"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <Nutritions/>
                    </Authorization>
                  }
                />
                <Route
                  path="/nutricionist/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NutricionistForm/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/nutricionist/:id"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <ViewNutricionist />
                    </Authorization>
                  }
                />
                  <Route
                  path="/nutricionist/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <EditNutricionist />
                    </Authorization>
                  }
                />
                
                <Route
                  path="/namirnice"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <Food/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/namirnice/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NewFood/>
                    </Authorization>
                  }
                />
                  <Route
                  path="/namirnice/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <EditFood/>
                    </Authorization>
                  }
                />
          
                <Route
                  path="/jelovnik"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <Jelovnik/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/jelovnik/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NewJelovnik/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/jelovnik/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <JelovnikDetalj/>
                    </Authorization>
                  }
                />
                  <Route
                  path="/jelovnik/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <JelovnikEdit/>
                    </Authorization>
                  }
                />
                <Route
                  path="/obrok"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <Meal/>
                    </Authorization>
                  }
                />
                <Route
                  path="/obrok/new"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <NewMeal/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/obrok/:id/detalj"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <MealDetalj/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/obrok/:id/edit"
                  element={
                    <Authorization allowedRoles={['admin','editor']}>
                      <EditMeal/>
                    </Authorization>
                  }
                />
                
                  <Route
                  path="/notifikacije"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <Notification/>
                    </Authorization>
                  }
                />
                  <Route
                  path="/notifikacije/novo"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      {/* <NewNotification/> */}
                      <NewNotification/>
                    </Authorization>
                  }
                />
                  <Route
                  path="/notifikacije/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      {/* <NewNotification/> */}
                      <NewMessage/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/reklame"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <Reklame/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/reklame/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      {/* <NewNotification/> */}
                      <NovaReklama/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/postavke"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <GuestGyms/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/postavke/new"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <NewSettings/>
                    </Authorization>
                  }
                />
                
                <Route
                  path="/log"
                  element={
                    <Authorization allowedRoles={['admin']}>
                      <LogViewer/>
                    </Authorization>
                  }
                />
                 <Route
                  path="/reset-password/:token"
                  element={<ResetPassword/>}
                />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
</main>
</div>
</ThemeProvider>
</GymContext.Provider>
</UserProvider>
);

};


export default App;

