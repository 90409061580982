import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function BarChartaverageTimeByDay({ data }) {
  const [groupedData, setGroupedData] = useState([]);
  const [busiestDay, setBusiestDay] = useState({ date: '', total: 0 });

  useEffect(() => {
    if (!data || !Array.isArray(data) || data.length === 0) {
      setGroupedData([]);
      return;
    }

    const newData = data.map((item) => {
      return {
        ...item,
        avgMinutesPerDay: item.avgSecondsPerDay,
      };
    });

    setGroupedData(newData);

    const busiestDay = newData.reduce((max, day) =>
      day.avgSecondsPerDay > max.avgSecondsPerDay ? day : max
    );

    setBusiestDay(busiestDay);
  }, [data]);

  return (
    <ResponsiveContainer width='100%' height={300}>
      <BarChart
        data={groupedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar
          dataKey='avgMinutesPerDay'
          stackId='a'
          name='Prosjecno vrijeme u sat/dan'
          fill={busiestDay.date ? '#ff7300' : '#8884d8'}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default BarChartaverageTimeByDay;
