import React,{ useState, useContext,useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
// import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
// import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import Inventory2Icon from '@mui/icons-material/Inventory2';
// import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
// import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
// import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import QrCodeIcon from '@mui/icons-material/QrCode';
// import InputLabel from '@mui/material/InputLabel';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
// import WindowIcon from '@mui/icons-material/Window';
import { GymContext } from "../../theme";
import CampaignIcon from '@mui/icons-material/Campaign';
import GroupsIcon from '@mui/icons-material/Groups';
// import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
// import CardMembershipIcon from '@mui/icons-material/CardMembership';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MonitorIcon from '@mui/icons-material/Monitor';
// import Card from '@material-ui/core/Card';
// import CardContent from '@material-ui/core/CardContent';
import Badge from '@mui/material/Badge';
import { UserContext } from '../../context/UserProvider';

// import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
// import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
// import InboxIcon from "@mui/icons-material/MoveToInbox";
import ArticleIcon from '@mui/icons-material/Article';
// import DraftsIcon from "@material-ui/icons/Drafts";
// import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
// import StarBorder from "@mui/icons-material/StarBorder";
import SportsMmaIcon from '@mui/icons-material/SportsMma';
import EggAltIcon from '@mui/icons-material/EggAlt';
import SetMealIcon from '@mui/icons-material/SetMeal';
// import { getUsersByRole } from '../../api/userApi';
import { getAllMembershipStatusByGym } from '../../api/membershipApi';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(4),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  card: {
    width: '80%',
    marginTop: theme.spacing(2),
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  nestedSecondLevel: {
    paddingLeft: theme.spacing(8)
  }
}));


const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const classes = useStyles();
  // const { defaultGym, setDefaultGym, gyms } = useContext(GymContext);
  const { user} = useContext(UserContext);
  const userRole = user?.role;
  // const userRole = localStorage.getItem('userRole');
  const defaultGym = JSON.parse(localStorage.getItem('defaultGym'));
  // console.log("defaultGym",defaultGym._id)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
   const [open, setOpen] = useState(false);
   const [open1, setOpen1] = useState(false);
   const [open2, setOpen2] = useState(false);

    // console.log(userRole)

   const [usersbyStatus, setusersbyStatus] = useState([]);

  //  console.log(usersbyStatus)
   useEffect(() => {
    const fetchData = async () => {
      try {
        if (defaultGym && defaultGym?._id) {
          const apiResponse = await getAllMembershipStatusByGym(defaultGym._id, 'pending');
          setusersbyStatus(apiResponse);
        } else {
          // console.error("defaultGym or defaultGym._id is not defined");
        }
      } catch (error) {
        console.error('Greška prilikom dohvata korisnika:', error);
      }
    };
  
    fetchData();
  }, []);



    const handleClick = () => {
      setOpen(!open);
    };
    const handleClick1 = () => {
      setOpen1(!open1);
    };
    const handleClick2 = () => {
      setOpen2(!open2);
    };
  
   
  return (
    <div className={classes.root}>
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  {/* ADMINIS */}
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100%"
                  height="100%"
                  src={defaultGym?.photo}
                  style={{ cursor: "pointer"}}
                />
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          {(userRole === 'admin' || userRole === 'employee') && (
          <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "0px 0 5px 10px" }}
            >
              {/* Poslovnica */}
              <Item
              title={defaultGym?.naziv}
              to="/"
              selected={selected}
              setSelected={setSelected}
              icon={< HomeWorkIcon />}
            />
            </Typography>
              )}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Podaci poslovnica
            </Typography>
            {/* <Item
              title="Svi clanovi"
              to="/users"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {(userRole === 'admin' || userRole === 'employee') && (
           <div style={{ display: 'flex', alignItems: 'center' }}>
          
            <Item
              title="Članovi"
              to={`/gym/${defaultGym?._id}/users`}
              selected={selected}
              setSelected={setSelected}
              icon={<PeopleOutlinedIcon />}
            >
              Članovi
            </Item>
            
            <Badge badgeContent={usersbyStatus?.length} color="warning" style={{ marginLeft: '0' }} />
          </div>
            )}
            {/* <Item
              title="Zahtjevi"
              to={`/gym/${defaultGym?._id}/guest`}
              // icon={<CardMembershipIcon />}
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
               {(userRole === 'admin' || userRole === 'employee') && (
            <Item
              title="Paketi"
              to="/paketi"
              icon={<Inventory2Icon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
            {(userRole === 'admin' || userRole === 'employee') && (
            <Item
              title="Posjete"
              to={`/gym/${defaultGym?._id}/posjete`}
              icon={<QrCodeIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'employee') && (
            <Item
              title="Monitor"
              to={`/gym/${defaultGym?._id}/monitor`}
              icon={<MonitorIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
           
             <Item
              title="Poslovnice"
              to="/gym"
              icon={<AddHomeWorkIcon />}
              selected={selected}
              setSelected={setSelected}
            />
       
{(userRole === 'admin' || userRole === 'trainer') && (
    <ListItem button onClick={handleClick1}
      style={{paddingLeft:"0px"}}
      >
          
        <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0px", paddingLeft:"0px" }}
            >
              Trening
            </Typography>
           
        {open1 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
            )}
      <Collapse in={open1} timeout="auto" unmountOnExit>
      {(userRole === 'admin' || userRole === 'editor') && (
            <Item
              title="Treneri"
              to="/users/treneri"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
                {(userRole === 'admin' || userRole === 'editor') && (
            <Item
              title="Vježbe"
              to={`/gym/${defaultGym?._id}/vjezbe`}
              icon={<FitnessCenterIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'editor') && (
             <Item
              title="Oprema"
              to={`/gym/${defaultGym?._id}/oprema`}
              icon={<SportsMmaIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'editor') && (
            <Item
              title="Programi"
              to={`/gym/${defaultGym?._id}/programs`}
              icon={<ArticleIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'trainer') && (
              <Item
              title="Grupe"
              to="/grupe"
              icon={<GroupsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'trainer') && (
            <Item
              title="Calendar"
              to="/calendar"
              icon={<CalendarTodayOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
      </Collapse>

{/* NUTRICIONIZAM */}
{(userRole === 'admin' || userRole === 'nutritionist') && (
<ListItem button onClick={handleClick2}
      style={{paddingLeft:"0px"}}
      >
        <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0px", paddingLeft:"0px" }}
            >
              Nutricionizam
            </Typography>
        {open2 ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
        )}
        
      <Collapse in={open2} timeout="auto" unmountOnExit>
      {(userRole === 'admin' || userRole === 'nutritionist') && (
            <Item
              title="Nutricionisti"
              to="/users/nutricionisti"
              icon={<PeopleOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
            {(userRole === 'admin' || userRole === 'nutritionist') && (
            <Item
              title="Namirnice"
              to={`/namirnice`}
              icon={<EggAltIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'nutritionist') && (
             <Item
              title="Obroci"
              to={`/obrok`}
              icon={<SetMealIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
             {(userRole === 'admin' || userRole === 'nutritionist') && (
             <Item
              title="Jelovnici"
              to={`/jelovnik`}
              icon={<SetMealIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
      </Collapse>
      {userRole === 'admin'  && (
             <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            // subheader={
            //   <ListSubheader component="div" id="nested-list-subheader">
            //     Nested List Items
            //   </ListSubheader>
            // }
            // className={classes.root}
          >
     
      <ListItem button onClick={handleClick}
      style={{paddingLeft:"0px"}}
      >
        {/* <ListItemIcon>
        <BarChartOutlinedIcon />
        </ListItemIcon> */}
        {/* <ListItemText primary="Izvjestaji" /> */}
        <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 0px", paddingLeft:"0px" }}
            >
              Izvjestaji
            </Typography>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem
            button
            style={{paddingLeft:"0px"}}
            // className={classes.nested}
            // onClick={handleClickSecondLevel}
          >
            <Item
              title="Izvještaj o prihodima"
              to="/izvjestaj/prihodi"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
              
            />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem 
          button 
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
          <Item
              title="Izvještaj o troškovima"
              to="/izvjestaj/troskovi"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem button  
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
          <Item
              title="Izvještaj o clanovima"
              to="/izvjestaj/clanovi"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem button  
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
          <Item
              title="Izvještaj o članarinama"
              to="/izvjestaj/clanarine"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem button  
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
             {/* <span style={{fontSize:12, padding:2, borderRadius:10,position: "absolute", right: 0, top: 0, marginRight: "0%", backgroundColor:'turquoise', color:"black"  }}>novo</span> */}
          <Item
              title="Izvještaj prodaja - paketi"
              to="/izvjestaj/paketi"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem button 
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
          <Item
              title="Izvještaj o pojecenosti"
              to="/izvjestaj/posjete"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
        </List>
        <List component="div" disablePadding>
          <ListItem button 
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
          <Item
              title="Izvještaj o fakturama"
              to="/izvjestaj/fakture"
              icon={<PieChartOutlineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
          
        </List>
        <List component="div" disablePadding>
          <ListItem button 
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
             <Item
              title="Izvještaj uposlenici"
              to="/izvjestaj/uposlenici"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
          
        </List>
        <List component="div" disablePadding>
          <ListItem button 
          style={{paddingLeft:"0px"}}
          // className={classes.nested}
          >
             <Item
              title="Izvještaj tripod"
              to="/izvjestaj/tripod"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </ListItem>
          
        </List>
      </Collapse>
    </List>
       )}
        {userRole === 'admin' && (
           <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
             {/* Finansije
            </Typography>
            <Item
              title="Bar Chart"
              to="/bar"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            > */}
             Marketing
            </Typography>
             )}
               {userRole === 'admin' && (
            <Item
              title="Marketing"
              to="/"
              icon={<BarChartOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            )}
               {userRole === 'admin' && (
                <>
              <Item
              title="Obavjestenja"
              to={`/notifikacije`}
              selected={selected}
              setSelected={setSelected}
              icon={<CampaignIcon />}
            >
              Obavjestenja
            </Item>
             <Item
             title="Reklame"
             to={`/reklame`}
             selected={selected}
             setSelected={setSelected}
             icon={<CampaignIcon />}
           >
             Reklame
           </Item>
           </>
             )}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
    </div>
  );
};

export default Sidebar;
