import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation} from 'react-router-dom';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Chip,
} from '@mui/material';

// ... (import other necessary components and libraries)
const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      // gap: theme.spacing(2),
      // maxWidth: '400px',
      margin: '0 auto',
      padding:20
    },
    inField: {
      backgroundColor: "#ffffff14"
    }
  }));


const DetailedEqupment = () => {
  const classes = useStyles();
  const { state: data } = useLocation();
  const navigate = useNavigate();
  // console.log(data)
  // Extract exercise details
  const {
    name,
    description,
    exercises,
    qrCode,
    // equipment,
    // type,
    image,
  } = data;
  const handleClick = () => {
    navigate(`/oprema/${data._id}/edit`, { state: data});
  };
// console.log(data)
  // Convert muscleGroups to an array of strings
  // const muscleGroupsArray = Array.isArray(muscleGroups) ? muscleGroups : [];
  const handleClickVjezba = (e) => {
    const dataEqupment={
        description: e.description,
          equipment: [{
            _id: data._id,
            image: data.image,
            name: data.name
          }],
        image: e.image,
        muscleGroups: e.muscleGroups,
        name: e.name,
        type: e.type,
        video: e.video,
        _id: e._id
    }
    navigate(`/vjezba/${e._id}/detalj`, { state: dataEqupment });
  }
  return (
    <> 
    <Grid container spacing={2} style={{marginLeft:20}}>
    <Header title="Detalj opreme" />  
    </Grid>
    <Grid container spacing={2} style={{padding:20}}>
      <Grid item xs={4}>
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "100%" }}
            src={image}
            alt={`Slika vježbe - ${name}`}
          />
        </div>
      </Grid>
      <Grid item xs={8}>
        <Paper elevation={3} className={classes.form}>
              {/* <Link > */}
   <EditIcon onClick={handleClick}></EditIcon>
   {/* </Link> */}
          {/* <Header title="Detalji vježbe" /> */}
          <Typography variant="h4">Naziv oreme: {name?.bs}</Typography>
          <Typography variant="body1">Opis: {description?.bs}</Typography>
          {/* <Typography variant="body1">Mišićne grupe: {muscleGroups[0].name}</Typography> */}

        </Paper>
        <Grid container spacing={2} style={{padding:20, }}>
    <Typography variant="h4">Vjezbe: {exercises.length}</Typography>
    </Grid>
        <Grid container spacing={2} style={{padding:20}}>
        {exercises.map((e, index) => (
  <Grid container alignItems="center" spacing={2} key={index}>
    <Grid item xs={5} sm={1}>
      <img
        style={{ width: "100%" }}
        src={e.image}
        alt={`Slika vježbe - ${e.name}`}
        onClick={() => handleClickVjezba(e)}
      />
    </Grid>
    <Grid item xs={5} sm={6}>
      <Typography variant="h5">{e.name.bs}</Typography>
    </Grid>
  </Grid>
))}

</Grid>
      </Grid>
    </Grid>
    <Typography variant="h4" style={{padding:20, paddingBottom:0}}>QR Code opreme</Typography>
    <Grid container spacing={2} style={{padding:20}}>
    <Grid item xs={2}>
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "100%" }}
            src={data.qrCode}
            alt={`Slika vježbe - ${data.name}`}
          />
        </div>
      </Grid>
    </Grid>
    </>
  );
};

export default DetailedEqupment;
