import React, { useEffect, useState,useContext } from 'react';
import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
import { getMembers } from '../../api/memberApi';
import BrojClanova from './BrojClanova';
import { TailSpin } from 'react-loader-spinner';
import BrojAktivnihClanova from './BrojAktivnihClanova';
import { getAllPaymentByRange } from '../../api/paymentApi';
import UplateDanas from './UplateDanas';
import UplateMjesec from './UplateMjesec';
// import FaktureIzvjestajKomponenta from '../../components/faktureIzvjestajKomponenta';
// import FaktureBarChart3 from '../../components/BarChart4';
import UplateMjesecBarChart from '../../components/BarChartMjesec';
import Rodjendan from './Rodjendan';
import ProsjekPoGodinama from './ProsjekGodina';
import { getAllPosjeteByGymByRange } from '../../api/attendenceApi';
import BrojPosjetaDanas from './BrojPosjetaDanas';
import BrojClanovaSala from './BrojClanovaSala';
import GuzvaPoVremenuIDanima from './Guzva';
import BarChartPosjete from '../../components/BarChartPosjete';
import { UserContext } from '../../context/UserProvider';

const Dashboard = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const defaultGym = JSON.parse(localStorage.getItem('defaultGym'));
  const gymID = defaultGym?._id;

  const { user } = useContext(UserContext);
  const isAdmin= user?.role === 'admin';


  const [uplate, setUplate] = useState([]);
  const [members, setMembers] = useState([]);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  const startYear = new Date(`${currentMonth}/01/${currentYear}`);
  // console.log(uplate)
  // const [startDate, setStartDate] = useState(startYear);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());

  // const [ukupanPrihodUplate, setUkupanPrihodUplate] = useState('');

  const [rangePosjete, setRangePosjete] = useState([]);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999)

  useEffect(()=>{

    async function fechData (){

    const responseMember = await getMembers()
    const responseUplate = await getAllPaymentByRange({
      startDate,
      endDate
    })

    // console.log(responseUplate)
    //posjete
    const posjete = await getAllPosjeteByGymByRange({
      gymID,
      startDate,
      endDate
    });
      // Sortirajte posjete od najstarijeg do najnovijeg datuma
      const sortiranePosjete = posjete.sort((a, b) => {
        const datumA = new Date(a.timeIn);
        const datumB = new Date(b.timeIn);
        return datumA - datumB;
      });
    setRangePosjete(sortiranePosjete);

    setMembers(responseMember)
    setUplate(responseUplate)
    setLoading(false);
    }

    fechData()
},[gymID])

const uplateNijePending = uplate.filter((uplata) => uplata.status !== 'pending');

const handleBusiestDay = (busiestDayData) => {
  // const { weekday, busiestDay } = busiestDayData;
  // setWeekday(weekday);
  // setBusiestDay(busiestDay.total);
};

  return (
    // <></>

    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Kontrolna ploča" 
        // subtitle="Welcome to your dashboard" 
        />
      </Box>

      {/* GRID & CHARTS */}
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
        </div>
      ) : (
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        {isAdmin ?
        <>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {/* <StatBox
            title="12,361"
            subtitle="Broj članova"
            progress="a"
          /> */}
          <BrojClanova clanovi={members}/>
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
         <BrojAktivnihClanova clanovi={members}/>
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <UplateDanas uplateData={uplateNijePending}/>
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
           <UplateMjesec uplateData={uplateNijePending}/>
          {/* <StatBox
            title="1,325,134"
            subtitle="Uplate mjesec"
            progress="d"
          /> */}
        </Box>
         {/* ROW 2 */}
         <Box
          gridColumn="span 7"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
           <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
            Uplate za trenutni mjesec
            </Typography>
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
     
          </Box>
          <Box height="250px" m="-20px 0 0 0">
          {/* <FaktureBarChart3 data={uplate} startDate={startDate}/> */}

          <UplateMjesecBarChart data={uplateNijePending} startDate={startDate}/>
          </Box>
        </Box>
        </>
        :""
        }
       
        <Box
          gridColumn="span 5"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          
            <Rodjendan clanoviData={members}/>
         
        
        </Box>

 {/* ROW 3 */}
 <Box
          gridColumn="span 2"
          
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BrojPosjetaDanas posjeteData={rangePosjete}/>
        </Box>
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            
            title={`${rangePosjete.length}`}
            subtitle="Broj posjeta mjesec"
            progress="w"
            // increase="+21%"
            // icon={
            //   <PointOfSaleIcon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <BrojClanovaSala posjeteData={rangePosjete}/>
        </Box>
       {!isAdmin ?
        <Box
          gridColumn="span 2"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <UplateDanas uplateData={uplateNijePending}/>
        </Box>
        : ""}
        <Box
          gridColumn="span 5"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <GuzvaPoVremenuIDanima posjeteData={rangePosjete}/>
        </Box>

          {/* ROW 4*/}
          <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Statistika posjeta
              </Typography>
  
            </Box>
            <Box>
    
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
          <BarChartPosjete data={rangePosjete} handleBusiestDay={handleBusiestDay}/>
            {/* <LineChart isDashboard={true} /> */}
          </Box>
        </Box>
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            {/* <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              Rođendan 
            </Typography> */}
            <ProsjekPoGodinama clanoviData={members}/>  
          </Box>
        
        </Box>
        {/* ROW 5 */}
        
      </Box>
        )}
    </Box>
  );
};

export default Dashboard;
