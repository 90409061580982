import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams, Link, useNavigate,useLocation} from 'react-router-dom';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Chip,
} from '@mui/material';

// ... (import other necessary components and libraries)
const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      // gap: theme.spacing(2),
      // maxWidth: '400px',
      margin: '0 auto',
      padding:20
    },
    inField: {
      backgroundColor: "#ffffff14"
    }
  }));

  export const muscleGroupColors = {
    "1": { group: "Ruke", color: "#2d3c93" },
    "2": { group: "Ramena", color: "#480355" },
    "3": { group: "Prsa", color: "#16a1ce" },
    "4": { group: "Ledja", color: "#019745" },
    "5": { group: "Vrat", color: "#9d8677" },
    "6": { group: "Stomak", color: "#fcb141" },
    "7": { group: "Noge", color: "#b430cb" },
    "8": { group: "Gluteus", color: "#f04238" },
    // Dodajte ostale grupe mišića i boje ovdje
  };
  
  
const DetailedExercise = () => {
  const classes = useStyles();
  const { state: data } = useLocation();
  const navigate = useNavigate();

  // Extract exercise details
  const {
    name,
    description,
    muscleGroups,
    videoID,
    equipment,
    type,
    image,
  } = data;
  const handleClick = () => {
    navigate(`/vjezba/${data._id}/edit`, { state: data});
  };
  // console.log(data)
  // Convert muscleGroups to an array of strings
  // const muscleGroupsArray = Array.isArray(muscleGroups) ? muscleGroups : [];
  const muscleGroupsArray = muscleGroups[0].split(",");
  return (
    <> 
    <Grid container spacing={2} style={{marginLeft:20}}>
    <Header title="Detalj vježbe" />  
    </Grid>
    <Grid container spacing={2} style={{padding:20}}>
      <Grid item xs={4}>
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "100%" }}
            src={image}
            alt={`Slika vježbe - ${name}`}
          />
        </div>
      </Grid>
      <Grid item xs={8}>
        <Paper elevation={3} className={classes.form}>
              {/* <Link > */}
   <EditIcon onClick={handleClick}></EditIcon>
   {/* </Link> */}
          {/* <Header title="Detalji vježbe" /> */}
          <Typography variant="h4">Naziv vježbe: {name?.bs}</Typography>
          <Typography>
          {muscleGroupsArray?.map((item) => item.split("-")[0]) // Razdvajanje brojeva
                .filter((item, index, array) => array.indexOf(item) === index) // Uklanjanje duplih brojeva
                .map((number) => {
                  const muscleGroup = muscleGroupColors[number];
                  return (
                    <Typography
                    key={number}
                    style={{
                      fontWeight: 'bold',
                      color: "white",
                      marginRight: 5,
                      backgroundColor: muscleGroup?.color,
                      padding: 2,
                      borderRadius: 5,
                      overflow: 'hidden',
                      width:100
                    }}
                  >
                    {muscleGroup?.group}
                  </Typography>
                  );
                })}
            {/* {muscleGroupsArray.map((muscleGroup, index) => (
              <Chip
                key={index}
                label={muscleGroup}
                className={classes.muscleGroupChip}
              />
            ))} */}
          </Typography>
          <Typography variant="body1">Opis: {description?.bs}</Typography>
          <Typography variant="body1">Tip vježbe: {type}</Typography>
          <Typography variant="body1">Video ID: {videoID}</Typography>
          {/* <Typography variant="body1">Mišićne grupe: {muscleGroups[0].name}</Typography> */}

        </Paper>
      </Grid>
    </Grid>
    <Grid container spacing={2} style={{marginLeft:20}}>
    <Typography variant="h4">Oprema: {equipment[0].name?.bs}</Typography>
    </Grid>
    <Grid container spacing={2} style={{padding:20}}>
    <Grid item xs={4}>
        <div style={{ position: "relative" }}>
          <img
            style={{ width: "100%" }}
            src={equipment[0].image}
            alt={`Slika vježbe - ${equipment[0].name}`}
          />
        </div>
      </Grid>
    </Grid>
    </>
  );
};

export default DetailedExercise;
