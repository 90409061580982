  import React, { useState, useEffect } from 'react';
  import Header from "../../components/Header";
  import Grid from '@material-ui/core/Grid';
  import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
  import { makeStyles } from '@mui/styles';
  import {
    TextField,
    Button,
  } from '@mui/material';
  import Autocomplete from "@mui/material/Autocomplete";
  import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  // import { createExercise } from '../../api/exerciseApi';
  import { getAllEquipmentsByGymId } from '../../api/equpmentsApi';
  import axios from 'axios';
  import ConfigApp from '../../config/ConfigApp';
  import { handleImageUpload, MAX_IMAGE_SIZE } from '../../imageUploadUtils'; 
  import {TailSpin} from 'react-loader-spinner';

  const baseUrl = `${ConfigApp.URL}`;

  const tijeloImg = process.env.PUBLIC_URL + '/assets/img/tijelo.png';

  const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      maxWidth: '400px',
      margin: '0 auto',
    },
    inField: {
      backgroundColor: "#ffffff14"
    }
  }));

  const NewExercise = () => {
    const classes = useStyles();

    // const [name, setName] = useState('');
    // const [description, setDescription] = useState('');
    const [name, setName] = useState({ en: '', bs: '' });
    const [description, setDescription] = useState({ en: '', bs: '' });

    const [muscleGroups, setMuscleGroups] = useState([]);
    const [videoID, setVideoID] = useState(null);
    const [equipment, setEquipment] = useState([]);
    const [equipments, setEquipments] = useState([])
    const [image, setImage] = useState(null);
    const [selectedTipVjezbe, setSelectedTipVjezbe] = useState("");
    const savedGym = JSON.parse(localStorage.getItem('defaultGym'));
    const vjezbaType = ['zagrijavanje', 'trening', 'hladjenje'];
    const [loading, setLoading] = useState(false);
    const [isExerciseCreated, setIsExerciseCreated] = useState(false);
  // console.log(image)
    const [selectedMuscleGroups, setSelectedMuscleGroups] = useState([]);
    const [selectedImage, setSelectedImages] = useState([]);
    
    const handleMuscleGroupChange = (event) => {
      const muscleGroup = event.target.value;
      if (muscleGroups.includes(muscleGroup)) {
        setMuscleGroups(muscleGroups.filter((group) => group !== muscleGroup));
        setSelectedImages(selectedImage.filter((image) => image !== getImageByMuscleGroup(muscleGroup)));
      } else {
        setMuscleGroups([...muscleGroups, muscleGroup]);
        setSelectedImages([...selectedImage, getImageByMuscleGroup(muscleGroup)]);
      }
    };
    
    const getImageByMuscleGroup = (muscleGroup) => {
      switch (muscleGroup) {
        case '3':
          return '../../assets/img/tijelo/3.png';
        case '1-1':
          return '../../assets/img/tijelo/1-1.png';
        case '2':
          return '../../assets/img/tijelo/2.png';
        case '1-2':
          return '../../assets/img/tijelo/1-2.png'
        case '1-3':
          return '../../assets/img/tijelo/1-3.png';
        case '5':
          return '../../assets/img/tijelo/5.png';
        case '4-1':
          return '../../assets/img/tijelo/4-1.png';
        case '4-3':
          return '../../assets/img/tijelo/4-3.png';
        case '4-2':
          return '../../assets/img/tijelo/4-2.png';
        case '4-4':
          return '../../assets/img/tijelo/4-4.png';
        case '6-2':
          return '../../assets/img/tijelo/6-2.png';
        case '6-1':
          return '../../assets/img/tijelo/6-1.png';
        case '7-1':
          return '../../assets/img/tijelo/7-1.png';
        case '7-2':
          return '../../assets/img/tijelo/7-2.png';
        case '7-3':
          return '../../assets/img/tijelo/7-3.png';
        case '7-4':
          return '../../assets/img/tijelo/7-4.png';
        case '7-5':
          return '../../assets/img/tijelo/7-5.png';
        case '8':
          return '../../assets/img/tijelo/8.png';
        case '7-6':
          return '../../assets/img/tijelo/7-6.png';
        default:
          return '';
      }
    };

    useEffect(() => {
      const images = getImageByMuscleGroup(selectedMuscleGroups);
      setSelectedImages(images);
    }, [selectedMuscleGroups]);



  // console.log(selectedImage)
    const handleTipVjezbeChange = (e, selectedValues) => {
      setSelectedTipVjezbe(selectedValues);
    };

    // const handleMuscleGroupsChange = (event) => {
    //   setMuscleGroups(event.target.value);
    // };

    const handleVideoIDChange = (event) => {
      setVideoID(event.target.value);
    };

    const handleEquipmentChange = (event) => {
      setEquipment(event.target.value);
    };

    useEffect(() => {
      async function getData() {
        const fetchedData = await getAllEquipmentsByGymId(savedGym._id);
        setEquipments(fetchedData);
      }

      getData();
    }, []);

    useEffect(() => {
      if (!loading && isExerciseCreated) {
        toast.success('Uspješno ste kreirali vježbu.');
        setIsExerciseCreated(false); // Resetujte stanje nakon prikazivanja poruke
      }
    }, [loading, isExerciseCreated]);
    
    const handleSubmit = async (event) => {
      event.preventDefault();
      setLoading(true);

      // const exerciseData = {
      //   name,
      //   description,
      //   muscleGroups,
      //   videoID,
      //   equipment,
      //   type: selectedTipVjezbe,
      //   image,
      // };
        const exerciseData = {
          name: JSON.stringify(name),
          description: JSON.stringify(description),
          muscleGroups,
          videoID,
          equipment,
          type: selectedTipVjezbe,
          image,
        };

    
        const formData = new FormData();
        formData.append('name', exerciseData.name);
        formData.append('description', exerciseData.description);
        formData.append('muscleGroups', exerciseData.muscleGroups);
        formData.append('videoID', exerciseData.videoID);
        formData.append('equipment', exerciseData.equipment);
        formData.append('type', exerciseData.type);
        formData.append('image', exerciseData.image);
    
        try {
          const res =await axios.post(`${baseUrl}/vjezbe`, formData);
          // alert('Podaci su uspješno poslani na backend!');
          // console.log(res.status ===201)
          if (res.status === 201) {
            setIsExerciseCreated(true);
          }
        } catch (error) {
          console.error(error);
          toast.error('Došlo je do greške prilikom kreiranja vježbe.');
          // alert('Došlo je do greške prilikom slanja podataka.');
        } finally {
        setLoading(false); // Postavi loading na false nakon završetka
        // toast.success('Uspješno ste kreirali vježbu.');
    }
      
      
    };

    // const handleImageUpload = (event) => {
    //   const file = event.target.files[0];
    //   setImage(file);
    // };

  // console.log(muscleGroups)
    return (
      <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <TailSpin height={80} width={80} />
            </div>
          ) : (
      <Grid container spacing={2}>
            <ToastContainer />
      {/* <div style={{ display: "flex", alignItems: "center" }}> */}
      <Grid item xs={6}>
          <div style={{ position: "relative" }} >
          <img
            style={{ width: "100%" }}
            src={tijeloImg}
            alt="Slika vježbe"
          />
          {selectedImage.length > 0 && (
            selectedImage.map((image, index) => (
              <img
                key={index}
                style={{ width: "100%", position: "absolute", top: 0, left: 0, zIndex: 1 }}
                src={image}
                alt="Slika vježbe"
              />
            ))
          )}
        </div>
        </Grid>
        <Grid item xs={2}>
        {/* <div> */}
        <h3>Odaberi mišiće:</h3>
        <h4 style={{margin:0}}>--------Ruke---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="1-1"
                checked={muscleGroups.includes("1-1")}
                onChange={handleMuscleGroupChange}
              />
              Ruke - Biceps
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="1-2"
                checked={muscleGroups.includes("1-2")}
                onChange={handleMuscleGroupChange}
              />
              Ruke - Triceps
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="1-3"
                checked={muscleGroups.includes("1-3")}
                onChange={handleMuscleGroupChange}
              />
              Ruke - podlaktica
            </label>
          </div>
          <h4 style={{margin:0}}>--------Rame---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="2"
                checked={muscleGroups.includes("2")}
                onChange={handleMuscleGroupChange}
              />
              Rame
            </label>
          </div>
          <h4 style={{margin:0}}>--------Grudi---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="3"
                checked={muscleGroups.includes("3")}
                onChange={handleMuscleGroupChange}
              />
              Grudi
            </label>
          </div>
          <h4 style={{margin:0}}>--------Leđa---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="4-1"
                checked={muscleGroups.includes("4-1")}
                onChange={handleMuscleGroupChange}
              />
              Leđa - gore
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="4-2"
                checked={muscleGroups.includes("4-2")}
                onChange={handleMuscleGroupChange}
              />
              Leđa - dole
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="4-3"
                checked={muscleGroups.includes("4-3")}
                onChange={handleMuscleGroupChange}
              />
              Ledja - Trapezius
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="4-4"
                checked={muscleGroups.includes("4-4")}
                onChange={handleMuscleGroupChange}
              />
              Ledja - vanjski kosi
            </label>
          </div>
          <h4 style={{margin:0}}>--------Vrat---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="5"
                checked={muscleGroups.includes("5")}
                onChange={handleMuscleGroupChange}
              />
              Vrat
            </label>
          </div>
          <h4 style={{margin:0}}>-------Stomak---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="6-2"
                checked={muscleGroups.includes("6-2")}
                onChange={handleMuscleGroupChange}
              />
              Stomak - vanjski kosi misic
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="6-1"
                checked={muscleGroups.includes("6-1")}
                onChange={handleMuscleGroupChange}
              />
              Stomak - trbusni
            </label>
          </div>
          <h4 style={{margin:0}}>--------Noge---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="7-1"
                checked={muscleGroups.includes("7-1")}
                onChange={handleMuscleGroupChange}
              />
              Noge - Prednja podkoljenica
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="7-2"
                checked={muscleGroups.includes("7-2")}
                onChange={handleMuscleGroupChange}
              />
              Noge - quadriceps
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="7-3"
                checked={muscleGroups.includes("7-3")}
                onChange={handleMuscleGroupChange}
              />
              Noge - abductors
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="7-4"
                checked={muscleGroups.includes("7-4")}
                onChange={handleMuscleGroupChange}
              />
              Noge - zadnja loza
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="7-5"
                checked={muscleGroups.includes("7-5")}
                onChange={handleMuscleGroupChange}
              />
              Noge - list
            </label>
          </div>
          <div>
            <label>
              <input
                type="checkbox"
                value="7-6"
                checked={muscleGroups.includes("7-6")}
                onChange={handleMuscleGroupChange}
              />
              Noge -  siroki list misic 
            </label>
          </div>
          <h4 style={{margin:0}}>--------Gluteus---------</h4>
          <div>
            <label>
              <input
                type="checkbox"
                value="8"
                checked={muscleGroups.includes("8")}
                onChange={handleMuscleGroupChange}
              />
              Gluteus
            </label>
          </div>
        {/* </div> */}
        </Grid>
        <Grid item xs={4}>
        {/* <div> */}
          {/* Forma */}
          <form className={classes.form} onSubmit={handleSubmit}>
            <Header title="Nova vježba" subtitle="" />
        
    
            {/* <TextField
              className={classes.inField}
              label="Naziv vježbe"
              value={name}
              onChange={(event) => setName(event.target.value)}
              variant="outlined"
              required
            /> */}
            <TextField
              label="Naziv vježbe (Bosanski)"
              value={name.bs}
              onChange={(event) => setName({ ...name, bs: event.target.value })}
              variant="outlined"
              required
            />
    
            <Autocomplete
              single
              options={vjezbaType}
              getOptionLabel={(option) => option}
              value={selectedTipVjezbe}
              onChange={handleTipVjezbeChange}
              renderInput={(params) => (
                <TextField {...params} label="Tip vjezbe" variant="outlined" />
              )}
            />
    
          {/* <TextField
            label="Opis"
            className={classes.inField}
            multiline
            rows={8}
            fullWidth
            variant="outlined"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          /> */}
            
            <TextField
              label="Opis (Bosanski)"
              multiline
              rows={4}
              value={description.bs}
              onChange={(event) => setDescription({ ...description, bs: event.target.value })}
              variant="outlined"
              required
            />
    
            <FormControl>
              <InputLabel style={{ color: "#b9bbc0" }}>Oprema</InputLabel>
              <Select
                multiple
                className={classes.inField}
                value={equipment}
                onChange={handleEquipmentChange}
                variant="outlined"
                required
                style={{ border: "1px solid #4a505f", color: "#b9bbc0" }}
              >
                {equipments.map((equipment) => (
                  <MenuItem key={equipment._id} value={equipment._id}>
                    {equipment.name.bs}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
    
            <TextField
              label="Slika"
              className={classes.inField}
              type="file"
              accept="image/*"
              // onChange={handleImageUpload}
              onChange={(e) => handleImageUpload(e, setImage)}
              required
            />
    
            <TextField
              label="Video ID"
              className={classes.inField}
              value={videoID}
              onChange={handleVideoIDChange}
              variant="outlined"
              // required
            />
        <Header subtitle="Engleski jezik" />
              <TextField
                label="Naziv vježbe (Engleski)"
                value={name.en}
                onChange={(event) => setName({ ...name, en: event.target.value })}
                variant="outlined"
                required
              />
              <TextField
                label="Opis (Engleski)"
                multiline
                rows={4}
                value={description.en}
                onChange={(event) => setDescription({ ...description, en: event.target.value })}
                variant="outlined"
                required
              />

            <Grid container justify="left" alignItems="left" spacing={2}>
              <Grid item>
                <Button type="submit" variant="contained" color="secondary">
                  Kreiraj vježbu
                </Button>
              </Grid>
            </Grid>
          </form>
          </Grid>
  
      </Grid>
      )}
      </>
    );
    
  };

  export default NewExercise;
