import React,{useEffect, useState} from "react";
import StatBox from "../../components/StatBox";


const BrojAktivnihClanova = (clanovi) =>{

// console.log(clanovi)
const [activeMembers, setActiveMember] = useState([]);
const [uposlenici, setUposlenici] = useState([]);

useEffect(()=>{
    let activeMembers = [];
    let uposlenici = []
;
    
    for (const member of clanovi.clanovi) {
      if (member.user.membership.status === "active") {
        activeMembers.push(member);
        } else {
        //   existingMembers++;
        }
        if (member.user.membership.plan.nazivPaketa.bs === "UPOSLENICI") {
            uposlenici.push(member);
            } else {
            //   existingMembers++;
            }
    }

    setActiveMember(activeMembers);
    setUposlenici(uposlenici);
    
},[])


    return(
        <>
         <StatBox
           title={`${activeMembers.length}`}
            subtitle="Broj aktivnih članova"
            title2={`${uposlenici.length} uposlenika`}
            // subtitle="Broj aktivnih članova"
            progress="a"
          />
        
        </>
    )
}

export default BrojAktivnihClanova;

