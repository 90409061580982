import React, { useState, useEffect } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
  Checkbox
} from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import { createMessage } from '../../api/messageApi.js'; // Pretpostavka za API pozive
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUsers } from '../../api/userApi';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
}));

// ... postojeći importi

const NewMessage = () => {
  const classes = useStyles();

  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [users, setUsers] = useState([]); // Pretpostavka za više korisnika
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [membershipsExpired, setMembershipsExpired] = useState(false);
  const [daysExpired, setDaysExpired] = useState(0);
  const [showExpiredMembers, setShowExpiredMembers] = useState(false);
  const [daysToExpiration, setDaysToExpiration] = useState('');
  
  useEffect(() => {
    async function fetchUser() {
      try {
        const fetchedUser = await getUsers();
    
        // Filtriranje korisnika sa isteklim članstvima i odgovarajućim brojem dana do isteka
        const filteredUsers = showExpiredMembers
          ? fetchedUser.filter(user => {
              if (daysToExpiration === '') {
                return user.membership.status === 'expired';
              } else {
                const daysDifference = Math.floor(
                  (new Date(user.membership.endDate) - new Date()) / (1000 * 60 * 60 * 24)
                );
                return user.membership.status === 'expired' && daysDifference <= daysToExpiration;
              }
            })
          : fetchedUser;
    
        setUsers(filteredUsers);
      } catch (error) {
        console.error(error);
      }
    }
    fetchUser();
  }, []);

  useEffect(() => {
    if (selectAll) {
      setSelectedUsers(users);
    } else {
      setSelectedUsers([]);
    }
  }, [selectAll]);

  const sendMessage = async () => {
    try {
      const response = await createMessage({ title, content, selectedUsers });
      // console.log(response);
      console.log('Message sent successfully');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const filterUsersByMembership = (user) => {
    // Logika za filtriranje korisnika čijoj je članarina istekla
    if (membershipsExpired && user.membership && user.membership.status === 'expired') {
      const endDate = new Date(user.membership.endDate);
      const today = new Date();
      const daysDifference = Math.floor((endDate - today) / (1000 * 60 * 60 * 24));
      return daysDifference <= daysExpired;
    }
    return true;
  };

  const handleFilterChange = () => {
    const filteredUsers = users.filter(filterUsersByMembership);
    setSelectedUsers(filteredUsers);
  };


  return (
    <div className={classes.form}>
      <Header title="Kreiraj novu poruku" subtitle="" />
      <TextField
        label="Naslov"
        name="title"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        variant="outlined"
      />
      <TextField
        label="Poruka"
        multiline
        rows={8}
        name="content"
        value={content}
        onChange={(e) => setContent(e.target.value)}
        variant="outlined"
      />
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Checkbox
          checked={selectAll}
          onChange={(e) => setSelectAll(e.target.checked)}
        />
        <span>Selektuj sve korisnike</span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Checkbox
          checked={membershipsExpired}
          onChange={(e) => setMembershipsExpired(e.target.checked)}
        />
        <span>Selektuj korisnike kojima je članarina istekla</span>
      </div>
      <TextField
      label="Broj dana do isteka članarine"
      type="number"
      value={daysToExpiration}
      onChange={(e) => setDaysToExpiration(e.target.value)}
      variant="outlined"
    />
      <Button variant="contained" color="secondary" onClick={handleFilterChange}>Filtriraj korisnike</Button>
      <Autocomplete
        multiple
        options={users}
        getOptionLabel={(option) => option.firstName}
        value={selectedUsers}
        onChange={(event, newValue) => {
          setSelectedUsers(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Korisnici" variant="outlined" />
        )}
      />
      <Grid container justify="center" spacing={2}>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={sendMessage}>Posalji poruku</Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default NewMessage;