import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const UplateMjesecBarChart = ({ data, startDate }) => {
  // Filtrirajte uplate koje nisu odgodjene
  const filteredPayments = data.filter(payment => payment.paymentMethod !== "odgodjeno");

  // Izvadite trenutni mjesec i godinu iz datuma početka
  const currentYear = startDate.getFullYear();
  const currentMonth = startDate.getMonth() + 1;

  // Filtrirajte uplate koje su samo za trenutni mjesec
  const monthlyPayments = filteredPayments.filter(payment => {
    const paymentDate = new Date(payment.createdAt);
    return paymentDate.getFullYear() === currentYear && paymentDate.getMonth() + 1 === currentMonth;
  });

  // Kreirajte niz objekata s podacima za grafikon, grupirajući ih po danima
  const chartData = monthlyPayments.reduce((acc, payment) => {
    const paymentDate = new Date(payment.createdAt);
    const day = paymentDate.getDate();

    if (!acc[day]) {
      acc[day] = 0;
    }

    acc[day] += payment.amount;

    return acc;
  }, {});

  // Konvertujte grupirane podatke u niz objekata pogodnih za grafikon
  const chartDataArray = Object.keys(chartData).map(day => ({
    name: `${day}.${currentMonth}.${currentYear}`,
    amount: chartData[day],
  }));

  // define the colors to use for each bar
  const colors = ['#82ca9d', '#8884d8', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffc1c1', '#888888'];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartDataArray}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amount" stackId="a" fill={colors[0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default UplateMjesecBarChart;
