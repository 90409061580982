import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/gym`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/gym';

export const getAllGyms = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getGymById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createGym = async (newGym) => {
  const response = await axios.post(baseUrl, newGym);
  // console.log(newGym)
  return response.data;
};

export const updateGym = async (id, updateGym) => {
  const response = await axios.put(`${baseUrl}/${id}`, updateGym);
  return response.data;
};

export const deleteGym = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }