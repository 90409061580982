import { useState, useEffect } from 'react';
import { updateMembership } from "../api/membershipApi";
import moment from 'moment-timezone';




function RemainingDays({ startDate, endDate, membership }) {
  const now = moment().tz('Europe/Belgrade');
  const end = moment(membership.endDate).tz('Europe/Belgrade');
  const daysRemaining = end.diff(now, 'days', true);
  const daysRemainingRounded = Math.ceil(daysRemaining);
  
  return (
    <div>
      {daysRemainingRounded > 0 ? `${daysRemainingRounded-1} dana` : 'Isteklo'}
    </div>
  );
}

export default RemainingDays;



// const RemainingDays = ({ startDate, endDate, membership }) => {

//   const [remainingDay, setRemainingDays] = useState(null);

//   useEffect(() => {
//     // const start = new Date(startDate);
//     const start = Date.now();
//     const end = new Date(endDate);
//     const diffMs = end - start;
//     const diff = Math.ceil(diffMs / (1000 * 60 * 60 * 24));
//     const remainingDays = Math.max(0, diff);

//     if (remainingDays === 0) {
//         try {
//                 const status = 'expired'
//                 const res = updateMembership(membership._id, {status: status});
//             } catch (error) {
//                 console.log(error);
//         }
//     }
//     setRemainingDays(remainingDays);

//     // const countdown = setInterval(() => {
//     //   setRemainingDays(prevDays => prevDays - 1);
//     // }, 1000 * 60 * 60 * 24);

//     // return () => clearInterval(countdown);
//   }, [remainingDay]);

//   return (<p>{remainingDay}</p>);
// };
// export default RemainingDays;
//Ova komponenta će prikazati preostali broj dana na početku, a onda će ga smanjivati svaki dan. Kada broj dana dođe do 0, više se neće mijenjati. Naravno, ovisno o vašim potrebama, možete dodati više logike za pauziranje ili ponovno aktiviranje pretplate nakon pauziranja.


// function RemainingDays({startDate, endDate, membership  }) {
//     const oneDay = 24 * 60 * 60 * 1000; // broj milisekundi u jednom danu
//     const today = new Date(); // trenutni datum

//     const end = new Date(endDate); // pretvara krajnji datum u objekt tipa Date
//     // console.log(endDate)
//     let remainingDays = Math.round((end - today) / oneDay); // izračunava preostali broj dana
//   console.log(remainingDays)
//     // Ako je broj preostalih dana manji od 0, postavljamo ga na 0
//     if (remainingDays < 0) {
//       remainingDays = 0;
//       try {
//            const status = 'expired'
//             const res = updateMembership(membership._id, {status: status});
//              } catch (error) {
//                console.log(error);
//              }
//             }
//             // setRemainingDays(remainingDays);
    
//     // Ako je broj preostalih dana veći od ukupnog broja dana u razdoblju, postavljamo ga na maksimalnu vrijednost
//     const totalDays = Math.round((end - new Date(startDate)) / oneDay);
//     if (remainingDays > totalDays) {
//       remainingDays = totalDays;
//     }
  
//     return <p>{remainingDays}</p>;
//   }
  

// export default RemainingDays;



