import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/tripod`;
const baseUrlPrijava = `${ConfigApp.URL}/tripod/prijava`;
const baseUrlOdjava = `${ConfigApp.URL}/tripod/odjava`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/oprema';

// export const getAllCosts = async () => {
//   const response = await axios.get(baseUrl);
//   return response.data;
// };

export const getAllPrijaveTripodByRange = async ({startDate, endDate}) => {
  const response = await axios.get(`${baseUrl}/${startDate}/${endDate}`);
  return response.data;
};

export const getAllTripodByUserAndDateRange = async (userID, startDate, endDate) => {
    // console.log(userID,startDate, endDate)
    const response = await axios.get(`${baseUrl}/user/${userID}/${startDate}/${endDate}`);
    return response.data;
  };

// export const getCostById = async (id) => {
//   const response = await axios.get(`${baseUrl}/${id}`);
//   return response.data;
// };

export const createPrijavuTripod = async (userId) => {
    // console.log(userId)
  const response = await axios.post(baseUrlPrijava, userId);
  return response.data;
};

export const createOdjavuTripod = async () => {
    const response = await axios.post(baseUrlOdjava);
    return response.data;
  };

// export const updateCost = async (id, updatedUser) => {
//   const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   return response.data;
// };

export const deletePrijavuTripod = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};
