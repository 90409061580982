import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/jelovnik`;
// const baseUrl = 'http://localhost:3001/api/jelovnik';

export const getAllJelovnik = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getJelovnikById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createJelovnik = async (newJelovnik) => {
  const response = await axios.post(baseUrl, newJelovnik);
//   console.log(newJelovnik)
  return response.data;
};

export const updateJelovnik = async (id, data) => {
  const response = await axios.put(`${baseUrl}/${id}`, data);
  return response.data;
};

export const deleteJelovnik = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }