import React, { useEffect, useState } from 'react';
import { Typography, useTheme, Box, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import {getMembers, getMembersByRange} from '../../api/memberApi'
import {getPaket} from '../../api/paketApi'
import PieIzvjestajClanovi from '../pie/pieClanoviIzvjestaj';
import SpolClanova from '../../components/PieChartspolaClanova';
import ClanoviIzvjestajKomponenta from '../../components/clanoviIzvjestajKomponenta';
import { TailSpin } from 'react-loader-spinner';
  
const IzvjestajClanovi = () => {
    const theme = useTheme();
const colors = tokens(theme.palette.mode);

  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [brojNovihClanova, setBrojNovihClanova] = useState('');
  const [activeMember, setActiveMember] = useState([])
  const [grupisaniPaketi, setGrupisaniPaketi] = useState([])
  const [clanTrenings, setClanTrening] = useState(0)
  const [membershipStatus, setMembershipStatus] = useState("");
  const [paket, setPaket] = useState("");
  const [paketi, setPaketi] = useState([]);
  const [brojDanaDoIsteka, setBrojDanaDoIsteka] = useState(null);

  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999)

useEffect(()=>{

    async function fechData (){

    // const members = await getMembers()
    const members = await getMembersByRange({
      startDate,
      endDate
    });

    const fachedPaketi = await getPaket()
        setPaketi(fachedPaketi);
        setLoading(false);

  // filtriranje clanova po datumu i statusu clanarine te preostalom vremenu do isteka
  const filteredMembers = members.filter((member) => {
    const user = member?.user; // Check if member.user exists
    const membership = user?.membership; // Check if user.membership exists
    const packageName = membership?.plan?.nazivPaketa.bs; // Check if membership.plan exists
  
    const statusMatch = !membershipStatus || membership?.status === membershipStatus;
    const paketMatch = !paket || membership?.plan?._id === paket;
  
    const today = new Date();
    const expirationDate = new Date(membership?.endDate);
    const timeDiff = expirationDate.getTime() - today.getTime();
    const daysLeft = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let daysMatch = true;
  
    if (brojDanaDoIsteka !== null) {
      daysMatch = daysLeft <= parseInt(brojDanaDoIsteka);
    }
  
    return user && membership && packageName && statusMatch && paketMatch && daysMatch;
  });
  // console.log("members",members)
// Sortiraj filteredMembers niz prema user.createdAt od najnovijeg prema najstarijem
filteredMembers.sort((a, b) => {
  const dateA = new Date(a?.user?.createdAt);
  const dateB = new Date(b?.user?.createdAt);
  return dateB - dateA;
});
  // console.log("filteredMembers",filteredMembers)
      
   const today = new Date();
   
    let newMembers = 0;
    let existingMembers = 0;
    let activeMembers = []
    let clanTrening = 0;

    for (const member of filteredMembers) {
      if (member?.user?.membership?.status === "active") {
        activeMembers.push(member);
        } else {
        //   existingMembers++;
        }
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1); // Postavlja datum na jučer
        
        const registrationDate = new Date(member?.user?.createdAt);
        // registrationDate.setHours(0, 0, 0, 0); 
  const daysSinceRegistration = Math.ceil((today - registrationDate) / (1000 * 60 * 60 * 24));

  if (daysSinceRegistration <= 1) { // Ako je prošlo manje ili jednako 1 dan od registracije (jučer ili danas)
    newMembers++;
      }else {
        existingMembers++;
      }

      if(member.groupPrograms.length > 0){
        clanTrening++
      }
    }
    //broj novih clanova u 30 dana
    setBrojNovihClanova(newMembers)
    //dodaj filtrirane clanove
    setMembers(filteredMembers)
    //dodaj activne clanove
    setActiveMember(activeMembers)
    setClanTrening(clanTrening)
        //grupisanje clanova
    // prvo kreirajte objekat koji će sadržati broj članova po paketima
    let packagesCount = {};

// zatim prolazimo kroz objekte iz niza
for (let obj of filteredMembers) {
  // čitamo naziv paketa za trenutni objekat
  const packageName = obj?.user?.membership?.plan?.nazivPaketa.bs;
  
  // ako paket već postoji u objektu, uvećavamo broj članova za taj paket
  if (packagesCount[packageName]) {
    packagesCount[packageName]++;
  } else {
    // inače, inicijaliziramo broj članova za taj paket na 1
    packagesCount[packageName] = 1;
  }
}

// sada imamo objekat packagesCount koji sadrži broj članova po paketima
    setGrupisaniPaketi(packagesCount)
    }

    fechData()
},[endDate, startDate, membershipStatus, paket, brojDanaDoIsteka])

// console.log("brojDanaDoIsteka", brojDanaDoIsteka)

  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
      </div>
    ) : (
    <Box m="20px">
    <Box display="flex" justifyContent="space-between" alignItems="center">
   
    <Box>
    <Header title="IZVJESTAJ O ČLANOVIMA"/>
   
    <Box>
    
    <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
    <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
            //  value={gym}
            //  onChange={handleChangeGym}
           >
    {/* {gyms?.map((e, key) => (
    <MenuItem key={key} value={e}>
    {e.naziv}
    </MenuItem>
    ))} */}
    </Select>
     <FormControl component="fieldset">
       <label htmlFor="membership-status">Status članarine:</label>
     <RadioGroup
        row
        aria-label="membership status"
        name="membership-status"
        // value={selectedStatus}
        onChange={(e) => setMembershipStatus(e.target.value)}
      >
        <FormControlLabel value="active" control={<Radio  style={{color:"white"}} />} label="Aktivna" />
        <FormControlLabel value="expired" control={<Radio  style={{color:"white"}}/>} label="Istekla" />
      </RadioGroup>
    </FormControl>
   <TextField
        id="expiry-days"
        label="Raspon dana isteka"
        type="number"
        InputLabelProps={{ shrink: true }}
        variant="standard"
        value={brojDanaDoIsteka}
        onChange={(e) => setBrojDanaDoIsteka(e.target.value)}
      />
       {/* <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Način plaćanja</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paymentType}
          label="Način plaćanja"
          onChange={(e) => setNacinPlacanjaStatus(e.target.value)}
        >
          <MenuItem value="">Svi načini plaćanja</MenuItem>
          <MenuItem value="kartica">Kartica</MenuItem>
          <MenuItem value="odgodjeno">Odgodjeno plaćanje</MenuItem>
          <MenuItem value="gotovina">Gotovina</MenuItem>
        </Select>
      </FormControl> */}
    <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Paketi</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paket}
          label="Način plaćanja"
          onChange={(e) => setPaket(e.target.value)}
        >
          <MenuItem value="" disabled>Odaberi paket</MenuItem>
          {paketi?.map((e, key)=>{
                    return <MenuItem key={key} value={e._id}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
                  })
                  }
        </Select>
      </FormControl>
    
      <FormControl  sx={{minWidth:"200px" }}>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
        />
      
      </FormControl>
  </Box>
  </Box>
  </Box>
  {/* GRID & CHARTS */}
  <Box
    display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridAutoRows="140px"
    gap="20px"
  >
    {/* ROW 1 */}
    <Box
      gridColumn="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <StatBox
        title={members.length}
        subtitle="Broj članova"
        progress="a"
        // increase={`+${progressPaketi.length}%`}
        // icon={
        //   <HowToRegIcon
        //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        //   />
        // }
      />
    </Box>
   
<Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
        title={brojNovihClanova}
        subtitle="Novi clanovi (zadnja 24h)"
        progress="a"
    />
  </Box>
  <Box
  
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
    title={activeMember.length}
    subtitle="Aktivni clanovi"
      progress="a"
    />
  </Box>
  <Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
    title={clanTrenings}
    subtitle="Clanovi koji koriste grupni trening"
      progress="a"
    />
  </Box>
  {/* ROW 2 */}
  {/* <Box
    gridColumn="span 6"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    <Box
      mt="25px"
      p="0 30px"
      display="flex "
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="600"
          color={colors.grey[100]}
        >
          Broj posjeta u toku mjeseca
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          color={colors.greenAccent[500]}
        >
      
        </Typography>
      </Box>
      <Box>
       
      </Box>
    </Box>
    <Box height="250px" m="-20px 0 0 0">
    <BarChartPosjete data={rangePosjete} handleBusiestDay={handleBusiestDay}/>
    </Box>
  </Box> */}
 
  <Box
    gridColumn="span 4"
    gridRow="span 4"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    Spol članova
    <SpolClanova data={members}/>

  </Box>
  <Box
    gridColumn="span 8"
    gridRow="span 4"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    Analiza članarina po paketima
    <PieIzvjestajClanovi data={grupisaniPaketi}/>
  </Box>
  <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                {/* Prosječno vrijeme posjeta po danu */}
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              </Typography>
            </Box>
            <Box>
              {/* <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton> */}
            </Box>
          </Box>
          <Box height="450px" m="-40px 0 0 0">
              <ClanoviIzvjestajKomponenta data={members}/>
          </Box>
        </Box>
      
</Box>

</Box>
)}
</>
);
};

export default IzvjestajClanovi;