import React, { useEffect, useState } from 'react';
import { Typography, useTheme, Box, InputLabel, MenuItem, Select, TextField,Button } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from "../../theme";
// import StatBox from "../../components/StatBox";
// import {getAllPaymentByRange} from '../../api/paymentApi'
// import {getPaket} from '../../api/paketApi'
// import PieIzvjestajClanovi from '../pie/pieClanoviIzvjestaj';
// import SpolClanova from '../../components/PieChartspolaClanova';
// import FaktureIzvjestajKomponenta from '../../components/faktureIzvjestajKomponenta';
// import FaktureBarChart3 from '../../components/BarChart4';
// import UkPrihodOdUplataIzUp from './UkupanPrihodOdUplata';
import { getAllPrijaveTripodByRange } from '../../api/tripodApi';
import TripodIzvjestajKomponenta from '../../components/tripodIzvjestajKomponenta';

const IzvjestajTripod = () => {
    const theme = useTheme();
const colors = tokens(theme.palette.mode);

const currentYear = new Date().getFullYear();
const startYear = new Date(`01/01/${currentYear}`);

const yesterday = new Date();
yesterday.setDate(yesterday.getDate());
yesterday.setHours(0, 0, 1, 0); // Postavi na 00:01

const today = new Date();
today.setHours(23, 59, 59, 999); // Postavi na 23:59:59.999

const [startDate, setStartDate] = useState(yesterday);
const [endDate, setEndDate] = useState(today);

  // console.log(filterUplate)

  const [tripodData, setTripodData] = useState([])


  const [resetFilters, setResetFilters] = useState(false);

  const[grupisaneFaktureZaOsobu, setGrupisaneFaktureZaOsobu] = useState([])
  
// console.log("grupisaneFaktureZaOsobu",grupisaneFaktureZaOsobu)


const handleResetFilters = () => {
  setStartDate(startYear);
  setEndDate(new Date());
  // setFakturaStatus('');
  // setPaymentType('');
  // setPaket('');
  setResetFilters(true);
};

useEffect(()=>{

    async function fechData (){

    const tripodData = await getAllPrijaveTripodByRange({
      startDate,
      endDate
    })

    setTripodData(tripodData)
    }

    fechData()




},[endDate, startDate])



// console.log("tripodData",tripodData)

  return (

    <Box m="20px">
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Box>
      <Header title="IZVJESTAJ TRIPOD" />
      
      
      <Box>
    {/* <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
    <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
   
           >
 
    </Select> */}
      <FormControl style={{minWidth:"200px", marginTop:"-20px"}}>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
        />
      
      </FormControl>

  </Box>
  
    </Box>
    {/* <Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleResetFilters} variant="contained" color="secondary">
    Resetuj filtere
  </Button>
</Box> */}
  </Box>

<Box
  display="grid"
  gridTemplateColumns="repeat(12, 1fr)"
  gridAutoRows="140px"
  gap="20px"
>

  <Box
    gridColumn="span 12"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    
    <Box
      mt="25px"
      p="0 30px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          {/* Text */}
        </Typography>
        <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
          {/* Text */}
        </Typography>
      </Box>
      <Box>
        {/* Other components */}
      </Box>
    </Box>
    <Box height="250px" m="-40px 0 0 0">
    <TripodIzvjestajKomponenta data={tripodData}/>
      {/* ... */}
    </Box>
  </Box>

</Box>

</Box>


);
};

export default IzvjestajTripod;