import React, {useState, useContext, useEffect} from 'react';
import { Box, Button, TextField,Paper,Grid,Typography } from "@mui/material";
import Header from "../../components/Header";
// import AutoInput from '../../components/Autoinput';
// import { GymContext } from "../../theme";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {Link } from 'react-router-dom';
// import MembershipRenewalForm from '../../components/MembershipRenewalForm';
import Badge from '@mui/material/Badge';
import { StartDateConverting } from '../../components/DateConverting';
// import RemainingDays from "../../components/RemainingDays";
import {getUserById} from "../../api/userApi"
import io from "socket.io-client";
// import ConfigApp from '../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;
// const baseUrl = `${ConfigApp.URL}`;
// const socket = io("http://localhost:8080");
// const socket = io.connect()
const socket = io.connect("https://stayfit-app-a6czv.ondigitalocean.app");
// const socket = io.connect("https://gym-elita-app-o44uu.ondigitalocean.app");
// const socket = io.connect("https://demo-app-9c5zi.ondigitalocean.app");
// const socket = io({imageUrl});


const Monitor = () => {
    const [message, setMessage] = useState('');
    // const [user, setUser] = useState('');
    const [member, setMember] = useState('');
    const [statusPoruke, setStatusPoruke ] = useState(" ")
    const datum = new Date();
// console.log(message);

    useEffect(() => {
        socket.on('new-attendance', (data) => {
          // console.log("data",data)
          setMessage(data);
          setStatusPoruke(data.greska);
        });
    
        async function fetchUser() {
          if (message === '') {
            return;
          }
          const uid = message.userId;
          try {
            const fetchedUser = await getUserById(uid);
            setMember(fetchedUser);
          } catch (error) {
            console.error(error);
          }
        }
    
        fetchUser();
      }, [message]);
      // console.log("message",message)
    // useEffect(() => {
    //     socket.on('new-attendance', (data) => {
    //       setMessage(data);
    //       setStatusPoruke(data.greska)
    //     });
        
    //     async function fetchPaket() {
    //       const uid = message.userId
    //       try {
    //         const fetchedUPaket = await getUserById(uid);
    //         setMember(fetchedUPaket);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     }
      
    //     if (message !== '') {
    //       fetchPaket();
    //     }
    //   }, [message]);

  // console.log(member.membership.maxVisits)
    // const [member, setMember] = useState(null);
  // const { defaultGym} = useContext(GymContext);
  const defaultGym = JSON.parse(localStorage.getItem('defaultGym'));
    // console.log(member)
    // const handleData = data => {
    //   setMember(data);
    // };
    // console.log("Data", defaultGym._id)
    const poslovnica = member && member?.membership?.gym?.naziv;
    const statusPaketa = member && member?.membership?.status;
    const datumKreiranja = member && member?.membership?.startDate;
    const datumIsteka = member && member?.membership?.endDate;
    const paket = member && member?.membership?.plan?.nazivPaketa.bs;
    const notes = member && member?.membership?.notes;
    const membership = member && member?.membership;

    function getRemainingDays(startDate, endDate) {
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diff = Math.floor((end - start) / (1000 * 60 * 60 * 24));
        // const diffDays = Math.floor(Math.abs((start - end) / oneDay));
        const remainingDays = Math.max(0, diff);
        return remainingDays;
      }
      var stylingObject = {

        // div: {
        //     backgroundColor: "green",
        //     height: "40px"
        // },
        span:{
            border: "23px solid #f00"
        },
        span2:{
            border: "23px solid #de6926b0"
        },
        span3:{
            border: "23px solid #63ce4c"
        },
        span5:{
          border: "23px solid #d9870cdb"
      },
        div:{
            backgroundColor: "red",
            height: "40px"
        },
        span4:{
          textAlign:"center"
        }
      }
    
  return (
    <Box m="20px">
      <Header title="MONITOR" subtitle="" />
      {/* <AutoInput
        label="Pretrazi po imenu ili RFID"
        apiUrl={`http://localhost:3001/api/membership/gym/${defaultGym._id}`}
        // apiUrlRFID={`http://localhost:3001/api/membership/gym/${defaultGym._id}/rfid`}
        onData={handleData}
      /> */}
      {defaultGym._id == message.gymId ?
      <div>
       <Typography variant="h3" component="h2" color="secondary" align='center'>
       <Header title={member.firstName+" "+member.lastName} style={{textAlign:"center", margin:"auto"}} subtitle={`br. clanske: ${member?.membership?.brojClanske}`}/>
       Datum: {new Date().toLocaleString()} 
       </Typography>
      <div style={
          statusPoruke === 412 ? stylingObject.span 
        : statusPoruke === 406 ? stylingObject.span2
        : statusPoruke === 407 ? stylingObject.span2
        : statusPoruke === 408 ? stylingObject.span2
        : statusPoruke === 409 ? stylingObject.span2
        : statusPoruke === 410 ? stylingObject.span2
        : statusPoruke === 411 ? stylingObject.span5
        : statusPoruke === true ? stylingObject.span3
        // : statusPoruke === true ? stylingObject.span
        : null}>
        <Box m="20px">
                <Typography variant="h3" component="h2" align='center' color="white">
                { statusPoruke === 412 ? "Istekla članarina" 
                : statusPoruke === 406 ? "Žao nam je Vaš paket ima vremensko ograničenje" 
                : statusPoruke === 407 ? "Žao nam je iskoristili ste maksimalan broj posjeta po danu" 
                : statusPoruke === 408 ? "Žao nam je iskoristili ste maksimalan broj termina" 
                : statusPoruke === 409 ? "Već ste prijavljeni" 
                : statusPoruke === 410 ? "Odjavljeni ste, molimo prijavite se!" 
                : statusPoruke === 411 ? "Uspješna odjava"
                : statusPoruke === true ? "Uspješna prijava"
                : statusPoruke === 404 ? "Greska 404"
                : ""}
                </Typography>
            </Box>
      {member && (
        <div>
            <Grid container spacing={2}  sx={{marginTop:"2px"}}>
            <Grid item xs={4} md={4}>
            {notes?.length > 0 ? <Link to={`/membership/${member?.membership?._id}/notes`}>
      {/* <ErrorIcon style={{color: "darkgoldenrod"}}/> */}
      <Badge badgeContent={notes?.length} color="secondary" style={{padding:"7px"}}>
      {/* <ErrorIcon style={{color: "darkgoldenrod"}}/> */}
    </Badge>
      </Link> : ""}
            <Stack direction="row" spacing={2}>
        <Avatar
        alt={`${member.firstName}`}
        // src={`${imageUrl}/${member.photo}` }
        src={`${member.photo}`}
        sx={{ width: 406, height: 406,marginLeft:"12px",marginBottom:"12px" }}
        variant="square"
      />
      
    </Stack>
   
</Grid>
  <Grid item xs={4} md={3}>
  <Box>   
  <Typography variant="h4" component="h2" color="white">
    <p>--Podaci--</p>
          <p>Email: {member.email}</p>
          <p>Telefon: {member.phone}</p>
          <p>Članska kartica: {member.rfid}</p>
          <p>Poslovnica: {poslovnica}</p>
          </Typography>
        </Box>
  </Grid>
  <Grid item xs={4} md={3}>
  <Typography variant="h4" component="h2" color="white">
  <p>--Clanarina--</p>
          <p>Status: {statusPaketa}</p>
          <p>Pocetka clanarine: <StartDateConverting date={datumKreiranja}/></p>
          <p>Datum isteka: <StartDateConverting date={datumIsteka}/></p>
          <p>Paket: {paket}</p>
          <p>Broj dnevnih dolazaka: {member?.membership?.maxVisitsPerDay}</p>
          <p>Broj dolazaka: {member.membership.maxVisits}</p>
          <p>Ostalo dana: {getRemainingDays(datum, datumIsteka)} </p>
          </Typography>
  </Grid>
 
  {/* <Grid item xs={4} md={3}>
  <MembershipRenewalForm clan={member}/>
  </Grid> */}
</Grid>
  


       
        {/* <Link to={`/membership-renewal/${member?._id}`} color="secondary" variant="contained">
       
        
        <Button color="secondary" variant="contained"> Produzi clanarinu</Button>
        </Link> */}
        </div>
      )}
      </div>
      </div>
      : <Typography></Typography>}
    </Box>
  );
};


export default Monitor;
