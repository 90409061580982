import React, { useState } from 'react';
import Header from "../../../components/Header";
import Grid from '@material-ui/core/Grid';
import Tooltip from '@mui/material/Tooltip';
import QuizIcon from '@mui/icons-material/Quiz';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createFood, updateFood } from '../../../api/foodApi';
import { useLocation} from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
  inField: {
    backgroundColor: "#ffffff14"
  }
}));

const EditFood = () => {
  const classes = useStyles();
  const { state: data } = useLocation();

//   console.log("data",data);
 //bs
 const [name, setName] = useState(data.name.bs);
 //en
 const [nameEn, setNameEn] = useState(data.name.en);

  const [image, setImage] = useState(data.image);
  const [calories, setCalories] = useState(data.calories);
  const [proteins, setProteins] = useState(data.proteins);
  const [carbs, setCarbs] = useState(data.carbs);
  const [fats, setFats] = useState(data.fats);
  const [fiber, setFiber] = useState(data.fiber);
  const [servingSize, setServingSize] = useState(data.servingSize);
  const [servingUnit, setServingUnit] = useState(data.servingUnit);
  const [brand, setBrand] = useState(data.brand);
  const [unsaturatedFatt, setUnsaturatedFatt] = useState(data.unsaturatedFatt);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const newData = {
      name: {
        bs: name,
        en: nameEn,
      },
    }

    const formData = new FormData();
    formData.append('name', JSON.stringify(newData.name));
    formData.append('calories', parseFloat(calories));
    formData.append('proteins', parseFloat(proteins));
    formData.append('carbs', parseFloat(carbs));
    formData.append('fats', parseFloat(fats));
    formData.append('unsaturatedFatt', parseFloat(unsaturatedFatt));
    formData.append('fiber', parseFloat(fiber));
    formData.append('servingSize', parseFloat(servingSize));
    formData.append('servingUnit', servingUnit);
    formData.append('brand', brand);
    formData.append('image', image);

//     console.log("Form Data Content:");
// for (const [key, value] of formData.entries()) {
//   console.log(image);
// }
    try {
      const res = await updateFood(data._id, formData);
      if (res) {
        toast.success('Uspješno ste azurirali namirnicu.');
        // Reset the form fields after successful submission
        setName('');
        setImage('');
        setCalories('');
        setProteins('');
        setCarbs('');
        setFats('');
        setFiber('');
        setServingSize('');
        setServingUnit('');
        setBrand('');
        setUnsaturatedFatt('');
      }
    } catch (error) {
      console.error(error);
      toast.error('Došlo je do greške prilikom kreiranja hrane.');
    }
  };
  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima
  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      setImage(imageFile);
    }
  };
  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  // };


  return (
    <>
    <form className={classes.form} onSubmit={handleSubmit}>
    <Header title="Uredi namirnicu" subtitle="" />
    <ToastContainer />
    <Grid item xs={6}>
        <div style={{ position: "relative" }}>
        {typeof image === "string" ? (
            <img
              style={{ width: "100%" }}
              src={image}
              alt="Slika vježbe"
            />
          ) : (
            <img
              style={{ width: "100%" }}
              src={URL.createObjectURL(image)}
              alt="Slika vježbe"
            />
          )}
        </div>
      </Grid>
      <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            // required
          />
  <TextField
    className={classes.inField}
    label="Naziv"
    value={name}
    onChange={(event) => setName(event.target.value)}
    variant="outlined"
    required
  />
    
  <Grid container spacing={2}>
  <Grid item>
  <TextField
    label="Veličina serviranja"
    className={classes.inField}
    value={servingSize}
    onChange={(event) => setServingSize(event.target.value)}
    variant="outlined"
    required
  />
  </Grid>
  <Grid item>
    <Tooltip
      title="Veličina serviranja je numerička vrijednost koja predstavlja količinu hrane 
      koja se koristi kao jedna porcija, npr. 100 grama, 1 šalica, 1 kriška, itd. 
      Ova vrijednost se koristi u kombinaciji sa vrijednostima hranjivih tvari kako 
      bi se izračunala nutritivna vrijednost hrane za jednu porciju."
    >
      <QuizIcon />
    </Tooltip>
  </Grid>
</Grid>
  <Grid container spacing={2}>
  <Grid item>
  <TextField
    label="Jedinica mjere"
    className={classes.inField}
    value={servingUnit}
    onChange={(event) => setServingUnit(event.target.value)}
    variant="outlined"
    required
  />
  </Grid>
  <Grid item>
    <Tooltip
      title='Jedinica mjere je jedinica mjere za servingSize u kojoj se navodi koliko grama ili 
      koliko komada hrane određene veličine predstavlja jedna porcija. Na primjer, ako je 
      servingSize 100 grama, servingUnit bi mogao biti "komad" ili "gram". 
      Ova informacija je korisna za precizno određivanje količine i nutritivne vrijednosti hrane koju konzumiramo.'
    >
      <QuizIcon />
    </Tooltip>
  </Grid>
</Grid>
  <TextField
    label="Brend"
    className={classes.inField}
    value={brand}
    onChange={(event) => setBrand(event.target.value)}
    variant="outlined"
    // required
  />

  <TextField
    label="Kalorije"
    className={classes.inField}
    value={calories}
    onChange={(event) => setCalories(event.target.value)}
    variant="outlined"
    required
  />
  <TextField
    label="Proteini"
    className={classes.inField}
    value={proteins}
    onChange={(event) => setProteins(event.target.value)}
    variant="outlined"
    required
  />
  <TextField
    label="Ugljikohidrati"
    className={classes.inField}
    value={carbs}
    onChange={(event) => setCarbs(event.target.value)}
    variant="outlined"
    required
  />
  <TextField
    label="Masnoće"
    className={classes.inField}
    value={fats}
    onChange={(event) => setFats(event.target.value)}
    variant="outlined"
    required
  />
  <TextField
    label="Nezasićene masne kiseline"
    className={classes.inField}
    value={unsaturatedFatt}
    onChange={(event) => setUnsaturatedFatt(event.target.value)}
    variant="outlined"
    required
  />
  <TextField
    label="Vlakna"
    className={classes.inField}
    value={fiber}
    onChange={(event) => setFiber(event.target.value)}
    variant="outlined"
    required
  />
    <Header subtitle="Engleski jezik" />
              <TextField
                label="Naziv opreme (Engleski)"
                value={nameEn}
                onChange={(event) => setNameEn(event.target.value)}
                variant="outlined"
                // required
              />
     <Grid container justify="left" alignItems="left" spacing={2}>
    <Grid item>
      <Button type="submit" variant="contained" color="secondary">
    Ažuriraj namirnicu
    </Button>
  </Grid>
</Grid>
</form>
</>
  );
};

export default EditFood;
