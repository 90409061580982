import React, { useState } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';
import { createSettings } from '../../api/getSettingsApi'; // Promenite putanju do API poziva
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
}));

const NewSettings = () => {
  const classes = useStyles();

  const [guestGymUrl, setGuestGymUrl] = useState("");
  const [guestGymId, setGuestGymId] = useState("");
  const [guestNameGym, setGuestName] = useState("");

  const handleGuestGymUrlChange = (event) => {
    setGuestGymUrl(event.target.value);
  };

  const handleGuestGymIdChange = (event) => {
    setGuestGymId(event.target.value);
  };

  const handleGuestNameChange = (event) => {
    setGuestName(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const settingsData = {
      guestGymUrl,
      guestGymId,
      guestNameGym,
    };

    try {
      const createdSettings = await createSettings(settingsData);
      toast.success('Uspješno ste kreirali podešavanja.');
    } catch (error) {
      console.error(error);
      toast.error('Došlo je do greške prilikom kreiranja podešavanja.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Header title="Kreiraj nova podešavanja" subtitle="gostujuća teretana" />
      <ToastContainer />

      <TextField
        label="URL gostujuće teretane"
        name="guestGymUrl"
        value={guestGymUrl}
        onChange={handleGuestGymUrlChange}
        variant="outlined"
      />

      <TextField
        label="ID gostujuće teretane"
        name="guestGymId"
        value={guestGymId}
        onChange={handleGuestGymIdChange}
        variant="outlined"
      />

      <TextField
        label="Naziv teretane"
        name="guestNameGym"
        value={guestNameGym}
        onChange={handleGuestNameChange}
        variant="outlined"
      />

      <Grid container justify="left" alignItems="left" spacing={2}>
        <Grid item>
          <Button type="submit" variant="contained" color="secondary">
            Kreiraj podešavanja
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NewSettings;

