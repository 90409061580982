import React, { createContext, useState, useEffect } from 'react';
import { getUserById } from '../api/userApi';
import { TailSpin } from 'react-loader-spinner';
export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const savedUserId = localStorage.getItem('userId');

    if (savedUserId) {
      getUserById(savedUserId)
        .then((userData) => {
          setUser(userData);
          setIsAuthenticated(true);
        })
        .catch((error) => {
          console.error('Greška prilikom dohvaćanja korisnika:', error);
          // Možete postaviti neko stanje za grešku, ako je potrebno
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return   <TailSpin
    height="80"
    width="80"
    color="#4fa94d"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
  />
    
    // <div>Učitavanje aplikacije...</div>;
  }

  return (
    <UserContext.Provider value={{ user, setUser, isAuthenticated }}>
      {children}
    </UserContext.Provider>
  );
};


// import React, { createContext, useContext, useState, useEffect } from 'react';
// import { getUserById } from '../api/userApi';

// export const UserContext = createContext();

// export const UserProvider = ({ children }) => {
//   const [user, setUser] = useState(null); // Ovdje možete postaviti početno stanje korisnika
// // console.log("user",user)

// useEffect(() => {
//   // Provera da li je korisnik već prijavljen
//   const savedUserId = localStorage.getItem('userId'); // Pretpostavljamo da ste sačuvali korisnikov ID u localStorage prilikom prijave

//   // Samo ako nemate trenutnog korisnika u stanju, učitajte korisnika iz localStorage
//   if (!user && savedUserId) {
//     // Pozivamo funkciju za dohvatanje korisnika koristeći Axios
//     getUserById(savedUserId)
//       .then((userData) => {
//         setUser(userData);
//       })
//       .catch((error) => {
//         // Handlovanje greške ako dohvatanje nije uspelo
//       });
//   }
// }, [user, setUser]);

//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       {children}
//     </UserContext.Provider>
//   );
// };
