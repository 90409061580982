import React from "react";
import StatBox from "../../components/StatBox";


const UkPrihodOdUplataIzUp = (fakture) =>{

    // console.log("fakture",fakture.fakture)
    const ukupanPrihod = fakture.fakture.reduce((acc, cur) => {
        if (cur.paymentMethod !== "odgodjeno") {
          return acc + cur.amount;
        } else {
          return acc;
        }
      }, 0);
      console.log("fakture",ukupanPrihod)
    return(
        <>
        <StatBox
        // title={`${ukupanPrihodUplate} KM`}
        title={`${ukupanPrihod} KM`}
        subtitle="Ukupan prihod od uplata"
        progress="a"
    />
        </>
    )
}

export default UkPrihodOdUplataIzUp;

