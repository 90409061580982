import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
import { deleteEquipment, getAllEquipmentsByGymId } from '../../api/equpmentsApi';
// import ConfigApp from '../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;
import {TailSpin} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

const Equpment = () => {
  let id = useParams();
  const gymId = id.gymID
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [equpmentToDelete, setEqupmentToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isExerciseCreated, setIsExerciseCreated] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [equipments, setEquipments] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // const [gym, setGym] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllEquipmentsByGymId(gymId);
        setEquipments(fetchedData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  // console.log(equipments)
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredMembership = equipments.filter((member) => {

    if (member) {
      return member.name.bs.toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });


  const handleDelete = (cell) => {
    // console.log(cell)
    setEqupmentToDelete(cell);
    setDeleteDialogOpen(true);
  };
  
  const handleCancelDelete = () => {
    setEqupmentToDelete(null);
    setDeleteDialogOpen(false);
    // setPassword('');
  };
  useEffect(() => {
    if (!loading && isExerciseCreated) {
      toast.success('Uspješno ste obrisali opremu.');
      setIsExerciseCreated(false); // Resetujte stanje nakon prikazivanja poruke
    }
  }, [loading, isExerciseCreated]);


  const handleConfirmDelete = async () => {
    if (equpmentToDelete) {
      setLoading(true);
      try {
        // Pozivanje API za brisanje vježbe
        const res = await deleteEquipment(equpmentToDelete.row._id);
        if (res.status === 201) {
          // console.log(res)
          setIsExerciseCreated(true);
        }
  
        // Ažuriranje stanja kako bi se uklonila obrisana vježba
        setEquipments((prevExercises) => prevExercises.filter((ex) => ex._id !== equpmentToDelete.row._id));
  
        // Zatvaranje dijaloga i resetovanje stanja
        setDeleteDialogOpen(false);
        setEqupmentToDelete(null);
        // Ovdje možete dodati toast obavijest o uspehu
        // toast.success("Vježba je uspješno obrisana.");
      } catch (error) {
        // console.error("Došlo je do greške prilikom brisanja: ", error.response.data.message);
        // Ovdje možete dodati toast obavijest o grešci
        alert(error.response.data.message)
        // toast.error("Došlo je do greške prilikom brisanja vježbe.");
      } finally {
        setLoading(false); // Postavi loading na false nakon završetka
        // toast.success('Uspješno ste kreirali vježbu.');
    }
    }
  };
  

  const nameRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.name) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.name.bs}
      </p>
    );
  }
  
  const descriptionRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.description) {
      return <p>Nema opisa</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.description.bs}
      </p>
    );
  }


// const editRender = (cell)=>{
//   // console.log(cell.row)
// return( 
//   <Link data={cell} to={`/users/${cell?.id}/edit`}>
//    <EditIcon></EditIcon>
//    </Link>
// )
// }
const editRender = (cell)=>{
  // console.log(cell.row)
  const handleClick = () => {
    navigate(`/oprema/${cell.row._id}/edit`, { state: cell.row });
  };
return( 
  // <Link data={cell} to={`/users/${cell?.id}/edit`}>
    <EditIcon onClick={handleClick}></EditIcon>
  //  </Link>
)
}


const fotografijaRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row || !cell.row.image) {
    return null; // Return null or a placeholder value if user data is undefined
  }
  const handleClick = () => {
    navigate(`/oprema/${cell.row._id}/detalj`, { state: cell.row });
  };
  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.image}` }
        src={cell.row.image} 
        style={{width:"75%"}}
        onClick={handleClick}
  
      />
    </>
  );
};

const qrCodeRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row || !cell.row.qrCode) {
    return null; // Return null or a placeholder value if user data is undefined
  }
  
  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        src={cell.row.qrCode} 
        style={{width:"75%"}}
      />
    </>
  );
};



  const columns = [

    { field: 'image', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'name', headerName: 'Naziv opreme', width: 300, 
    renderCell: (params) => {
        return nameRender(params);
    }
    },
    { field: 'description', headerName: 'Opis opreme', width: 400, 
    renderCell: (params) => {
        return descriptionRender(params);
    }
    },
    // { field: 'gym', headerName: 'Poslovnica', 
    // renderCell: (params) => {
    //     return gymRender(params);
    // }
    // },
    { field: '#', headerName: 'Broj vezbi', width: 100,
    renderCell: (params) => {
      return nameRender(params);
    }
    },
    { field: 'qrCode', headerName: 'qrCode', 
    renderCell: (params) => {
        return qrCodeRender(params);
    }
    },
  
    { field: 'yyy', headerName: 'Uredi',  width: 50,
    renderCell: (params) => {
        return editRender(params);
    }
    },
    { field: 'ccc', headerName: 'Brisi',  width: 50,
    renderCell: (params) => {
      return (
        <p direction="row" spacing={0}>
          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(params)}
          />
        </p>
      );
    }
    },
  ];
  // console.log(membership)
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <Box m="20px">
       <ToastContainer />
      <Header
        title="OPREMA"
        // subtitle="List of Contacts for Future Reference"
      />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/oprema/new">
          <Button type="submit" color="secondary" variant="contained">
            Nova oprema
          </Button>
        </Link>
        </Box>
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={filteredMembership}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Potvrdite brisanje opreme!
          </DialogContentText>
          {/* {memberToDelete?.row?.user?.firstName + " " + memberToDelete?.row?.user?.lastName } */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Šifra"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.blackBorder} 
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
     )}
     </>
  );
};

export default Equpment;
