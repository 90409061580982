import React, { useState,useContext,useEffect } from 'react';
import { GymContext } from "../../theme";
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
// import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createReklama } from '../../api/reklamaApi';
import {TailSpin} from 'react-loader-spinner';

const useStyles = makeStyles((theme) => ({
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      maxWidth: '400px',
      margin: '0 auto',
    },
    inField:{
        backgroundColor:"#ffffff14"
    }

  }));

const NovaReklama = () => {
  const { defaultGym  } = useContext(GymContext);
  const classes = useStyles();
  const [link, setLink] = useState();
  // const [qrCode, setQrCode] = useState('');
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isExerciseCreated, setIsExerciseCreated] = useState(false);
console.log(link);
  useEffect(() => {
    if (!loading && isExerciseCreated) {
      toast.success('Uspješno ste kreirali reklamu.');
      setIsExerciseCreated(false); // Resetujte stanje nakon prikazivanja poruke
    }
  }, [loading, isExerciseCreated]);
  

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const equpmentsData = {
      link: JSON.stringify(link),
        image
    }

    const formData = new FormData();
    formData.append('link', equpmentsData.link);
    // formData.append('description', equpmentsData.description);
    // formData.append('gym', equpmentsData.gym);
    formData.append('image', equpmentsData.image);

    try {

    const res = await createReklama(formData);
    // console.log(res)
    if (res) {
      setIsExerciseCreated(true);
    }
  //   if(res){
  //     // alert("Uspješno stvorena transakcija");
  //     toast.success('Uspješno ste kreirali opremu.');
  //     // alert("Uspjesno se kreirali clana");
  // }

    // console.log(res)
    // Resetiranje polja forme nakon spremanja
    setLink('');
    setImage('');
    // setQrCode('');
    }catch(error){
      console.log(error);
      toast.error('Došlo je do greške prilikom kreiranja reklame.');
  } finally {
    setLoading(false); // Postavi loading na false nakon završetka
    // toast.success('Uspješno ste kreirali vježbu.');
}
  };

  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <form className={classes.form} onSubmit={handleSubmit}>
        <Header title="Nova reklama" subtitle="" />
        <ToastContainer />
      {/* <TextField
        className={classes.inField}
        label="Naziv opreme"
        value={name}
        onChange={(event) => setName(event.target.value)}
        variant="outlined"
        required
      /> */}
      <TextField
       className={classes.inField}
              label="Link reklame"
              value={link}
              onChange={(event) => setLink(event.target.value)}
              variant="outlined"
              required
            />
  
         <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />

         <Grid container justify="left" alignItems="left" spacing={2}>
        <Grid item>
          <Button type="submit" variant="contained" color="secondary">
        Kreiraj reklamu
        </Button>
      </Grid>
    </Grid>
    </form>
        )}
        </>
  );
};

export default NovaReklama;
