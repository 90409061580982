import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/trosak`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/oprema';

// export const getAllCosts = async () => {
//   const response = await axios.get(baseUrl);
//   return response.data;
// };

export const getAllCoststByRange = async ({startDate, endDate}) => {
  const response = await axios.get(`${baseUrl}/${startDate}/${endDate}`);
  return response.data;
};

export const getCostById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createCost = async (newTrosak) => {
  // console.log(newTrosak)
  const response = await axios.post(baseUrl, newTrosak);
  return response.data;
};

export const updateCost = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteCost = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};
