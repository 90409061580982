import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/members`;
// const baseUrl = 'http://localhost:3001/api/members';


export const getMembers = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getMembersByRange = async ({startDate, endDate}) => {
  // const response = await axios.get(baseUrl);
  const response = await axios.get(`${baseUrl}/${startDate}/${endDate}`);
  return response.data;
};


export const getMembersByGym = async (id) => {
  const gymID = id
  // console.log(gymID)
  const response = await axios.get(`${baseUrl}/gym/${gymID}`);
  return response.data;
};


export const getMembersById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const getGostMemberById = async ({userGuestId}) => {
    // console.log(startDate, endDate)
    const response = await axios.get(`${baseUrl}/guest/user/${userGuestId}`);
    return response.data;
}


export const getMemberByProgramId = async (programId) => {
  // console.log("programId",programId)
  const response = await axios.get(`${baseUrl}/program/${programId}/detalj`);
  return response.data;
}


export const getMembersByUserId = async (userId) => {
  const response = await axios.get(`${baseUrl}/${userId}`);
  return response.data;
};
///double
export const getMemberByUserId = async (userId) => {
  const response = await axios.get(`${baseUrl}/${userId}`);
  return response.data;
};


export const getMemberById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};


export const createMember = async (newMembers) => {
  const response = await axios.post(baseUrl, newMembers);
  // console.log(newMembers)
  return response.data;
};

export const updateMembers = async (id, updatedMembers) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedMembers);
  return response.data;
};

export const deleteMember = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


export const updateDefaultGym = async (MembersId, gymId) => {
  // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  // console.log(userId, gymId)
}
