import React, { useEffect, useState } from 'react';
import { Typography, useTheme, Box, InputLabel, MenuItem, Select, TextField,Button,Checkbox } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import {getAllPaymentByRange} from '../../api/paymentApi'
import {getPaket} from '../../api/paketApi'
// import PieIzvjestajClanovi from '../pie/pieClanoviIzvjestaj';
// import SpolClanova from '../../components/PieChartspolaClanova';
import FaktureIzvjestajKomponenta from '../../components/faktureIzvjestajKomponenta';
import FaktureBarChart3 from '../../components/BarChart4';
import { TailSpin } from 'react-loader-spinner';

const IzvjestajFakture = () => {
    const theme = useTheme();
const colors = tokens(theme.palette.mode);

const currentYear = new Date().getFullYear();
const startYear = new Date(`01/01/${currentYear}`);

// const [startDate, setStartDate] = useState(startYear);
const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date());
  // const [status, setStatus] = useState('');
  // const [expiryDays, setExpiryDays] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [filterUplate, setFiltriraneUplate] = useState([]);
  const [BrojNovihClanarina, setBrojNovihClanarina] = useState(0)
  const [BrojProduzenihClanarinaClan, setBrojProduzenihClanarinaClan] = useState(0)
  const [BrojProduzenihClanarinaUposlenik, setBrojProduzenihClanarinaUposlenik] = useState(0)

  // console.log(filterUplate)
  const [ukupanPrihodUplate, setUkupanPrihodUplate] = useState('');
  // const [activeMember, setActiveMember] = useState([])
  const [grupisaniPaketi, setGrupisaniPaketi] = useState([])
  const [odgodjenoPlacanje, setUkupnoOdgodjenoPlacanje] = useState([])
  const [clanTrenings, setClanTrening] = useState(0)
  const [membershipStatus, setMembershipStatus] = useState("");
  const [fakturaStatus, setFakturaStatus] = useState("");
  const [paket, setPaket] = useState("");
  const [paketi, setPaketi] = useState([]);
  // const [brojDanaDoIsteka, setBrojDanaDoIsteka] = useState(null);
  const[clanoviSaUplatamSort, setSortiraniClanoviSaUplatam] = useState([])
  const[clanoviKasneSaUplatamSort, setSortiraniKojiKasneSaUplatam] = useState([])
  const [resetFilters, setResetFilters] = useState(false);
  const [loading, setLoading] = useState(true);

  // const [showEmployees, setShowEmployees] = useState(false);

// console.log("filterUplate",filterUplate)
startDate.setHours(0, 0, 0, 0);
endDate.setHours(23, 59, 59, 999)

const handleResetFilters = () => {
  setStartDate(startYear);
  setEndDate(new Date());
  setFakturaStatus('');
  setPaymentType('');
  setPaket('');
  setResetFilters(true);
};

useEffect(()=>{
  if (resetFilters) {
    setFakturaStatus('');
    setPaymentType('');
    setPaket('');
    setResetFilters(false);
  }
    async function fechData (){

    const uplate = await getAllPaymentByRange({
      startDate,
      endDate
    })
    // console.log("uplate", uplate)
    const fachedPaketi = await getPaket()
        setPaketi(fachedPaketi)
// console.log(uplate)
setLoading(false);
    // let novaClanarina = 0
  // filtriranje clanova po datumu i statusu clanarine te preostalom vremenu do isteka
  const filteredUplate = uplate.filter((uplata) => {
    const createdDate = new Date(uplata.createdAt);
    createdDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999)
    const dateMatch = createdDate >= startDate && createdDate <= endDate;
    // console.log("dateMatch",dateMatch)
    const statusMatch = fakturaStatus === "" || uplata.status === fakturaStatus;
    const paymentTypeMatch = paymentType === "" || uplata.paymentMethod === paymentType;
    const paketTypeMatch = paket === "" || uplata?.member?.user?.membership?.plan?._id === paket;
    return dateMatch && statusMatch && paymentTypeMatch && paketTypeMatch;
  });
  
  //Broj novih clanarina
  const novaClanarina = filteredUplate.filter((e)=>e.opis == "Nova clanarina");
  //broj produzenih clanarina clan
  const produzenjeClan = filteredUplate.filter((e)=>e.opis == "update od strane clana");
    //broj produzenih clanarina uposlenik
    const produzenjeUposlenik = filteredUplate.filter((e)=>e.opis == "Produzenje clanarine");
  // console.log("filteredUplate",filteredUplate[0].opis)
  
  //ukupan prihod o uplatama ako nije odgodjeno placanje
  const ukupanPrihod = filteredUplate.reduce((acc, cur) => {
    if (cur.paymentMethod !== "odgodjeno") {
      return acc + cur.amount;
    } else {
      return acc;
    }
  }, 0);
  const ukupanoOdgodjenoPlacanje= filteredUplate.reduce((acc, cur) => {
    if (cur.paymentMethod === "odgodjeno") {
      return acc + cur.amount;
    } else {
      return acc;
    }
  }, 0);
  
  // nakon što se dobiju podaci o uplatama u varijabli "uplate"
// izračunaj ukupne uplate svakog člana
const clanoviSaUplatama = {};
for (const cur of filteredUplate) {
  const idClana = cur.member && cur.member.user ? cur.member.user._id : null;
  const ime = cur.member && cur.member.user ? cur.member.user.firstName : null;
  const prezime = cur.member && cur.member.user ? cur.member.user.lastName : null;
  if (idClana && cur.paymentMethod !== "odgodjeno") {
    if (!clanoviSaUplatama[idClana]) {
      clanoviSaUplatama[idClana] = {
        ime,
        prezime,
        ukupnoUplata: 0,
      };
    }
    clanoviSaUplatama[idClana].ukupnoUplata += cur.amount;
  }
}


// Konvertiraj objekt u polje objekata
const clanoviSaUplatamaArray = Object.values(clanoviSaUplatama);
// Sortiraj polje objekata po svojstvu ukupnoUplata od najvećeg prema najmanjem
clanoviSaUplatamaArray.sort((a, b) => b.ukupnoUplata - a.ukupnoUplata);

const clanoviKojikasneSaUplatama = {};
for (const cur of filteredUplate) {
  const idClana = cur.member && cur.member.user ? cur.member.user._id : null;
  const ime = cur.member && cur.member.user ? cur.member.user.firstName : null;
  const prezime = cur.member && cur.member.user ? cur.member.user.lastName : null;
  if (idClana && cur.paymentMethod === "odgodjeno") {
    if (!clanoviKojikasneSaUplatama[idClana]) {
      clanoviKojikasneSaUplatama[idClana] = {
        ime,
        prezime,
        ukupnoUplata: 0,
      };
    }
    clanoviKojikasneSaUplatama[idClana].ukupnoUplata += cur.amount;
  }
}

// Konvertiraj objekt u polje objekata
const clanoviKojiKasneSaUplatamaArray = Object.values(clanoviKojikasneSaUplatama);
// Sortiraj polje objekata po svojstvu ukupnoUplata od najvećeg prema najmanjem
clanoviKojiKasneSaUplatamaArray.sort((a, b) => b.ukupnoUplata - a.ukupnoUplata);



    setSortiraniClanoviSaUplatam(clanoviSaUplatamaArray);
    setSortiraniKojiKasneSaUplatam(clanoviKojiKasneSaUplatamaArray);
    setFiltriraneUplate(filteredUplate);
    setUkupanPrihodUplate(ukupanPrihod);
    setUkupnoOdgodjenoPlacanje(ukupanoOdgodjenoPlacanje);
    setBrojNovihClanarina(novaClanarina);
    setBrojProduzenihClanarinaClan(produzenjeClan);
    setBrojProduzenihClanarinaUposlenik(produzenjeUposlenik);
    
    // prvo kreirajte objekat koji će sadržati broj članova po paketima
    let packagesCount = {};
// zatim prolazimo kroz objekte iz niza
// console.log(" packageName", filteredUplate)
for (let obj of filteredUplate) {
  // čitamo naziv paketa za trenutni objekat
  const packageName = obj?.member?.user?.membership?.plan?.nazivPaketa;
  // ako paket već postoji u objektu, uvećavamo broj članova za taj paket
  if (packagesCount[packageName]) {
    packagesCount[packageName]++;
  } else {
    // inače, inicijaliziramo broj članova za taj paket na 1
    packagesCount[packageName] = 1;
  }
}
    // console.log(packagesCount)
// sada imamo objekat packagesCount koji sadrži broj članova po paketima
    setGrupisaniPaketi(packagesCount)
    }

    fechData()
},[endDate, startDate, membershipStatus, paket, fakturaStatus, paymentType, resetFilters])

// console.log("clanoviSaUplatamaArray", clanoviSaUplatamSort);
// console.log("brojDanaDoIsteka", brojDanaDoIsteka)
// console.log("filterUplate", filterUplate)
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
      </div>
    ) : (
    <Box m="20px">
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Box>
      <Header title="IZVJESTAJ O FAKTURAMA" />
      <Box>
    
    <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
    <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
            //  value={gym}
            //  onChange={handleChangeGym}
           >
    {/* {gyms?.map((e, key) => (
    <MenuItem key={key} value={e}>
    {e.naziv}
    </MenuItem>
    ))} */}
    </Select>
     <FormControl component="fieldset">
       <label htmlFor="faktura-status">Status fakture:</label>
     <RadioGroup
        row
        aria-label="faktura status"
        name="faktura-status"
        // value={selectedStatus}
        // onChange={(e) => setMembershipStatus(e.target.value)}
        onChange={(e) => setFakturaStatus(e.target.value)}
      >

        <FormControlLabel value="completed" control={<Radio  style={{color:"white"}} />} label="Placene" />
        {/* <FormControlLabel value="expired" control={<Radio  style={{color:"white"}}/>} label="Istekla" /> */}
        <FormControlLabel value="pending" control={<Radio  style={{color:"white"}}/>} label="U obradi" />
      </RadioGroup>
    </FormControl>
   {/* <TextField
        id="expiry-days"
        label="Raspon dana isteka"
        type="number"
        InputLabelProps={{ shrink: true }}
        variant="standard"
        value={brojDanaDoIsteka}
        onChange={(e) => setBrojDanaDoIsteka(e.target.value)}
      /> */}
       <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Način plaćanja</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paymentType}
          label="Način plaćanja"
          onChange={(e) => setPaymentType(e.target.value)}
        >
          <MenuItem value="" disable>Svi načini plaćanja</MenuItem>
          <MenuItem value="gotovina">Gotovina</MenuItem>
          <MenuItem value="virman">Virman</MenuItem>
          <MenuItem value="kartica">Kartica</MenuItem>
          <MenuItem value="odgodjeno">Odgodjeno plaćanje</MenuItem>
        </Select>
      </FormControl>
    <FormControl  sx={{minWidth:"200px" }}>
         <InputLabel id="payment-type-label">Paketi</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paket}
          label="Način plaćanja"
          onChange={(e) => setPaket(e.target.value)}
        >
          <MenuItem value="" disabled>Odaberi paket</MenuItem>
          {paketi?.map((e, key)=>{
                    return <MenuItem key={key} value={e._id}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
                  })
                  }
        </Select>
      </FormControl>
      <FormControl  sx={{minWidth:"200px" }}>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
        />
      
      </FormControl>
      {/* <FormControlLabel
  control={
    <Checkbox
      color="primary"
      style={{ color: "#ffffff" }}
      name="showEmployees"
      checked={showEmployees}
      onChange={() => setShowEmployees(!showEmployees)}
    />
  }
  label="Prikazi uposlenike"
/> */}

   
  </Box>
  
    </Box>
    <Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleResetFilters} variant="contained" color="secondary">
    Resetuj filtere
  </Button>
</Box>
  </Box>

<Box
  display="grid"
  gridTemplateColumns="repeat(12, 1fr)"
  gridAutoRows="140px"
  gap="20px"
>
  {/* ROW 1 */}
  <Box
    gridColumn="span 2"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    <StatBox
        title={filterUplate.length}
        subtitle="Broj uplata"
        progress="a"
        // increase={`+${progressPaketi.length}%`}
        // icon={
        //   <HowToRegIcon
        //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        //   />
        // }
      />
  </Box>
  <Box
    gridColumn="span 2"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    <StatBox
        title={BrojNovihClanarina.length}
        subtitle="Broj uplata novi članovi"
        progress="a"
        // increase={`+${progressPaketi.length}%`}
        // icon={
        //   <HowToRegIcon
        //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        //   />
        // }
      />
  </Box>
  <Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    {/* BrojProduzenihClanarinaClan
BrojProduzenihClanarinaUposlenik */}
    <StatBox
        title={(BrojProduzenihClanarinaClan.length + BrojProduzenihClanarinaUposlenik.length)}
        subtitle2={`Član: ${BrojProduzenihClanarinaClan.length} /`}
        subtitle3={`Uposlenik: ${BrojProduzenihClanarinaUposlenik.length}`}
        subtitle="Broj uplata produženje članarine"
        progress="a"
        // increase={`+${progressPaketi.length}%`}
        // icon={
        //   <HowToRegIcon
        //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
        //   />
        // }
      />
  </Box>
  <Box
    gridColumn="span 2"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    <StatBox
        title={`${ukupanPrihodUplate} KM`}
        subtitle="Ukupan prihod od uplata"
        progress="a"
    />
    {/* ... */}
  </Box>
  <Box
    gridColumn="span 1"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    <StatBox
    title={odgodjenoPlacanje}
    subtitle="Odgodjeno placanje"
      progress="a"
    />
    {/* ... */}
  </Box>
  <Box
    gridColumn="span 2"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    <StatBox
    title={clanTrenings}
    subtitle="Clanovi koji koriste grupni trening"
      progress="a"
    />
    {/* ... */}
  </Box>

  <Box
    gridColumn="span 3"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
     Top 10 clanova sa najvecim uplatama
    <ol type="1">
    {clanoviSaUplatamSort.map((e, index)=>{
      return(
          <li key={index}>{`${e.ime} ${e.prezime} : ${e.ukupnoUplata} KM`}</li>
        )
    })}
    </ol>
  </Box>
  <Box
    gridColumn="span 3"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    {/* List of Top 10 clanova koji kasne sa uplatama */}
    Top 10 clanova koji kasne sa uplatama
    
    {clanoviKasneSaUplatamSort.map((e)=>{
      return(
        <ol>
          <li>{`${e.ime} ${e.prezime} : ${e.ukupnoUplata} KM`}</li>
        </ol>
        )
    })}
  </Box>
  <Box
    gridColumn="span 6"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    {/* FaktureBarChart3 component */}
    <FaktureBarChart3 data={filterUplate} startDate={startDate}/>
    {/* ... */}
  </Box>

  <Box
    gridColumn="span 12"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    <Box
      mt="25px"
      p="0 30px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          {/* Text */}
        </Typography>
        <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
          {/* Text */}
        </Typography>
      </Box>
      <Box>
        {/* Other components */}
      </Box>
    </Box>
    <Box height="250px" m="-40px 0 0 0">
    <FaktureIzvjestajKomponenta data={filterUplate}/>
      {/* ... */}
    </Box>
  </Box>

</Box>

</Box>
)}
</>

);
};

export default IzvjestajFakture;