import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/obrok`;
// const baseUrl = 'http://localhost:3001/api/obrok';

export const getAllMeal = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getMealById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createMeal = async (newMeal) => {
  const response = await axios.post(baseUrl, newMeal);
  console.log(newMeal)
  return response.data;
};

export const updateMeal = async (id, updateMeal) => {
  const response = await axios.put(`${baseUrl}/${id}`, updateMeal);
  return response.data;
};

export const deleteMeal = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }