import React, { useEffect, useState } from "react";
import { Routes, Route, useParams, Link, useNavigate } from 'react-router-dom';
import Avatar from "@mui/material/Avatar"; // Uvoz Avatar komponente
import { deepPurple } from "@mui/material/colors"; // Opcionalno: Uvoz boje za Avatar


const Rodjendan = ({ clanoviData }) => {
const navigate = useNavigate();
  const [rodjendan, setRodjendan] = useState([]);
  const clanovi = clanoviData;

  useEffect(() => {
    const danas = new Date();

    // Filtrirajte članove koji imaju rođendan danas
    const rodjendani = clanovi.filter((clan) => {
      const datumRodjenja = new Date(clan.date_of_birth);
      return (
        clan.user.membership.status === "active" &&
        datumRodjenja.getDate() === danas.getDate() &&
        datumRodjenja.getMonth() === danas.getMonth()
      );
    });

    setRodjendan(rodjendani);
  }, []);

  const handleClick = (clan) => {
    //  console.log(user)
    navigate(`/users/${clan.user._id}`, { state: clan });
  };
 
  return (
    <>
      {rodjendan.length > 0 ? (
        <div>
          <h3>Članovi koji danas slave rođendan:</h3>
          <ul>
            {rodjendan.map((clan) => (
              <li key={clan._id} style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                  alt={`${clan.user.firstName} ${clan.user.lastName}`}
                  src={clan.user.photo}
                  sx={{ width: 50, height: 50, backgroundColor: deepPurple[500] }}
                  onClick={()=> handleClick(clan)}
                />
                <div style={{ marginLeft: "10px" }}>
                  <p>
                   {clan.user.firstName} {clan.user.lastName}, Paket: {clan.user.membership.plan.nazivPaketa.bs}, Datum rođenja: {new Date(clan.date_of_birth).toLocaleDateString("hr-HR")}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Trenutno nema članova koji slave rođendan danas.</p>
      )}
    </>
  );
};

export default Rodjendan;
