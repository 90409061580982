import React,{useEffect,useState} from 'react';
import { Box, Container, Button,Typography, Card, CardContent, CardMedia, List, ListItem, ListItemText, Divider } from '@mui/material';
import { useLocation, useParams, useNavigate} from 'react-router-dom';
import { getTreningProgramById,deleteMemberTreningPrograms } from '../../api/treningProgramApi';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import Avatar from '@mui/material/Avatar';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
// import Stack from '@mui/material/Stack';
import { getMemberByProgramId } from '../../api/memberApi';
import DeleteIcon from '@mui/icons-material/Delete';
import {TailSpin} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';


const TrainingProgramDetalj = () => {
    // const { state: data } = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const [program, setProgram] = useState([])
    const [members, setMembers] = useState([])
    const [expanded, setExpanded] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);


// console.log(members)
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    
 const openDeleteDialog = (member) => {
    setMemberToDelete(member);
    setDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteDialogOpen(false);
    setMemberToDelete(null);
  };

  const handleConfirmDelete = async () => {
    if (memberToDelete) {
      setIsDeleting(true);
      try {
        const res = await deleteMemberTreningPrograms(id, memberToDelete._id);
        if (res.status === 200) {
          setMembers(prevMembers => prevMembers.filter(member => member._id !== memberToDelete._id));
          setDeleteDialogOpen(false);
          setMemberToDelete(null);
          toast.success('Član je uspješno obrisan iz trening programa');
        }
      } catch (error) {
        toast.error('Greška prilikom brisanja člana iz trening programa');
      } finally {
        setIsDeleting(false);
      }
    }
  };
    

    useEffect(() => {
      async function fetchProgram() {
        try {
          const fetchedProgram = await getTreningProgramById(id);
        //console.log("fetchedProgram",fetchedProgram._id)
          const fetchedMember = await getMemberByProgramId(fetchedProgram._id);
        //console.log("fetchedMember",fetchedMember)
          setProgram(fetchedProgram);
          setMembers(fetchedMember);
        } catch (error) {
          console.error(error);
        }
      }
  
      fetchProgram();
    }, []);

    const data = [program, members]
    // console.log("program",program)
    const handleClick = () => {
      navigate(`/programs/${program._id}/edit`, { state: data});
    };
    
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <Container>
        <ToastContainer />
      <Card>
        <CardMedia
          component="img"
          // alt={program.name.bs}
          // width="50%"
          height="300"
          image={program.image}
          style={{ float:"left", marginRight:20, width:"50%" }} 
        />
        <CardContent>
        <EditIcon onClick={handleClick}></EditIcon>
          <Typography variant="h4" component="div">
            {program?.name?.bs}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {program?.description?.bs}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Trajanje: {program.duration} days
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Tip: {program.type}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Intenzitet: {program.intensity}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Cilj: {program.goal}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            Nivo: {program.level}
          </Typography>
        </CardContent>
      </Card>
      <Typography variant="h6" gutterBottom>Clanovi</Typography>
      {members?.map((e) => (
        <Box key={e.user._id} style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
          <Box style={{ marginRight: 5 }}>
            <Avatar alt="Remy Sharp" src={e.user.photo} />
          </Box>
          <Box flexGrow={1}>
            <Typography variant="h6" gutterBottom>
              {e.user.firstName + ' ' + e.user.lastName}
            </Typography>
          </Box>
          <Box>
          <DeleteIcon
            style={{ cursor: 'pointer', color: "red" }}
            onClick={() => openDeleteDialog(e)}
            disabled={isDeleting}
          />
          </Box>
        </Box>
      ))}

      <Typography variant="h6" gutterBottom>Detalji</Typography>
      {program?.days?.map((day) => (
        <Card key={day._id}>
          <CardContent>
            <Typography variant="h5" component="div">
              {/* Dan {day.day} */}
            </Typography>
            <Typography variant="h4" color="text.secondary">
              {/* {day.dayOpis} */}
            </Typography>

            <List>
              <ListItem>
                <ListItemText primary="Vježbe" />
              </ListItem>

  {day?.exercises?.map((exercise, key) => (
      <Accordion expanded={expanded === `panel${key + 1}`} onChange={handleChange(`panel${key + 1}`)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
             <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CardMedia
                        component="img"
                        alt={exercise.exerciseId.image}
                        width="70"
                        height="70"
                        image={exercise.exerciseId.image}
                        style={{ objectFit: 'contain', marginRight: '10px' }}
                    />
                     </div>
          <Typography sx={{ width: '50%', flexShrink: 0 }}>{exercise.exerciseId.name.bs}</Typography>
          {/* <Typography sx={{ color: 'text.secondary' }}>I am an accordion</Typography> */}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          {/* <Box style={{marginRight:10}}>
                    </Box> */}
                    <Box style={{marginRight:10}}>
                    <Typography variant="body1" color="text.secondary">
                    Trajanje:  {exercise.duration} 
                    </Typography>
                    </Box>
                    <Box style={{marginRight:10}}>
                    <Typography variant="body1" color="text.secondary">
                    MET: {exercise.met} 
                    </Typography>
                    </Box>

                    {exercise?.repetitions?.map((rep, key) => (
                          <>
                         <Typography style={{fontSize:'16px', fontWeight:800}} color="text.secondary">Set: {key + 1} </Typography>
                           <Typography variant="body1" color="text.secondary">Ponavljanja: {rep.reps} </Typography>
                           <Typography variant="body1" color="text.secondary">Tezina: {rep.heavy} </Typography>
                           <Typography variant="body1" color="text.secondary">Odmor: {rep.rest} </Typography>
                          </>
                      ))}
          </Typography>
        </AccordionDetails>
      </Accordion>
  ))}

              <Divider />

            </List>
          </CardContent>
        </Card>
      ))}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Potvrda brisanja"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Da li ste sigurni da želite obrisati člana iz programa treninga?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
     )}
     </>
  );
};

export default TrainingProgramDetalj;
