
import React,{useState, useEffect} from 'react' 
import { Box, Button, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { Link} from 'react-router-dom';
// import { mockTransactions } from "../../data/mockData";
// import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
// import EmailIcon from "@mui/icons-material/Email";
// import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
// import TrafficIcon from "@mui/icons-material/Traffic";
import Header from "../../components/Header";
// import LineChart from "../../components/LineChart";
// import GeographyChart from "../../components/GeographyChart";
// import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
// import ProgressCircle from "../../components/ProgressCircle";
// import Inventory2Icon from '@mui/icons-material/Inventory2';
// import EuroIcon from '@mui/icons-material/Euro';
// import Pie from "../pie";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {InputLabel} from '@mui/material';
import { getAllGyms } from '../../api/gymApi';
import { getAllCoststByRange } from '../../api/trosakApi';
// import IzvjestajChart from '../../components/LineChartIzvjestaj';
// import BarChart3 from '../../components/BarChart3';
import TroskoviIzvjestajKomponenta from '../../components/troskoviIzvjestajKomponenta';


const IzvjestajTroskovi = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

const defaultGym = JSON.parse(localStorage.getItem("defaultGym"));
const gymID = defaultGym;
  
const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));
const [endDate, setEndDate] = useState(new Date());
const [gyms, setGyms] = useState([]);
const [gym, setGym] = useState(gymID || null); // postavite početnu vrijednost na null ako gymID nije definiran

const [troskovi, setTroskovi] = useState([]); 

// console.log(gym)
const handleChangeGym = (event) => {
  setGym(event.target.value);
};

useEffect(() => {
  async function fetchGym() {
    try {
      const fetchedGyms = await getAllGyms();
      const fetchedCosts = await getAllCoststByRange({
        startDate,
        endDate
      });
      setTroskovi(fetchedCosts)
        setGyms(fetchedGyms);
    } catch (err) {
      console.error(err);
    }
  }
  fetchGym();
}, [endDate, startDate]);
  

const sumaTroskova = troskovi.reduce((acc, trosak) => acc + (trosak?.iznos || 0), 0);

return (
  <Box m="20px">
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Header title="IZVJESTAJ O TROSKOVIMA"/>
      <Box>
      <div>
      <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
             <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
            showTimeSelect={false} // dodano
            showTimeSelectOnly={false} // dodano
             dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
            style={{ display: "inline-block", margin: "0 auto" }}
            />

            <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
            showTimeSelect={false} // dodano
            showTimeSelectOnly={false} // dodano
            dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
            style={{ display: "inline-block", margin: "0 auto" }}
            />
 
    </div>
      </Box>
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/trosak/new">
          <Button type="submit" color="secondary" variant="contained">
            Kreiraj trošak
          </Button>
        </Link>
        </Box>
    </Box>
  

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          
          <StatBox
            title={troskovi.length}
            subtitle="Broj troskova"
            progress="a"
            // increase={`+${troskovi.length}%`}
            // icon={
            //   <Inventory2Icon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>
        <Box
          gridColumn="span 6"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
              title={`${sumaTroskova} KM`}
              subtitle="Suma troskova"
              progress="a"
              // progress={`${(prihodiPaketi / prodatiPaketi).toFixed(2)}`}
            //   increase={`+${progressPaketi.length}%`}
            // icon={
            //   <EuroIcon
            //     sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
            //   />
            // }
          />
        </Box>


        {/* ROW 2 */}
        <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
         
          <Box height="250px" m="-40px 0 0 0">
        <TroskoviIzvjestajKomponenta data={troskovi}/>
      {/* ... */}
    </Box>
        </Box>

      </Box>
      <p>

      </p>
    </Box>
  );
};

export default IzvjestajTroskovi;