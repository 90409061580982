import React, { useState } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';
import { createTrener } from '../../api/trenerApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
}));

const TrainerForm = () => {
  const classes = useStyles();

  const [image, setImage] = useState();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [certification, setCertification] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
  const [password, setPassword] = useState("");


  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };
  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };
  const handleSpecializationChange = (event) => {
    setSpecialization(event.target.value);
  };
  const handleCertificationChange = (event) => {
    setCertification(event.target.value);
  };
  const handleYearsOfExperienceChange = (event) => {
    setYearsOfExperience(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };
  


  const handleSubmit = async (event) => {
    event.preventDefault();
    const trenerData = {
      firstName,
      email,
      lastName,
      phoneNumber,
      specialization,
      certification,
      yearsOfExperience,
      password,
      image
    };
    const formData = new FormData();
    formData.append('firstName', trenerData.firstName);
    formData.append('email', trenerData.email);
    formData.append('lastName', trenerData.lastName);
    formData.append('phoneNumber', trenerData.phoneNumber);
    formData.append('specialization', trenerData.specialization);
    formData.append('image', trenerData.image);
    formData.append('certification', trenerData.certification);
    formData.append('yearsOfExperience', trenerData.yearsOfExperience);
    formData.append('password', trenerData.password);

  
    try {
      // console.log(trenerData)
      // const res = await axios.post('http://localhost:3001/api/treneri', formData);
      const createdTrainer = await createTrener(formData);
 
      toast.success('Uspješno ste kreirali trenera.');
    } catch (error) {
      console.error(error);
      toast.error('Došlo je do greške prilikom kreiranja trenera.');
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
           <Header title="Kreiraj novog trenera" subtitle="" />
           <ToastContainer />
      <TextField
        label="Ime"
        name="firstName"
        value={firstName}
        onChange={handleFirstNameChange}
        variant="outlined"
      />
      <TextField
        label="Prezime"
        name="lastName"
        value={lastName}
        onChange={handleLastNameChange}
        variant="outlined"
      />

       <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />
      <TextField
        label="Email"
        name="email"
        type="email"
        value={email}
        onChange={handleEmailChange}
        variant="outlined"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={password}
        onChange={handlePasswordChange}
        variant="outlined"
      />
      <TextField
        label="Broj telefona"
        name="phoneNumber"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        variant="outlined"
      />
      <TextField
        label="Specializacija"
        name="specialization"
        value={specialization}
        onChange={handleSpecializationChange}
        variant="outlined"
      />
      <TextField
        label="Certifikati"
        name="certification"
        value={certification}
        onChange={handleCertificationChange}
        variant="outlined"
      />
      <TextField
        label="Godine iskustva"
        name="yearsOfExperience"
        type="number"
        value={yearsOfExperience}
        onChange={handleYearsOfExperienceChange}
        variant="outlined"
      />
        <Grid container justify="left" alignItems="left" spacing={2}>
      <Grid item>
        <Button type="submit" variant="contained" color="secondary">
        Kreiraj trenera
        </Button>
      </Grid>
    </Grid>
    </form>
  );
};

export default TrainerForm;
