import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/send-notification`;

export const createNotification = async (newMessage) => {

    // console.log(newMessage);
    const response = await axios.post(baseUrl, newMessage);
    // console.log(newGym)
    return response.data;
  };