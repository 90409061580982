import React,{useEffect, useState} from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const FaktureBarChart3 = ({ data, startDate }) => {
  // console.log("data",data)
  // const [newData, seNewData] = useState(data)
  // useEffect(()=>{

  // },[data])
  // console.log("data",data)
  //   console.log("newData",newData)
  // Filtrirajte uplate koje nisu odgodjene
  const filteredPayments = data.filter(payment => payment.paymentMethod !== "odgodjeno");
 
  // Izvadite godinu i mjesec iz svake uplate, koristeci to kao kljuc u objektu
  const monthlyPayments = filteredPayments.reduce((acc, payment) => {
    // console.log("payment",payment)
    const date = new Date(payment.createdAt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const key = `${year}-${month.toString().padStart(2, '0')}`; // npr. 2023-05

    if (!acc[key]) {
      acc[key] = 0;
    }

    acc[key] += payment.amount;

    return acc;
  }, {});

  // Kreirajte niz objekata s podacima za grafikon, uključujući mjesece u kojima nije bilo uplata
  const year = startDate.getFullYear();
  const months = Array.from(Array(12).keys()).map(i => i + 1);
  const chartData = months.map(month => {
    const key = `${year}-${month.toString().padStart(2, '0')}`; // npr. 2023-05
    const name = `${month}.${year}`;
    const amount = monthlyPayments[key] || 0;

    return { name, amount };
  });

  // define the colors to use for each plan
  const colors = ['#82ca9d', '#8884d8', '#ffc658', '#8dd1e1', '#a4de6c', '#d0ed57', '#ffc1c1', '#888888'];

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        data={chartData}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" interval="preserveStartEnd" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amount" stackId="a" fill={colors[0]} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default FaktureBarChart3;
