import React,{ useState, useEffect,useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
// import Header from "../components/Header";
// import { getUsers } from "../../api/userApi";
// import { getPaket } from "../../api/paketApi";
// import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import AddTaskIcon from '@mui/icons-material/AddTask';
import { Link } from 'react-router-dom';
import RemainingDays from "../components/RemainingDays";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import {EndDateConvertingWithTime } from "./DateConverting";
// import ErrorIcon from '@mui/icons-material/Error';
// import ConfigApp from '../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;

const ClanoviIzvjestajKomponenta = ({data}) => {

  // console.log(data)
  // const { statusclanarine, setStatusClanarine } = useContext(GymContext);
  // const [isOpen, setIsOpen] = useState(false);

  // console.log(statusclanarine)

  // const handleOpen = () => {
  //   setIsOpen(!isOpen);
  // };
  // const userId = "aaa"
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }


  // console.log(user)
  // const pocetakClanarine = (cell)=>{
  //     // console.log(cell.row.membership)
  //     const start = new Date(cell?.row?.membership?.startDate);
  //    const date =  start.toLocaleString('en-GB', { timeZone: 'UTC' });
  //   return( 
  //     <p direction="row" spacing={1}>
  //       {date}
  //   </p>
  //   )
  // }
  
  const fotografijaRender = (cell)=>{
    // console.log(cell.row.user.photo)
  return( 
    <Link data={cell} to={`/users/${cell.row.user?.id}`}>
       <Stack direction="row" spacing={2}>
      <Avatar alt="Image" 
        //  src={`${imageUrl}/${cell.row.user.photo}` }
      src={cell.row.user.photo} 
      />
    </Stack>

    </Link>
  )
}

const imeRender = (cell)=>{
  // console.log(cell.row.user)
return( 
  <p direction="row" spacing={1}>
      {cell.row.user.firstName}
  </p>
)
}

const prezimeRender = (cell)=>{
  // console.log(cell.row.user)
return( 
  <p direction="row" spacing={1}>
      {cell.row.user.lastName}
  </p>
)
}
// const istekClanarine = (cell)=>{
//     // console.log(cell.row.membership)
//   const end = new Date(cell?.row?.membership?.endDate);
//   const date = end.toLocaleString('en-GB', { timeZone: 'UTC' });

//   return( 
//     <p direction="row" spacing={1}>
//       {date}
//   </p>
//   )
// }
//Kalkulacija broj dana se obracunava u kompomenti RemainigDays
const ostaloDana = (cell)=>{
  const startDate = new Date(cell.row.user.membership.startDate)
  const endDate = new Date(cell.row.user.membership.endDate)

return( 
  <p direction="row" spacing={1}>
    <RemainingDays startDate={startDate} endDate={endDate} membership={cell.row.user.membership} />
</p>
)
}



const paketRender = (cell)=>{
  const paket = cell.row?.user.membership.plan.nazivPaketa.bs
return( 
  <p direction="row" spacing={1}>
     {paket}
   
</p>
)
}

const clanskaRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell?.row?.user.membership.brojClanske}
  </p>
  )
  }
const placanjeRender = (cell)=>{
  // console.log(cell?.row)
// const gym = cell?.row?.user.membership?.gym?.naziv;
return( 
  <p direction="row" spacing={1}>
    {cell?.row?.payments[cell?.row?.payments.length - 1]?.paymentMethod}
</p>
)
}


const datumIstekaRender = (cell)=>{
  // console.log("d",cell?.row.user.membership.endDate)
// const gym = cell?.row?.user.membership?.gym?.naziv;
return( 
  <p direction="row" spacing={1}>
    {/* {cell?.row.user.membership.endDate} */}
    <EndDateConvertingWithTime date={cell?.row.user.membership.endDate}/>
    {/* {cell?.row?.payments[cell?.row?.payments.length - 1]?.paymentMethod} */}
</p>
)
}
const spolRender = (cell)=>{
const spol = cell?.row?.user.spol;
return( 
  <p direction="row" spacing={1}>
    {spol ==="male" ? "M" 
    :spol ==="female" ? "Z" : ""}
</p>
)
}
const statusClanarine = (cell)=>{
const status = cell.row.user.membership.status;
// setStatusClanarine(status)
return( 
  <p direction="row" spacing={1}>
    {status}
</p>
)
}


  const columns = [
    { field: 'brojClanske', headerName: 'br. Clan', 
    renderCell: (params) => {
        return clanskaRender(params);
    }
    },
    { field: 'photo', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'firstName', headerName: 'Ime', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return imeRender(params);
    }
    },
    { field: 'lastName', headerName: 'Prezime', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return prezimeRender(params);
    }
    },
    { field: 'datumIsteka', headerName: 'Datum isteka', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return datumIstekaRender(params);
    }
    },

    { field: '#', headerName: 'Status', 
    renderCell: (params) => {
        return statusClanarine(params);
    }
    },
    { field: 'plan', headerName: 'Paket', 
    renderCell: (params) => {
        return paketRender(params);
    }
    },
    { field: 'payments', headerName: 'Placanje', 
    renderCell: (params) => {
        return placanjeRender(params);
    }
    },
    { field: 'spol', headerName: 'Spol', flex: 1,
    renderCell: (params) => {
        return spolRender(params);
    }
    },
    { field: '-', headerName: 'Ostalo', flex: 1,
    renderCell: (params) => {
        return ostaloDana(params);
    }
    },
    // { field: 'gym', headerName: 'Poslovnica', 
    // renderCell: (params) => {
    //     return poslovnicaRender(params);
    // }
    // },
  ];
  
  return (
    <Box m="2px">
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        // rowHeight={30}
        checkboxSelection 
        pageSize={10}
      rowsPerPageOptions={[10]}
        rows={data} 
        columns={columns} 
        getRowId={(row) =>  row._id}
        />

      </Box>
    </Box>
  );
};

export default ClanoviIzvjestajKomponenta;
