import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  FormControlLabel,
  Autocomplete,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import { createGuestUserReservation, getGuests, updateGuestGroupReservation } from '../api/guestApi';


function NovaRezervacijaDialog({ open, onClose, reservations }) {

    // console.log("rezervacijerezervacije", reservations)
    const [addGuest, setAddGuest] = useState(false);
    const [guestName, setGuestName] = useState('');
    const [guestLastName, setGuestLastName] = useState('');
    const [guestMembershipNumber, setGuestMembershipNumber] = useState('');
    const [selectedReservations, setSelectedReservations] = useState([]);
    const [guests, setGuests] = useState([]);
    const [selectedGuests, setSelectedGuests] = useState([]);


    const handleToggleReservation = (reservation) => () => {
        const currentIndex = selectedReservations.indexOf(reservation);
        const newSelected = [...selectedReservations];
    
        if (currentIndex === -1) {
          newSelected.push(reservation);
        } else {
          newSelected.splice(currentIndex, 1);
        }
    
        setSelectedReservations(newSelected);
      };
    
      const handleGuestCheckboxChange = () => {
        setAddGuest(!addGuest);
      };
  
        useEffect(()=>{
            async function fetchGuests() {
                const resData = await getGuests()
                
                setGuests(resData);
            }

            fetchGuests()
        },[])

        // console.log(selectedGuests);

      const newGuest = {
        guestName,
        guestLastName,
        guestMembershipNumber,
        selectedReservations
    }

    const handleSave = async () => {
        
        if (addGuest === true){
            const res = await createGuestUserReservation(newGuest);
            console.log(res);
            // console.log("add")
        }else{
        const res = await updateGuestGroupReservation(selectedGuests._id, selectedReservations);
        // console.log("update", res)
        }
        // console.log(guestName)
        // console.log(guestLastName)
        // console.log(guestMembershipNumber)
        // console.log(selectedReservations)
        
        
        
      // Ovdje možete obraditi podatke, npr. dodati goste ili rezervacije
      // Koristite guestName, guestLastName, guestMembershipNumber i selectedReservations
      // Nakon obrade, zatvorite dijalog pozivom onClose
      onClose();
    };
   // Sortiranje rezervacija po danu
   const sortedReservations = [...reservations].sort((a, b) => {
    // Konvertirajte dane u redoslijed brojeva (ponedjeljak = 0, utorak = 1, itd.)
    const daysOrder = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  });
// console.log(weeklySchedule)
const translateToCroatian = (day) => {
  switch (day) {
    case 'Monday':
      return 'Ponedjeljak';
    case 'Tuesday':
      return 'Utorak';
    case 'Wednesday':
      return 'Srijeda';
    case 'Thursday':
      return 'Četvrtak';
    case 'Friday':
      return 'Petak';
    case 'Saturday':
      return 'Subota';
    case 'Sunday':
      return 'Nedjelja';
    default:
      return '';
  }
}
    return (
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Nova rezervacija</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Dodajte novog gosta i/ili odaberite postojećeg.
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={addGuest}
                onChange={handleGuestCheckboxChange}
              />
            }
            label="Dodaj gosta"
          />
          {addGuest && (
            <div>
              <TextField
                label="Ime"
                fullWidth
                value={guestName}
                onChange={(e) => setGuestName(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Prezime"
                fullWidth
                value={guestLastName}
                onChange={(e) => setGuestLastName(e.target.value)}
                margin="normal"
              />
              <TextField
                label="Broj članske karte"
                fullWidth
                value={guestMembershipNumber}
                onChange={(e) => setGuestMembershipNumber(e.target.value)}
                margin="normal"
              />
              {/* Autocomplete za odabir gosta */}
              {/* <Autocomplete
                options={[]}
                value={selectedReservations}
                onChange={(event, newValue) => setSelectedReservations(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Odaberite rezervacije" fullWidth />
                )}
              /> */}
            </div>
          )}
          {!addGuest && (
           <Autocomplete
        // multiple
        id="gost"
        options={guests}
        value={selectedGuests} // Postavite value na selectedGuests
        onChange={(event, newValue) => setSelectedGuests(newValue)} // Ažurirajte odabrane goste
        getOptionLabel={(option) => option.firstName +" "+ option.lastName +" "+option.brojClanske} // Prilagodite ovisno o tome kako želite prikazati ime gosta
        renderInput={(params) => (
            <TextField {...params} label="Odaberite gosta" fullWidth />
        )}
        />
        )}
             <List>
          {sortedReservations.map((reservation) => (
          <ListItem
            key={reservation._id}
            button
            onClick={handleToggleReservation(reservation)}
          >
              <Checkbox
                edge="start"
                checked={selectedReservations.indexOf(reservation) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': reservation._id }}
              />
              <ListItemText
                primary={`${translateToCroatian(reservation.day)} ${reservation.startTime}-${reservation.endTime}`}
                secondary={`Grupa: ${reservation.group.name}`}
              />
            </ListItem>
          ))}
        </List>

        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Odustani
          </Button>
          <Button onClick={handleSave} color="primary" variant="contained">
            Spremi
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  
  export default NovaRezervacijaDialog;
  