import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../context/UserProvider';

const Authorization = ({ allowedRoles, children }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
  const userRole = user?.role || localStorage.getItem('userRole');;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else if (!allowedRoles.includes(userRole)) {
      navigate('/unauthorized');
    }
  }, [navigate, isAuthenticated, userRole, allowedRoles]);

  // Prijavljeno i autorizirano
  if (isAuthenticated && allowedRoles.includes(userRole)) {
    return <>{children}</>;
  }

  // Ako nije autorizirano ili autentificirano, ne prikazuje ništa
  // Navigacija će se obaviti putem useEffect
  return null;
};

export default Authorization;

//15.novebar
// import React, { useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { UserContext } from '../context/UserProvider';

// const Authorization = ({ allowedRoles, children }) => {
//   const navigate = useNavigate();
//   const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
//   const { user } = useContext(UserContext);
//   const userRole = user?.role;
// // console.log("userRole", user)
//   if (!isAuthenticated) {
//     navigate('/login');
//     return null;
//   }

//   // Provjeri je li korisnikova uloga dopuštena
//   if (!allowedRoles.includes(userRole)) {
//     navigate('/unauthorized');
//     return null;
//   }

//   // Prijavljeno i autorizirano
//   return <>{children}</>;
// };

// export default Authorization;

