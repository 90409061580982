import React, { useState,useEffect} from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Button, Select, MenuItem, Snackbar } from '@mui/material';
import Modal from '@mui/material/Modal';
import { getAllReservation, updateReservation } from '../../api/reservationsApi';
import { getAllTrener } from '../../api/trenerApi';
import { updateGroup } from '../../api/groupApi';
import MuiAlert from '@mui/material/Alert';



const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const EventModal = (props) => {
  const { event, onClose, open } = props;
  
//   console.log(event.resource.group.trainer.user)
  const [startTime, setStartTime] = useState(event.resource.startTime);
  const [endTime, setEndTime] = useState(event.resource.endTime);
  const [trainers, setSelectedTrainers] = useState([]);
  const [day, setDay] = useState(event.resource.day);
  const [message, setMessage] = useState(null);
  const [selectedTrainer, setSelectedTrainer] = useState(event.resource.group.trainer._id);
  const [severity, setSeverity] = useState('success');

  useEffect(() => {
    async function fetchTrainers() {
    try {
        const reservationsData = await getAllTrener();
        setSelectedTrainers(reservationsData);
    
} catch (error) {
    console.error(error);
  }
}
fetchTrainers();
  }, []);

  

  const handleClose = () => {
    onClose();
  };

  const handleSave = async () => {
    try {
      await Promise.all([
        updateReservation(event.resource._id, { startTime, endTime, day }),
        updateGroup(event.resource.group._id, { trainer: selectedTrainer })
      ]);
      setMessage('Reservation and group updated successfully');
      setSeverity('success');
      onClose();
    } catch (error) {
      console.error(error);
      setMessage('An error occurred while updating the reservation or group');
      setSeverity('error');
    }
  };
// console.log(event.resource.group._id)
const handleSnackbarClose = () => {
    setMessage(null);
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
    //   BackdropProps={{
    //     sx: {
    //       backdropFilter: 'blur(2px)',
    //       WebkitBackdropFilter: 'blur(2px)',
    //       backgroundColor: 'rgba(0, 0, 0, 0.4)',
    //     },
    //   }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: '5px', width: '400px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
        <Typography variant="h6" id="modal-title" sx={{ mb: 2 }}>
          Detalji grupe
        </Typography>
        <Typography variant="subtitle1" id="modal-description">
          <b>Grupa:</b> {event.resource.group.name}
        </Typography>
        <Typography variant="subtitle1" id="modal-description">
        <b>Trener:</b>
        <select value={selectedTrainer} onChange={(e) => setSelectedTrainer(e.target.value)}>
  {trainers.map((trainer) => (
    <option key={trainer._id} value={trainer._id}>
      {trainer.user.firstName} {trainer.user.lastName}
    </option>
  ))}
</select>

        </Typography>
        <Typography variant="subtitle1" id="modal-description">
          <b>Dan:</b> 
          <select value={day} onChange={(e) => setDay(e.target.value)}>
  {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"].map((day) => (
    <option key={day} value={day}>{day}</option>
  ))}
</select>
        </Typography>
        <Typography variant="subtitle1" id="modal-description">
          <b>Pocetak:</b>
          <input
            type="time"
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
          />
        </Typography>
        <Typography variant="subtitle1" id="modal-description">
          <b>Karj:</b> 
          <input
            type="time"
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
          />
        </Typography>
        <Typography variant="subtitle1" id="modal-description">
          <b>Boja:</b> {event.resource.color}
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Button variant="contained" onClick={handleSave}>Spasi</Button>
          <Button variant="contained" onClick={handleClose} sx={{ ml: 2 }}>Odustani</Button>
        </Box>
      </Box>
  
    </Modal>
  );
};

EventModal.propTypes = {
  event: PropTypes.object.isRequired,
//   onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default EventModal;
