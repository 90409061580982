import React from 'react';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import PieChart from "../../components/PieChart";

const Pie = (props) => {

  const prodatiPaketi = props.data;
  // console.log(prodatiPaketi)
  return (
    <Box m="20px">
      <Header />
      <Box height="50vh">
        <PieChart data={prodatiPaketi}/>
      </Box>
    </Box>
  );
};

export default Pie;
