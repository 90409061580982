import React, { useState } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';
import { createTrener } from '../../api/trenerApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createNutricionist } from '../../api/nutricionistApi';

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
}));

const NutricionistForm = () => {
  const classes = useStyles();

  const [nutricionist, setNutricionist] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    specialization: '',
    certification: '',
    yearsOfExperience: '',
    password: '',
    photo: '',
  });

  const handleChange = (event) => {
    setNutricionist({ ...nutricionist, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const createdNutricionist = await createNutricionist(nutricionist);
      toast.success('Uspješno ste kreirali nutricionistu.');
    } catch (error) {
      console.error(error);
      toast.error('Došlo je do greške prilikom kreiranja nutricioniste.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
           <Header title="Kreiraj novog nutricionistu" subtitle="" />
           <ToastContainer />
      <TextField
        label="Ime"
        name="firstName"
        value={nutricionist.firstName}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Prezime"
        name="lastName"
        value={nutricionist.lastName}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Photo"
        name="photo"
        value={nutricionist.photo}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Email"
        name="email"
        type="email"
        value={nutricionist.email}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        value={nutricionist.password}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Broj telefona"
        name="phoneNumber"
        value={nutricionist.phoneNumber}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Specializacija"
        name="specialization"
        value={nutricionist.specialization}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Certifikati"
        name="certification"
        value={nutricionist.certification}
        onChange={handleChange}
        variant="outlined"
      />
      <TextField
        label="Godine iskustva"
        name="yearsOfExperience"
        type="number"
        value={nutricionist.yearsOfExperience}
        onChange={handleChange}
        variant="outlined"
      />
        <Grid container justify="left" alignItems="left" spacing={2}>
      <Grid item>
        <Button type="submit" variant="contained" color="secondary">
        Kreiraj nutricionistu
        </Button>
      </Grid>
    </Grid>
    </form>
  );
};

export default NutricionistForm;
