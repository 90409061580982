import React, { useState, useEffect, useMemo } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Box } from "@mui/material";
import { getAllReservation, updateReservation } from '../../api/reservationsApi';
import { getTrenerById } from '../../api/trenerApi';
import { RRule } from "rrule";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import EventModal from './EventModal'; // or the path to your EventModal component

const localizer = momentLocalizer(moment);

const ReservationCalendar = () => {
  const defaultGym = JSON.parse(localStorage.getItem("defaultGym"));
const gymID = defaultGym;
  // const group = props.data;
  const [reservations, setReservations] = useState([]);
  const [minTime, setMinTime] = useState(moment().hours(8).minutes(0)); // set default minimum time
  const [selectedEvent, setSelectedEvent] = useState(null);

  const handleEventSelect = (event) => {
    // console.log(event)
    setSelectedEvent(event);
  };
  


  useEffect(() => {
    async function fetchReservations() {
      try {
        const reservationsData = await getAllReservation();
        setReservations(reservationsData);
// console.log(reservationsData)
        // find earliest reservation and set the minimum time accordingly
        const sortedReservations = reservationsData.sort((a, b) => {
          const startTimeA = moment(`${a.day} ${a.startTime}`, "dddd HH:mm");
          const startTimeB = moment(`${b.day} ${b.startTime}`, "dddd HH:mm");
          if (startTimeA.isBefore(startTimeB)) {
            return -1;
          } else if (startTimeA.isAfter(startTimeB)) {
            return 1;
          } else {
            return 0;
          }
        });

        if (sortedReservations.length > 0) {
          const earliestReservation = sortedReservations[0];
          const startTime = moment(`${earliestReservation.day} ${earliestReservation.startTime}`, "dddd HH:mm");
          setMinTime(startTime);
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchReservations();
  }, []);

console.log(reservations)

  const events = useMemo(() => {
    const recurringEvents = [];

    reservations.forEach(async (reservation) => {
      if (reservation.repeat) {
        const startTime = moment(
          `${reservation.day} ${reservation.startTime}`,
          "dddd HH:mm"
        );
        const endTime = moment(
          `${reservation.day} ${reservation.endTime}`,
          "dddd HH:mm"
        );

        const rule = new RRule({
          freq: RRule.WEEKLY,
          interval: 1,
          byweekday: moment(reservation.day, "dddd").day(),
          dtstart: startTime.toDate(),
          until: moment().add(6, "months").toDate(),
        });

        const recurringDates = rule.all();

        recurringDates.forEach((date) => {
          const startDate = moment(date).set({
            hour: startTime.hour(),
            minute: startTime.minute(),
            second: 0,
            millisecond: 0,
          });
          const endDate = moment(date).set({
            hour: endTime.hour(),
            minute: endTime.minute(),
            second: 0,
            millisecond: 0,
          });

          const trainerName =
            reservation.group.trainer?.user?.firstName +
            " " +
            reservation.group.trainer?.user?.lastName;

          recurringEvents.push({
            start: startDate.toDate(),
            end: endDate.toDate(),
            title: `${reservation.group.name} - ${trainerName}`,
            resource: reservation,
            color: reservation.color,
            day: reservation.day
          });
        });
        } else {
        const startTime = moment(
        `${reservation.day} ${reservation.startTime}`,
        "dddd HH:mm"
        );
        const endTime = moment(
        `${reservation.day} ${reservation.endTime}`,
        "dddd HH:mm"
        );
        const trainerName =
        reservation.group.trainer?.user?.firstName +
        " " +
        reservation.group.trainer?.user?.lastName;
      recurringEvents.push({
        start: startTime.toDate(),
        end: endTime.toDate(),
        title: `${reservation.group.name} - ${trainerName}`,
        resource: reservation,
        color: reservation.color,
        day: reservation.day
      });
    }
  });
  return recurringEvents;
}, [reservations]);

const eventStyleGetter = (event) => {
  const backgroundColor = event.color;
  const style = {
    backgroundColor,
    borderRadius: "5px",
    opacity: 0.8,
    color: "white",
    border: "0px",
    display: "block",
  };
  return {
    style: style,
  };
};
  
const handleEventResize = async ({ event, start, end }) => {
  const { resource } = event;

  const updatedReservation = {
    ...resource,
    startTime: moment(start).format("HH:mm"),
    endTime: moment(end).format("HH:mm"),
    startDate: moment(start).format("YYYY-MM-DD"),
    endDate: moment(end).format("YYYY-MM-DD"),
  };
  // console.log(updatedReservation)
  try {
    const updatedData = await updateReservation(updatedReservation._id, updatedReservation);
    setReservations((reservations) => reservations.map((r) => (r._id === updatedData._id ? updatedData : r)));
  } catch (error) {
    console.error(error);
  }
};
  
const isDraggableEvent = (event) => !event.isRecurring

const handleEventDrop = async ({ event, start, end }) => {
  const { resource } = event;

  const updatedReservation = {
    ...resource,
    startTime: moment(start).format("HH:mm"),
    endTime: moment(end).format("HH:mm"),
    day: moment(start).format("dddd"),
  };

  // console.log(updatedReservation)
  try {
    const updatedData = await updateReservation(updatedReservation._id, updatedReservation);
    setReservations((reservations) => reservations.map((r) => (r._id === updatedData._id ? updatedData : r)));
  } catch (error) {
    console.error(error);
  }
};

const DragAndDropCalendar = withDragAndDrop(Calendar);

return (
  <Box sx={{ height: 600, width: 1200 }}>
    <DndProvider backend={HTML5Backend}>
      <DragAndDropCalendar
        localizer={localizer}
        events={events}
        defaultView="week"
        views={["week"]}
        onSelectEvent={handleEventSelect}
        resizable
        selectable
        defaultDate={moment().toDate()}
        // onEventDrop={handleEventDrop}
        // onEventResize={handleEventResize}
        eventPropGetter={eventStyleGetter}
        // draggableAccessor={isDraggableEvent}
        startAccessor="start"
        endAccessor="end"
      />
    </DndProvider>
    {selectedEvent && (
  <EventModal
    event={selectedEvent}
    onClose={() => setSelectedEvent(null)}
    open={!!selectedEvent} // add the open prop here
  />
)}
  </Box>
);
};

export default ReservationCalendar;