import React,{ useState, useEffect,useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import {makeStyles} from '@material-ui/core';
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { deletePayments } from '../api/paymentApi';
// import Header from "../components/Header";
// import { getUsers } from "../../api/userApi";
// import { getPaket } from "../../api/paketApi";
// import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import AddTaskIcon from '@mui/icons-material/AddTask';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
// import RemainingDays from "../components/RemainingDays";
// import Avatar from '@mui/material/Avatar';
// import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { UserContext } from '../context/UserProvider';

// import ErrorIcon from '@mui/icons-material/Error';
import { StartDateConverting } from "./DateConverting";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    margin: theme.spacing(2),
    display: 'flex',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  leftColumn: {
    flex: '1',
  },
  rightColumn: {
    flex: '1',
    marginLeft: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  borderColor: "black"
}));

const FaktureIzvjestajKomponenta = ({data}) => {
  const { user } = useContext(UserContext);
  const classes = useStyles();
  const navigate = useNavigate();
  // console.log("datasss", data) 
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data);
// console.log("filteredData", filteredData)
const [selectedFakturaIds, setSelectedFakturaIds] = useState([]);
const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
const [password, setPassword] = useState('');
const [loading, setLoading] = useState(false);

const handleExportToExcel = () => {
  const exportData = filteredData.map((item) => ({
    ID:item._id,
    Ime: item.member?.user?.firstName,
    Prezime: item.member?.user?.lastName,
    Opis: item.opis,
    Paket: item.paket.nazivPaketa.bs,
    Iznos: item?.paymentMethod === "odgodjeno" ? 0 :  Number(item?.amount),
    //Iznos: Number(item.amount), // Spremite iznos kao broj, ne kao string
    'Datum kreiranja': StartDateConverting({ date: item.createdAt }),
    'Nacin placanja': item.paymentMethod,
    Status: item.status === 'completed' ? 'Plaćeno' : 'U obradi',
    'Datum placanja': item.paymentMethod !== "odgodjeno" ? StartDateConverting({ date: item.date }) : 'Nije plaćeno',
    odobrio: item.associatedClanarinaUredjivanje[0]?.uredio.firstName,
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  
  // Postavite širinu stupaca ručno
  worksheet['!cols'] = [
    { width: 10 }, // Širina za ID
    { width: 15 }, // Širina za Ime
    { width: 15 }, // Širina za Prezime
    { width: 15 }, // Širina za Opis
    { width: 40 }, // Širina za Opis
    // Dodajte preostale širine za ostale stupce prema potrebi
  ];

  // Dodajte sumu iznosa na kraju tabele
  const sumFormula = `SUM(F2:F${exportData.length + 1})`; // Formula za zbrajanje iznosa
  XLSX.utils.sheet_add_aoa(worksheet, [[null, null, null, null,'Ukupno', { f: sumFormula }]], { origin: -1 });

  // Formatirajte stupac 'Iznos' kao broj
  for (let i = 2; i <= exportData.length + 2; i++) {
    XLSX.utils.cell_set_number_format(worksheet, `F${i}`, '$#,##0.00');
  }

  const workbook = XLSX.utils.book_new();

  // Dodajte datum i ime odobravajuće osobe u naziv datoteke
  const currentDate = new Date();
  const formattedDate = `${currentDate.getDate()}-${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
  const odobravajucaOsoba = exportData.length > 0 ? exportData[0].odobrio : 'Nepoznato';

  XLSX.utils.book_append_sheet(workbook, worksheet, `Fakture_${formattedDate}_${odobravajucaOsoba}`);

  // Export radne knjige u Excel datoteku
  XLSX.writeFile(workbook, `Izvjestaj_fakture_${formattedDate}.xlsx`);
};

  // useEffect(() => {
  //   const results = data?.filter(
     
  //     (d) =>
  //       d?.member?.user?.firstName.toLowerCase().includes(searchText.toLowerCase()) ||
  //       d?.member?.user?.lastName.toLowerCase().includes(searchText.toLowerCase())
  //   );

  //   setFilteredData(results);
  // }, [data, searchText]);

  useEffect(() => {
    const results = data?.filter((d) => {
      const member = d?.member;
      if (member) {
        const user = member.user;
        if (
          user?.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
          user?.lastName?.toLowerCase().includes(searchText.toLowerCase())
        ) {
          return true;
        }
      } else {
        // Ako member nije definiran, ovdje možete odlučiti što želite raditi s tim zapisima
        // Na primjer, ako želite prikazati zapise bez člana, možete vratiti true ili ih ignorirati.
        return true;
      }
      return false;
    });
  
    setFilteredData(results);
  }, [data, searchText]);
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  

// console.log("filteredData", filteredData)
  // const { statusclanarine, setStatusClanarine } = useContext(GymContext);
  const [isOpen, setIsOpen] = useState(false);

  // console.log(statusclanarine)

  // const handleOpen = () => {
  //   setIsOpen(!isOpen);
  // };
  // const userId = "aaa"
  const handleDeleteFakture = () => {
    //otavra se dialog box za potvrdu sa emalom
    setDeleteDialogOpen(true);
    // Implementirajte logiku za brisanje faktura s ID-ovima iz selectedFakturaIds
    // Prikazivanje upozorenja o brisanju ili potvrde može biti korisno
    // console.log("Brisanje faktura s ID-ovima:", selectedFakturaIds);
  };
  const handleCancelDelete = () => {
    // setMemberToDelete(null);
    setDeleteDialogOpen(false);
    setPassword('');
  };

  const handleConfirmDelete = async () => {
  
    // const memberIdToDelete = memberToDelete.row._id;
    const uposlenik = user._id;
    // console.log(memberToDelete?.row?.user?._id)
    try {
      setLoading(true);
      const response = await deletePayments(selectedFakturaIds, {password,uposlenik});
      console.log(response)
      if(response.success == false){
        toast.error('Pogresana lozinka');
      }else{
        setLoading(false);
        toast.success('Uspješno se izvršili brisanja fakture.');
      }

    } catch (error) {
      toast.error('Greška prilikom brisanja fakure.');
      console.error("Greška prilikom brisanja člana:", error);
    } finally {
      // setMemberToDelete(null);
      setDeleteDialogOpen(false);
    }
  
};

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }

const imeRender = (cell) => {
  return (
    <p direction="row" spacing={1}>
      {`${cell?.row?.member?.user?.firstName}`}
    </p>
  );
};

const prezimeRender = (cell) => {
  return (
    <p direction="row" spacing={1}>
      {`${cell?.row?.member?.user?.lastName}`}
    </p>
  );
};

const opisRender = (cell)=>{
  // console.log(cell.row.opis)
return( 
  <p direction="row" spacing={1}>
      {`${cell.row.opis}`}
  </p>
)
}
const iznosRender = (cell)=>{
    // console.log(cell.row.amount)
  return( 
    <p direction="row" spacing={1}>
      {`${cell.row.amount} KM`}
  </p>
  )
}
//Kalkulacija broj dana se obracunava u kompomenti RemainigDays
const datumPlacanja = (cell)=>{
  

return( 
  <p direction="row" spacing={1}>
    {cell.row.paymentMethod !== "odgodjeno" ?
    <StartDateConverting date={cell.row?.createdAt}/>
    :"-"
    }
</p>
)
}

const datumKreiranjaRender = (cell)=>{
  // const datumUplate = new Date(cell.row.date)
return( 
  <p direction="row" spacing={1}>
    {cell.row.date !== null ? <StartDateConverting date={cell.row?.date}/> : "Nije placano"}
</p>
)
}


const pnacinPLacanjaRender = (cell)=>{
  const nacinPlacanja = cell.row?.paymentMethod
return( 
  <p direction="row" spacing={1}>
     {nacinPlacanja}
   
</p>
)
}

const statusFakture = (cell)=>{
  // console.log(cell.row.status)
  return( 
    <p direction="row" spacing={1}>
      {cell.row.status === "completed" ? "Plaćeno" : "U obradi"}
  </p>
  )
  }
const paketRender = (cell)=>{
  // console.log(cell?.row)
// const gym = cell?.row?.user.membership?.gym?.naziv;
return( 
  <p direction="row" spacing={1}>
    {cell?.row?.paket?.nazivPaketa.bs}
</p>
)
}
// console.log(filteredData)
const odobrio = (cell)=>{
  // console.log("sss",cell.row)
return( 
  <p direction="row" spacing={1}>
  {cell?.row.associatedClanarinaUredjivanje[0]?.uredio?.firstName}
</p>
)
}
const fakturaDetaljRender = (cell) => {
  // console.log(cell.row)
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  const handleClick = () => {
    navigate(`/izvjestaj/fakture/${cell.row._id}/detalj`, { state: cell.row });
  };
  
  return (
    <>
       {/* <Link data={cell} to={`/fakture/${cell.row._id}/detalj`}> */}
   <RemoveRedEyeIcon  onClick={handleClick}></RemoveRedEyeIcon>
    {/* </Link> */}
    </>
  );
};


  const columns = [
    { field: '_id', headerName: 'ID', 
    renderCell: (params) => {
        return fakturaDetaljRender(params);
    }
    },
    // { field: 'photo', headerName: 'Foto', 
    // renderCell: (params) => {
    //     return fotografijaRender(params);
    // }
    // },
    { field: 'firstName', headerName: 'Ime', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return imeRender(params);
    }
    },
    { field: 'lasttName', headerName: 'Prezime', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return prezimeRender(params);
    }
    },
    { field: 'opis', headerName: 'Opis', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return opisRender(params);
    }
    },
    { field: 'paket', headerName: 'Paket', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return paketRender(params);
    }
    },

    { field: 'amount', headerName: 'Iznos', 
    renderCell: (params) => {
        return iznosRender(params);
    }
    },
    { field: 'createdAt', headerName: 'Datum plaćanja', 
    renderCell: (params) => {
        return datumPlacanja(params);
    }
    },
    { field: 'paymentMethod', headerName: 'Nacin placanja', 
    renderCell: (params) => {
        return pnacinPLacanjaRender(params);
    }
    },
    { field: 'status', headerName: 'Status', 
    renderCell: (params) => {
        return statusFakture(params);
    }
    },
    { field: 'date', headerName: 'Datum kreiranja', flex: 1,
    renderCell: (params) => {
        return datumKreiranjaRender(params);
    }
    },
    { field: 'oodbrio', headerName: 'Odobrio', flex: 1,
    renderCell: (params) => {
        return odobrio(params);
    }
    },
    // { field: 'gym', headerName: 'Poslovnica', 
    // renderCell: (params) => {
    //     return poslovnicaRender(params);
    // }
    // },
  ];
  
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
        <TailSpin height={80} width={80} />
      </div>
    ) : (
    <Box m="px">
    <ToastContainer />
      <Box
        m="0px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
             <Box display="flex" justifyContent="flex-end">
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearch}
        />
      </Box>
   
<Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
<Button
  style={{ marginRight: 20, backgroundColor: "#ffbf02" }}
  variant="contained"
  color="secondary"
  onClick={handleDeleteFakture}
>
  Obriši fakture
</Button>
  <Button onClick={handleExportToExcel} variant="contained" color="secondary">
  Izvezi u Excel
  </Button>
</Box>
      {/* Render the DataGrid using filteredData instead of data */}
     <DataGrid
  checkboxSelection
  pageSize={9}
  rowsPerPageOptions={[5]}
  rows={filteredData}
  columns={columns}
  getRowId={(row) => row._id}
  onSelectionModelChange={(newSelection) => {
    setSelectedFakturaIds(newSelection);
  }}
  components={{
    Toolbar: () => (
      <div>
        {/* <Typography>Brisanje odabranih faktura</Typography> */}
      </div>
    ),
  }}
/>



      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unesite šifru za potvrdu brisanja fakture:
          </DialogContentText>
          Broj odabranih faktura: {selectedFakturaIds.length}
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Šifra"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{backgroundColor:"gray"}}
            // className={classes.blackBorder} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            disabled={selectedFakturaIds.length < 1}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    )}
    </>
  );
};

export default FaktureIzvjestajKomponenta;
