import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, TextField, Button, Select, MenuItem, Typography,Avatar,Autocomplete } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GymContext } from '../../theme';
import Header from '../../components/Header';
import { getJelovnikById, updateJelovnik } from '../../api/jelovnikApi';
import { getAllMeal } from "../../api/mealApi";

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '800px',
    margin: '0 auto',
  },
  inField: {
    backgroundColor: '#ffffff14',
  },
}));

const JelovnikEdit = () => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state: data } = useLocation();
  const { defaultGym } = useContext(GymContext);

  const jelovnikTypes = [
    "Fitness",
    "Standardni",
    "Veganski",
    "Vegetarijanski",
    "Bezglutenski",
    "Paleo",
    "Ketogena",
    "Pescetarijanski",
    "Mediterranski",
    "Niskokalorični",
    "Visokoproteinski",
    "Makrobiotički",
    "Sirova hrana",
    "Ayurvedski",
    "Jednostavni obroci",
    "Detoksikacijski",
    "Niskomasni",
    "Energetski obroci",
    "Flexitarijanski",
    "Jelovnik za trudnice",
    "Jelovnik za dojenje",
    "Jelovnik za dijabetes",
    "Jelovnik za održavanje težine",
    "Jelovnik za visoki krvni tlak",
    "Jelovnik za alergičare",
    "Jelovnik za snagu i izdržljivost",
    "Jelovnik za mršavljenje",
    "Jelovnik za rast mišića",
    "Jelovnik za ravnotežu hormona",
    "Jelovnik za probavne smetnje",
    "Jelovnik za veganske sportaše",
    "Jelovnik za povećanje energije",
    "Jelovnik za detoksikaciju jetre",
  ];
  // const mealOptions = ["Doručak", "Ručak", "Večera", "Užina"];
  const [jelovnik, setJelovnik] = useState(data || {
    name: '',
    description: '',
    type: '',
    mealsOption: [],
  });
  const [loading, setLoading] = useState(!data);
  const [mealOptions, setMealOptions] = useState([]);
  const [image, setImage] = useState(null);
  // console.log("mealOptions",mealOptions)
  const fetchMealOptions = async () => {
    try {
      const response = await getAllMeal();
      setMealOptions(response);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchMealOptions();
  }, []);

   // Ako nisu, dohvatite ih pomoću fetchMealOptions funkcije
   useEffect(() => {
    if (!data?.mealOptions) {
      fetchMealOptions();
    } else {
      setMealOptions(data.mealOptions);
    }
  }, [data]);

useEffect(() => {
  if (!data) {
    const fetchJelovnik = async () => {
      try {
        const data = await getJelovnikById(id);
        // Ovdje možete mapirati mealType na odgovarajući _id iz mealOptions
        // Ovo pretpostavlja da već imate informacije o tome koji _id odgovara svakom mealType
        const updatedMealsOption = data.mealsOption.map(day => ({
          ...day,
          meals: day.meals.map(meal => ({
            ...meal,
            mealType: meal.mealType // Ovdje postavite _id koji odgovara mealType
          }))
        }));
        setJelovnik({...data, mealsOption: updatedMealsOption});
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };

    fetchJelovnik();
  }
}, [id, data]);

  // useEffect(() => {
  //   if (!data) {
  //     const fetchJelovnik = async () => {
  //       try {
  //         const data = await getJelovnikById(id);
  //         setJelovnik(data);
  //         setLoading(false);
  //       } catch (error) {
  //         console.error(error);
  //         setLoading(false);
  //       }
  //     };

  //     fetchJelovnik();
  //   }
  // }, [id, data]);

  const handleInputChange = (e, field) => {
    setJelovnik({ ...jelovnik, [field]: e.target.value });
  };

  const handleDayChange = (dayIndex, e) => {
    const updatedDays = jelovnik.mealsOption.map((day, index) => {
      if (index === dayIndex) {
        return { ...day, [e.target.name]: e.target.value };
      }
      return day;
    });

    setJelovnik({ ...jelovnik, mealsOption: updatedDays });
  };

  const handleMealChange = (dayIndex, mealIndex, field, value) => {
    const updatedMealsOption = jelovnik.mealsOption.map((day, idx) => {
      if (idx === dayIndex) {
        const updatedMeals = day.meals.map((meal, mIdx) => {
          if (mIdx === mealIndex) {
            return { ...meal, [field]: value };
          }
          return meal;
        });
        return { ...day, meals: updatedMeals };
      }
      return day;
    });

    setJelovnik({ ...jelovnik, mealsOption: updatedMealsOption });
  };

  const handleAddDay = () => {
    setJelovnik(prevJelovnik => ({
      ...prevJelovnik,
      mealsOption: [...prevJelovnik.mealsOption, { dayName: '', meals: [] }]
    }));
  };

  const handleRemoveDay = (dayIndex) => {
    setJelovnik(prevJelovnik => ({
      ...prevJelovnik,
      mealsOption: prevJelovnik.mealsOption.filter((_, index) => index !== dayIndex)
    }));
  };

  const handleAddMeal = (dayIndex) => {
    const newMeal = {
      mealType: '',
      mealTime: '',
      mealKind: {},
    };

    const updatedMealsOption = jelovnik.mealsOption.map((day, idx) => {
      if (idx === dayIndex) {
        return { ...day, meals: [...day.meals, newMeal] };
      }
      return day;
    });

    setJelovnik({ ...jelovnik, mealsOption: updatedMealsOption });
  };

  const handleRemoveMeal = (dayIndex, mealIndex) => {
    const updatedMealsOption = jelovnik.mealsOption.map((day, idx) => {
      if (idx === dayIndex) {
        return {
          ...day,
          meals: day.meals.filter((_, mIdx) => mIdx !== mealIndex),
        };
      }
      return day;
    });

    setJelovnik({ ...jelovnik, mealsOption: updatedMealsOption });
  };

  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      setImage(imageFile);
      // setPendingImage("1");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    const jelovnikData = {
      _id: jelovnik._id, // Ako postoji
      name: jelovnik.name,
      description: jelovnik.description,
      type: jelovnik.type,
      mealsOption: jelovnik.mealsOption.map(day => ({
        ...day,
        meals: day.meals.map(meal => ({
          ...meal,
          mealKind: meal.mealKind._id || meal.mealKind // Ako je mealKind objekt, šaljemo samo njegov _id
        }))
      }))
    };
    
    const formData = new FormData();
    formData.append('data', JSON.stringify(jelovnikData));
    
    if (image) {
      formData.append('image', image);
    }
    // const formData = new FormData();
    // formData.append('data', JSON.stringify(jelovnikData)); // Dodajemo cijeli objekt kao string
  
    // if (image) {
    //   formData.append('image', image);
    // }
  
    try {
      await updateJelovnik(id, formData); // Pretpostavljamo da funkcija može rukovati slikom
      toast.success('Jelovnik je ažuriran!');
      // navigate('/jelovnik');
    } catch (error) {
      console.error(error);
      toast.error('Greška prilikom ažuriranja jelovnika.');
    }
  };
  

  if (loading) {
    return <div>Učitavanje...</div>;
  }

  return (
    <>
      <Header title={`Uređivanje jelovnika: ${jelovnik.name}`} />
      <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit} className={classes.form}>
              <TextField
                label="Naziv jelovnika"
                variant="outlined"
                className={classes.inField}
                value={jelovnik.name}
                onChange={(e) => handleInputChange(e, 'name')}
                required
              />
              <TextField
                label="Opis jelovnika"
                multiline
                rows={4}
                variant="outlined"
                className={classes.inField}
                value={jelovnik.description}
                onChange={(e) => handleInputChange(e, 'description')}
                required
              />
              <TextField
  type="file"
  onChange={handleImageUpload}
  variant="outlined"
  className={classes.inField}
  sx={{ mt: 2 }}
/>
              <Select
                label="Tip jelovnika"
                value={jelovnik.type}
                onChange={(e) => handleInputChange(e, 'type')}
                className={classes.inField}
              >
                {jelovnikTypes.map((type, index) => (
                  <MenuItem key={index} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>

              {jelovnik.mealsOption.map((day, dayIndex) => (
                <Box key={dayIndex} sx={{ mt: 2 }}>
                  <Typography variant="h6">Dan: {day.dayName}</Typography>
                  <TextField
                    label="Naziv dana"
                    variant="outlined"
                    className={classes.inField}
                    name="dayName"
                    value={day.dayName}
                    onChange={(e) => handleDayChange(dayIndex, e)}
                  />



{day.meals.map((meal, mealIndex) => (
  <Box key={mealIndex} sx={{ mt: 2 }}>
    <Typography variant="subtitle1">Obrok {mealIndex + 1}</Typography>
    
    {/* Autocomplete za odabir obroka */}
    <Autocomplete
      options={mealOptions}
      getOptionLabel={(option) => option.name}
      value={meal.mealKind} // Pretpostavimo da meal.mealKind sadrži objekt iz mealOptions
      onChange={(event, newValue) => {
        handleMealChange(dayIndex, mealIndex, 'mealKind', newValue);
      }}
      renderInput={(params) => (
        <TextField {...params} label="Vrsta obroka" variant="outlined" className={classes.inField} />
      )}
    />

    {/* TextField za unos vremena obroka */}
    <TextField
      label="Vrijeme obroka"
      type="time"
      variant="outlined"
      className={classes.inField}
      value={meal.mealTime}
      onChange={(e) => handleMealChange(dayIndex, mealIndex, 'mealTime', e.target.value)}
      sx={{ mt: 1 }}
    />

    {/* Dugme za uklanjanje obroka */}
    <Button
      variant="outlined"
      color="error"
      onClick={() => handleRemoveMeal(dayIndex, mealIndex)}
      sx={{ mt: 1 }}
    >
      Ukloni obrok
    </Button>
  </Box>
))}
  
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleAddMeal(dayIndex)}
                    sx={{ mt: 2 }}
                  >
                    Dodaj obrok
                  </Button>

                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => handleRemoveDay(dayIndex)}
                    sx={{ mt: 2 }}
                  >
                    Ukloni dan
                  </Button>
                </Box>
              ))}
 <div style={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={handleAddDay}  variant="outlined" color="secondary" sx={{ mt: 2 }}>
                Dodaj dan
              </Button>
              </div>
              <div style={{ display: "flex", justifyContent: "center", marginBottom:40 }}>
              <Button type="submit" color="secondary" variant="contained" >
              {/* <Button type="submit" variant="contained" sx={{ mt: 2 }}> */}
                Ažuriraj jelovnik
              </Button>
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <ToastContainer />
    </>
  );
};

export default JelovnikEdit;
