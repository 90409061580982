import React, {useState, useEffect,useContext} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
// import { getAllMembershipByGym } from "../../api/membershipApi";
import { deleteNutricionist, getAllNutricionist } from '../../api/nutricionistApi';
// import { StartDateConverting } from '../../components/DateConverting';
// import AddTaskIcon from '@mui/icons-material/AddTask';
import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
import { UserContext } from '../../context/UserProvider';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { ToastContainer, toast } from 'react-toastify';

const Nutritions = () => {
  // let gymId = useParams();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  // let newGymId = gymId?.gymID || props?.data?._id;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [treneri, setTreneri] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [loading, setLoading] = useState(true);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [trenerToDelete, setMemberToDelete] = useState(null);
  const [nutricionist, setNutricionist] = useState([]);

  const [password, setPassword] = useState('');

  // console.log("trenerToDelete",trenerToDelete)

  useEffect(() => {
    async function fetchData() {
      try {
        await getAllNutricionist()
        .then((response) => {
            // console.log(response)
            setNutricionist(response);
            setLoading(false);
          });
        // setNutricionist(fetchedData);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);


  const handleDelete = (cell) => {
    setMemberToDelete(cell);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (trenerToDelete) {
      const trenerIdToDelete = trenerToDelete.row._id;
      const uposlenik = user._id;
      // console.log(memberToDelete?.row?.user?._id)
      try {
        // setLoading(true); 
        const treberzabrisanje = trenerToDelete._id;
        const response = await deleteNutricionist(trenerToDelete?.row?.member?.user?._id, {password,uposlenik, treberzabrisanje});
        // await deleteMember(trenerIdToDelete);
        // console.log(response);
     
        if(response.success === true){
          toast.success('Uspješno se izvršili brisanja nutricioniste.');
        }else{
          toast.error('Upsss. Pojavio se problem prilikom brisanja nutricioniste.');
        }
        setTreneri((prevTrener) =>
        prevTrener.filter((trener) => trener._id !== trenerIdToDelete)
        );
      } catch (error) {
        toast.error('Greška prilikom brisanja člana.');
        console.error("Greška prilikom brisanja člana:", error);
      } finally {
        setMemberToDelete(null);
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setMemberToDelete(null);
    setDeleteDialogOpen(false);
    setPassword('');
  };


//   console.log(treneri)
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredMembership = nutricionist?.filter((e) => {

    if (e?.member.user) {
      return e?.member.user?.firstName.toLowerCase().includes(searchValue.toLowerCase());
    }
    return false;
  });

  const userRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.member.user) {
      return <p>No user data available</p>; // Return a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.member.user.firstName} {cell.row.member.user.lastName}
      </p>
    );
  }
  
// console.log(membership)

const certifikatiRender = (cell)=>{
  // console.log(cell.row)
  return( 
    <p direction="row" spacing={1}>
      {cell.row.certification}
  </p>
  )
}
const specializationRender = (cell)=>{
    // console.log(cell.row)
    return( 
      <p direction="row" spacing={1}>
        {cell.row.specialization}
    </p>
    )
  }


const editRender = (cell)=>{
  // console.log(cell.row)
  const handleEdit = () => {
    navigate(`/nutricionist/${cell.row.member.user._id}/edit`, { state: cell.row });
  };
return( 
  // <Link data={cell} to={`/trener/${cell?.id}/edit`}>
   <EditIcon
    onClick={handleEdit}
   />
  //  </Link>
)

}

const fotografijaRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row || !cell.row.member.user) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  const handleClick = () => {
    navigate(`/nutricionist/${cell.row.member.user._id}`, { state: cell.row });
  };
  
  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.user.photo}` }
        src={cell.row.member.user.photo} 
        style={{width:"75%"}}
        onClick={handleClick}
      />
    </>
  );
};


const individualProgramsRender = (cell)=>{

  return( 
    <p direction="row" spacing={1}>
      {cell.row?.jelovnici?.length}
  </p>
  )
}

// const groupProgramsRender = (cell)=>{
//     // const nazivGrupe = cell.row.groups.map((e)=>e.name)
//   return( 
//     <p direction="row" spacing={1}>
//       {/* {cell.row.groups.length} */}
//   </p>
//   )
// }
const yearsOfExperienceRender = (cell)=>{

  return( 
    <p direction="row" spacing={1}>
      {cell.row.yearsOfExperience}
  </p>
  )
}

  const columns = [
    { field: 'photo', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'user', headerName: 'Ime i prezime', 
    renderCell: (params) => {
        return userRender(params);
    }
    },
    { field: 'certification', headerName: 'Certifikati', 
    renderCell: (params) => {
        return certifikatiRender(params);
    }
    },
    { field: 'specialization', headerName: 'Specijalizacija', 
    renderCell: (params) => {
        return specializationRender(params);
    }
    },
    { field: 'yearsOfExperience', headerName: 'Godine iskustva', 
    renderCell: (params) => {
        return yearsOfExperienceRender(params);
    }
    },
    { field: 'jelovnici', headerName: 'Broj jelovnika', width: 150, 
    renderCell: (params) => {
        return individualProgramsRender(params);
    }
    },
    // { field: 'groups', headerName: 'Grupni program', width: 150, 
    // renderCell: (params) => {
    //     return groupProgramsRender(params);
    // }
    // },

  
    { field: 'yyy', headerName: 'Uredi',  width: 50,
    renderCell: (params) => {
        return editRender(params);
    }
    },
    { field: 'ccc', headerName: 'Brisi',  width: 50,
    renderCell: (params) => {
      return (
        <p direction="row" spacing={0}>
         {user?.role === "admin" &&  (
          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(params)}
          />
        )}
        </p>
      );
    }
    },
  ];
  // console.log(membership)
  const uloga = "nutritionist";
  return (
    <Box m="20px">
           <ToastContainer />
      <Header
        title="NUTRICIONISTI"
        // subtitle="List of Contacts for Future Reference"
      />
      <Box display="flex" justifyContent="end" mt="20px">
       <Link to={`/users/${uloga}/new`}>
        <Button type="submit" color="secondary" variant="contained">
          Novi Nutritionist
        </Button>
      </Link>
        </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={filteredMembership}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unesite lozinku za potvrdu brisanja računa:
          </DialogContentText>
          {trenerToDelete?.row?.user?.firstName + " " + trenerToDelete?.row?.user?.lastName }
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Šifra"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            // className={classes.blackBorder} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Nutritions;


// import React, {useState, useEffect} from 'react'
// import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import Header from "../../components/Header";
// import {  Link, useNavigate} from 'react-router-dom';
// // import { getAllMembershipByGym } from "../../api/membershipApi";
// // import { StartDateConverting } from '../../components/DateConverting';
// // import AddTaskIcon from '@mui/icons-material/AddTask';
// import EditIcon from '@mui/icons-material/Edit';
// // import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import {TailSpin} from 'react-loader-spinner';

// import { getAllNutricionist } from '../../api/nutricionistApi';
// // import ConfigApp from "../../config/ConfigApp";
// // const imageUrl = `${ConfigApp.URLIMAGE}`;


// const Nutritions = () => {
//   // let gymId = useParams();
//   const navigate = useNavigate();

//   // let newGymId = gymId?.gymID || props?.data?._id;
//   const theme = useTheme();
//   const colors = tokens(theme.palette.mode);
//   const [nutricionist, setNutricionist] = useState([]);
//   const [searchValue, setSearchValue] = useState('');
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     async function fetchData() {
//       try {
//         await getAllNutricionist()
//         .then((response) => {
//             // console.log(response)
//             setNutricionist(response);
//             setLoading(false);
//           });
//         // setNutricionist(fetchedData);
//         // setLoading(false);
//       } catch (error) {
//         console.error(error);
//       }
//     }

//     fetchData();
//   }, []);

// //   console.log(treneri)
//   const handleSearch = (event) => {
//     setSearchValue(event.target.value);
//   };

//   const filteredMembership = nutricionist.filter((member) => {

//     if (member?.user) {
//       return member.user.firstName.toLowerCase().includes(searchValue.toLowerCase());
//     }
//     return false;
//   });

//   const userRender = (cell) => {
//     // console.log(cell.row); // Log cell.row to check its value
//     if (!cell?.row || !cell.row.user) {
//       return <p>No user data available</p>; // Return a placeholder value or an error message
//     }
//     return (
//       <p direction="row" spacing={1}>
//         {cell.row.user.firstName} {cell.row.user.lastName}
//       </p>
//     );
//   }
  
// // console.log(membership)

// const certifikatiRender = (cell)=>{
//   // console.log(cell.row)
//   return( 
//     <p direction="row" spacing={1}>
//       {cell.row.certification}
//   </p>
//   )
// }
// const specializationRender = (cell)=>{
//     // console.log(cell.row)
//     return( 
//       <p direction="row" spacing={1}>
//         {cell.row.specialization}
//     </p>
//     )
//   }
// const editRender = (cell)=>{
//   // console.log(cell.row)
// return( 
//   <Link data={cell} to={`/users/${cell?.id}/edit`}>
//    <EditIcon></EditIcon>
//    </Link>
// )
// }
// const deleteRender = (cell)=>{
// return( 
//   <p direction="row" spacing={0}>
//     <DeleteIcon style={{ color:  'red' }}/>
// </p>
// )
// }

// const fotografijaRender = (cell) => {
//   // console.log(cell.row); // Log cell.row to check its value
//   if (!cell?.row || !cell.row.user) {
//     return null; // Return null or a placeholder value if user data is undefined
//   }

//   const handleClick = () => {
//     navigate(`/nutricionist/${cell.row.user._id}`, { state: cell.row });
//   };
  
//   return (
//     <>
//       <img 
//         alt="Slika nije pronadjena"
//         // src={`${imageUrl}/${cell.row.user.photo}` }
//         src={cell.row.user.photo} 
//         style={{width:"75%"}}
//         onClick={handleClick}
//       />
//     </>
//   );
// };


// // const individualProgramsRender = (cell)=>{
// //     // console.log("individual programs",cell.row)
// //   return( 
// //     <p direction="row" spacing={1}>
// //       {cell.row.individualPrograms?.length}
// //   </p>
// //   )
// // }

// // const groupProgramsRender = (cell)=>{
// //     // const nazivGrupe = cell.row.groups.map((e)=>e.name)
// //   return( 
// //     <p direction="row" spacing={1}>
// //       {cell.row.groups?.length}
// //   </p>
// //   )
// // }
// const yearsOfExperienceRender = (cell)=>{

//   return( 
//     <p direction="row" spacing={1}>
//       {cell.row.yearsOfExperience}
//   </p>
//   )
// }

//   const columns = [
//     // { field: "brojClanske", headerName: "br.clanske", flex: 0.5, width: 50 },
//     // { field: 'brojClanske', headerName: 'br.clanske', flex: 0.5, width: 50,
//     // renderCell: (params) => {
//     //     return clanskaRender(params);
//     // }
//     // },
//     { field: 'photo', headerName: 'Foto', 
//     renderCell: (params) => {
//         return fotografijaRender(params);
//     }
//     },
//     { field: 'user', headerName: 'Trener', 
//     renderCell: (params) => {
//         return userRender(params);
//     }
//     },
//     { field: 'certification', headerName: 'Certifikati', 
//     renderCell: (params) => {
//         return certifikatiRender(params);
//     }
//     },
//     { field: 'specialization', headerName: 'Specijalizacija', 
//     renderCell: (params) => {
//         return specializationRender(params);
//     }
//     },
//     { field: 'yearsOfExperience', headerName: 'Godine iskustva', 
//     renderCell: (params) => {
//         return yearsOfExperienceRender(params);
//     }
//     },
//     { field: 'yyy', headerName: 'Uredi',  width: 50,
//     renderCell: (params) => {
//         return editRender(params);
//     }
//     },
//     { field: 'ccc', headerName: 'Brisi',  width: 50,
//     renderCell: (params) => {
//         return deleteRender(params);
//     }
//     },
//   ];
//   // console.log(membership)
//   const uloga = "nutritionist"
//   return (
//     <Box m="20px">
//       <Header
//         title="NUTRICIONISTI"
//         // subtitle="List of Contacts for Future Reference"
//       />
//       <Box display="flex" justifyContent="end" mt="20px">
//   <Link to={`/users/${uloga}/new`}>
//           <Button type="submit" color="secondary" variant="contained">
//             Novi Nutritionist
//           </Button>
//         </Link>
//         </Box>
//         {loading ? (
//     <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
//         <TailSpin height={80} width={80} />
//         </div>
//       ) : (
//       <Box
//         m="40px 0 0 0"
//         height="70vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .name-column--cell": {
//             color: colors.greenAccent[300],
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: colors.blueAccent[700],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: colors.primary[400],
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: colors.blueAccent[700],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${colors.greenAccent[200]} !important`,
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${colors.grey[100]} !important`,
//           },
//         }}
//       >
//         <TextField
//           id="search"
//           label="Pretraga"
//           variant="outlined"
//           size="small"
//           value={searchValue}
//           onChange={handleSearch}
//           style={{ marginBottom: "10px", width: "250px" }}
//         />
//         <DataGrid
//            pageSize={9}
//            rowsPerPageOptions={[5]}
//            rows={filteredMembership}
//           columns={columns}
//           components={{ Toolbar: GridToolbar }}
//           getRowId={(row) =>row._id}
//         />
//       </Box>
//         )}
//     </Box>
//   );
// };
// export default Nutritions;
