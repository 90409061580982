import React, { PureComponent, useState, useEffect } from "react";
import {
LineChart,
Line,
XAxis,
YAxis,
CartesianGrid,
Tooltip,
Legend,
Brush,
AreaChart,
Area,
ResponsiveContainer,
} from "recharts";
import { getAllPosjeteByGymUserID } from "../api/attendenceApi";
import { getAllGyms } from "../api/gymApi";

const LineFullChart = (props) => {
  // console.log("props", props.data)
const gymLocal = JSON.parse(localStorage.getItem("defaultGym"));
const gymID = gymLocal._id;

const [posjete, setPosjete] = useState("");
const [gymIDs, setGyms] = useState([]);

useEffect(() => {
async function fetchPosjete() {
// Provjerite je li user definiran prije nego što nastavite
if (!props.data) {
return;
}
  try {
    const fetchedUser = await getAllPosjeteByGymUserID(gymID, props.data);
    const allGyms = await getAllGyms();
    setPosjete(fetchedUser);
    setGyms(allGyms);
  } catch (error) {
    console.error(error);
  }
}

fetchPosjete(posjete);
}, [props.data]);

const currentDate = new Date();
const currentMonth = currentDate.getMonth() + 1;
const currentYear = currentDate.getFullYear();

const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();
const posjeteArray = Object.entries(posjete);
const posjetePoDanima = Array(daysInMonth)
  .fill()
  .map((_, index) => {
    const day = index + 1;
    const name = `${day < 10 ? "0" : ""}${day}.${currentMonth}.${currentYear}`;
    const posjeteObj = { name, gymName: gymLocal.name };
    gymIDs.forEach((gym, index) => {
      posjeteObj[`posjeta${index + 1}`] = 0;
    });
    return posjeteObj;
  });


posjeteArray.forEach((posjeta) => {
  const datum = new Date(posjeta[1].timeIn);
  const dan = datum.getDate();
  if (datum.getMonth() + 1 === currentMonth) {
    for (let i = 0; i < gymIDs.length; i++) {
      const gym = gymIDs[i];
      const index = posjetePoDanima.findIndex(
        (el) => el.name === `${dan < 10 ? "0" : ""}${dan}.${currentMonth}.${currentYear}`
      );
      if (index !== -1) {
        if (posjeta[1].gymVisited === gym._id) {
          posjetePoDanima[index][`posjeta${i + 1}`]++;
        } else if (i === 1 && !posjetePoDanima[index].posjeta2) {
          posjetePoDanima[index].posjeta2 = 0;
        }
      }
    }
  }
});

return (

<div style={{ width: "100%" }}>
<ResponsiveContainer width="100%" height={200}>
  <AreaChart
    width={500}
    height={200}
    data={posjetePoDanima}
    syncId="anyId"
    margin={{
      top: 10,
      right: 30,
      left: 0,
      bottom: 0,
    }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis dataKey="name" />
    <YAxis />
    <Tooltip />
    <Legend />
    {gymIDs.map((gym, index) => {
        // console.log(gym)
      return (
        <Area
          type="monotone"
          key={gym._id}
          dataKey={`posjeta${index + 1}`}
          stroke={gym.color}
          fill={gym.color}
          fillOpacity={0.1}
          name={gym.naziv}
        />
      );
    })}
    {/* <Brush dataKey="name" height={30} stroke="#8884d8" /> */}
  </AreaChart>
</ResponsiveContainer>
</div>
);
};
export default LineFullChart;