import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/group`;
// const baseUrl = 'http://localhost:3001/api/group';

export const getGroupByGymId = async (gymID) => {
    const response = await axios.get(`${baseUrl}/gym/${gymID}`);
    return response.data;
  };
  
export const getAllGroups = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getGroupById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createGroup = async (newGroup) => {
  const response = await axios.post(baseUrl, newGroup);
  // console.log(newGroup)
  return response.data;
};

export const updateGroup = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteGroup = async (id,queryParameters) => {
  const response = await axios.delete(`${baseUrl}/${id}`, { params: queryParameters });
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }