import React, { useState, useEffect } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getAllGyms } from "../api/gymApi";

const BarChart2 = (props) => {
  const { data } = props;
  const gymLocal = JSON.parse(localStorage.getItem("defaultGym"));
  // console.log(data)
  // Define state variables for gyms and grouped data
  const [gyms, setGyms] = useState([]);
  const [groupedData, setGroupedData] = useState([]);

  // Use useEffect to fetch the gyms when the component mounts
  useEffect(() => {
    async function fetchGyms() {
      try {
        const gyms = await getAllGyms();
        setGyms(gyms);
      } catch (error) {
        console.error(error);
      }
    }
    fetchGyms();
  }, []);

  // Use useEffect to group the payment data by month and gym when the data prop changes
  useEffect(() => {
    if (data?.user.membership) {
      const currentYear = new Date().getFullYear();
      const newGroupedData = {};
      for (let i = 1; i <= 12; i++) {
        newGroupedData[i] = {
          date: `${i}.${currentYear}`,
          // Initialize the payment amount for each gym to 0
          ...gyms.reduce((acc, gym) => ({
            ...acc,
            [gym._id]: 0
          }), {}),
          // Initialize the total payment amount for the month to 0
          amount: 0
        }
      }
    
      if (data?.user.membership && typeof data.user.membership === 'object') {
       
        const membership = data.user.membership;
        if (data?.payments && Array.isArray(data.payments)) {
      
          for (let i = 0; i < data.payments.length; i++) {
            
            const paymentObj = data.payments[i];
        
            const date = new Date(paymentObj.date);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            
            if (year === currentYear && gyms.find(gym => gym._id === data.user.membership.gym)) {
              newGroupedData[month][data.user.membership.gym] += paymentObj.amount;
              newGroupedData[month].amount += paymentObj.amount;
            }
          }
        }
      }
      
      setGroupedData(Object.values(newGroupedData));
    }
  }, [data, gyms]);
  // console.log(groupedData)
  return (
    <ResponsiveContainer width='100%' height='100%'>
      <BarChart
        width={500}
        height={300}
        data={groupedData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='date' />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="amount" stackId="a" name="uplate"/>
        {/* {gyms && gyms.length > 0 && gyms.map((gym) =><Bar key={gym._id} dataKey="amount" name={gym.naziv} stackId='a' fill={gym.color} />)} */}
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChart2;
