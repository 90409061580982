//////////////////// CONFIG APP

// import Constants from 'expo-constants';
// const baseUrl = 'http://localhost:3001/api/oprema';
// const isStandAloneApp = Constants.appOwnership == "standalone";

const ConfigApp = {

    // backend url (with slash at end)
    // const ip = "192.168.1.132";
    
    // URL:"http://192.168.1.210:8080/api",
    // URLIMAGE:"http://192.168.1.210:8080",
    //      URL:"http://192.168.0.231:8080/api",
    // URL:"http://192.168.0.159:8080/api",
    // URLIMAGE:"http://192.168.0.159:8080",
    // URLIMAGE:"http://192.168.0.163:8080",
    //all4sport
    URL:"https://stayfit-app-a6czv.ondigitalocean.app/api",
    URLIMAGE:"https://stayfit-app-a6czv.ondigitalocean.app",

    // URL:"https://gym-elita-app-o44uu.ondigitalocean.app/api",
    // URLIMAGE:"https://gym-elita-app-o44uu.ondigitalocean.app",
    
      //StayFit
    //  URL:"https://demo-app-9c5zi.ondigitalocean.app/api",
    //  URLIMAGE:"https://demo-app-9c5zi.ondigitalocean.app",
    
    // DEFAULTLANG: "en",
    // THEMEMODE: "light", // light or dark

    // // testdevice id, DON'T CHANGE IT
    // TESTDEVICE_ID : isStandAloneApp ? "EMULATOR" : "EMULATOR"

};

export default ConfigApp;