import React, {useState,useEffect} from 'react'
import { useParams } from 'react-router-dom';
import {getAllMembershipNotesById} from '../api/membershipApi'
import {deleteOneNote} from '../api/noteApi'
import { StartDateConverting } from './DateConverting';
import DeleteIcon from '@mui/icons-material/Delete';
// import axios from 'axios';

const MembershipNotes =  () => {

    const { id } = useParams();
    const [data, setData] = useState([])
    const [response, setResponse] = useState("")

    console.log("data",data);

    useEffect(() => {
   
         //fatch paket
    async function fetcheNote () {
        try {
          const fetchedNote = await getAllMembershipNotesById(id);
          setData(fetchedNote);
        //   console.log(fetchedNote)
        } catch (error) {
          console.error(error);
        }
      }
      fetcheNote()
}, []);

  const  deleteNote = async (id) => {
    try {
        // await axios.delete(`http://localhost:3001/api/note/${id}`);
        const res =  await deleteOneNote(id)
        setResponse(res)
        console.log(res)
    //   axios.delete(`/api/notes/${id}`);
        const resId = res.message.split("!")
        console.log(resId[1])
        const x = data.notes.filter((note) => note._id !== resId[1])
        // console.log(x)
      setData(x);

    } catch (error) {
      console.error(error);
    }
  };
    return(
        <>
          {`Clan: ${data?.user?.firstName} ${data?.user?.lastName} Paket: ${data?.plan?.nazivPaketa}`}
          {response.message}
            {data?.notes?.map((e)=>{
                return (
                    <>
                   
                        <ol>
                            <li>
                                <p>Napomena: {e?.note} <StartDateConverting date={e?.createdAt}/> 
                                <button 
                                        onClick={() => deleteNote(e._id)}>
                                    <DeleteIcon style={{ color:  'red' }}/>
                                    </button></p>
                            </li>
                        </ol>
                   
                    </>
                )
            })}
          
        </>
    )
}

export default MembershipNotes;