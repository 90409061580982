import React, { useState, useEffect,useContext } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';

import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
    // Container,
    // Typography,
    // Card,
    // CardContent,
    Avatar,
    // Divider,
    IconButton, Box, Button, TextField, Modal, Zoom, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import axios from 'axios';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
// import ConfigApp from '../../config/ConfigApp';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import { getUserById, updateUser } from '../../api/userApi';
import { UserContext } from '../../context/UserProvider'; 
// import { GymContext } from "../../theme";
import { getPaket } from "../../api/paketApi";
import { createUser } from '../../api/userApi';
// import { createPayment } from '../../api/paymentApi';
// import { createMember } from "../../api/memberApi";
// const baseUrl = `${ConfigApp.URL}`;


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
    marginBottom:'100px'
  },
  inField: {
    backgroundColor: "#ffffff14"
  }
}));

const NewUserForm = () => {
  const classes = useStyles();
  const { uloga } = useParams();
// console.log("uloga", uloga)
  const { user} = useContext(UserContext);
  // const uloga = "user";
  // console.log("uloga", uloga)
  // const { defaultGym  } = useContext(GymContext);
  const defaultGym = JSON.parse(localStorage.getItem('defaultGym'));
  // const gymID = defaultGym?._id;
   const userRole = user?.role;

// console.log("uloga", user?.role)
  const [clan, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    address:"",
    photo: "",
    phone: "",
  });

  // console.log(clan)
  // const [loading, setLoading] = useState(true);
  const [weight, setWeight] = useState(0);
  const [height, setHeight] = useState(0);
  const [gender, setGender] = useState();
  const [dateOfBirth, setDateOfBirth] = useState();
  const [selectedImage, setSelectedImage] = useState(); // Initialize with the existing user's photo
  const [pendingImage, setPendingImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [paketi, setPaketi] = useState([]);
  const [paket, setPaket] = useState("");
  const [placanje, setPlacanje] = useState("");
  const [date, setDate] = useState(new Date())
  const [role, setRole] = useState("");
  const [brojMjeseciPaket, setBrojMjeseciPaket] = useState(1);
  // console.log(gender)
  const [price, setPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [popust, setPopust] = useState(0);
  const [popustKM, setPopustKM] = useState(0)
// console.log(role)
  // const isTrainerSelected = role === "trainer";
  // const uposlenik = user.firstName + " " + user.lastName;
  const uposlenik = user._id;

  const [specialization, setSpecialization] = useState("");
  const [certification, setCertification] = useState("");
  const [yearsOfExperience, setYearsOfExperience] = useState("");
 
  const handleSpecializationChange = (event) => {
    setSpecialization(event.target.value);
  };
  const handleCertificationChange = (event) => {
    setCertification(event.target.value);
  };
  const handleYearsOfExperienceChange = (event) => {
    setYearsOfExperience(event.target.value);
  };

// console.log("paket",paket)
  // Funkcija za konverziju datuma u format "YYYY-MM-DD"
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Dodajte nule ispred meseca ako je potrebno
    const day = String(date.getDate()).padStart(2, '0'); // Dodajte nule ispred dana ako je potrebno
    return `${year}-${month}-${day}`;
  };

  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      setSelectedImage(imageFile);
      setPendingImage("1");
    }
  };

  useEffect(() => {
    async function handleGyms() {
      // Calculate the price and discounted price
  
      // Pronađite odgovarajući popust na temelju broja mjeseci
      const popust = paket?.popusti?.find(
        (popust) =>
          brojMjeseciPaket >= popust.raspon.minMjeseci &&
          brojMjeseciPaket <= popust.raspon.maxMjeseci
      );
      // console.log(popust)
      const cijneas = brojMjeseciPaket * paket?.cijena;
      const popusts = popust?.postotak ? (brojMjeseciPaket * paket?.cijena) * (popust?.postotak / 100) : 0;
      const CijenaSaPopustom = popust?.postotak ? (brojMjeseciPaket * paket?.cijena) - (brojMjeseciPaket * paket?.cijena) * (popust?.postotak / 100) : 0;
  
      setPrice(cijneas);
      setPopust(popust?.postotak || 0);
      setDiscountedPrice(CijenaSaPopustom);
      setPopustKM(popusts);
    }
    handleGyms();
  }, [brojMjeseciPaket, paket?.cijena, paket?.popusti]);

    useEffect(() => {
    //fatch paket
    async function fetchPaket() {
      try {
        const fetchedUPaket = await getPaket({});
        setPaketi(fetchedUPaket);
      } catch (error) {
        console.error(error);
      }
    }

    fetchPaket()
  }, []);

  // console.log(role)
  useEffect(() => {

    if(uloga === "trener"){
        setRole("trainer");
        setPlacanje("gotovina");
    }if(uloga === "nutritionist"){
      setRole("nutritionist");
      setPlacanje("gotovina");
    }
    
  }, []);

  const handleChangePaket = (event) => {
    setPaket(event.target.value);
  };

  const handleUloga = (event) => {
    setRole(event.target.value);
  };
  const handleBrojMjeseci = (event) => {
    // console.log(paket)
    setBrojMjeseciPaket(event.target.value);
  };

    const handleNacinPlacanja = (event) => {
    setPlacanje(event.target.value)
    if(event.target.value == "odgodjeno"){
      setDate("");
    }
  };

  const setujPrazneVrijednosti = ()=>{
    // Postavljanje svih vrednosti na prazne vrednosti
    setUser({
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      photo: "",
      phone: "",
      });
      setWeight(0);
      setHeight(0);
      setGender(undefined);
      setDateOfBirth(undefined);
      setSelectedImage(undefined);
      setPendingImage(null);
      setShowPassword(false);
      setPassword('');
      setPaketi([]);
      setPaket("");
      setPlacanje("");
      setDate(new Date());
      setRole("");
      setBrojMjeseciPaket(1);
      setPrice(0);
      setDiscountedPrice(0);
      setPopust(0);
      setPopustKM(0);
        }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append('firstName', clan.firstName);
    formData.append('lastName', clan.lastName);
    formData.append('email', clan.email);
    // formData.append('contact', clan.contact);
    formData.append('address', clan.address);
    formData.append('password', password);
    // formData.append('photo', user.photo);
    formData.append('gymId', defaultGym._id);
    formData.append('plan', paket._id);
    formData.append('gender', gender);
    formData.append('role', role);
    formData.append('image', selectedImage);
    formData.append('phone', clan.phone);
    
   //member data
   formData.append('weight', weight);
   formData.append('height', height);
   formData.append('date_of_birth', dateOfBirth);

   //payment data
   formData.append('popustProcenat', popust);
   formData.append('trajanjePaketa', brojMjeseciPaket);
   formData.append('placanje', placanje);
   formData.append('popust', popust);
   formData.append('uposlenik', uposlenik);
   formData.append('uplata', discountedPrice === 0 ? brojMjeseciPaket * paket.cijena : discountedPrice);

   if (uloga ==="trener" || "nutritionist") {
    formData.append('specialization', specialization);
    formData.append('certification', certification);
    formData.append('yearsOfExperience', yearsOfExperience);
  }
    try {
  
      const createdUser = await createUser(formData);
                // console.log(createdUser)

      toast.success('User information updated successfully.');
      setujPrazneVrijednosti()
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while creating user information.');
    }
  };
// console.log(data)
  return (
    <Grid container justify="center" style={{ height: '100vh' }}>
      {/* {loading ? (
        <div>Loading...</div>
      ) : ( */}
        <Grid item xs={6}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Header title={`Novi ${uloga}`} subtitle="" />
            <ToastContainer />
            {typeof selectedImage === "string" ? (
            <Avatar
                alt={clan.firstName}
                src={selectedImage}
                className={classes.avatar}
                sx={{ width: 200, height: 200 }}
            />
            ) : (
            <Avatar
                alt={clan.firstName}
                src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
                className={classes.avatar}
                sx={{ width: 200, height: 200 }}
            />
            )}

            <label htmlFor="upload-photo">
              <input
                type="file"
                id="upload-photo"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <IconButton component="span">
                <AddPhotoAlternateIcon style={{ color: 'white' }} />
              </IconButton>
            </label>
            <TextField
              className={classes.inField}
              label="Ime"
              value={clan.firstName}
              onChange={(event) => setUser({ ...clan, firstName: event.target.value })}
              variant="outlined"
              required
            />
            <TextField
              label="Prezime"
              className={classes.inField}
              value={clan.lastName}
              onChange={(event) => setUser({ ...clan, lastName: event.target.value })}
              variant="outlined"
              required
            />
            <TextField
              className={classes.inField}
              label="Email"
              type="email"
              value={clan.email}
              onChange={(event) => setUser({ ...clan, email: event.target.value })}
              variant="outlined"
              required
            />
            {/* <TextField
              label="Contact"
              className={classes.inField}
              value={user.contact}
              onChange={(event) => setUser({ ...user, contact: event.target.value })}
              variant="outlined"
              required
            /> */}
            <TextField
              label="Adresa"
              className={classes.inField}
              value={clan.address}
              onChange={(event) => setUser({ ...clan, address: event.target.value })}
              variant="outlined"
            />
            {/* <TextField
              label="Password"
              className={classes.inField}
              type="password"
              value={user.password}
              onChange={(event) => setUser({ ...user, password: event.target.value })}
              variant="outlined"
            /> */}
                  <TextField
            label="Password"
            className={classes.inField}
            type={showPassword ? 'text' : 'password'}
            value={password || ''}
            onChange={(event) => setPassword(event.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
           <TextField
            className={classes.inField}
            label="Datum rodjenja"
            type="date"
            value={formatDate(dateOfBirth)} // Formatirajte datum pre prikazivanja
            InputLabelProps={{
            shrink: true,
            }}
            onChange={(event) => setDateOfBirth(event.target.value)}
            variant="outlined"
        />

            <FormControl component="fieldset">
              <FormLabel component="legend">Spol</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                style={{color:"white"}}
              >
                <FormControlLabel value="female" control={<Radio style={{color:"white"}}/>} label="Ženski" />
                <FormControlLabel value="male" control={<Radio style={{color:"white"}}/>} label="Muški" />
                {/* <FormControlLabel value="other" control={<Radio style={{color:"white"}}/>} label="Other" /> */}
              </RadioGroup>
            </FormControl>
            <TextField
                className={classes.inField}
                label="Težina"
                type="number" // Promijenite type u "number"
                value={weight}
                onChange={(event) => setWeight( event.target.value )}
                variant="outlined"
                />

            <TextField
                className={classes.inField}
                label="Visina"
                type="number" // Promijenite type u "number"
                value={height}
                onChange={(event) => setHeight(event.target.value)}
                variant="outlined"
                />

            <TextField
              className={classes.inField}
              label="Telefon"
              type="text"
              value={clan.phone}
              onChange={(event) => setUser({ ...clan, phone: event.target.value })}
              variant="outlined"
            />
              <InputLabel id="demo-simple-select-label">Odaberi paket</InputLabel>  
                 
                 <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={paket}
                    label="Paket"
                    onChange={handleChangePaket}
                >
                  {paketi?.map((e, key)=>{
                    return <MenuItem key={key} value={e}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
                  })
                  }
                </Select>
                <Box>   
                <InputLabel id="demo-simple-select-label" style={{color:"white"}}>Broj mjeseci</InputLabel>
                <TextField
                  style={{ backgroundColor: 'white', width: '100%' }}
                  inputStyle={{ color: 'black' }}
                  id="outlined-multiline-flexible-napomena"
                  type="number"
                  label="Broj mjeseci"
                  multiline
                  value={brojMjeseciPaket}
                  onChange={handleBrojMjeseci}
                />
                   </Box>
                {/* { uloga !=='trener' || uloga !=='nutritionist' && ( */}
                  <>
              <p>--Iznos--</p>
    
                    <p>Cijena bez popusta: {price}</p>
                    <p>Popust: {popust}</p>
                    <p>Iznos popusta: {popust}</p>
                    <p>Članska kartica: {popustKM}</p>
                    {/* <p>Trenutni paket: {member.rfid}</p> */}
                    <p>Iznos sa popusom: {discountedPrice}</p>
         
                <InputLabel id="demo-simple-select-label">Nacin placanja</InputLabel>
                 <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={placanje}
                    label="Paket"
                    onChange={handleNacinPlacanja}
                >
                  <MenuItem value={"gotovina"}>Gotovina</MenuItem>
                  <MenuItem value={"kartica"}>Karica</MenuItem>
                  <MenuItem value={"odgodjeno"}>Odgodjeno</MenuItem>
                </Select>
           
                {userRole === 'admin' ? 
                <FormControl>
                <InputLabel id="demo-simple-select-label">Odaberi ulogu</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Uloga"
                  onChange={handleUloga}
                >
                  
                  <MenuItem value={"superAdmin"}>Super Administrator</MenuItem>
                  <MenuItem value={"admin"}>Administrator</MenuItem>
                  <MenuItem value={"editor"}>Editor</MenuItem>
                  <MenuItem value={"member"}>Član</MenuItem>
                  <MenuItem value={"employee"}>Uposlenik</MenuItem>
                  {/* <MenuItem value={"trainer"}>Trener</MenuItem>
                  <MenuItem value={"doctor"}>Doktor</MenuItem>
                  <MenuItem value={"nutritionist"}>Nutritionist</MenuItem> */}
                </Select>
              </FormControl>
            :
            <FormControl>
                <InputLabel id="demo-simple-select-label">Odaberi ulogu</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={role}
                  label="Uloga"
                  onChange={handleUloga}
                >
                  <MenuItem value={"member"}>Član</MenuItem>
                  <MenuItem value={"employee"}>Uposlenik</MenuItem>
                  
                </Select>
              </FormControl>
          }
               </>
               {/* )} */}
          { uloga ==='trener' || uloga ==='nutritionist' ? (
          <>
            <TextField
              label="Specializacija"
              name="specialization"
              value={specialization}
              onChange={handleSpecializationChange}
              variant="outlined"
            />
            <TextField
              label="Certifikati"
              name="certification"
              value={certification}
              onChange={handleCertificationChange}
              variant="outlined"
            />
            <TextField
              label="Godine iskustva"
              name="yearsOfExperience"
              type="number"
              value={yearsOfExperience}
              onChange={handleYearsOfExperienceChange}
              variant="outlined"
            />
          </>
        ): ""}
            <Button type="submit" variant="contained" color="secondary">
              Kreiraj {uloga}
            </Button>
          </form>
        </Grid>
      {/* )} */}
    </Grid>
  );
};

export default NewUserForm;
