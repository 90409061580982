import React, {useState} from 'react'

export const StartDateConverting = (props) => {
    
    const dateOld = props.date;
    const start = new Date(dateOld);
    // const date =  start.toLocaleString('en-GB', { timeZone: 'UTC' });
    const dateStr = '2023-4-3 1:28:07';
    // const start = new Date(dateStr);
    const formattedDate = start.toLocaleDateString('sr-BA');
    return formattedDate
}

export const EndDateConverting = (props) => {
    const dateOld = props.date;
    const end = new Date(dateOld);
    // const date =  end.toLocaleString('en-GB', { timeZone: 'UTC' });
    // const date = end.toLocaleString('bs-BA', { timeZone: 'Europe/Sarajevo' });
    const dateStr = '2023-4-3 1:28:07';
    // const date = new Date(dateStr);
    const formattedDate = end.toLocaleDateString('sr-BA');
    return formattedDate
}

export const EndDateConvertingWithTime = (props) => {
    const dateOld = props.date;
    const end = new Date(dateOld);

    // Format the date and time according to the desired locale and time zone
    const formattedDateTime = end.toLocaleString('sr-BA', {
        timeZone: 'Europe/Sarajevo', // Specify the time zone
        hour12: false, // Use 24-hour format
    });

    return formattedDateTime;
};

export const StartDateConvertingWithTime = (props) => {
    const dateOld = props.date;
    const start = new Date(dateOld);

    // Format the date and time according to the desired locale and time zone
    const formattedDateTime = start.toLocaleString('sr-BA', {
        timeZone: 'Europe/Sarajevo', // Specify the time zone
        hour12: false, // Use 24-hour format
    });

    return formattedDateTime;
};