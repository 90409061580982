import React,{useEffect, useState} from "react";
import StatBox from "../../components/StatBox";


const BrojPosjetaDanas = ({posjeteData}) =>{

    // const posjete = posjeteData;

    // Dobijte današnji datum u formatu 'YYYY-MM-DD'
    const danasnjiDatum = new Date().toISOString().split('T')[0];
    
    // Filtrirajte uplate kako biste zadržali samo one s datumom koji odgovara današnjem datumu
    const posjeteDanas = posjeteData.filter((posjeta) => posjeta.timeIn.split('T')[0] === danasnjiDatum);
    
    
    return(
        <>
         <StatBox
           title={`${posjeteDanas.length}`}
            subtitle="Posjete danas"
            // subtitle="Broj aktivnih članova"
            progress="a"
          />
        
        </>
    )
}

export default BrojPosjetaDanas;

