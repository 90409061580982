// 1. Napravite formu koja će omogućiti korisniku da unese novi datum isteka pretplate.
// 2. Napravite PUT zahtjev prema API-ju kako biste ažurirali postojeći zapis pretplate u bazi podataka.
// 3. Kada se zahtjev uspješno izvrši, ažurirajte lokalni state komponente s novim datumom isteka pretplate.
// 4. Ažurirajte korisničko sučelje da prikaže novi datum isteka pretplate.
import React, {useState, useEffect} from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, TextField } from "@mui/material";
// import { Formik } from "formik";
// import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from "../components/Header";
import { updatePayment } from '../api/paymentApi';
import { createPayment } from '../api/paymentApi';
// import { getPaket } from "../api/paketApi";
import { useParams } from 'react-router-dom';
import { getUserById } from "../api/userApi";
import { Typography, Grid } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import { EndDateConverting, StartDateConverting } from './DateConverting';
import QuizIcon from '@mui/icons-material/Quiz';
import './membership.css'

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(4),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
    card: {
      width: '80%',
      marginTop: theme.spacing(2),
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }));

const PaymentsEdit = () => {
    const classes = useStyles();
  const { id } = useParams();
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [response, setResponse] = useState("");
  // const [paketi, setPaketi] = useState([]);
  const [paket, setPaket] = useState("");
  const [gym, setGym] = useState("");
  const [user, setUser] = useState("");
  const [placanje, setPlacanje] = useState("");
  const [napomena, setNapomena] = useState("");
  // cell?.row?.payments[cell?.row?.payments.length - 1]?.paymentMethod
// console.log(user?.payments[user?.payments?.length - 1])

  let y = user?.payments
  if (user?.payments?.length == 0){
    y = user?.payments[0]
    console.log("ok")
  } else if (user?.payments?.length > 0){
    console.log("nije ok")
    y = user?.payments[user?.payments?.length - 1]
    // y = user?.payments[0]?.paymentMethod
    
  }
  
  // user?.payments?.length == 0 ? user.payments[0] : user.payments[user?.payments?.length - 1]
   console.log(y)
  const handleChangePaket = (event) => {
    // console.log(paket)
    setPaket(event.target.value);
  };
  const handleChangePlacanje = (event) => {
    // console.log(paket)
    setPlacanje(event.target.value);
  };
  const handleNapomena = (event) => {
    // console.log(paket)
    setNapomena(event.target.value);
  };

  useEffect(() => {
    //fatch user
    async function fetchUser() {
        try {
          const fetchedUser = await getUserById(id);
          setUser(fetchedUser);
        } catch (error) {
          console.error(error);
        }
      }
      fetchUser()
       //fatch paket
   //fatch paket
  //  async function fetchPaket() {
  //   try {
  //     const fetchedUPaket = await getPaket({});
  //     setPaketi(fetchedUPaket);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // fetchPaket()

    //fetch gymIf from local storage
    const savedGym = localStorage.getItem('defaultGym');
    // console.log(savedGym)
    if (savedGym) {
      //  console.log(savedGym)
      setGym(JSON.parse(savedGym));
    }

  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
// console.log(values)
    // const {address1,contact,email,firstName,lastName,password,photo} = values;
    // const membershipId = user?.membership?._id
    // const newPaket = {
    //   paket: paket,
    //   gym: gym,
    //   napomena: napomena, //clanarinu
    //   placanje: placanje //ide na placanje
    // };
    try {
      const payment = {
        // user: user._id,
        // membership: membershipId,
        // gym: gym,
        // amount: paket.cijena,
        paymentMethod: placanje,
        date: new Date()
        // description: "Opis transakcije",
        // Dodajte ovdje ostale potrebne vrijednosti za stvaranje transakcije
    }
    const updatedPayment = await updatePayment(y._id, payment);
          if(updatedPayment){
              // alert("Uspješno stvorena transakcija");
              alert("Uspjesno se izvrsili naknadno placanje");
          }

    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box m="20px">
      <Header title={`Odgodjeno placnje za: ${user?.firstName +" "+ user?.lastName}`} 
      // subtitle="Create a New User Profile" 
      />
      {/* {response} */}
     
            <form onSubmit={handleSubmit}>
            <Box
              display="grid"
            //   gap="30px"
            //   gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            //   sx={{
            //     "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            //   }}
            >
        <Card className={classes.card} sx={{ display: 'flex' }}>
        <Tooltip title="
        Updatuje se clan, status, poslovnica i trajanje paketa. 
        Poslovnica zavisi od korisnika koji ce produziti paket korisniku.
        Cijena paketa je cijan clanarine i prikazate ce se u izvjestajima.
        ">
          <QuizIcon alignItems="flex-end"/>
        </Tooltip>
        <Grid item xs={3} style={{float:"left", minWidth: "15%"}}>   
            <Typography>{user?.firstName} {user?.lastName}</Typography>
            <Typography>Informacije o članstvu</Typography>
            <Typography>Tip članarine: {user?.membership?.plan?.nazivPaketa}</Typography>
            <Typography>Početak članarine: <StartDateConverting date={user?.membership?.startDate}/> </Typography>
            <Typography>Kraj članarine: <EndDateConverting date={user?.membership?.endDate}/></Typography>
            <Typography>Status Clanarine: {user?.membership?.status}</Typography>
            <Typography>Informacije o placanju</Typography>
            <Typography>Datum kreiranja racuna/clanarine: <StartDateConverting date={y?.createdAt}/></Typography>
            <Typography>Status uplate: {y?.date == null ? "nije placeno" : "placeno"}</Typography>
        </Grid>
     
        </Card>
        {/* <Card className={classes.card} sx={{ display: 'flex' }}>
        <InputLabel id="demo-simple-select-label" style={{color:"black"}}>Odaberi paket</InputLabel>
        <Select
        style={{minWidth: "200px",border: "1px solid #000", color:"black"}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paket}
            label="Paket"
            onChange={handleChangePaket}
            >
            {paketi?.map((e, key)=>{
            return <MenuItem key={key} value={e}>{e?.nazivPaketa} -- {e?.cijena}</MenuItem>
            })
             }
        </Select>
        </Card> */}
        <Card className={classes.card} sx={{ display: 'flex' }}>
        <InputLabel id="demo-simple-select-label" style={{color:"black"}}>Nacin placanja</InputLabel>
        <Select
        style={{minWidth: "200px",border: "1px solid #000", color:"black"}}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={placanje}
            label="Placanje"
            onChange={handleChangePlacanje}
            >

            <MenuItem value={"gotovina"}>Gotovina</MenuItem>
            <MenuItem value={"kartica"}>Karica</MenuItem>
            {/* <MenuItem value={"odgodjeno"}>Odgodjeno</MenuItem> */}
            
        </Select>
        
        </Card>
            </Box>
            {/* <Box>
            <Card className={classes.card} sx={{ display: 'flex' }} style={{color:"black"}}>
            <InputLabel id="demo-simple-select-label" style={{color:"black"}}>Napomena</InputLabel>
            <TextField
                id="outlined-multiline-flexible-napomena"
                label="Multiline"
                multiline
                maxRows={6}
                onChange={handleNapomena}
        />
            </Card>
            </Box> */}
            <Card className={classes.card} sx={{ display: 'flex' }} style={{backgroundColor:'transparent'}}>
            <Box display="flex" justifyContent="end" mt="20px">
              <Button type="submit" color="secondary" variant="contained">
               Spasi
              </Button>
            </Box>
            </Card>
          </form>
    </Box>
  );
};



export default PaymentsEdit;
