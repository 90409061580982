import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField,InputLabel,Select,MenuItem } from "@mui/material";
import { FormControl } from '@material-ui/core';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DatePicker from "react-datepicker";
// import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link,useNavigate } from 'react-router-dom';
import { getAllGuestPosjeteByGymByRange, getAllGuestVisitsByRange } from "../../api/attendenceApi";
import StartDateAndTimeConverting from '../../components/StartDateAndTimeConverting';
import { getAllGyms } from '../../api/gymApi';
import { getSettings } from '../../api/getSettingsApi';
import * as XLSX from 'xlsx';
import axios from 'axios';


const PosjeteIzDrguihTeretana = () => {
  let gymId  = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const colors = tokens(theme.palette.mode);
  // const currentYear = new Date().getFullYear();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate());
  yesterday.setHours(0, 0, 1, 0); // Postavi na 00:01
  
  const today = new Date();
  today.setHours(23, 59, 59, 999); // Postavi na 23:59:59.999
  
  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(today);
  
    // const [gyms, setGyms] = useState([]);
    const [settingsGym, setSettingsGym] = useState([]);
    const [posjete, setPosjete] = useState([]);
    // const [gymsCombined, setGymsCombined] = useState([]);
    const [selectedGym, setSelectedGym] = useState(null);
    // const gost = true;
  // Pretpostavka: Dobili ste JWT token od gostujuće teretane
//  const guestToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDQ1OTZkODFmMTJkOTE5YjNkNjNjNzUiLCJlbWFpbCI6ImFAYS5hIiwicm9sZSI6Im1lbWJlciIsImlhdCI6MTY5MDIzNDUwNH0.jTl-_7pfoh7WploGywUuhxZ5n3qw5itfTyY7pw-Ba5Y';
  const guestToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDQ1OTZkODFmMTJkOTE5YjNkNjNjNzUiLCJlbWFpbCI6ImFAYS5hIiwicm9sZSI6Im1lbWJlciIsImlhdCI6MTY5MDIzNDUwNH0.jTl-_7pfoh7WploGywUuhxZ5n3qw5itfTyY7pw-Ba5Y';
  // Postavite URL gostujuće teretane
 const baseUrl = 'https://stayfit-app-a6czv.ondigitalocean.app';
    // const baseUrl = 'http://localhost:8080';
//   // ID korisnika koji želite dohvatiti
//   const userGuestId = '64ed835e55761ed4077209dc';
  
  // Postavite zaglavlje zahtjeva s JWT tokenom
  const headers = {
    Authorization: `Bearer ${guestToken}`
  };

      console.log(posjete)
  useEffect(() => {
    // Fetch posjete and other data here
  
    async function fetchData() {
      try {
        // Fetch posjete data
        const fetchedPosjete = await getAllGuestVisitsByRange({ startDate, endDate });
        console.log(fetchedPosjete)
        // ... (fetch other data as needed)
  
        // Calculate uniqueUserIDs
        const uniqueUserIDs = [...new Set(fetchedPosjete?.map(posjeta => posjeta.user))];
  
        // Create an array for storing member information
        const clanoviInfo = [];
  
        // Function to get member information by user ID
        async function getClanInfo(userID) {
         
          try {
            
//            const response = await axios.get(`${baseUrl}/api/guest/gosti/user/${userID}`, { headers });
            const response = await axios.get(`${baseUrl}/api/guests/gosti/user/${userID}`);
//            console.log(response.data)
            return response.data;
          } catch (error) {
            console.error(`Greška prilikom dobivanja informacija o članu za userID: ${userID}`, error);
            return null;
          }
        }
  
        // Iterate through uniqueUserIDs and get member information
        for (const userID of uniqueUserIDs) {
          const clanInfo = await getClanInfo(userID);
          if (clanInfo) {
            clanoviInfo.push(clanInfo);
          }
        }
  
        // Process posjete with member information
        const updatedPosjetes = fetchedPosjete.map((posjeta) => {
          const clan = clanoviInfo.find((clan) => clan._id == posjeta.user);
          if (clan) {
            return {
              ...posjeta,
              user: clan,
            };
          }
          return posjeta;
        });
  
        // Now, you can set the state with the updatedPosjetes and other data if needed
        setPosjete(updatedPosjetes);
        // ... (set other state variables)
  
      } catch (error) {
        console.error(error);
      }
    }
  
    fetchData(); // Call the fetchData function
  }, [startDate, endDate, baseUrl]);
  

// Mapirajmo članove po njihovim ID-ovima radi brže pretrage
// Kreirajte novi niz posjeta s nadograđenim korisničkim informacijama


const updatedPosjete = posjete
.filter(posjeta => settingsGym.some(gym => gym.guestGymId === posjeta.gymVisited))
.map(posjeta => {
    const matchingGym = settingsGym.find(gym => gym.guestGymId === posjeta.gymVisited);
    return {
        ...posjeta,
        naziv: matchingGym ? matchingGym.guestNameGym : null
    };
});
const handleExportToExcel = () => {
  const exportData = updatedPosjete.map((item) => ({
    clan: item.user.firstName + " "+ item.user.lastName,
    prijava: item.timeIn,
    odjava: item.timeOut,
    poslovnica: item.naziv,
    // iznos: `${item.iznos} KM`,
  }));

  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Posjete');

  // Export the workbook to an Excel file
  XLSX.writeFile(workbook, 'posjete-gost.xlsx');
};


  const userRender = (cell)=>{
    return( 

      <p direction="row" spacing={1}>
        {/* {console.log(cell.row)} */}
        {cell.row.user.firstName +" "+cell.row.user.lastName}
    </p>
    )
  }
  const poslovnicaRender = (cell)=>{
    return( 
      <p direction="row" spacing={1}>
        {cell.row.user.default_gym}
    </p>
    )
  }
  const statusRender = (cell)=>{
    return( 
      <p direction="row" spacing={1}>
        {cell.row.timeOut !== null ? "Odjavljen" : "Prijavljen"}
    </p>
    )
  }
  const fotografijaRender = (cell) => {

    if (!cell?.row || !cell.row.user) {
      return null; // Return null or a placeholder value if user data is undefined
    }
  
    const handleClick = () => {
      navigate(`/users/${cell.row.user._id}`, { state: cell.row });
    };
    
    return (
      <>
        <img 
          alt="Slika nije pronadjena"
          // src={`${imageUrl}/${cell.row.user.photo}` }
          src={cell.row.user.photo} 
          style={{width:"75%"}}
          onClick={handleClick}
        />
      </>
    );
  };
 const vrijemePrijave = (cell)=>{
  
 return <StartDateAndTimeConverting datetime={cell.row?.timeIn}/>
  }
  const vrijemeOdjave = (cell)=>{
  
    return <StartDateAndTimeConverting datetime={cell.row?.timeOut}/>
     }
  
  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    { field: 'photo', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'user', headerName: 'Clan', 
    renderCell: (params) => {
        return userRender(params);
    }
    },
    { field: 'gymVisited', headerName: 'Poslovnica', 
    renderCell: (params) => {
        return poslovnicaRender(params);
    }
    },
    
    { field: 'timeIn', headerName: 'Vrijeme prijave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemePrijave(params);
    }
    },
  
    { field: 'timeOut', headerName: 'Vrijeme odjave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemeOdjave(params);
    }
    },
    { field: 'status', headerName: 'Status', 
    renderCell: (params) => {
        return statusRender(params);
    }
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="POSJETE IZ DRUGIH TERETANA"
        // subtitle="List of Contacts for Future Reference"
      />
     
      <Box display="flex" justifyContent="end" mt="20px">
        {/* <Link to="/check-in/new"> */}
        <Button onClick={handleExportToExcel} variant="contained" color="secondary">
      Izvezi u Excel
    </Button>
        {/* </Link> */}
            </Box>
            {/* <Box marginTop="50px" marginRight="10px">
    <Button onClick={handleExportToExcel} variant="contained" color="secondary">
      Izvezi u Excel
    </Button>
  </Box> */}
            <FormControl  sx={{minWidth:"200px" }}>
            <Box display="flex" mt="20px">
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        style={{ display: "inline-block", margin: "0 auto" }}
        />
                  <Box display="flex" justifyContent="end" mt="0px">
        <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedGym}
          onChange={(event) => setSelectedGym(event.target.value)}
        >
          {settingsGym?.map((e, key) => (
            <MenuItem key={key} value={e.guestGymId}>
              {e.guestNameGym}
            </MenuItem>
          ))}
        </Select>
        {/* <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          // value={gym}
          // onChange={handleChangeGym}
        >
          {gymsCombined?.map((e, key) => (
            <MenuItem key={key} value={e}>
              {e.naziv}
            </MenuItem>
          ))}
        </Select> */}
        
      </Box>
          </Box>

      </FormControl>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={posjete}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default PosjeteIzDrguihTeretana;


// import React, {useState, useEffect} from 'react'
// import { Box, Typography, useTheme, Button, TextField,InputLabel,Select,MenuItem } from "@mui/material";
// import { FormControl } from '@material-ui/core';
// import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import { tokens } from "../../theme";
// import DatePicker from "react-datepicker";
// // import { mockDataContacts } from "../../data/mockData";
// import Header from "../../components/Header";
// import { Routes, Route, useParams, Link,useNavigate } from 'react-router-dom';
// import { getAllGuestPosjeteByGymByRange, getAllGuestVisitsByRange } from "../../api/attendenceApi";
// import StartDateAndTimeConverting from '../../components/StartDateAndTimeConverting';
// import { getAllGyms } from '../../api/gymApi';
// import { getSettings } from '../../api/getSettingsApi';
// import * as XLSX from 'xlsx';
// import axios from 'axios';


// const PosjeteIzDrguihTeretana = () => {
//   let gymId  = useParams();
//   const theme = useTheme();
//   const navigate = useNavigate();
//   const colors = tokens(theme.palette.mode);
//   // const currentYear = new Date().getFullYear();
//   const yesterday = new Date();
//   yesterday.setDate(yesterday.getDate());
//   yesterday.setHours(0, 0, 1, 0); // Postavi na 00:01
  
//   const today = new Date();
//   today.setHours(23, 59, 59, 999); // Postavi na 23:59:59.999
  
//   const [startDate, setStartDate] = useState(yesterday);
//   const [endDate, setEndDate] = useState(today);
  
//     // const [gyms, setGyms] = useState([]);
//     const [settingsGym, setSettingsGym] = useState([]);
//     const [posjete, setPosjete] = useState([]);
//     // const [gymsCombined, setGymsCombined] = useState([]);
//     const [selectedGym, setSelectedGym] = useState(null);
//     // const gost = true;
//   // Pretpostavka: Dobili ste JWT token od gostujuće teretane
//   const guestToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDQ1OTZkODFmMTJkOTE5YjNkNjNjNzUiLCJlbWFpbCI6ImFAYS5hIiwicm9sZSI6Im1lbWJlciIsImlhdCI6MTY5MDIzNDUwNH0.jTl-_7pfoh7WploGywUuhxZ5n3qw5itfTyY7pw-Ba5Y';

//   // Postavite URL gostujuće teretane
//   // const baseUrl = 'https://stayfit-app-a6czv.ondigitalocean.app';
//   const baseUrl = 'http://localhost:8080';
  
// //   // ID korisnika koji želite dohvatiti
// //   const userGuestId = '64ed835e55761ed4077209dc';
  
//   // Postavite zaglavlje zahtjeva s JWT tokenom
//   const headers = {
//     Authorization: `Bearer ${guestToken}`
//   };

  
//   useEffect(() => {
//     // Fetch posjete and other data here
  
//     async function fetchData() {
//       try {
//         // Fetch posjete data
//         const fetchedPosjete = await getAllGuestVisitsByRange({ startDate, endDate });
  
//         // ... (fetch other data as needed)
  
//         // Calculate uniqueUserIDs
//         const uniqueUserIDs = [...new Set(fetchedPosjete?.map(posjeta => posjeta.user))];
  
//         // Create an array for storing member information
//         const clanoviInfo = [];
  
//         // Function to get member information by user ID
//         async function getClanInfo(userID) {
         
//           try {
            
//             // const response = await axios.get(`${baseUrl}/api/guest/gosti/user/${userID}`, { headers });
//             // console.log("response",response)
//             // return response.data;
//           } catch (error) {
//             console.error(`Greška prilikom dobivanja informacija o članu za userID: ${userID}`, error);
//             return null;
//           }
//         }
  
//         // Iterate through uniqueUserIDs and get member information
//         for (const userID of uniqueUserIDs) {
//           const clanInfo = await getClanInfo(userID);
//           if (clanInfo) {
//             clanoviInfo.push(clanInfo);
//           }
//         }
  
//         // Process posjete with member information
//         const updatedPosjetes = fetchedPosjete.map((posjeta) => {
//           const clan = clanoviInfo.find((clan) => clan._id == posjeta.user);
//           if (clan) {
//             return {
//               ...posjeta,
//               user: clan,
//             };
//           }
//           return posjeta;
//         });
  
//         // Now, you can set the state with the updatedPosjetes and other data if needed
//         setPosjete(updatedPosjetes);
//         // ... (set other state variables)
  
//       } catch (error) {
//         console.error(error);
//       }
//     }
  
//     fetchData(); // Call the fetchData function
//   }, [startDate, endDate, baseUrl]);
  

// // Mapirajmo članove po njihovim ID-ovima radi brže pretrage
// // Kreirajte novi niz posjeta s nadograđenim korisničkim informacijama


// const updatedPosjete = posjete
// .filter(posjeta => settingsGym.some(gym => gym.guestGymId === posjeta.gymVisited))
// .map(posjeta => {
//     const matchingGym = settingsGym.find(gym => gym.guestGymId === posjeta.gymVisited);
//     return {
//         ...posjeta,
//         naziv: matchingGym ? matchingGym.guestNameGym : null
//     };
// });
// const handleExportToExcel = () => {
//   const exportData = updatedPosjete.map((item) => ({
//     clan: item.user.firstName + " "+ item.user.lastName,
//     prijava: item.timeIn,
//     odjava: item.timeOut,
//     poslovnica: item.naziv,
//     // iznos: `${item.iznos} KM`,
//   }));

//   const worksheet = XLSX.utils.json_to_sheet(exportData);
//   const workbook = XLSX.utils.book_new();
//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Posjete');

//   // Export the workbook to an Excel file
//   XLSX.writeFile(workbook, 'posjete-gost.xlsx');
// };


//   const userRender = (cell)=>{
//     return( 

//       <p direction="row" spacing={1}>
//         {/* {console.log(cell.row)} */}
//         {cell.row.user.firstName +" "+cell.row.user.lastName}
//     </p>
//     )
//   }
//   const poslovnicaRender = (cell)=>{
//     return( 
//       <p direction="row" spacing={1}>
//         {cell.row.gymVisited?.naziv}
//     </p>
//     )
//   }
//   const statusRender = (cell)=>{
//     return( 
//       <p direction="row" spacing={1}>
//         {cell.row.timeOut !== null ? "Odjavljen" : "Prijavljen"}
//     </p>
//     )
//   }
//   const fotografijaRender = (cell) => {

//     if (!cell?.row || !cell.row.user) {
//       return null; // Return null or a placeholder value if user data is undefined
//     }
  
//     const handleClick = () => {
//       navigate(`/users/${cell.row.user._id}`, { state: cell.row });
//     };
    
//     return (
//       <>
//         <img 
//           alt="Slika nije pronadjena"
//           // src={`${imageUrl}/${cell.row.user.photo}` }
//           src={cell.row.user.photo} 
//           style={{width:"75%"}}
//           onClick={handleClick}
//         />
//       </>
//     );
//   };
//  const vrijemePrijave = (cell)=>{
  
//  return <StartDateAndTimeConverting datetime={cell.row?.timeIn}/>
//   }
//   const vrijemeOdjave = (cell)=>{
  
//     return <StartDateAndTimeConverting datetime={cell.row?.timeOut}/>
//      }
  
//   const columns = [
//     // { field: "_id", headerName: "ID", flex: 0.5 },
//     { field: 'photo', headerName: 'Foto', 
//     renderCell: (params) => {
//         return fotografijaRender(params);
//     }
//     },
//     { field: 'user', headerName: 'Clan', 
//     renderCell: (params) => {
//         return userRender(params);
//     }
//     },
//     { field: 'gymVisited', headerName: 'Poslovnica', 
//     renderCell: (params) => {
//         return poslovnicaRender(params);
//     }
//     },
    
//     { field: 'timeIn', headerName: 'Vrijeme prijave', flex: 1,
//     cellClassName: "name-column--cell",
//     renderCell: (params) => {
//         return vrijemePrijave(params);
//     }
//     },
  
//     { field: 'timeOut', headerName: 'Vrijeme odjave', flex: 1,
//     cellClassName: "name-column--cell",
//     renderCell: (params) => {
//         return vrijemeOdjave(params);
//     }
//     },
//     { field: 'status', headerName: 'Status', 
//     renderCell: (params) => {
//         return statusRender(params);
//     }
//     },
//   ];

//   return (
//     <Box m="20px">
//       <Header
//         title="POSJETE IZ DRUGIH TERETANA"
//         // subtitle="List of Contacts for Future Reference"
//       />
     
//       <Box display="flex" justifyContent="end" mt="20px">
//         {/* <Link to="/check-in/new"> */}
//         <Button onClick={handleExportToExcel} variant="contained" color="secondary">
//       Izvezi u Excel
//     </Button>
//         {/* </Link> */}
//             </Box>
//             {/* <Box marginTop="50px" marginRight="10px">
//     <Button onClick={handleExportToExcel} variant="contained" color="secondary">
//       Izvezi u Excel
//     </Button>
//   </Box> */}
//             <FormControl  sx={{minWidth:"200px" }}>
//             <Box display="flex" mt="20px">
//     <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
//     <DatePicker
//         selected={startDate}
//         onChange={(date) => setStartDate(date)}
//         selectsStart
//         startDate={startDate}
//         endDate={endDate}
//         showTimeSelect={false} // dodano
//         showTimeSelectOnly={false} // dodano
//         style={{ display: "inline-block", margin: "0 auto" }}
//     />
//     <DatePicker
//         selected={endDate}
//         onChange={(date) => setEndDate(date)}
//         selectsEnd
//         startDate={startDate}
//         endDate={endDate}
//         minDate={startDate}
//         showTimeSelect={false} // dodano
//         showTimeSelectOnly={false} // dodano
//         style={{ display: "inline-block", margin: "0 auto" }}
//         />
//                   <Box display="flex" justifyContent="end" mt="0px">
//         <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
//         <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           value={selectedGym}
//           onChange={(event) => setSelectedGym(event.target.value)}
//         >
//           {settingsGym?.map((e, key) => (
//             <MenuItem key={key} value={e.guestGymId}>
//               {e.guestNameGym}
//             </MenuItem>
//           ))}
//         </Select>
//         {/* <Select
//           labelId="demo-simple-select-label"
//           id="demo-simple-select"
//           // value={gym}
//           // onChange={handleChangeGym}
//         >
//           {gymsCombined?.map((e, key) => (
//             <MenuItem key={key} value={e}>
//               {e.naziv}
//             </MenuItem>
//           ))}
//         </Select> */}
        
//       </Box>
//           </Box>

//       </FormControl>
//       <Box
//         m="40px 0 0 0"
//         height="75vh"
//         sx={{
//           "& .MuiDataGrid-root": {
//             border: "none",
//           },
//           "& .MuiDataGrid-cell": {
//             borderBottom: "none",
//           },
//           "& .name-column--cell": {
//             color: colors.greenAccent[300],
//           },
//           "& .MuiDataGrid-columnHeaders": {
//             backgroundColor: colors.blueAccent[700],
//             borderBottom: "none",
//           },
//           "& .MuiDataGrid-virtualScroller": {
//             backgroundColor: colors.primary[400],
//           },
//           "& .MuiDataGrid-footerContainer": {
//             borderTop: "none",
//             backgroundColor: colors.blueAccent[700],
//           },
//           "& .MuiCheckbox-root": {
//             color: `${colors.greenAccent[200]} !important`,
//           },
//           "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
//             color: `${colors.grey[100]} !important`,
//           },
//         }}
//       >
//         <DataGrid
//           rows={posjete}
//           columns={columns}
//           components={{ Toolbar: GridToolbar }}
//           getRowId={(row) => row._id}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default PosjeteIzDrguihTeretana;
