import React, { useState } from 'react';
import {
  Box,
  Grid,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@mui/material';
import Header from '../../components/Header';
import Tooltip from '@mui/material/Tooltip';
import { ToastContainer, toast } from 'react-toastify';
import { TailSpin } from 'react-loader-spinner';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { updatePaket } from '../../api/paketApi';
import "./paket.css";

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999,
    padding: '20px',
    borderRadius: '5px',
  },
}));

const EditPaket = () => {;
  const { state: data } = useLocation();
  // console.log(data)
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  //bs
  const [initialNazivPaketaBs, setInitialNazivPaketa] = useState(data.nazivPaketa.bs);
  const [initialOpisBs, setInitialopis] = useState(data.opis.bs);
  //en
  const [initialNazivPaketaEn, setInitialNazivPaketaEn] = useState(data.nazivPaketa.en);
  const [initialOpisEn, setInitialopisEn] = useState(data.opis.en);

  const [initialCijena, setInitialCijena] = useState(data.cijena);
  const [initialbrojDolazaka, setInitialBrojDolazaka] = useState(data.brojDolazaka);
  const [initialTrajanje, setInitialTrajanje] = useState(data.trajanje);
  const [initialOgranicenjeBrojDana, setInitialOgranicenjeBrojDana] = useState(data.ogranicenjeBrojDana);
  const [initialBrojDolazakaPoDanu, setInitialBrojDolazakaPoDanu] = useState(data.brojDolazakaPoDanu);
  const [initialDostupnoVrijemePocetak, setInitialDostupnoVrijemePocetak] = useState(data.dostupnoVrijemePocetak);
  const [initialdostupnoVrijemeKraj, setInitialdostupnoVrijemeKraj] = useState(data.dostupnoVrijemeKraj);
  const [initialgrupniTreninzi, setInitialgrupniTreninzi] = useState(data.grupniTreninzi);
  const [initialtreningProgram, setInitialtreningProgram] = useState(data.treningProgram);
  const [initialindividualniTreningProgram, setInitialindividualniTreningProgram] = useState(data.individualniTreningProgram);
  const [initialishrana, setInitialishrana] = useState(data.ishrana);
  const [initialvjezbe, setInitialvjezbe] = useState(data.vjezbe);
  const [initialqrVjezbe, setInitialqrVjezbe] = useState(data.qrVjezbe);
  const [initialstatInOut, setInitialstatInOut] = useState(data.statInOut);
  const [initialfields, setInitialfields] = useState(data.fields || []);
  const [statsExercises, setStatsExercises] = useState(data.statsExercises);
  const [statusPaketa, setStatusPaketa] = useState(data?.aktivan);
  // const [initialpopusti, setInitialpopusti] = useState(data.popusti.map((popust) => ({ ...popust, isEditing: false })));
  const [initialpopusti, setInitialpopusti] = useState(data.popusti || []);
//   const [fields, setFields] = useState(data.fields || []);
const [initialTipPak, setInitialTipPaketa] = useState(data.grupniPaket);
const [initialBrojClanova, setInitialBrojClanova] = useState(data.brojClanova);

//
// console.log(initialNazivPaketa)
  const handleFormSubmit = async () => {
    
    const dataValues = {
      nazivPaketa: {
        bs: initialNazivPaketaBs,
        en: initialNazivPaketaEn,
      },
      opis: {
        bs: initialOpisBs,
        en: initialOpisEn,
      },
        // nazivPaketa: initialNazivPaketa,
        cijena: initialCijena,
        // opis: initialopis,
        grupniPaket:initialTipPak,
        brojClanova: initialBrojClanova,
        trajanje: initialTrajanje,
        ogranicenjeBrojDana: initialOgranicenjeBrojDana,
        dostupnoVrijemePocetak: initialDostupnoVrijemePocetak,
        dostupnoVrijemeKraj: initialdostupnoVrijemeKraj,
        brojDolazaka: initialbrojDolazaka,
        brojDolazakaPoDanu: initialBrojDolazakaPoDanu,
        grupniTreninzi: initialgrupniTreninzi,
        treningProgram: initialtreningProgram,
        individualniTreningProgram: initialindividualniTreningProgram,
        ishrana: initialishrana,
        vjezbe: initialvjezbe,
        qrVjezbe: initialqrVjezbe,
        statInOut: initialstatInOut,
        fields: initialfields,
        popusti: initialpopusti,
        aktivan:statusPaketa,
        statsExercises:statsExercises,
  };
  // console.log(dataValues);
    try {
      setIsLoading(true);
      // console.log(dataValues);
      const res = await updatePaket(data._id, dataValues);
      // Implementirajte logiku za ažuriranje podataka
      setIsLoading(false);
      toast.success('Uspješno ste ažurirali paket.');
    } catch (error) {
      console.log(error);
    }
  };

  const addField = () => {
    setInitialfields([...initialfields, { naziv: '', vrijednost: '', checked: false }]);
  };

  const removeField = (index) => {
    const updatedFields = [...initialfields];
    updatedFields.splice(index, 1);
    setInitialfields(updatedFields);
  };

  const updateField = (index, fieldName, value) => {
    const updatedFields = [...initialfields];
    updatedFields[index][fieldName] = value;
    setInitialfields(updatedFields);
  };


  const addPopust = () => {
    const newPopust = {
      raspon: {
        minMjeseci: 0,
        maxMjeseci: 0,
      },
      postotak: 0,
      isEditing: true,
    };

    setInitialpopusti([...initialpopusti, newPopust]);
  };

  const removePopust = (index) => {
    const updatedPopusti = [...initialpopusti];
    updatedPopusti.splice(index, 1);
    setInitialpopusti(updatedPopusti);
  };

  // const editPopust = (index) => {
  //   const updatedPopusti = [...initialpopusti];
  //   updatedPopusti[index].isEditing = true;
  //   setInitialpopusti(updatedPopusti);
  // };

  const savePopust = (index, updatedData) => {
    const updatedPopusti = [...initialpopusti];
    updatedPopusti[index] = { ...updatedData};
    setInitialpopusti(updatedPopusti);
  };

  return (
    <Box m="20px">
      <Header title="Ažuriraj paket" />
      <ToastContainer />
      {isLoading ? (
        <div className={classes.loadingIndicator}>
          <TailSpin color="#007BFF" height={80} width={80} />
        </div>
      ) : (
        <div>
             <Box display="flex" justifyContent="end" mt="20px" style={{marginBottom:20}}>
            <Button onClick={handleFormSubmit} color="secondary" variant="contained">
              Ažuriraj paket
            </Button>
            
          </Box>
  <Grid container spacing={2}>
  <Grid item xs={6} md={3}>
  <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Naziv paketa"
              name="nazivPaketa"
              value={initialNazivPaketaBs}
              onChange={(e) => setInitialNazivPaketa(e.target.value)}
            />
  </Grid>
  <Grid item xs={6} md={3}>
  <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Cijena"
                  name="cijena"
                  onChange={(e) => setInitialCijena(Number(e.target.value))} // Ovdje ažuriramo initialNazivPaketa pomoću useState
                  value={initialCijena}
                />
  </Grid>
  <Grid item xs={6} md={3}>
  <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Broj termina"
              name="initialbrojDolazaka"
              onChange={(e) => setInitialBrojDolazaka(Number(e.target.value))}
              value={initialbrojDolazaka}
            />
  </Grid>
  <Grid item xs={6} md={3}>
  <TextField
                  fullWidth
                  variant="filled"
                  type="number"
                  label="Broj mjeseci"
                  name="initialTrajanje"
                  onChange={(e) => setInitialTrajanje(Number(e.target.value))} // Ovdje ažuriramo initialNazivPaketa pomoću useState
                  value={initialTrajanje}
                />
   </Grid>
  <Grid item xs={6} md={3}>
  <TextField
              fullWidth
              variant="filled"
              type="Number"
              label="Broj dana"
              onChange={(e) => setInitialOgranicenjeBrojDana(Number(e.target.value))}
              value={initialOgranicenjeBrojDana}
            />
  </Grid>
  <Grid item xs={6} md={3}>
  <TextField
              fullWidth
              variant="filled"
              type="number"
              label="Broj dolazaka po danu"
              name="initialBrojDolazakaPoDanu"
              onChange={(e) => setInitialBrojDolazakaPoDanu(Number(e.target.value))}
              value={initialBrojDolazakaPoDanu}
            />
  </Grid>
  <Grid item xs={6} md={3}>
  <TextField
                  fullWidth
                  variant="filled"
                  type="text"
                  label="Dostupno vrijeme početka"
                  name="initialDostupnoVrijemePocetak"
                  onChange={(e) => setInitialDostupnoVrijemePocetak(e.target.value)} // Ovdje ažuriramo initialNazivPaketa pomoću useState
                  value={initialDostupnoVrijemePocetak}
                />
   </Grid>
  <Grid item xs={6} md={3}>
  <TextField
              fullWidth
              variant="filled"
              type="text"
              label="Dostupno vrijeme kraja"
              onChange={(e) => setInitialdostupnoVrijemeKraj(e.target.value)}
              value={initialdostupnoVrijemeKraj}
            />
  </Grid>
  <Grid item xs={6} md={3}>
  <TextField
              fullWidth
              variant="filled"
              type="text"
              multiline
              rows={2}
              label="Opis"
              onChange={(e) => setInitialopis(e.target.value)}
              value={initialOpisBs}
            />
  </Grid>
  <Grid item xs={6} md={3}>
  <FormControlLabel
        control={
          <Checkbox
            color="primary"
            style={{ color: '#ffffff' }}
            name="tipPaketa"
          //   onChange={(event) => {
          //     handleChange(event);
          //     setTipPaketa(event.target.checked);
          //   }}
          //   checked={values.tipPaketa}
          //   error={!!touched.tipPaketa && !!errors.tipPaketa}
          // />
          onChange={(event) =>
            setInitialTipPaketa(event.target.checked)
          }
          checked={initialTipPak}
        />
        }
        label="Grupni paket (Porodični...)"
      />
 </Grid>
          {initialTipPak && (
                <Grid item xs={6} md={3}>
                <TextField
                                fullWidth
                                variant="filled"
                                type="number"
                                label="Broj članova u paketu"
                                name="initialTrajanje"
                                onChange={(e) => setInitialBrojClanova(Number(e.target.value))} // Ovdje ažuriramo initialNazivPaketa pomoću useState
                                value={initialBrojClanova}
                              />
                 </Grid>
              )}
</Grid>


            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="grupniTreninzi"
                  onChange={(event) =>
                    setInitialgrupniTreninzi(event.target.checked)
                  }
                  checked={initialgrupniTreninzi}
                />
              }
              label="Pristup grupnim treninzima"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="treningProgram"
                  onChange={(event) =>
                    setInitialtreningProgram(event.target.checked)
                  }
                  checked={initialtreningProgram}
                />
              }
              label="Standardni planovi treninga i ishrane"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="individualniTreningProgram"
                  onChange={(event) =>
                    setInitialindividualniTreningProgram(event.target.checked)
                  }
                  checked={initialindividualniTreningProgram}
                />
              }
              label="Individualni planovi treninga i ishrane"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="ishrana"
                  onChange={(event) =>
                    setInitialishrana(event.target.checked)
                  }
                  checked={initialishrana}
                />
              }
              label="Ishrana"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="vjezbe"
                  onChange={(event) =>
                    setInitialvjezbe(event.target.checked)
                  }
                  checked={initialvjezbe}
                />
              }
              label="Vježbe"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="qrVjezbe"
                  onChange={(event) =>
                    setInitialqrVjezbe(event.target.checked)
                  }
                  checked={initialqrVjezbe}
                />
              }
              label="Prikaz vježbi na spravi QR kode skeniranjem"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="statInOut"
                  onChange={(event) =>
                    setInitialstatInOut(event.target.checked)
                  }
                  checked={initialstatInOut}
                />
              }
              label="Statistika prijava i odjava"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="statsExercises"
                  onChange={(event) =>
                    setStatsExercises(event.target.checked)
                  }
                  checked={statsExercises}
                />
              }
              label="Statistika vježbanja"
            />
            </Grid>
            <Grid item xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  style={{ color: '#ffffff' }}
                  name="statusPaketa"
                  onChange={(event) =>
                    setStatusPaketa(event.target.checked)
                  }
                  checked={statusPaketa}
                />
              }
              label="Status paketa"
            />
            </Grid>
            
      <Typography variant="h4" component="h2" style={{ padding: 20 }}>Popusti</Typography>
      <Grid item xs={12}>
            {initialpopusti.map((popust, index) => (
              <Grid item xs={12} key={index}>
            
                  <div>
                    {/* Prikaz forme za uređivanje popusta */}
                    <TextField
                      label="Min mjeseci"
                      value={popust.raspon.minMjeseci}
                      onChange={(e) =>
                        savePopust(index, {
                          ...popust,
                          raspon: {
                            ...popust.raspon,
                            minMjeseci: parseInt(e.target.value),
                          },
                        })
                      }
                    />
                    <TextField
                      label="Max mjeseci"
                      value={popust.raspon.maxMjeseci}
                      onChange={(e) =>
                        savePopust(index, {
                          ...popust,
                          raspon: {
                            ...popust.raspon,
                            maxMjeseci: parseInt(e.target.value),
                          },
                        })
                      }
                    />
                    <TextField
                      label="Postotak"
                      value={popust.postotak}
                      onChange={(e) =>
                        savePopust(index, {
                          ...popust,
                          postotak: parseInt(e.target.value),
                        })
                      }
                    />
                    {/* <Button
                      variant="contained"
                      color="primary"
                      onClick={() => savePopust(index, popust)}
                    >
                      Spremi
                    </Button> */}
                  </div>
                  
                  <div>
                    {/* Prikaz informacija o popustu */}
                    {/* <Typography>Popust {index + 1}:</Typography>
                    <Typography>
                      Raspon: {popust.raspon.minMjeseci} -{' '}
                      {popust.raspon.maxMjeseci} mjeseci
                    </Typography>
                    <Typography>Postotak: {popust.postotak}%</Typography> */}
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        style={{ backgroundColor: 'red' }}
                        onClick={() => removePopust(index)}
                      >
                        Ukloni
                      </Button>
                      {/* <Button
                        variant="contained"
                        color="primary"
                        onClick={() => editPopust(index)}
                      >
                        Uredi
                      </Button> */}
                    </div>
                  </div>
                
              </Grid>
            ))}
            <div>
              <Button
                variant="contained"
                color="secondary"
                onClick={addPopust}
              >
                Dodaj popust
              </Button>
            </div>
          </Grid>

                <Typography variant="h4" component="h2" style={{padding:20}}>Dodatna polja za opis paketa</Typography>
           <Grid item xs={12}>
        {initialfields.map((field, index) => (
   <Grid item xs={12}>

      <TextField
        label="Naziv"
        value={field.naziv}
        onChange={(e) => updateField(index, 'naziv', e.target.value)}
      />

    <Tooltip
      title="Ako se promijeni stanje u polju vrijednost onda će checkbox biti isključen i obratno."
      arrow
    >
    
        <TextField
          type="text"
          label="Vrijednost"
          value={field.vrijednost}
          onChange={(e) =>
            updateField(index, 'vrijednost', e.target.value)
          }
          disabled={field.vrijednost ? field.checked : false}
        />
 
    </Tooltip>
  
      <Checkbox
        style={{ color: '#ffffff' }}
        checked={field.checked}
        onChange={(e) =>
          updateField(index, 'checked', e.target.checked)
        }
        disabled={field.vrijednost ? true : false}
      />
    <div>
      <Button
        variant="contained"
        color="secondary"
        style={{ backgroundColor: 'red' }}
        onClick={() => removeField(index)}
      >
        Ukloni
      </Button>
    </div>
  </Grid>
        ))}
        <div>
        <Button
          variant="contained"
          color="secondary"
          onClick={addField}
        >
          Dodaj dodatna polja 
        </Button>
      
      </div>
      </Grid>
        </div>
      )}
        <Box style={{paddingBottom:20}}></Box>
        <Header subtitle="Engleski jezik" />
        
<TextField
                className={classes.inField}
                label="Naziv paketa (Engleski)"
                value={initialNazivPaketaEn}
                onChange={(e) => setInitialNazivPaketaEn(e.target.value)}
                variant="filled"
                required
              />
               <div>
               <TextField
                className={classes.inField}
                label="Opis (Engleski)"
                multiline
                rows={2}
                value={initialOpisEn}
                onChange={(e) => setInitialopisEn( e.target.value)}
                variant="filled"
                required
              />
              </div>
    </Box>
  );
};

export default EditPaket;