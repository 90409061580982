import React from 'react';
import { Box } from "@mui/material";
import Header from "../../components/Header";
import PieChartIzvjestajClanovi from "../../components/PieChartIzvjestajClanovi";

const PieIzvjestajClanovi = (props) => {

  const grupisanoPaketi = props.data;
  // console.log(grupisanoPaketi)
  return (
    <Box m="20px">
      <Header />
      <Box height="60vh">
        <PieChartIzvjestajClanovi data={grupisanoPaketi}/>
      </Box>
    </Box>
  );
};

export default PieIzvjestajClanovi;
