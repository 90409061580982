import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/attendance/checkin`;

// const baseUrl = 'http://localhost:3001/api/attendance/checkin';
// /api/attendance/checkin/gym/640271c2222b43a3d722fd7b

// export const createPaket = async (newPaket) => {
//   const response = await axios.post(baseUrl, newPaket);
//   return response.data;
// };

//ne znam sta je ovo ali mi se ne cini dobro jer fali gymID
export const getAllPosjeteByGymByRange = async ({startDate, endDate}) => {
  // console.log(startDate, endDate)
  const response = await axios.get(`${baseUrl}/${startDate}/${endDate}`);
  return response.data;
};
//ovo bi trebalo da je ispravno

export const getAllPosjeteByGymAndDateRange = async (gymID, startDate, endDate) => {
  console.log(gymID.gymID)
  const response = await axios.get(`${baseUrl}/gym/${gymID.gymID}/${startDate}/${endDate}`);
  return response.data;
};

export const getAllGuestPosjeteByGymByRange = async ({startDate, endDate}) => {
  // console.log(startDate, endDate)
  const response = await axios.get(`${baseUrl}/guest-gym/${startDate}/${endDate}`);
  return response.data;
};

export const getAllGuestVisitsByRange = async ({startDate, endDate}) => {
  // console.log(startDate, endDate)
  const response = await axios.get(`${baseUrl}/guest/visit/${startDate}/${endDate}`);
  return response.data;
};

export const getAllPosjeteByGym = async (id) => {
  // const response = await axios.get(baseUrl);
  // return response.data;
  // const {gymID} = id
  let gymID;
  if (typeof id === 'object') {
    gymID = id.gymID;
  } else {
    gymID = id;
  }
  const response = await axios.get(`${baseUrl}/gym/${gymID}`);
  // console.log(response)
  return response.data;
};

// export const getAllVisitsByRange = async ({id, startDate, endDate}) => {
//   let gymID;
//   if (typeof id === 'object') {
//     gymID = id.gymID;
//   } else {
//     gymID = id;
//   }
//   // console.log(id)
//   const response = await axios.get(`${baseUrl}/gym/${gymID}/${startDate}/${endDate}`);
//   return response.data;
// };


export const getAllPosjeteByGymUserID = async (gym, user) => {
  // console.log(gym, user)
  if (!user) {
    // User is undefined, return an empty array or throw an error
    return [];
  }
  const gymID = gym
  const userID = user
  // console.log(gymID, userID)
  const response = await axios.get(`${baseUrl}/posjete/gym/${gymID}/user/${userID}`);
  // console.log(response)
  return response.data;
};

// export const getPosjete = async () => {
//   const response = await axios.get(baseUrl);
//   // console.log(response)
//   return response.data;
// };

export const getPosjetaID = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createCheckInn = async (newCheckInn) => {
  const response = await axios.post(baseUrl, newCheckInn);
  return response.data;
};

export const createCheckOut = async (newCheckOut) => {
  const response = await axios.post(baseUrl, newCheckOut);
  return response.data;
};

// export const updateUser = async (id, updatedUser) => {
//   const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   return response.data;
// };

// export const deleteUser = async (id) => {
//   const response = await axios.delete(`${baseUrl}/${id}`);
//   return response.data;
// };