import React, {useState, useEffect, useContext} from 'react'
// import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { getPaket } from "../../api/paketApi";
// import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
// // import { FormControl, InputLabel, MenuItem, Select } from '@material-ui';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
import { useTheme, Button, TextField } from "@mui/material";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import EditIcon from '@mui/icons-material/Edit';
import Select from '@mui/material/Select';
import { updateDefaultGym } from "../../api/userApi";
import { GymContext } from "../../theme";
import { getAllGyms } from '../../api/gymApi';
import { UserContext } from '../../context/UserProvider';
// import {useNavigate} from 'react-router-dom';
// import { fetchProtectedData } from '../../api/fetchProtectedData';

const AllGym = () => {

  const { defaultGym, setDefaultGym, gyms } = useContext(GymContext);
  // const navigate = useNavigate();
  // const userId  = 1
  const theme   = useTheme();
  const colors  = tokens(theme.palette.mode);
  const { user} = useContext(UserContext);
  const userRole = user?.role;

  const [checkIn, setCheckIn] = useState([]);

  const [gymss, setGyms] = useState([]);
// console.log(response.address)
// console.log("gymss",gymss)

useEffect(() => {
  async function fetchGym() {
    try {
      const response = await getAllGyms({});
      setGyms(response)
    } catch (error) {
      console.error(error);
    }
  }

  fetchGym();
}, []);
  useEffect(() => {

    async function protData() {
      try {
        //  await fetchProtectedData();
      } catch (error) {
        console.error(error);
      }
    }

    protData()
  }, [])
  //odaberi radnju
  // const [defaultGym1, setDefaultGym1] = useState(localStorage.getItem('defaultGym') ||  gyms[0]);

  // useEffect(() => {
  //   if (!localStorage.getItem('defaultGym')) {
  //     localStorage.setItem('defaultGym', gyms[0]);
  //   }
  // }, []);

  const handleGymChange = (event) => {
    const selectedGym = event.target.value;
    // console.log(selectedGym)
    setDefaultGym(selectedGym);
    localStorage.setItem('defaultGym', JSON.stringify(selectedGym));
    updateDefaultGym(user._id, selectedGym); // API poziv za ažuriranje zadane poslovnice za korisnika
  };

  // const handleChange = (event) => {
  //   // console.log(event.target)
  //   setPoslovnica(event.target.value);
  // };

  // useEffect(() => {

  //   async function fetchPaket() {
  //     try {
  //       const fetchedUPaket = await getPaket({});
  //       setCheckIn(fetchedUPaket);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //     // await fetchProtectedData();
  //   }

  //   fetchPaket()
  // }, []);


  // console.log(gyms)

  const clanskaRender = (cell) => {
    const radnoVrijeme = cell.row.workingDays;
    return (
      <p direction="row" spacing={1}>
        {radnoVrijeme.map((e)=>{
          return(
            <p style={{margin:0}}>{e.day}: {e.hours}</p>
          )
        })}
      </p>
    )
  }

  const editRender = (cell)=>{
    // console.log(cell.row._id)
    // const handleClick = () => {
    //   navigate(`/gym/${cell.row._id}/edit`, { state: cell.row });
    // };
  return( 
    <>
      {userRole === 'admin'  && (
      <Link data={cell} to={`/gym/${cell?.row._id}/edit`}>
      {/* <EditIcon   onClick={handleClick}></EditIcon> */}
      <EditIcon></EditIcon>
      </Link>
      )}
    </>
  )
  }



  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    { field: "transakRacun",   flex: 1, headerName: "Transakcijski racun" },
    {
      field: "naziv",
      headerName: "Naziv poslovnice",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "address",
      headerName: "Adresa",
      // type: "number",
      headerAlign: "left",
      flex: 1,
      align: "left",
    },
    {
      field: "city",
      headerName: "Grad",
      flex: 1,
    },
    {
      field: "phone",
      headerName: "Telefon",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Mail",
      flex: 1,
    },
    // {
    //   field: "workingDays",
    //   headerName: "Radno vrijeme",
    //   flex: 1,
    // },
    { field: 'workingDays', headerName: 'Radno vrijeme', flex: 1,renderCell: (params) => clanskaRender(params) },
    { field: 'yyy', headerName: 'Uredi',  width: 50,renderCell: (params) => editRender(params)},

  ];

  return (
    <Box m="20px">
      <Header
        title={`POSLOVNICE`}
        // subtitle="List of Contacts for Future Reference"
      />
      <Box 
      display="flex" 
      // justifyContent="end" 
      mt="20px">
      
        <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={defaultGym}
          label="Poslovnica"
          onChange={handleGymChange}
        >
          {gyms.map((gym) => (
          <MenuItem  key={gym._id} value={gym}>
            {gym.naziv}
          </MenuItem>
        ))}
          
        </Select>
        </Box>
        <Box 
      display="flex" 
      justifyContent="end" 
      mt="20px">
        <Link to="/gym/new">
          <Button type="submit" color="secondary" variant="contained">
            Nova poslovnica
          </Button>
        </Link>
            </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
           {/* <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
    {/* <InputLabel id="demo-simple-select-label">Radnje</InputLabel> */}
            
        <DataGrid
          rows={gymss}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
          rowHeight={150}
        />
      </Box>
    </Box>
  );
};

export default AllGym;
