import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/namirnice`;
// const baseUrl = 'http://localhost:3001/api/namirnice';

export const getAllFood = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getFoodById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createFood = async (newFood) => {
  const response = await axios.post(baseUrl, newFood);
  // console.log(newFood)
  return response.data;
};

export const updateFood = async (id, updatedData) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedData);
  return response.data;
};

export const deleteFood = async (id) => {
  const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }