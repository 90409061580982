import React, { useState } from 'react';
import axios from 'axios';
import { createNotification } from '../../api/notificationApi';

const NewNotification = () => {
  const [notification, setNotification] = useState({
    title: '',
    body: '',
    expoPushToken: '', // Expo push token korisnika
    imageUrl: '', // Dodajte polje za URL slike
  });

  const handleSendNotification = async () => {
    try {
      const response = await createNotification(notification);
      console.log('Notification sent successfully', response);
    } catch (error) {
      console.error('Failed to send notification', error);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Title"
        value={notification.title}
        onChange={(e) => setNotification({ ...notification, title: e.target.value })}
      />
      <input
        type="text"
        placeholder="Body"
        value={notification.body}
        onChange={(e) => setNotification({ ...notification, body: e.target.value })}
      />
      <input
        type="text"
        placeholder="Expo Push Token"
        value={notification.expoPushToken}
        onChange={(e) => setNotification({ ...notification, expoPushToken: e.target.value })}
      />
      <input
        type="text"
        placeholder="Image URL"
        value={notification.imageUrl}
        onChange={(e) => setNotification({ ...notification, imageUrl: e.target.value })}
      />
      <button onClick={handleSendNotification}>Send Notification</button>
    </div>
  );
};

export default NewNotification;
