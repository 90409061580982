import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/payment`;
// const baseUrl = 'http://localhost:3001/api/payment';

export const getAllPayment = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getAllPaymentByRange = async ({startDate, endDate}) => {
  const response = await axios.get(`${baseUrl}/${startDate}/${endDate}`);
  return response.data;
};

export const getAllPaymentById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createPayment = async (newPayment) => {
  const response = await axios.post(baseUrl, newPayment);
  // console.log(newPayment)
  return response.data;
};

export const updatePayment = async (id, updateMembership) => {
  // console.log(id,updateMembership)
  const response = await axios.put(`${baseUrl}/${id}`, updateMembership);
  return response.data;
};
// export const deletePayment = async (id) => {
//   const response = await axios.delete(`${baseUrl}/${id}`);
//   return response.data;
// };
export const deletePayment = async (id, queryParameters) => {
  // console.log("data", queryParameters)
  // const response = await axios.delete(`${baseUrl}/${id}`, password);
  const response = await axios.delete(`${baseUrl}/${id}`, { params: queryParameters });
  // console.log(response.data);
  return response.data;
};

export const deletePayments = async (ids, queryParameters) => {
  // console.log("data", queryParameters)
  // const response = await axios.delete(`${baseUrl}/${id}`, password);
  const response = await axios.delete(`${baseUrl}/fakture/${ids}`, { params: queryParameters });
  // console.log(response.data);
  return response.data;
};


//priomjena cijene iz fakture
export const updatePaymentByID = async (id, dataCijena) => {
  // console.log(id, dataCijena)
  const response = await axios.put(`${baseUrl}/cijena/${id}`, dataCijena);
  return response.data;
};


export const getAllPaymentByYear = async (godina) => {
  const response = await axios.get(`${baseUrl}/faktura/godina/${godina}`);
  return response.data;
};
// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }