import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, TextField, Typography, Container,Grid } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { resetSifre } from '../api/resertSifreApi';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

  // Provjerite da li se password i confirmPassword slažu
  if (password !== confirmPassword) {
    toast.error('Šifre se ne slažu. Molimo unesite iste šifre u oba polja.');
    return; // Prekinite proces ako se šifre ne slažu
  }

    try {
      const requestData = {
        token,
        password,
        confirmPassword,
      };

      const response = await resetSifre(requestData);
      console.log(response)
      if (response.success === true)  {
        setIsPasswordReset(true);
        toast.success('Šifra je uspešno resetovana');
      } else {
        toast.error('Došlo je do greške prilikom resetovanja šifre. Vaš token je istekao.');
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <Container maxWidth="sm">
         <Grid container justifyContent="center">
        <img src="../../assets/a4s_logo_white.png" alt="Vaš logo" />
      </Grid>
      <Typography variant="h4" align="center" gutterBottom>
        Resetovanje Šifre
      </Typography>
      {isPasswordReset ? (
        <Typography variant="h6" align="center">
          Šifra je uspešno resetovana.
        </Typography>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            type="password"
            label="Nova Šifra"
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <TextField
            fullWidth
            type="password"
            label="Potvrdite Šifru"
            variant="outlined"
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
            <Button type="submit" color="secondary" variant="contained">
            Resetuj Šifru
          </Button>
          {/* <Button
            type="submit"
            // variant="contained"
            // color="primary"
            variant="contained"
            // color="primary"
            fullWidth
            size="large"
            sx={{ mt: 2 }}
          >
            Resetuj Šifru
          </Button> */}
        </form>
      )}
      <ToastContainer />
    </Container>
  );
};

export default ResetPassword;
