
import React, {useState, useEffect, useContext} from 'react';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import { Box, Button, TextField, Modal,Zoom } from "@mui/material";
// import CardMedia from '@mui/material/CardMedia';
// import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  useTheme,
  Divider,
  IconButton, Box, Button, TextField, Modal,Zoom
} from '@mui/material';

// import Select, { SelectChangeEvent } from '@mui/material/Select';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { styled } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigApp from '../../config/ConfigApp';
// import { updateMembershipDate } from '../../api/membershipApi';
// import axios from 'axios';
import { createClanarinaUredjivanje, getAllClanarinaUredjivanjeByUser } from '../../api/clanarinaUredjivanjeApi';
import { updateMembershipPaket, updateUserImage } from '../../api/userApi';
// import { getPaketWeb } from '../../api/paketApi';
import { UserContext } from '../../context/UserProvider'; 
import OdobreneClanarine from './odobreneClanarine';


// const imageUrl = `${ConfigApp.URLIMAGE}`;
const baseUrl = `${ConfigApp.URL}`;

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
  userDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  userDetailsText: {
    marginLeft: theme.spacing(2),
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    padding: 16,
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontSize: 40,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    fontSize: 16,
    color: '#888',
    marginBottom: theme.spacing(1),
  },
  image: {
    // width: '100%',
    // height: '100%',
    objectFit: 'cover', // Omogućava da se slika prilagodi dimenzijama containera zadržavajući omjer
  },
  // container1: {
  //   // width: '300px', // Primjer: postavite željenu širinu containera
  //   // height: '300px', // Primjer: postavite željenu visinu containera
  //   border: '1px solid #ccc', // Primjer: dodajte željeni stil okvira
  //   overflow: 'hidden', // Spriječava prelazak slike preko granica containera
  // },

}));
const ZoomableImage = styled('img')(({ theme, zoomed }) => ({
  width: zoomed ? '100%' : '40%',
  height: zoomed ? 'auto' : '40%',
  objectFit: 'contain',
  transition: 'all 0.3s ease',
}));

const ViewUposlenik = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
//   const { id } = useParams();
// //   const { state: data } = useLocation();
//   const navigate = useNavigate();
  // console.log(data)
  const { user} = useContext(UserContext);
  // const userId = localStorage.getItem('userId'); 
    const userId = user._id; 
  // console.log("userId",userId)
  // console.log("User", data ? data : "User object is null or undefined");
  const [data, setData] = useState([]);
  // console.log(data)


// Spoji sve pakete unutar data u jedan niz
const sveUplate = [].concat(...data);

// Saberi iznose uplata gde fakturaId nije "pending"
const sumaIznosa = sveUplate.reduce((suma, uplata) => {
  if (uplata.fakturaId.status !== 'pending') {
    suma += uplata.fakturaId.amount;
  }
  return suma;
}, 0);


// Inicijalizuj brojače za fakture sa i bez statusa "pending"
let brojFakturaSaPending = 0;
let brojFakturaBezPending = 0;

// Iteriraj kroz sve uplate i inkrementiraj brojače prema statusu
sveUplate.forEach((uplata) => {
  if (uplata.fakturaId.status === 'pending') {
    brojFakturaSaPending++;
  } else {
    brojFakturaBezPending++;
  }
});

//   const [paket, setPaket] = useState("");
  // const isNonMobile = useMediaQuery("(min-width:600px)");
  // console.log(paket)
  // const userRole = localStorage.getItem('userRole');
//   const userRole = user?.role;
  // const [user, setUser] = useState("")
  // const [member, setMember] = useState({})
  const options = { timeZone: 'Europe/Sarajevo' };
  const start = new Date(user?.membership?.startDate);
  const startDay = start.toLocaleString('en-GB', options);

  const end = new Date(user?.membership?.endDate);
  const endDay = end.toLocaleString('en-GB',options);
  const [startD, setStartD] = useState(startDay);
  const [endD, setEndD] = useState(endDay);

//   const [newStartD, setNewStartD] = useState(startD);
//   const [newEndD, setNewEndD] = useState(endD);
//   const [reason, setReason] = useState('');
  // const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(user.photo); // Initialize with the existing user's photo
  const [pendingImage, setPendingImage] = useState(null); // Store the pending image until saved
  // const [image, setImage] = useState(data.user.photo);
  const classes = useStyles();

//   const [isOpen, setIsOpen] = useState(false);

//   const [zoomed, setZoomed] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [isModalPaketOpen, setIsModalPaketOpen] = useState(false);
//   const [isOpenPodaciClana, setIsOpenPodaciClana] = useState(false);
  


  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    // Add other user attributes here and initialize their values
  });


  const handleImageUpload = (event) => {
    const imageFile = event.target.files[0];
    // console.log(imageFile)
    if (imageFile) {
      // const imageUrl = URL.createObjectURL(imageFile);
      setSelectedImage(imageFile);
      setPendingImage("1")
    }
  };
  const handleSaveImage = async () => {
    if (pendingImage) {
      try {
        const formData = new FormData();
        formData.append('image', selectedImage);

        const updatedUser = await updateUserImage(user._id, formData);
  
        // Validate that updatedUser is not undefined before accessing 'photo'.
        const updatedImageUrl = updatedUser?.photo;
  
        if (updatedImageUrl) {
          setSelectedImage(updatedImageUrl);
          setPendingImage(null); // Clear the pending image
  
          // Optionally, you can also display a success message to the user.
          toast.success('Uspješno ste ažurirali sliku.');
        } else {
          // Handle the case where 'updatedUser' or 'updatedImageUrl' is undefined.
          console.error('Error updating user image: Invalid API response');
        }
      } catch (error) {
        console.error('Error updating user image:', error);
        // Handle the error and display an error message to the user if needed.
      }
    }
  };
  

// 
  useEffect(() => {
    async function fetchBrojOdobrenihFaktura() {
     try {
       const fetchedUredjene = await getAllClanarinaUredjivanjeByUser(userId);
    //    const response = await axios.get(`${baseUrl}/users/${id}`);
    // console.log(fetchedUredjene)
       setData(fetchedUredjene);
     } catch (error) {
       console.error(error);
     }
   }
 
   fetchBrojOdobrenihFaktura()
   }, []);


   const handleClick = () => {
    // navigate(`/users/${user._id}/edit`, { state: data});
  };
const date = new Date();
  return (
    <Container maxWidth="mx" className={classes.container}>
         <ToastContainer />
      <div className={classes.userDetails}>
      
         {typeof selectedImage === "string" ? (
             <Avatar 
             alt={user.firstName} 
             // src={`${imageUrl}/${data.user.photo}` }
             src={selectedImage} 
             className={classes.avatar} 
             sx={{ width: 200, height: 200 }}
           />
          ) : (
            <Avatar 
            alt={user.firstName} 
            // src={`${imageUrl}/${data.user.photo}` }
            src={URL.createObjectURL(selectedImage)}
            className={classes.avatar} 
            sx={{ width: 200, height: 200 }}
          />
            // <img
            //   style={{ width: "100%" }}
            //   src={URL.createObjectURL(selectedImage)}
            //   alt="Slika vježbe"
            // />
          )}
        <label htmlFor="upload-photo">
            <input
              type="file"
              id="upload-photo"
              accept="image/*"
              style={{ display: 'none' }}
              onChange={handleImageUpload}
            />
            {/* <IconButton component="span">
              <AddPhotoAlternateIcon style={{ color: 'white' }} />
            </IconButton> */}
          </label>
        <div className={classes.userDetailsText}>
          <Typography variant="h1" component="h2" gutterBottom>
          {user.firstName} {user.lastName}
         
          </Typography>
          
          <Typography variant="body1" gutterBottom>
            Telefon:  {user?.phone}
          </Typography>
          <Typography variant="body1" gutterBottom>
          Ulica: {user?.address}
        </Typography>
        </div>
        <div className={classes.userDetailsText}>
        <Typography variant="h1" component="h2" gutterBottom>
          {/* {data.user.firstName} {data.user.lastName} */}#
          </Typography>
        <Typography variant="body1" gutterBottom>
        Email: {user?.email}
        </Typography>


        <Typography variant="body1" gutterBottom>
          Kraj članarine: {endD}
        </Typography>
        <Typography variant="body1" gutterBottom>
        Status članarine: {user?.membership?.status === "active" ? "Aktivna" 
        : user?.membership?.status === "pending" ? "U obradi" 
        : "Neaktivana"}
        </Typography> 
        {pendingImage && (
            <Button type="submit" variant="contained" color="success" onClick={handleSaveImage}>
              Spasi Sliku
            </Button>
          )}


          </div>
      </div>
      {/* <Divider /> */}
      {/* <Grid item xs={12} sm={6}> */}
      <Box
    display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridAutoRows="140px"
    gap="20px"
  >
      <Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
        title={`${sumaIznosa} KM`}
        subtitle="Suma fakture"
        progress="a"
    />
  </Box>
  <Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
        title={`${brojFakturaBezPending}`}
        title2={`Neplacene fakture: ${brojFakturaSaPending}`}
        subtitle="Broj faktura"
        progress="a"
    />
  </Box>
  </Box>
      {/* </Grid> */}
      <Grid >
        <OdobreneClanarine data={data}/>
      {/* <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h3" component="h1" className={classes.cardTitle} style={{color:"#141b2e"}}>
              Broj odobrenih članarina za dan: {date.toLocaleString()}
                </Typography>
                <Typography variant="h1" component="h1" className={classes.cardTitle} style={{color:"#141b2e"}}>
    <Box height="25vh" width={"70vh"}>
                {brOdobrenihClanarina.length}
      </Box>
        </Typography> 
            </CardContent>
          </Card>
        </Grid> */}
        <Grid item xs={12} sm={6}> 
          {/* <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle}  style={{color:"#141b2e"}}>
              Historija posjeta
              </Typography>
              <Typography>
    <Box height="25vh" width={"70vh"}>
        <LineFullChart data={user._id}/>
      </Box>
        </Typography>  
            </CardContent>
          </Card> */}
        </Grid>

       
      </Grid>
      
    </Container>
  );
};

export default ViewUposlenik;


