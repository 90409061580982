import React, { useState, useContext, useEffect } from "react";
// import { Box } from "@mui/material";
import { GymContext } from "../../../theme";
import Header from "../../../components/Header";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@mui/styles";
import { TextField, Button } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { createEquipment } from "../../../api/equpmentsApi";
import { getAllFood } from "../../../api/foodApi";
import { TailSpin } from "react-loader-spinner";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { createMeal } from "../../../api/mealApi";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
    maxWidth: "800px",
    margin: "0 auto",
  },
  inField: {
    backgroundColor: "#ffffff14",
  },
}));

const NewMeal = () => {
  // const { defaultGym } = useContext(GymContext);
  const classes = useStyles();

  const [name, setName] = useState({ en: '', bs: '' });
  const [description, setDescription] = useState({ en: '', bs: '' });
  const [foodList, setFoodList] = useState([]);
  const [image, setImage] = useState(null);
  // const [name, setName] = useState("");
  const [time, setTime] = useState("");
  // const [description, setDescription] = useState("");
  const [selectedFoods, setSelectedFoods] = useState([]);
  const [selectedTipObroka, setSelectedTipObroka] = useState("");
  const [loading, setLoading] = useState(true);
  const mealTypes = ["Dorucak", "Rucak", "Vecera", "Medjuobrok"];

  // console.log(selectedFoods);
  const [totalNutrients, setTotalNutrients] = useState({
    calories: 0,
    proteins: 0,
    carbs: 0,
    fats: 0,
    unsaturatedFats: 0,
    fiber: 0,
  });

  const handleTipObrokaChange = (e, selectedValues) => {
    setSelectedTipObroka(selectedValues);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        await getAllFood().then((response) => {
          setFoodList(response);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const calculateNutrients = (food, grams) => {
    const gramsFactor = grams / 100;
    return {
      calories: food.calories * gramsFactor,
      proteins: food.proteins * gramsFactor,
      carbs: food.carbs * gramsFactor,
      fats: food.fats * gramsFactor,
      unsaturatedFats: food.unsaturatedFatt * gramsFactor,
      fiber: food.fiber * gramsFactor,
    };
  };

  const getTotalNutrients = () => {
    let newTotalNutrients = {
      calories: 0,
      proteins: 0,
      carbs: 0,
      fats: 0,
      unsaturatedFats: 0,
      fiber: 0,
    };

    for (const selectedFood of selectedFoods) {
      const gramsFactor = selectedFood.grams / 100;
      newTotalNutrients.calories += selectedFood.calories * gramsFactor;
      newTotalNutrients.proteins += selectedFood.proteins * gramsFactor;
      newTotalNutrients.carbs += selectedFood.carbs * gramsFactor;
      newTotalNutrients.fats += selectedFood.fats * gramsFactor;
      newTotalNutrients.unsaturatedFats += selectedFood.unsaturatedFatt * gramsFactor;
      newTotalNutrients.fiber += selectedFood.fiber * gramsFactor;
    }

    setTotalNutrients(newTotalNutrients);
  };

  useEffect(() => {
    getTotalNutrients();
  }, [selectedFoods]);

  const updateSelectedFoodGrams = (foodId, newGrams) => {
    setSelectedFoods((prevState) =>
      prevState.map((item) =>
        item._id === foodId ? { ...item, grams: newGrams } : item
      )
    );
  };

  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima
  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      setImage(imageFile);
    }
  };
  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const mealData = {
      name: JSON.stringify(name),
      description: JSON.stringify(description),
      // name,
      time,
      // description,
      // calories: totalNutrients.calories,
      // proteins: totalNutrients.proteins,
      // carbs: totalNutrients.carbs,
      // fats: totalNutrients.fats,
      // unsaturatedFatt: totalNutrients.unsaturatedFats,
      // fiber: totalNutrients.fiber,
      type: selectedTipObroka,
      foods: selectedFoods.map((selectedFood) => ({
        foodId: selectedFood._id,
        grams: selectedFood.grams,
      })),
    };

    const formData = new FormData();
    formData.append("image", image);
    formData.append("mealData", JSON.stringify(mealData));
    try {
      const res = await createMeal(formData);
      if (res) {
        toast.success("Uspješno ste kreirali obrok.");
      }

      setName("");
      setDescription("");
      setImage(null);
      setSelectedFoods([]);
      setSelectedTipObroka("");
    } catch (error) {
      console.log(error);
      toast.error("Došlo je do greške prilikom kreiranja obroka.");
    }
  };

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <TailSpin height={80} width={80} />
        </div>
      ) : (
        <form className={classes.form} onSubmit={handleSubmit}>
          <Header title="Novi obrok" subtitle="" />
          <ToastContainer />

          {/* <TextField
            label="Naziv"
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            required
          /> */}
           <TextField
              label="Naziv vježbe (Bosanski)"
              value={name.bs}
              onChange={(event) => setName({ ...name, bs: event.target.value })}
              variant="outlined"
              required
            />

          <Autocomplete
            single
            options={mealTypes}
            getOptionLabel={(option) => option}
            value={selectedTipObroka}
            onChange={handleTipObrokaChange}
            renderInput={(params) => (
              <TextField {...params} label="Tip obroka" variant="outlined" />
            )}
          />
          <TextField
            label="Vrijeme pripreme obroka u minutama"
            value={time}
            onChange={(event) => setTime(event.target.value)}
            variant="outlined"
            required
          />

          {/* <TextField
            label="Opis"
            multiline
            rows={8}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            variant="outlined"
            required
          /> */}
        <TextField
              label="Opis (Bosanski)"
              multiline
              rows={8}
              value={description.bs}
              onChange={(event) => setDescription({ ...description, bs: event.target.value })}
              variant="outlined"
              required
            />
          <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />

          <Autocomplete
            multiple
            options={foodList}
            getOptionLabel={(option) => option.name.bs}
            value={selectedFoods}
            onChange={(event, newValue) => {
              setSelectedFoods(
                newValue.map((food) => ({
                  ...food,
                  grams: food.grams || 100,
                }))
              );
            }}
            renderInput={(params) => (
              <TextField {...params} label="Namirnice" variant="outlined" />
            )}
          />

          {selectedFoods.map((selectedFood) => (
            <div key={selectedFood._id}>
              <h3>{selectedFood.name.bs}</h3>
              <TextField
                label="Grama"
                type="number"
                value={selectedFood.grams}
                onChange={(event) =>
                  updateSelectedFoodGrams(
                    selectedFood._id,
                    parseFloat(event.target.value)
                  )
                }
                variant="outlined"
                required
              />
            </div>
          ))}

          <div>
            <h2>Ukupno hranjivih sastojaka:</h2>
            <TableContainer component={Paper}>
              <Table aria-label="nutrient table">
                <TableHead>
                  <TableRow>
                    <TableCell>Namirnica</TableCell>
                    <TableCell align="right">Kalorije</TableCell>
                    <TableCell align="right">Proteini</TableCell>
                    <TableCell align="right">Ugljikohidrati</TableCell>
                    <TableCell align="right">Masti</TableCell>
                    <TableCell align="right">Nezasićene masti</TableCell>
                    <TableCell align="right">Vlakna</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {selectedFoods.map((selectedFood) => (
                    <TableRow key={selectedFood._id}>
                      <TableCell component="th" scope="row">
                        {selectedFood.name.bs}
                      </TableCell>
                      {Object.values(
                        calculateNutrients(selectedFood, selectedFood.grams)
                      ).map((nutrientValue, index) => (
                        <TableCell key={index} align="right">
                          {nutrientValue.toFixed(2)}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Ukupno
                    </TableCell>
                    {Object.values(totalNutrients).map((nutrientValue, index) => (
                      <TableCell key={index} align="right">
                        {nutrientValue.toFixed(2)}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <Header subtitle="Engleski jezik" />
              <TextField
                label="Naziv vježbe (Engleski)"
                value={name.en}
                onChange={(event) => setName({ ...name, en: event.target.value })}
                variant="outlined"
                // required
              />
              <TextField
                label="Opis (Engleski)"
                multiline
                rows={4}
                value={description.en}
                onChange={(event) => setDescription({ ...description, en: event.target.value })}
                variant="outlined"
                // required
              />

          <Grid container justify="left" alignItems="left" spacing={2}>
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                Kreiraj obrok
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </>
  );
};

export default NewMeal;
