import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  useTheme,
  Button,
  TextField,
  Switch,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import { getSettings, updateSettings } from "../../api/getSettingsApi";
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: green[600],
    '&:hover': {
      backgroundColor: alpha(green[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: green[600],
  },
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };

const GuestGyms = (props) => {
  const theme = useTheme();
  const [settings, setSettings] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getSettings();
        setSettings(fetchedData);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);
  const handleChangeStatus = async (event, settingsId) => {
    // console.log(settingsId)
    try {
      const newStatus = event.target.checked;
      const response = await updateSettings(newStatus, settingsId);
      // Ažurirajte stanje settings u komponenti s novim podacima
      const updatedSettings = settings.map((setting) =>
        setting._id === settingsId ? { ...setting, status: newStatus } : setting
      );
      setSettings(updatedSettings);
    } catch (error) {
      console.error(error);
      // Obradite grešku ako je potrebno
    }
  };
  // const handleChangeStatus = (event, rowId) => {
  //   const updatedSettings = settings.map((setting) => {
  //     if (setting._id === rowId) {
  //       return { ...setting, status: event.target.checked };
  //     }
  //     return setting;
  //   });
  //   setSettings(updatedSettings);
  // };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  // Render funkcija za stupac Status
  const statusRender = (cell) => {
    return (
      <Switch
        {...label}
        onChange={(event) => handleChangeStatus(event, cell.row._id)}
        checked={cell.row.status}
        color="secondary"
        // Stilizacija prekidača može se postići korištenjem PinkSwitch
        // ili prilagoditi klasičnom Switch-u koristeći makeStyles
      />
    );
  };
  


console.log(settings)
  // Render functions for DataGrid columns
  const guestGymUrlRender = (cell) => {
    return  <p>{cell.row.guestGymUrl}</p>;
  };
  

  const guestGymIdRender = (cell) => {
    return <p>{cell.row.guestGymId}</p>;
  };

  const guestNameGymRender = (cell) => {
    return <p>{cell.row.guestNameGym}</p>;
  };
  const columns = [
    { field: 'guestNameGym', headerName: 'Naziv teretane/fitnesa', width: 300, renderCell: guestNameGymRender },
    { field: 'guestGymId', headerName: 'Gym ID', width: 300, renderCell: guestGymIdRender },
    { field: 'guestGymUrl', headerName: 'Gym URL', width: 300, renderCell: guestGymUrlRender },
    { field: 'status', headerName: 'Status', width: 100, renderCell: statusRender },
  ];

  return (
    <Box m="20px">
      <Header title="POSTAVKE" />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/log">
          <Button type="submit" color="secondary" variant="contained" style={{marginRight:10}}>
           Prikaz logova
          </Button>
        </Link>
        <Link to="/postavke/new">
          <Button type="submit" color="secondary" variant="contained">
            Novi gym
          </Button>
        </Link>
      </Box>
      <Box m="40px 0 0 0" height="70vh">
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
          pageSize={9}
          rowsPerPageOptions={[5]}
          rows={settings}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row._id}
        />
      </Box>
    </Box>
  );
};

export default GuestGyms;
