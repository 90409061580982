import React, { useEffect, useState } from 'react';
import { Typography, useTheme, Box, InputLabel, MenuItem, Select, TextField,Button } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@material-ui/core';
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import Header from '../../components/Header';
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import {getAllPaymentByRange} from '../../api/paymentApi'
import {getPaket} from '../../api/paketApi'
// import PieIzvjestajClanovi from '../pie/pieClanoviIzvjestaj';
import SpolClanova from '../../components/PieChartspolaClanova';
import FaktureIzvjestajKomponenta from '../../components/faktureIzvjestajKomponenta';
import FaktureBarChart3 from '../../components/BarChart4';
import UkPrihodOdUplataIzUp from './UkupanPrihodOdUplata';

const IzvjestajUposlenici = () => {
    const theme = useTheme();
const colors = tokens(theme.palette.mode);

const currentYear = new Date().getFullYear();
const startYear = new Date(`01/01/${currentYear}`);

const [startDate, setStartDate] = useState(startYear);
  const [endDate, setEndDate] = useState(new Date());
  // const [status, setStatus] = useState('');
  // const [expiryDays, setExpiryDays] = useState('');
  const [paymentType, setPaymentType] = useState('');
  const [filterUplate, setFiltriraneUplate] = useState([]);
  // console.log(filterUplate)
  const [ukupanPrihodUplate, setUkupanPrihodUplate] = useState('');
  // const [activeMember, setActiveMember] = useState([])
  const [grupisaniPaketi, setGrupisaniPaketi] = useState([])
  const [odgodjenoPlacanje, setUkupnoOdgodjenoPlacanje] = useState([])
  const [clanTrenings, setClanTrening] = useState(0)
  const [membershipStatus, setMembershipStatus] = useState("");
  const [fakturaStatus, setFakturaStatus] = useState("");
  const [paket, setPaket] = useState("");
  const [paketi, setPaketi] = useState([]);
  // const [brojDanaDoIsteka, setBrojDanaDoIsteka] = useState(null);
  const[clanoviSaUplatamSort, setSortiraniClanoviSaUplatam] = useState([])
  const[clanoviKasneSaUplatamSort, setSortiraniKojiKasneSaUplatam] = useState([])
  const [resetFilters, setResetFilters] = useState(false);
  const [izabraneFakture, setIzabraneFakture] = useState([]);
  const [izabranaOsoba, setIzabranaOsoba] = useState('');
  const[grupisaneFaktureZaOsobu, setGrupisaneFaktureZaOsobu] = useState([])
  
// console.log("grupisaneFaktureZaOsobu",grupisaneFaktureZaOsobu)


const handleResetFilters = () => {
  setStartDate(startYear);
  setEndDate(new Date());
  setFakturaStatus('');
  setPaymentType('');
  setPaket('');
  setResetFilters(true);
};

useEffect(()=>{
  if (resetFilters) {
    setFakturaStatus('');
    setPaymentType('');
    setPaket('');
    setResetFilters(false);
  }
    async function fechData (){

    const uplate = await getAllPaymentByRange({
      startDate,
      endDate
    })
    // console.log("uplate", uplate)
    const fachedPaketi = await getPaket()
        setPaketi(fachedPaketi)
// console.log(uplate)

  // filtriranje clanova po datumu i statusu clanarine te preostalom vremenu do isteka
  const filteredUplate = uplate.filter((uplata) => {
    const createdDate = new Date(uplata.createdAt);
    createdDate.setHours(0, 0, 0, 0);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999)
    const dateMatch = createdDate >= startDate && createdDate <= endDate;
    // console.log("dateMatch",dateMatch)
    const statusMatch = fakturaStatus === "" || uplata.status === fakturaStatus;
    const paymentTypeMatch = paymentType === "" || uplata.paymentMethod === paymentType;
    const paketTypeMatch = paket === "" || uplata?.member?.user?.membership?.plan?._id === paket;
    return dateMatch && statusMatch && paymentTypeMatch && paketTypeMatch;
  });
  
  // console.log("filteredUplate",filteredUplate)
  
  //ukupan prihod o uplatama ako nije odgodjeno placanje
  const ukupanPrihod = grupisaneFaktureZaOsobu.reduce((acc, cur) => {
    if (cur.paymentMethod !== "odgodjeno") {
      return acc + cur.amount;
    } else {
      return acc;
    }
  }, 0);

  const ukupanoOdgodjenoPlacanje= grupisaneFaktureZaOsobu.reduce((acc, cur) => {
    if (cur.paymentMethod === "odgodjeno") {
      return acc + cur.amount;
    } else {
      return acc;
    }
  }, 0);
  
  // nakon što se dobiju podaci o uplatama u varijabli "uplate"
// izračunaj ukupne uplate svakog člana
const clanoviSaUplatama = {};
for (const cur of grupisaneFaktureZaOsobu) {
  const idClana = cur.member && cur.member.user ? cur.member.user._id : null;
  const ime = cur.member && cur.member.user ? cur.member.user.firstName : null;
  const prezime = cur.member && cur.member.user ? cur.member.user.lastName : null;
  if (idClana && cur.paymentMethod !== "odgodjeno") {
    if (!clanoviSaUplatama[idClana]) {
      clanoviSaUplatama[idClana] = {
        ime,
        prezime,
        ukupnoUplata: 0,
      };
    }
    clanoviSaUplatama[idClana].ukupnoUplata += cur.amount;
  }
}


// Konvertiraj objekt u polje objekata
const clanoviSaUplatamaArray = Object.values(clanoviSaUplatama);
// Sortiraj polje objekata po svojstvu ukupnoUplata od najvećeg prema najmanjem
clanoviSaUplatamaArray.sort((a, b) => b.ukupnoUplata - a.ukupnoUplata);

const clanoviKojikasneSaUplatama = {};
for (const cur of grupisaneFaktureZaOsobu) {
  const idClana = cur.member && cur.member.user ? cur.member.user._id : null;
  const ime = cur.member && cur.member.user ? cur.member.user.firstName : null;
  const prezime = cur.member && cur.member.user ? cur.member.user.lastName : null;
  if (idClana && cur.paymentMethod === "odgodjeno") {
    if (!clanoviKojikasneSaUplatama[idClana]) {
      clanoviKojikasneSaUplatama[idClana] = {
        ime,
        prezime,
        ukupnoUplata: 0,
      };
    }
    clanoviKojikasneSaUplatama[idClana].ukupnoUplata += cur.amount;
  }
}

// Konvertiraj objekt u polje objekata
const clanoviKojiKasneSaUplatamaArray = Object.values(clanoviKojikasneSaUplatama);
// Sortiraj polje objekata po svojstvu ukupnoUplata od najvećeg prema najmanjem
clanoviKojiKasneSaUplatamaArray.sort((a, b) => b.ukupnoUplata - a.ukupnoUplata);



    // setSortiraniClanoviSaUplatam(clanoviSaUplatamaArray)
    // setSortiraniKojiKasneSaUplatam(clanoviKojiKasneSaUplatamaArray)
    setFiltriraneUplate(filteredUplate)
    setUkupanPrihodUplate(ukupanPrihod)
    setUkupnoOdgodjenoPlacanje(ukupanoOdgodjenoPlacanje)
    // prvo kreirajte objekat koji će sadržati broj članova po paketima
    let packagesCount = {};
// zatim prolazimo kroz objekte iz niza
// console.log(" packageName", filteredUplate)
for (let obj of filteredUplate) {
  // čitamo naziv paketa za trenutni objekat
  const packageName = obj?.member?.user?.membership?.plan?.nazivPaketa.bs;
  // ako paket već postoji u objektu, uvećavamo broj članova za taj paket
  if (packagesCount[packageName]) {
    packagesCount[packageName]++;
  } else {
    // inače, inicijaliziramo broj članova za taj paket na 1
    packagesCount[packageName] = 1;
  }
}
    // console.log(packagesCount)
// sada imamo objekat packagesCount koji sadrži broj članova po paketima
    setGrupisaniPaketi(packagesCount)
    }

    fechData()

     // Filtrirajte fakture za izabranu osobu
  const faktureZaIzabranuOsobu = filterUplate.filter((uplata) => {
    const uredioId = uplata?.associatedClanarinaUredjivanje[0]?.uredio?._id;
    return izabranaOsoba === '' || uredioId === izabranaOsoba;
  });

  // Postavite filtrirane fakture u novi state
  setGrupisaneFaktureZaOsobu(faktureZaIzabranuOsobu);


},[endDate, startDate, membershipStatus, paket, fakturaStatus, paymentType, resetFilters,izabranaOsoba])


const grupisaneFakture = {};

// Iteriramo kroz sve fakture i grupišemo ih po osobama koje su ih uredile.
filterUplate.forEach((faktura) => {
  const uredio = faktura.associatedClanarinaUredjivanje[0]?.uredio; // Uzimamo osobu koja je uredila fakturu
  if (uredio) {
    const uredioId = uredio._id;

    // Ako osoba već postoji u grupisanim fakturama, dodajemo fakturu u njen niz.
    if (grupisaneFakture[uredioId]) {
      grupisaneFakture[uredioId].push(faktura);
    } else {
      // Ako osoba ne postoji u grupisanim fakturama, kreiramo novi niz i dodajemo fakturu.
      grupisaneFakture[uredioId] = [faktura];
    }
  }
});

// const [izabranaOsoba, setIzabranaOsoba] = useState('');
// console.log(izabranaOsoba)
// Sada imate grupisane fakture po osobama koje su ih uredile u 'grupisaneFakture' objektu.
// console.log("grupisaneFakture",grupisaneFakture);
const handleOsobaChange = (event) => {
  const novaIzabranaOsoba = event.target.value;
  setIzabranaOsoba(novaIzabranaOsoba);

  if (novaIzabranaOsoba) {
    // Filtriraj fakture samo za odabranu osobu
    const faktureZaOdabranuOsobu = filterUplate.filter((uplata) => {
      return uplata.member && uplata.member.user && uplata.member.user._id === novaIzabranaOsoba;
    });
    setIzabraneFakture(faktureZaOdabranuOsobu);
  } else {
    // Ako nije izabrana osoba, prikaži sve fakture
    setIzabraneFakture(filterUplate);
  }
};

// console.log("izabraneFakture",izabraneFakture)

  return (

    <Box m="20px">
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <Box>
      <Header title="IZVJESTAJ UPOSLENICI" />
      
      
      <Box>
    <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
    <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
   
           >
 
    </Select>
    <Select
        native
        value={izabranaOsoba}
        onChange={handleOsobaChange}
        inputProps={{
          name: 'osoba',
          id: 'osoba-select',
        }}
      >
        <option value="">Sve osobe</option>
        {Object.keys(grupisaneFakture).map((osobaId) => (
          <option key={osobaId} value={osobaId}>
            {grupisaneFakture[osobaId][0]?.associatedClanarinaUredjivanje[0]?.uredio.firstName}{' '}
            {grupisaneFakture[osobaId][0]?.associatedClanarinaUredjivanje[0]?.uredio.lastName}
          </option>
        ))}
      </Select>


    <FormControl  style={{minWidth:"200px", marginTop:"-20px"}}>
         <InputLabel id="payment-type-label">Paketi</InputLabel>
         <Select
          labelId="payment-type-label"
          id="payment-type"
          value={paket}
          label="Način plaćanja"
          onChange={(e) => setPaket(e.target.value)}
        >
          {/* <MenuItem value="" disabled>Odaberi paket</MenuItem> */}
          {paketi?.map((e, key)=>{
                    return <MenuItem key={key} value={e._id}>{e?.nazivPaketa.bs} -- {e?.cijena}</MenuItem>
                  })
                  }
        </Select>
    </FormControl>
      <FormControl style={{minWidth:"200px", marginTop:"-20px"}}>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
    />
    <DatePicker
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        showTimeSelect={false} // dodano
        showTimeSelectOnly={false} // dodano
        dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
        style={{ display: "inline-block", margin: "0 auto" }}
        />
      
      </FormControl>

  </Box>
  
    </Box>
    <Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleResetFilters} variant="contained" color="secondary">
    Resetuj filtere
  </Button>
</Box>
  </Box>

<Box
  display="grid"
  gridTemplateColumns="repeat(12, 1fr)"
  gridAutoRows="140px"
  gap="20px"
>
  {/* ROW 1 */}

    <Box
    gridColumn="span 3"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    {/* StatBox component */}
    <StatBox
        title={grupisaneFaktureZaOsobu.length}
        subtitle="Broj odobrenih članarina"
        progress="a"
      />
  </Box>
  <Box
    gridColumn="span 3"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <UkPrihodOdUplataIzUp fakture={grupisaneFaktureZaOsobu}/>

  </Box>
  <Box
    gridColumn="span 6"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    {/* FaktureBarChart3 component */}
    <FaktureBarChart3 data={grupisaneFaktureZaOsobu} startDate={startDate}/>
    {/* ... */}
  </Box>

  <Box
    gridColumn="span 12"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    
    <Box
      mt="25px"
      p="0 30px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
          {/* Text */}
        </Typography>
        <Typography variant="h3" fontWeight="bold" color={colors.greenAccent[500]}>
          {/* Text */}
        </Typography>
      </Box>
      <Box>
        {/* Other components */}
      </Box>
    </Box>
    <Box height="250px" m="-40px 0 0 0">
    <FaktureIzvjestajKomponenta data={grupisaneFaktureZaOsobu}/>
      {/* ... */}
    </Box>
  </Box>

</Box>

</Box>


);
};

export default IzvjestajUposlenici;