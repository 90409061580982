import React,{ useState, useEffect,useContext } from "react";
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";

// import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
// import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


// // import ErrorIcon from '@mui/icons-material/Error';
// import { StartDateConverting } from "./DateConverting";

const TripodIzvjestajKomponenta = ({data}) => {
  // const navigate = useNavigate();
  // console.log("datasss", data) 
  const [searchText, setSearchText] = useState("");
  // const [filteredData, setFilteredData] = useState(data);
// console.log("filteredData", filteredData)
  
const handleExportToExcel = () => {
    const exportData = data.map((item) => ({
      Datum_i_vrijeme: item.datumPrijave,
      Uposlenik: item.user.firstName +" "+item.user.lastName,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tripod');
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, `izvjestaj_tripod.xlsx`);
  };
  


  useEffect(() => {
    // const results = data?.filter((d) => {
    //   const member = d?.member;
    //   if (member) {
    //     const user = member.user;
    //     if (
    //       user?.firstName?.toLowerCase().includes(searchText.toLowerCase()) ||
    //       user?.lastName?.toLowerCase().includes(searchText.toLowerCase())
    //     ) {
    //       return true;
    //     }
    //   } else {
    //     // Ako member nije definiran, ovdje možete odlučiti što želite raditi s tim zapisima
    //     // Na primjer, ako želite prikazati zapise bez člana, možete vratiti true ili ih ignorirati.
    //     return true;
    //   }
    //   return false;
    // });
  
    // setFilteredData(results);
  }, [data, searchText]);
  
  const handleSearch = (event) => {
    setSearchText(event.target.value);
  };
  

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }


//Kalkulacija broj dana se obracunava u kompomenti RemainigDays
const datumKreiranja = (cell)=>{
  // const datumUplate = new Date(cell.row.date)
// console.log(cell.row)
const formattedDateTime = new Date(cell.row?.datumPrijave).toLocaleString('en-GB', {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
});

return( 
  <p direction="row" spacing={1}>
   {formattedDateTime}
</p>
)
}
// console.log(filteredData)
const odobrio = (cell)=>{
  // console.log("sss",cell.row)
return( 
  <p direction="row" spacing={1}>
  {cell?.row.user?.firstName + " " +cell?.row.user?.lastName }
</p>
)
}


  const columns = [
    { field: '_id', headerName: 'ID', 
    renderCell: (params) => {
        // return fakturaDetaljRender(params);
    }
    },
    // { field: 'photo', headerName: 'Foto', 
    // renderCell: (params) => {
    //     return fotografijaRender(params);
    // }
    // },
   
    { field: 'datumPrijave', headerName: 'Datum i vrijeme otvaranja tripoda',  width: 400,
    renderCell: (params) => {
        return datumKreiranja(params);
    }
    },
  
    { field: 'oodbrio', headerName: 'Uposlenik', flex: 1,
    renderCell: (params) => {
        return odobrio(params);
    }
    },
 
  ];
  
  return (
    <Box m="px">
      <Box
        m="0px 0 0 0"
        height="65vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
             <Box display="flex" justifyContent="flex-end">
        <TextField
          label="Search"
          variant="outlined"
          value={searchText}
          onChange={handleSearch}
        />
      </Box>
   
<Box
  display="flex"
  justifyContent="flex-end"
  marginTop="50px" // Podesite ovo prema potrebi
  marginRight="10px" // Podesite ovo prema potrebi
>
  <Button onClick={handleExportToExcel} variant="contained" color="secondary">
  Izvezi u Excel
  </Button>
</Box>
      {/* Render the DataGrid using filteredData instead of data */}
      <DataGrid
        checkboxSelection
        pageSize={9}
        rowsPerPageOptions={[5]}
        rows={data}
        columns={columns}
        getRowId={(row) => row._id}
      />

      </Box>
    </Box>
  );
};

export default TripodIzvjestajKomponenta;
