import React,{ useState, useEffect,useContext } from "react";
import { Box, useTheme,} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import StartDateAndTimeConverting from '../components/StartDateAndTimeConverting';


const PosjeteIzvjestajKomponenta = ({data}) => {
// console.log(data)
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  if (!data) {
    return <div>Ucitavam korisnike...</div>;
  }

const userRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell?.row?.user?.firstName +" "+cell?.row?.user?.lastName}
  </p>
  )
}
const poslovnicaRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row?.gymVisited?.naziv}
  </p>
  )
}
const statusRender = (cell)=>{
  return( 
    <p direction="row" spacing={1}>
      {cell.row.timeOut !== null ? "Odjavljen" : "Prijavljen"}
  </p>
  )
}
const fotografijaRender = (cell) => {

  if (!cell?.row || !cell.row.user) {
    return null; // Return null or a placeholder value if user data is undefined
  }

  // const handleClick = () => {
  //   navigate(`/users/${cell.row.user._id}`, { state: cell.row });
  // };
  
  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.user.photo}` }
        src={cell.row.user.photo} 
        style={{width:"75%"}}
        // onClick={handleClick}
      />
    </>
  );
};
const vrijemePrijave = (cell)=>{

return <StartDateAndTimeConverting datetime={cell.row?.timeIn}/>
}
const vrijemeOdjave = (cell)=>{

  return <StartDateAndTimeConverting datetime={cell.row?.timeIn}/>
   }
  const columns = [
    { field: 'photo', headerName: 'Foto', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'user', headerName: 'Clan', 
    renderCell: (params) => {
        return userRender(params);
    }
    },
    { field: 'gymVisited', headerName: 'Poslovnica', 
    renderCell: (params) => {
        return poslovnicaRender(params);
    }
    },
    
    { field: 'timeIn', headerName: 'Vrijeme prijave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemePrijave(params);
    }
    },
  
    { field: 'timeOut', headerName: 'Vrijeme odjave', flex: 1,
    cellClassName: "name-column--cell",
    renderCell: (params) => {
        return vrijemeOdjave(params);
    }
    },
    { field: 'status', headerName: 'Status', 
    renderCell: (params) => {
        return statusRender(params);
    }
    },

  ];
  
  return (
    <Box m="2px">
      <Box
        m="0px 0 0 0"
        height="55vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid 
        // rowHeight={30}
        checkboxSelection 
        pageSize={8}
      rowsPerPageOptions={[5]}
        rows={data} 
        columns={columns} 
        getRowId={(row) => row._id}
        />

      </Box>
    </Box>
  );
};

export default PosjeteIzvjestajKomponenta;
