
import React, {useState, useEffect, PureComponent} from 'react';
// import InputLabel from '@mui/material/InputLabel';
import { Link } from "react-router-dom";
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, Button, TextField } from "@mui/material";
// import CardMedia from '@mui/material/CardMedia';
import useMediaQuery from "@mui/material/useMediaQuery";
// import Header from "../../components/Header";
import { useLocation, useParams } from "react-router-dom";

// import { getMembersByUserId } from '../../api/memberApi';
// import { getUserById } from "../../api/userApi";
// import {getMemberByUserId} from '../../api/memberApi'
// import BarChart2 from '../../components/BarChart2';
// import LineFullChart from '../../components/LineFullChart';
// import UserWeeklyCalendar from './userCalendar';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
} from '@mui/material';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  avatar: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginBottom: theme.spacing(2),
  },
  userDetails: {
    display: 'flex',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  userDetailsText: {
    marginLeft: theme.spacing(2),
  },
  card: {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    borderRadius: 4,
    padding: 16,
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 500,
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    fontSize: 16,
    color: '#888',
    marginBottom: theme.spacing(1),
  },
}));

const ViewNutricionist = () => {
  const { id } = useParams();
  const { state: data } = useLocation();
  console.log("User", data ? data : "User object is null or undefined");

  // const isNonMobile = useMediaQuery("(min-width:600px)");

  // const [user, setUser] = useState("")
  // const [member, setMember] = useState({})
  // const start = new Date(data.user?.membership?.startDate);
  // const startD = start.toLocaleString('en-GB', { timeZone: 'UTC' });

  // const end = new Date(data.user?.membership?.endDate);
  // const endD = end.toLocaleString('en-GB', { timeZone: 'UTC' });
  const classes = useStyles();

  // Dummy podaci za korisnika

  return (
    <Container maxWidth="mx" className={classes.container}>
      <div className={classes.userDetails}>
        <Avatar 
          alt={data?.member.user?.firstName} 
          src={data?.member.user?.photo} 
          className={classes.avatar} 
          sx={{ width: 200, height: 200 }}
        />
        <div className={classes.userDetailsText}>
          <Typography variant="h1" component="h2" gutterBottom>
          {data?.member.user?.firstName} {data?.member.user?.lastName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Datum rodjenja: 
          </Typography>
          <Typography variant="body1" gutterBottom>
            Spol: 
          </Typography>
          <Typography variant="body1" gutterBottom>
            Težina:  kg
          </Typography>
          <Typography variant="body1" gutterBottom>
            Visina:  cm
          </Typography>
          <Typography variant="body1" gutterBottom>
            Telefon:  {data.user?.phone}
          </Typography>
          <Typography variant="body1" gutterBottom>
          Ulica: {data.user?.address}
        </Typography>
        </div>
        <div className={classes.userDetailsText}>
        <Typography variant="h1" component="h2" gutterBottom>
          {/* {data.user.firstName} {data.user.lastName} */}#
          </Typography>
        <Typography variant="body1" gutterBottom>
        Email: {data.user?.email}
        </Typography>
        <Typography variant="body1" gutterBottom>
        Certifikat: {data.certification}
        </Typography>
        <Typography variant="body1" gutterBottom>
        Iskustvo: {data.yearsOfExperience} god.
        </Typography>
        <Typography variant="body1" gutterBottom>
        Specijalizacija: {data.specialization}
        </Typography>
        <Typography variant="body1" gutterBottom>
        {/* Status plaćanja: Plaćeno */}
        </Typography> 
          </div>
      </div>
      <Divider />
      <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle} style={{color:"#141b2e"}}>
              Raspored treninga
                </Typography>
                <Typography variant="body1" className={classes.cardDescription}  style={{color:"#141b2e"}}>
    <Box height="25vh" width={"70vh"}>
        {/* <BarChart2 data={data}/> */}
      </Box>
        </Typography> 
            </CardContent>
          </Card>
        </Grid>
        
       
        <Grid item xs={12} sm={6}>
        <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
 
    <CardContent>
    <Typography variant="h2" component="h3" className={classes.cardTitle}  style={{color:"#141b2e"}}>
      Grupni treninzi
      </Typography>
      <Box height="25vh" width={"70vh"} style={{backgroundColor:"#f0f0f0"}}>

      {data && data.groups && data.groups.length > 0 ? (
        <ol>
          {data.groups.map((groupProgram) => (
            <Typography variant="body1" className={classes.cardDescription}>
            <li key={groupProgram._id}><span style={{fontSize:"17px", color:"black"}}><Link to={`/grupe/${groupProgram._id}`}>{groupProgram.name}</Link></span> - Broj clanova: {groupProgram.members.length}</li>
            </Typography>
          ))}
        </ol>
      ) : (
        <Typography variant="body1" className={classes.cardDescription}>
          No group trainings found.
        </Typography>
      )}
        </Box>
    </CardContent>
    
  
  </Card>
</Grid>
       
      </Grid>
      <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle}  style={{color:"#141b2e"}}>
              Programi
              </Typography>
              <Typography variant="body1" className={classes.cardDescription}  style={{color:"#141b2e"}}>
              <Box height="25vh" width={"70vh"}>
              {/* <UserWeeklyCalendar data={data}/> */}
              </Box>
                  </Typography>   
            </CardContent>
          </Card>
        </Grid>
        {/* Ostatak koda komponenti... */}
        <Grid item xs={12} sm={6}>
          <Card className={classes.card} style={{backgroundColor:"#f0f0f0"}}>
            <CardContent>
              <Typography variant="h2" component="h3" className={classes.cardTitle} style={{color:"#141b2e"}}>
               -----
                </Typography>
                <Typography variant="body1" className={classes.cardDescription}  style={{color:"#141b2e"}}>

    {/* Users should be able to view their payment history and download invoices */}
    <Box height="25vh" width={"70vh"}>
        {/* <BarChart2 data={data}/> */}
      </Box>
        </Typography> 
            </CardContent>
          </Card>
        </Grid>
        {/* Ostatak koda komponenti... */}
      </Grid>
    </Container>
  );
};
export default ViewNutricionist;

