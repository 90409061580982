import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  useTheme,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { tokens } from '../../theme';
// import { mockTransactions } from '../../data/mockData';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import EmailIcon from '@mui/icons-material/Email';
// import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import TrafficIcon from '@mui/icons-material/Traffic';
import Header from '../../components/Header';
// import LineChart from '../../components/LineChart';
// import GeographyChart from '../../components/GeographyChart';
// import BarChart from '../../components/BarChart';
import StatBox from '../../components/StatBox';
// import ProgressCircle from '../../components/ProgressCircle';
// import Inventory2Icon from '@mui/icons-material/Inventory2';
// import EuroIcon from '@mui/icons-material/Euro';
// import Pie from '../pie';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  getAllPosjeteByGym, getAllPosjeteByGymAndDateRange,
  // getAllVisitsByRange,
} from '../../api/attendenceApi';
import { getAllGyms } from '../../api/gymApi';
// import { getAllPaymentByRange } from '../../api/paymentApi';
import BarChartPosjete from '../../components/BarChartPosjete';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import BarChartaverageTimeByDay from '../../components/BarChartaverageTimeByDay';
import PosjeteIzvjestajKomponenta from '../../components/posjeteIzvjestajKomponenta';
import { TailSpin } from 'react-loader-spinner';

const IzvjestajPosjete = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const defaultGym = JSON.parse(localStorage.getItem('defaultGym'));
  const gymID = defaultGym._id;

  const [startDate, setStartDate] = useState(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [gyms, setGyms] = useState([]);
  const [gym, setGym] = useState(gymID || null);
  const [rangePosjete, setRangePosjete] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChangeGym = (event) => {
    setGym(event.target.value);
  };

  //najposjeceniji dan u periodu
  const [busiestDay, setBusiestDay] = useState('');
  const [weekday, setWeekday] = useState('');

  const handleBusiestDay = (busiestDayData) => {
    const { weekday, busiestDay } = busiestDayData;
    setWeekday(weekday);
    setBusiestDay(busiestDay.total);
  };

  useEffect(() => {
    async function fetchGym() {
      try {
        const fetchedGyms = await getAllGyms();
        // const posjete = await getAllPosjeteByGym(gymID);
        const posjete = await getAllPosjeteByGymAndDateRange({
          gymID,
          startDate,
          endDate
        });
        // const filteredVisits = posjete.filter((visit) => {
        //   const visitDate = new Date(visit.timeIn);
        //   // createdDate.setHours(0, 0, 0, 0);
        //   startDate.setHours(0, 0, 0, 0);
        //   endDate.setHours(23, 59, 59, 999)
        //   return visitDate >= startDate && visitDate <= endDate;
        // });
        setRangePosjete(posjete);
        setGyms(fetchedGyms);
        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    }
    fetchGym();
  }, [endDate, gymID, startDate, busiestDay, weekday]);

  const usersWithVisits = rangePosjete.map(({ user, timeIn }) => ({
    userId: user?._id,
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    visits: 1,
    timeIn,
    }));
    
    const users = usersWithVisits.reduce((acc, user) => {
    const existingUser = acc.find(u => u.userId === user.userId);
    if (existingUser) {
    existingUser.visits += 1;
    } else {
    acc.push(user);
    }
    return acc;
    }, []);
    
    const sortedUsers = users.sort((a, b) => b.visits - a.visits);
    
    // console.log(sortedUsers)
    let totalSeconds = 0;
    for (let i = 0; i < rangePosjete.length; i++) {
    const timeIn = new Date(rangePosjete[i].timeIn);
    const timeOut = new Date(rangePosjete[i].timeOut);
    const timeDiff = Math.abs(timeOut - timeIn) / 1000; // divide by 1000 to convert milliseconds to seconds
    totalSeconds += timeDiff;
    }
    const avgSeconds = totalSeconds / rangePosjete.length;
    const avgMinutes = avgSeconds / 60;
    // console.log(`Average time spent in gym: ${avgMinutes.toFixed(2)} minutes`);
    
// Grupiramo posjete po danu
const visitsByDay = rangePosjete.reduce((acc, { timeIn }) => {
  const date = new Date(timeIn).toLocaleDateString();
  if (!acc[date]) {
    acc[date] = [];
  }
  acc[date].push(timeIn);
  return acc;
}, {});

// Računamo prosječno vrijeme po danu
const averageTimeByDay = Object.entries(visitsByDay).map(([date, visits]) => {
  const totalSeconds = visits.reduce((acc, timeIn) => {
    const timeOut = rangePosjete.find(p => p.timeIn === timeIn).timeOut;
    const timeDiff = Math.abs(new Date(timeOut) - new Date(timeIn)) / 1000; // Vrijeme u sekundama
    return acc + timeDiff;
  }, 0);
  const avgSecondsPerVisit = totalSeconds / visits.length;
  const avgSecondsPerDay = avgSecondsPerVisit / 60 / 60; // Vrijeme u satima
  // console.log(avgSecondsPerDay)
  return { date, avgSecondsPerDay };
});

//prosječno trajanje posjeta

const totalSecondsx = rangePosjete.reduce((acc, { timeIn, timeOut }) => {
  const timeDiff = Math.abs(new Date(timeOut) - new Date(timeIn)) / 1000; // Vrijeme u sekundama
  return acc + timeDiff;
}, 0);

const avgSecondsPerVisit = totalSecondsx / rangePosjete.length;
const avgHoursPerVisit = avgSecondsPerVisit / 3600; // Vrijeme u satima
// console.log("avgHoursPerVisit" , avgHoursPerVisit.toFixed());




    return (
      <>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
        </div>
      ) : (
    <Box m="20px">
    <Box display="flex" justifyContent="space-between" alignItems="center">
    <Header title="IZVJESTAJ O POSJETAMA"/>
    <Box>
    <InputLabel id="demo-simple-select-label">Odaberi poslovnicu</InputLabel>
    <Select
             labelId="demo-simple-select-label"
             id="demo-simple-select"
             value={gym}
             onChange={handleChangeGym}
           >
    {gyms?.map((e, key) => (
    <MenuItem key={key} value={e}>
    {e.naziv}
    </MenuItem>
    ))}
    </Select>
    </Box>
    <Box>
    <div>
    <InputLabel id="demo-simple-select-label">Odaberi razdoblje</InputLabel>
    <DatePicker
    selected={startDate}
    onChange={(date) => setStartDate(date)}
    selectsStart
    startDate={startDate}
    endDate={endDate}
    showTimeSelect={false} // dodano
    showTimeSelectOnly={false} // dodano
    dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
    style={{ display: "inline-block", margin: "0 auto" }}
    />
            <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          showTimeSelect={false} // dodano
          showTimeSelectOnly={false} // dodano
          dateFormat="dd/MM/yyyy" // Postavite format datuma na "dd/MM/yyyy"
          style={{ display: "inline-block", margin: "0 auto" }}
        />
      </div>
    </Box>
  </Box>

  {/* GRID & CHARTS */}
  <Box
    display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridAutoRows="140px"
    gap="20px"
  >
    {/* ROW 1 */}
    <Box
      gridColumn="span 3"
      backgroundColor={colors.primary[400]}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <StatBox
        title={rangePosjete.length}
        subtitle="Broj posjeta"
        progress="a"
        // increase={`+${progressPaketi.length}%`}
        icon={
          <HowToRegIcon
            sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
          />
        }
      />
    </Box>
   
<Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
        title={`${weekday} - Broj posjeta: ${busiestDay} `}
        subtitle="Najposjećeniji dan"
        progress="a"
    />
  </Box>
  <Box
  
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
    <StatBox
    title={`${avgHoursPerVisit.toFixed()}h`}
    subtitle="Prosjecno trajanje posjeta"
      progress="a"
    />
  </Box>
  <Box
    gridColumn="span 3"
    backgroundColor={colors.primary[400]}
    display="flex"
    alignItems="center"
    justifyContent="center"
  >
  </Box>
  {/* ROW 2 */}
  <Box
    gridColumn="span 8"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
  >
    <Box
      mt="25px"
      p="0 30px"
      display="flex "
      justifyContent="space-between"
      alignItems="center"
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="600"
          color={colors.grey[100]}
        >
          Broj posjeta u toku mjeseca
        </Typography>
        <Typography
          variant="h3"
          fontWeight="bold"
          color={colors.greenAccent[500]}
        >
      
        </Typography>
      </Box>
      <Box>
        <IconButton>
          <DownloadOutlinedIcon
            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
          />
        </IconButton>
      </Box>
    </Box>
    <Box height="250px" m="-20px 0 0 0">
    <BarChartPosjete data={rangePosjete} handleBusiestDay={handleBusiestDay}/>
    </Box>
  </Box>
 
  <Box
    gridColumn="span 4"
    gridRow="span 2"
    backgroundColor={colors.primary[400]}
    overflow="auto"
  >
    Top 20 posjetitelja
   <ol>
  {sortedUsers.map((user, index) => (
    <li key={index}>
      {user.firstName} {user.lastName} - {user.visits} posjeta
    </li>
  ))}
</ol>
  </Box>
  <Box
          gridColumn="span 12"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          <Box
            mt="25px"
            p="0 30px"
            display="flex "
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Prosječno vrijeme posjeta po danu
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          {/* <Box height="250px" m="-40px 0 0 0">
          <BarChartaverageTimeByDay data={averageTimeByDay}/>
          </Box> */}
          <Box height="350px" m="-40px 0 0 0">
          <PosjeteIzvjestajKomponenta data={rangePosjete}/>
          </Box>
        </Box>
      
</Box>
<p>
</p>
</Box>
)}
</>

);
};

export default IzvjestajPosjete;