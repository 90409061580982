import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ConfigApp from '../config/ConfigApp';

const baseUrl = `${ConfigApp.URL}`;

function LogViewer() {
  const [groupedLogs, setGroupedLogs] = useState({});
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedDayLogs, setSelectedDayLogs] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    async function fetchLog() {
      try {
        const response = await axios.get(`${baseUrl}/logs`);
        const logArray = typeof response.data === 'string' ? 
          response.data.split('\n').map(log => {
            try {
              return JSON.parse(log);
            } catch {
              return null;
            }
          }).filter(log => log !== null) : 
          response.data;

        const logsGroupedByMonthAndDay = logArray.reduce((acc, log) => {
          const date = new Date(log.time);
          const month = date.getMonth() + 1; // Mjeseci počinju od 0
          const day = date.getDate();
          const monthKey = `${date.getFullYear()}-${month}`; // Format: YYYY-M
          const dayKey = `${month}-${day}`; // Format: M-D

          if (!acc[monthKey]) {
            acc[monthKey] = {};
          }
          if (!acc[monthKey][dayKey]) {
            acc[monthKey][dayKey] = [];
          }

          acc[monthKey][dayKey].push(log);
          return acc;
        }, {});

        setGroupedLogs(logsGroupedByMonthAndDay);
      } catch (error) {
        console.error(error);
      }
    }

    fetchLog();
  }, []);

  const formatTime = (epochTime) => {
    const date = new Date(epochTime);
    return date.toLocaleString();
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month === selectedMonth ? '' : month); // Toggle selected month
  };

  const handleDayClick = (day) => {
    setSelectedDayLogs(groupedLogs[selectedMonth][day]);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const filterLogs = (logs) => {
    if (!searchText) return logs;
    return logs.filter(log => log.msg.toLowerCase().includes(searchText.toLowerCase()));
  };

  return (
    <div>
      {/* <TextField
        label="Pretraži logove"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={handleSearchChange}
      /> */}

      {Object.keys(groupedLogs).map((month) => (
        <div key={month} onClick={() => handleMonthClick(month)} style={{ cursor: 'pointer', marginBottom: '5px' }}>
          <h3>Mjesec: {month}</h3>
          {selectedMonth === month && Object.keys(groupedLogs[month]).map((day) => (
            <div key={day} onClick={(e) => {e.stopPropagation(); handleDayClick(day);}} style={{ cursor: 'pointer', marginLeft: '20px' }}>
              Dan: {day}
            </div>
          ))}
        </div>
      ))}

      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <DialogTitle>Logovi za odabrani dan</DialogTitle>
        <DialogContent>
          {filterLogs(selectedDayLogs).map((log, index) => (
            <div key={index} style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
              <div>Level: {log.level}</div>
              <div>Vrijeme: {formatTime(log.time)}</div>
              <div>PID: {log.pid}</div>
              <div>Hostname: {log.hostname}</div>
              <div>Poruka: {log.msg}</div>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary">Zatvori</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default LogViewer;
