const calculateNutrients = (mealKind, gramsFactor) => {
    console.log("mealKindmealKind",gramsFactor)
    const nutrients = {
      calories: 0,
      proteins: 0,
      carbs: 0,
      fats: 0,
      unsaturatedFats: 0,
      fiber: 0,
    };
  
    for (const food of mealKind.foods) {
      const foodGrams = food.grams * gramsFactor;
  
    //   nutrients.calories += food.foodId.calories * (foodGrams / 100);
    //   nutrients.proteins += food.foodId.proteins * (foodGrams / 100);
    //   nutrients.carbs += food.foodId.carbs * (foodGrams / 100);
    //   nutrients.fats += food.foodId.fats * (foodGrams / 100);
    //   nutrients.unsaturatedFats += food.foodId.unsaturatedFatt * (foodGrams / 100);
    //   nutrients.fiber += food.foodId.fiber * (foodGrams / 100);
    nutrients.calories += food.foodId.calories;
    nutrients.proteins += food.foodId.proteins;
    nutrients.carbs += food.foodId.carbs;
    nutrients.fats += food.foodId.fats;
    nutrients.unsaturatedFats += food.foodId.unsaturatedFatt;
    nutrients.fiber += food.foodId.fiber;
    }
  
    return nutrients;
  };

export default calculateNutrients;