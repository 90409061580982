import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/plan`;
// const baseUrl = 'https://jellyfish-app-og32n.ondigitalocean.app/api/plan';

export const createPaket = async (newPaket) => {
  const response = await axios.post(baseUrl, newPaket);
  return response.data;
};

export const getPaket = async () => {
  const response = await axios.get(baseUrl);
  // console.log(response)
  return response.data;
};


export const getPaketWeb = async () => {
  const response = await axios.get(`${baseUrl}/web`);
  // console.log(response)
  return response.data;
};
export const getPaketId = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

// export const createUser = async (newUser) => {
//   const response = await axios.post(baseUrl, newUser);
//   return response.data;
// };

export const updatePaket = async (id, dataPaket) => {
  // console.log("dataPaket", dataPaket);
  const response = await axios.put(`${baseUrl}/${id}`, dataPaket);
  return response.data;
};

// export const deleteUser = async (id) => {
//   const response = await axios.delete(`${baseUrl}/${id}`);
//   return response.data;
// };