import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TextField,
  TableSortLabel,
} from '@material-ui/core';

const METTableModal = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchValue, setSearchValue] = useState('');
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');

  const METData = [
    { activity: 'Hodanje (brzo hodanje, tempo 5 km/h)', MET: 3.8 },
  { activity: 'Trčanje (brzina 8 km/h)', MET: 8.3 },
  { activity: 'Statičko bicikliranje (umjereno)', MET: 6.8 },
  { activity: 'Statičko bicikliranje (brzo)', MET: 8.8 },
  { activity: 'Veslanje na ergometru (umjereno)', MET: 6.0 },
  { activity: 'Veslanje na ergometru (intenzivno)', MET: 7.8 },
  { activity: 'Skakanje užeta', MET: 10.0 },
  { activity: 'Podizanje utega (umjereno)', MET: 3.5 },
  { activity: 'Podizanje utega (intenzivno)', MET: 6.0 },
  { activity: 'Kickboxing', MET: 7.8 },
  { activity: 'Aerobik (umjereno)', MET: 5.5 },
  { activity: 'Aerobik (intenzivno)', MET: 7.4 },
  { activity: 'Plivanje (lagano)', MET: 5.3 },
  { activity: 'Plivanje (umjereno)', MET: 6.0 },
  { activity: 'Plivanje (brzo, intenzivno)', MET: 10.0 },
  { activity: 'Joga', MET: 3.2 },
  { activity: 'Trening snage (lagani)', MET: 4.5 },
  { activity: 'Trening snage (umjereni)', MET: 6.0 },
  { activity: 'Trening snage (intenzivni)', MET: 8.0 },
  { activity: 'Kružni trening', MET: 6.5 },
  { activity: 'Plesanje (umjereno)', MET: 4.0 },
  { activity: 'Plesanje (intenzivno)', MET: 6.0 },
  { activity: 'Plesanje (umjereno)', MET: 4.0 },
  { activity: 'Plesanje (intenzivno)', MET: 6.0 },
  { activity: 'Jogging u mjestu', MET: 6.5 },
  { activity: 'Boksački trening', MET: 8.5 },
  { activity: 'HIIT trening', MET: 9.5 },
  { activity: 'Aqua aerobik', MET: 5.8 },
  { activity: 'CrossFit', MET: 9.0 },
  { activity: 'Penjanje po stijeni', MET: 7.0 },
  { activity: 'Zumba', MET: 6.5 },
  { activity: 'Taekwondo', MET: 8.0 },
  { activity: 'Pilates', MET: 4.0 },
  { activity: 'Ski traka', MET: 7.5 },
  { activity: 'Razgibavanje (lagano)', MET: 2.5 },
  { activity: 'Razgibavanje (intenzivno)', MET: 4.0 },
  { activity: 'Plyometrijski trening', MET: 8.5 },
    { activity: 'Kardio trening (visokog intenziteta)', MET: 9.5 },
    { activity: 'Bicikliranje na otvorenom (umjereno)', MET: 7.0 },
    { activity: 'Bicikliranje na otvorenom (brzo)', MET: 10.0 },
    { activity: 'Rolanje/Trčanje na rolerima', MET: 7.5 },
    { activity: 'Kickboxing trening na vreći', MET: 8.0 },
    { activity: 'Pliometrijski trening s vijačom', MET: 9.0 },
    { activity: 'Kickboxing trening s partnerom', MET: 7.8 },
    { activity: 'Bodyweight trening', MET: 6.5 },
    { activity: 'Tabata trening', MET: 9.0 },
    { activity: 'Vježbe za trbušnjake', MET: 5.0 },
    { activity: 'Vježbe za leđa', MET: 4.5 },
    { activity: 'Vježbe za ramena', MET: 4.0 },
    { activity: 'Vježbe za prsa', MET: 5.0 },
    { activity: 'Vježbe za noge', MET: 5.5 },
    { activity: 'Vježbe za biceps', MET: 3.5 },
    { activity: 'Vježbe za triceps', MET: 3.5 },
    { activity: 'Vježbe za gluteus', MET: 4.5 },
    { activity: 'Cardio kickboxing', MET: 8.0 },
    { activity: 'Vježbe za kardio kondiciju', MET: 6.5 },
    { activity: 'Vježbe za fleksibilnost', MET: 3.0 },
    { activity: 'Vježbe za stabilizaciju', MET: 4.0 },
    { activity: 'Powerlifting', MET: 6.5 },
    { activity: 'Strongman trening', MET: 7.5 },
    { activity: 'Kettlebell trening', MET: 7.0 },
    { activity: 'Stretching', MET: 3.0 },
    { activity: 'Tae Bo', MET: 7.2 },
    { activity: 'Body Combat', MET: 7.6 },
    { activity: 'Cardio trening na stepperu', MET: 7.5 },
    { activity: 'Bodyweight HIIT trening', MET: 9.2 },
    { activity: 'Bicikliranje na sobnom biciklu', MET: 6.5 },
    { activity: 'Kombinacija kardio i treninga snage', MET: 7.8 },
    { activity: 'Yoga HIIT', MET: 6.8 },
    { activity: 'Vježbe za ravnotežu', MET: 3.5 },
    { activity: 'Sklekovi', MET: 6.0 }, 
    { activity: 'Vježbe za zadnjicu', MET: 4.2 },
  { activity: 'Vježbe za trbušne mišiće na lopti', MET: 4.5 },
  { activity: 'Bicikliranje na sobnom biciklu (brzo)', MET: 8.0 },
  { activity: 'Vježbe za noge na spravi', MET: 5.2 },
  { activity: 'Vježbe za ramena i trapeze', MET: 4.0 },
  { activity: 'Preskakanje konopca', MET: 9.0 },
  { activity: 'Trbušnjaci na klupi', MET: 3.8 },
  { activity: 'Vježbe za unutarnje bedrene mišiće', MET: 3.7 },
  { activity: 'Vježbe za donji dio leđa', MET: 4.2 },
  { activity: 'Jutarnje istezanje', MET: 2.0 },
  { activity: 'Razgibavanje zglobova', MET: 2.5 },
  { activity: 'Brzo hodanje na traci', MET: 5.5 },
  { activity: 'Trening s vlastitom težinom', MET: 6.2 },
  { activity: 'Trening s TRX trakama', MET: 6.8 },
  { activity: 'Plesni trening', MET: 5.2 },
  { activity: 'Trening s bulgom', MET: 4.5 },
  { activity: 'Stolni tenis', MET: 4.0 },
  { activity: 'Vježbe za leđa na spravi', MET: 4.2 },
  { activity: 'Zagrijavanje prije treninga', MET: 2.5 },
  { activity: 'Vježbe za grudi na spravi', MET: 4.5 },
  { activity: 'Bicikliranje u prirodi (brzo)', MET: 9.0 },
  { activity: 'Trčanje po stepenicama', MET: 12.0 },
  { activity: 'Aerobik na stepenicama', MET: 8.0 },
  { activity: 'Vježbe za listove', MET: 3.8 },
  { activity: 'Trening s utezima', MET: 6.5 },
  { activity: 'Hodanje uzbrdo', MET: 5.5 },
  { activity: 'Vježbe za ruke na spravi', MET: 4.0 },
  { activity: 'Plivanje delfin stilom', MET: 12.0 },
  { activity: 'Izlasci na stepenice', MET: 5.0 },
  { activity: 'Skakanje s mjesta', MET: 8.0 },
  { activity: 'Vježbe za triceps na spravi', MET: 4.2 },
{ activity: 'Plivanje kraul stilom', MET: 10.0 },
{ activity: 'Vježbe za bicepse na spravi', MET: 4.0 },
{ activity: 'Rolanje po ravnom', MET: 6.0 },
{ activity: 'Vježbe za kukove', MET: 3.5 },
{ activity: 'Sklekovi s nogama na podignutoj površini', MET: 7.0 },
{ activity: 'Vježbe za ramena s bučicama', MET: 4.2 },
{ activity: 'Vježbe za trbušne mišiće na vijači', MET: 4.8 },
{ activity: 'Kardio trening na veslačkom ergometru', MET: 8.5 },
{ activity: 'Vježbe za donji dio trbuha', MET: 4.0 },
{ activity: 'Plivanje leđno', MET: 7.0 },
{ activity: 'Vježbe za kvadricepse na spravi', MET: 4.5 },
{ activity: 'Vježbe za stražnjicu na spravi', MET: 4.2 },
{ activity: 'Skakanje na kutiji', MET: 7.5 },
{ activity: 'Vježbe za listove na spravi', MET: 3.5 },
{ activity: 'Brzo trčanje na traci', MET: 10.0 },
{ activity: 'Aerobik na sobnom biciklu', MET: 7.2 },
{ activity: 'Vježbe za leđa s bučicama', MET: 4.2 },
{ activity: 'Pliometrijski trening s bučicama', MET: 7.0 },
{ activity: 'Vježbe za ruke s elastičnom trakom', MET: 3.8 },
{ activity: 'Vježbe za trbušne mišiće s loptom', MET: 4.2 },
{ activity: 'Plivanje prsno', MET: 8.0 },
{ activity: 'Vježbe za kukove na spravi', MET: 3.8 },
{ activity: 'Boks na vreći', MET: 8.5 },
{ activity: 'Razgibavanje na stolici', MET: 2.0 },
{ activity: 'Skakanje na trambolini', MET: 6.5 },
{ activity: 'Vježbe za ramena s elastičnom trakom', MET: 3.5 },
{ activity: 'Vježbe za triceps s bučicama', MET: 4.0 },
{ activity: 'Plivanje leptir stilom', MET: 13.0 },
{ activity: 'Vježbe za prsa na spravi', MET: 4.2 },
{ activity: 'Vježbe za bicepse s elastičnom trakom', MET: 3.5 },
{ activity: 'Plesne aerobik vježbe', MET: 5.0 },
{ activity: 'Vježbe za triceps s elastičnom trakom', MET: 3.5 },
{ activity: 'Pilates trening', MET: 4.2 },
{ activity: 'Vježbe za stražnjicu s elastičnom trakom', MET: 3.8 },
{ activity: 'Aerobik na sobnom biciklu s intervalima', MET: 8.2 },
{ activity: 'Vježbe za ramena s bučicama', MET: 4.2 },
{ activity: 'Vježbe za trbušne mišiće s bučicama', MET: 4.5 },
{ activity: 'Plivanje prsno s daskom', MET: 7.2 },
{ activity: 'Vježbe za noge s elastičnom trakom', MET: 4.0 },
{ activity: 'Vježbe za ruke na kabelu', MET: 4.2 },
{ activity: 'Pilates na lopti', MET: 3.8 },
{ activity: 'Vježbe za stražnjicu s bučicama', MET: 4.0 },
{ activity: 'Cardio trening na sobnom biciklu s intervalima', MET: 8.5 },
{ activity: 'Vježbe za trbušne mišiće na klupi', MET: 3.8 },
{ activity: 'Vježbe za leđa na kabelu', MET: 4.2 },
{ activity: 'Bicikliranje na stacionarnom biciklu s otporom', MET: 6.5 },
{ activity: 'Vježbe za prsa na kabelu', MET: 4.2 },
{ activity: 'Vježbe za bicepse na kabelu', MET: 4.0 },
{ activity: 'Plivanje delfin stilom s daskom', MET: 11.0 },
{ activity: 'Vježbe za triceps na kabelu', MET: 4.0 },
{ activity: 'Aerobik na stepenicama s intervalima', MET: 8.2 },
{ activity: 'Vježbe za kvadricepse s bučicama', MET: 4.2 },
{ activity: 'Vježbe za zadnjicu s elastičnom trakom', MET: 4.0 },
{ activity: 'Kardio trening na veslačkom ergometru s intervalima', MET: 9.0 },
{ activity: 'Vježbe za listove s bučicama', MET: 3.5 },
{ activity: 'Trening s kettlebellom', MET: 7.0 },
{ activity: 'Vježbe za kukove s elastičnom trakom', MET: 3.8 },
{ activity: 'Hodanje po traci s nagibom', MET: 5.8 },
{ activity: 'HIIT trening s vlastitom težinom', MET: 9.0 },
{ activity: 'Vježbe za trbušne mišiće s vijačom', MET: 4.8 },
{ activity: 'Plivanje kraul stilom s daskom', MET: 10.0 },
{ activity: 'Vježbe za noge na stepenastoj klupi', MET: 4.5 },
{ activity: 'Vježbe za ramena s elastičnom trakom', MET: 3.8 },
{ activity: 'Pliometrijski trening s bučicama', MET: 7.0 },
{ activity: 'Vježbe za triceps s bučicama', MET: 4.0 },
{ activity: 'Plivanje leptir stilom s daskom', MET: 12.0 },
{ activity: 'Vježbe za prsa na spravi s kabelom', MET: 4.2 },
{ activity: 'Vježbe za bicepse s elastičnom trakom', MET: 3.5 },
{ activity: 'Plesne aerobik vježbe s loptom', MET: 5.2 },
{ activity: 'Vježbe za triceps s kružnim tegovima', MET: 4.0 },
{ activity: 'Pilates trening s loptom', MET: 4.2 },
{ activity: 'Vježbe za stražnjicu s elastičnom trakom', MET: 3.8 },
{ activity: 'Aerobik na sobnom biciklu s visokim otporom', MET: 7.8 },
{ activity: 'Vježbe za ramena s bučicama i elastičnom trakom', MET: 4.0 },
{ activity: 'Vježbe za trbušne mišiće s bučicama i loptom', MET: 4.2 },
{ activity: 'Plivanje prsno s daskom i rukavicama', MET: 7.5 },
{ activity: 'Vježbe za noge s elastičnom trakom i bučicama', MET: 4.2 },
{ activity: 'Vježbe za ruke s elastičnom trakom i bučicama', MET: 4.0 },

    // Dodajte ostale aktivnosti i njihove MET brojeve

  ];



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setPage(0);
  };

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedData = METData.sort((a, b) => {
    if (sortColumn) {
      if (a[sortColumn] < b[sortColumn]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[sortColumn] > b[sortColumn]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
    }
    return 0;
  });

  const filteredData = sortedData.filter((data) =>
    data.activity.toLowerCase().includes(searchValue.toLowerCase())
  );

  return (
    <div>
      <h2>Tablica vježbanja za MET</h2>
      <TextField
        label="Pretraži aktivnosti"
        variant="outlined"
        value={searchValue}
        onChange={handleSearchChange}
        style={{ marginBottom: 10 }}
      />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'activity'}
                  direction={sortColumn === 'activity' ? sortDirection : 'asc'}
                  onClick={() => handleSort('activity')}
                >
                  Aktivnost
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortColumn === 'MET'}
                  direction={sortColumn === 'MET' ? sortDirection : 'asc'}
                  onClick={() => handleSort('MET')}
                >
                  MET
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {data.activity}
                </TableCell>
                <TableCell align="right">{data.MET}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50
          ]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </TableContainer>
          </div>
          );
          };

export default METTableModal;
