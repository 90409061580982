import axios from 'axios';
import ConfigApp from '../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}/treneri`;
// const baseUrl = 'http://localhost:3001/api/treneri';

export const getAllTrener = async () => {
  const response = await axios.get(baseUrl);
  return response.data;
};

export const getTrenerById = async (id) => {
  const response = await axios.get(`${baseUrl}/${id}`);
  return response.data;
};

export const createTrener = async (newTrener) => {
  const response = await axios.post(baseUrl, newTrener);
  // console.log(newTrener)
  return response.data;
};

export const updateTrener = async (id, updatedUser) => {
  const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
  return response.data;
};

export const deleteTrener = async (id, queryParameters) => {
  const response = await axios.delete(`${baseUrl}/${id}`, { params: queryParameters });
  // const response = await axios.delete(`${baseUrl}/${id}`);
  return response.data;
};


// export const updateDefaultGym = async (userId, gymId) => {
//   // const response = await axios.put(`${baseUrl}/${id}`, updatedUser);
//   // console.log(userId, gymId)
// }