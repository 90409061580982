import React, { useState, useEffect,useContext } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
    Container,
    Typography,
    Card,
    CardContent,
    Avatar,
    Divider,
    IconButton, Box, Button, TextField, Modal, Zoom, FormControlLabel, FormControl, FormLabel, RadioGroup, Radio
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ConfigApp from '../../config/ConfigApp';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import { getUserById, updateUser } from '../../api/userApi';
import { UserContext } from '../../context/UserProvider'; 
const baseUrl = `${ConfigApp.URL}`;

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
    marginBottom:'100px'
  },
  inField: {
    backgroundColor: "#ffffff14"
  }
}));

const EditTrainer = () => {
  const classes = useStyles();
  const { id } = useParams();

  const { state: data } = useLocation();
//   console.log(data)
  const { user} = useContext(UserContext);
  const [clan, setUser] = useState({
    firstName: data.member.user.firstName,
    lastName: data.member.user.lastName,
    email: data.member.user.email,
    // contact: data.user.contact,
    address: data.member.user.address,
    // password: "",
    photo: data.member.user.photo,
    // date_of_birth: data.date_of_birth,
    // gender: data.gender,
    // weight: data.weight,
    // height: data.height,
    phone: data.member.user.phone,
  });

  const [loading, setLoading] = useState(true);
  const [weight, setWeight] = useState(data.member.weight);
  const [height, setHeight] = useState(data.member.height);
  const [gender, setGender] = useState(data.member.gender);
  const [dateOfBirth, setDateOfBirth] = useState(data.member.date_of_birth);
  const [selectedImage, setSelectedImage] = useState(data.member.user.image); // Initialize with the existing user's photo
  const [pendingImage, setPendingImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');

  //trener
  const [specialization, setSpecialization] = useState(data.specialization);
  const [certification, setCertification] = useState(data.certification);
  const [yearsOfExperience, setYearsOfExperience] = useState(data.yearsOfExperience);
// console.log("password",password)
  // Funkcija za konverziju datuma u format "YYYY-MM-DD"
const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Dodajte nule ispred meseca ako je potrebno
    const day = String(date.getDate()).padStart(2, '0'); // Dodajte nule ispred dana ako je potrebno
    return `${year}-${month}-${day}`;
  };

  
  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      setSelectedImage(imageFile);
      setPendingImage("1");
    }
  };

  useEffect(() => {
    async function getUserData() {
      try {
        setLoading(true);
        const response = await axios.get(`${baseUrl}/users/${id}`);
        setUser(response.data);
        setSelectedImage(clan.photo);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
    getUserData();
  }, [id, clan?.photo]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append('firstName', clan.firstName);
    formData.append('lastName', clan.lastName);
    formData.append('email', clan.email);
    formData.append('contact', clan.contact);
    formData.append('address', clan.address);
    formData.append('password', password);
    // formData.append('photo', user.photo);
    // formData.append('gymId', user.gymId);
    // formData.append('plan', user.plan);
    formData.append('gender', gender);
    formData.append('role', clan.role);
    formData.append('weight', weight);
    formData.append('height', height);
    formData.append('date_of_birth', dateOfBirth);
    formData.append('image', selectedImage);
    formData.append('uposlenik', user.firstName + " " + user.lastName);
      
    // Trainer details
       formData.append('specialization', specialization);
       formData.append('certification', certification);
       formData.append('yearsOfExperience', yearsOfExperience);
   
    try {
      const response = await axios.put(`${baseUrl}/users/${id}`, formData);
      setUser(response.data.user);
    //   console.log(response.data)
      toast.success('User information updated successfully.');
    } catch (error) {
      console.error(error);
      toast.error('An error occurred while updating user information.');
    }
  };
// console.log(data)
  return (
    <Grid container justify="center" style={{ height: '100vh' }}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Grid item xs={6}>
          <form className={classes.form} onSubmit={handleSubmit}>
            <Header title="Uredi trenera" subtitle="" />
            <ToastContainer />
            {typeof selectedImage === "string" ? (
            <Avatar
                alt={clan.firstName}
                src={selectedImage}
                className={classes.avatar}
                sx={{ width: 200, height: 200 }}
            />
            ) : (
            <Avatar
                alt={clan.firstName}
                src={selectedImage ? URL.createObjectURL(selectedImage) : ""}
                className={classes.avatar}
                sx={{ width: 200, height: 200 }}
            />
            )}

            <label htmlFor="upload-photo">
              <input
                type="file"
                id="upload-photo"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <IconButton component="span">
                <AddPhotoAlternateIcon style={{ color: 'white' }} />
              </IconButton>
            </label>
            <TextField
              className={classes.inField}
              label="First Name"
              value={clan.firstName}
              onChange={(event) => setUser({ ...clan, firstName: event.target.value })}
              variant="outlined"
              required
            />
            <TextField
              label="Last Name"
              className={classes.inField}
              value={clan.lastName}
              onChange={(event) => setUser({ ...clan, lastName: event.target.value })}
              variant="outlined"
              required
            />
            <TextField
              className={classes.inField}
              label="Email"
              type="email"
              value={clan.email}
              onChange={(event) => setUser({ ...clan, email: event.target.value })}
              variant="outlined"
              required
            />
            {/* <TextField
              label="Contact"
              className={classes.inField}
              value={user.contact}
              onChange={(event) => setUser({ ...user, contact: event.target.value })}
              variant="outlined"
              required
            /> */}
            <TextField
              label="Adresa"
              className={classes.inField}
              value={clan.address}
              onChange={(event) => setUser({ ...clan, address: event.target.value })}
              variant="outlined"
            />
            {/* <TextField
              label="Password"
              className={classes.inField}
              type="password"
              value={user.password}
              onChange={(event) => setUser({ ...user, password: event.target.value })}
              variant="outlined"
            /> */}
        <TextField
  label="Password"
  className={classes.inField}
  type={showPassword ? 'text' : 'password'}
  value={password || ''}
  onChange={(event) => setPassword(event.target.value)}
  variant="outlined"
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
        >
          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
           <TextField
            className={classes.inField}
            label="Datum rodjenja"
            type="date"
            value={formatDate(dateOfBirth)} // Formatirajte datum pre prikazivanja
            InputLabelProps={{
            shrink: true,
            }}
            onChange={(event) => setDateOfBirth(event.target.value)}
            variant="outlined"
        />

            <FormControl component="fieldset">
              <FormLabel component="legend">Spol</FormLabel>
              <RadioGroup
                aria-label="gender"
                name="gender"
                value={gender}
                onChange={(event) => setGender(event.target.value)}
                style={{color:"white"}}
              >
                <FormControlLabel value="female" control={<Radio style={{color:"white"}}/>} label="Female" />
                <FormControlLabel value="male" control={<Radio style={{color:"white"}}/>} label="Male" />
                <FormControlLabel value="other" control={<Radio style={{color:"white"}}/>} label="Other" />
              </RadioGroup>
            </FormControl>
            <TextField
                className={classes.inField}
                label="Težina"
                type="number" // Promijenite type u "number"
                value={weight}
                onChange={(event) => setWeight( event.target.value )}
                variant="outlined"
                />

            <TextField
                className={classes.inField}
                label="Visina"
                type="number" // Promijenite type u "number"
                value={height}
                onChange={(event) => setHeight(event.target.value)}
                variant="outlined"
                />

            <TextField
              className={classes.inField}
              label="Telefon"
              type="text"
              value={clan.phone}
              onChange={(event) => setUser({ ...clan, phone: event.target.value })}
              variant="outlined"
            />
            <TextField
              label="Specializacija"
              name="specialization"
              value={specialization}
              onChange={(event) => setSpecialization(event.target.value)}
            //   onChange={handleSpecializationChange}
              variant="outlined"
            />
            <TextField
              label="Certifikati"
              name="certification"
              value={certification}
              onChange={(event) => setCertification(event.target.value)}
            //   onChange={handleCertificationChange}
              variant="outlined"
            />
            <TextField
              label="Godine iskustva"
              name="yearsOfExperience"
              type="number"
              value={yearsOfExperience}
              onChange={(event) => setYearsOfExperience(event.target.value)}
            //   onChange={handleYearsOfExperienceChange}
              variant="outlined"
            />
          
            <Button type="submit" variant="contained" color="secondary">
              Ažuriraj trenera
            </Button>
          </form>
        </Grid>
      )}
    </Grid>
  );
};

export default EditTrainer;
